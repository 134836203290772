import React, { useEffect, useState } from "react";

function LocationMarker () {

const [direction,setDirection] = useState(true);
const [width,setWidth] = useState(80);
const [margin,setMargin] = useState(10);


useEffect(() => {

    // animation variables change every 100 milliseconds
    const interval = setInterval(() => {
        
        if(direction==true){
           
            if(width<60){
                setDirection(false)
            }else{
                setDirection(true)
            }
            setWidth(width - 2);
            setMargin(margin + 1);
        }else{
            if(width>80){
                setDirection(true)
            }else{
                setDirection(false)
            }
            setWidth(width + 2);
            setMargin(margin - 1);
        }
        
      }, 100);

      //console.log("WIDTH: ",`${width}px`)
      //console.log("MARGIN: ",`${margin}px`)
      //console.log("Direction: ", direction)

      return () => clearInterval(interval);

      
})

return (
      <div style={{cursor: 'pointer', width:'40px', height:'40px', backgroundColor:'white', borderRadius:'30px', }}>
        <button
            style={{ height:`${width}%`, width:`${width}%`,backgroundColor:'#4B286D', borderStyle:'none', margin:`${margin}%`, borderRadius:'30px' }}
        >
        </button> 
      </div>
);
  
};

  export default LocationMarker;