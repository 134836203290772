
import {user_frame_reducer} from './userFrameReducer.js'
import { house_reducer } from './houseReducer.js';

import { combineReducers, createStore } from 'redux'

const combine = combineReducers({
    user_frame_reducer,
    house_reducer
});

export const store = createStore(combine, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())