import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../../redux/actions/userFrameActions'

function ButtonJoined({}) {

  const userFrame = useSelector(state=>state.user_frame_reducer[0])
  const houses = useSelector(state=>state.house_reducer)
  const dispatch = useDispatch()

  function handleClick(buttonNumber){
    
    const frame = userFrame;
    frame.menuTab = buttonNumber
    dispatch(updateUserFrame(frame))
    
      
  }

  return(

    <div style={{height:'38px', width:'80%', backgroundColor:userFrame.darkMode?'#54595F':'white', borderBottom:'solid 4px', borderColor:'lightgrey'}}>
        
        
        <a onClick={() => handleClick(1)} style={{padding:'5px',marginLeft:'15px',marginRight:'15px', borderBottom:'solid 4px', borderColor:userFrame.menuTab==1?(userFrame.darkMode?'white':'#4B286D'):'lightgrey'}}>
        <h1 style={{display:'inline-block', fontSize:'23px', color:userFrame.menuTab==1?(userFrame.darkMode?'white':'#4B286D'):(userFrame.darkMode?'lightgrey':'grey') }}>Progress</h1>
        </a>
        
        &nbsp;
        <a onClick={() => handleClick(3)} style={{padding:'5px',marginLeft:'15px',marginRight:'15px', borderBottom:'solid 4px', borderColor:userFrame.menuTab==3?(userFrame.darkMode?'white':'#4B286D'):'lightgrey'}} >
        <h1 style={{display:'inline-block', fontSize:'23px', color:userFrame.menuTab==3?(userFrame.darkMode?'white':'#4B286D'):(userFrame.darkMode?'lightgrey':'grey') }}>Follow Up</h1>
        </a>

        &nbsp;
        <a onClick={() => handleClick(2)} style={{padding:'5px',marginLeft:'15px',marginRight:'15px', borderBottom:'solid 4px', borderColor:userFrame.menuTab==2?(userFrame.darkMode?'white':'#4B286D'):'lightgrey'}}>
        <h1 style={{display:'inline-block', fontSize:'23px', color:userFrame.menuTab==2?(userFrame.darkMode?'white':'#4B286D'):(userFrame.darkMode?'lightgrey':'grey')}}>Settings</h1>
        </a>
    </div>

  )

}

export default ButtonJoined;