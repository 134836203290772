import { ThemeProvider } from "styled-components";

/*
This House object holds all the state variables (globally) for each premise (similar to object oriented programming - but for javascript!). 
It keeps track of house icon, notes, location, and other customer details. Each House object is stored in the global redux store array of 
House objects. Each time a change is dispatched to the redux store, it forces a re-render (using useSelector()) so that everything looks 
and runs smoothly.

Most of these variables are initialized in MapContainer.js with the dummy data (pre-api)
*/
class House{

    icon; // String - icon type used in switch statement to show png images
    notes; // String - premise notes made by reps
    interactions; // Deprecated
    followUpDate; // String or Date() object - set in Follow-Up.js in the map and list views to keep track of premise follow ups
    followUpPhone; // String
    followUpEmail; // String
    followUpName; // String 
    followUpTimeMin; // String
    followUpTimeAMPM; // String
    followUpTimeHour; // String

    latitude; // Float - keeps track of premise location
    longitude; // Float
    houseId; // Integer - keeps track of house index in array
    title; // Deprecated
    customerNewOrExisting; // Deprecated
    address; // String
    existingProducts; // Array of strings
    loggedKnocks; // Array of booleans (t/f) - keep track of 'other knocks' selected in ButtonPill.js

    changed; 
    copperAvail; // True/False - Premise technology indicator in map/list views below the address
    fibreAvail; // True/False 
    copperCurrent; // True/False  
    fibreCurrent; // True/False 
    knockHistory; // Array of knock objects defined and initialized in the ConfirmBox.js
    details; // Array of premise details defined/initialized in the MapContainer.js under the dummy data, seen in map/list views
    dropRequired; // True or false - seen in address section of map/list views
    selfInstall; // True or false - seen in address section of map/list views

    initialized; // True or False - ensures that a house is not initialized more than once

    expand; // True/False - keeps track of the size of the premise tile in the list view

    existingCustomer; // True/False - keeps track of Marker color on map in Marker.js

    noOneHomeCounter; // Integer - keeps track of how many times 'no one home' has been knocked. If > 3, show icon, otherwise show outlined house with no icon. ConfirmBox.js handles the counter.

    westernPremise; // True/False - handles the technology type and which products are not available (grey)
    ruralTech; // True/False - handles the type of internet product to display
    dueDate; // String - A date in the adddress card
    
    offer; // String[] - array of offers selected

    constructor(i){
        this.icon = 'house';
        this.notes = "Copper technology, eligible for fibre, drop not required";
        this.interactions = true;
        this.followUpDate = null;
        this.followUpPhone = "";
        this.followUpEmail = "";
        this.followUpName = "";
        this.followUpTimeHour = "1";
        this.followUpTimeMin = "00";
        this.followUpTimeAMPM = "PM";
        this.longitude = 49.23041;
        this.latitude = -123.1336;
        this.houseId = i+1;
        //this.title = "Copper technology, eligible for fibre, drop not required";
        //this.customerNewOrExisting = 0;
        this.address = "123 Address Road";
        this.existingProducts = [false,false,false,false,false,false,false,false];
        this.loggedKnocks=[false,false,false,false,false,false,false,false,false,
            false,false,false,false,false,false,false,false,
            false,false,false,false,false,false,false];

        this.changed=false;
        this.copperCurrent=true;
        this.fibreCurrent=false;
        this.copperAvail=true;
        this.fibreAvail=false;
        this.dropRequired = false;
        this.selfInstall = false;
        this.knockHistory = [{value:'No One Home', date:'01/09/21', time:'4:18pm'},{value:'Come Back', date:'01/03/22', time:'11:23am'},{value:'Covid', date:'01/23/22', time:'1:17pm'}]
        this.details = [{key:'Existing', value:'HP, Internet 150, PikTv '},{key:'Available', value:'Fibre, Copper'},{key:'COID', value:'AB87H90'},{key:'FSA', value:'4081A'}]
        this.initialized = false;

        this.expand = false;

        this.existingCustomer = false;
        
        this.noOneHomeCounter = 0;

        this.westernPremise = false;
        this.ruralTech = false;
        this.dueDate = "";

        this.offer = [];
    }

    
    changeSomething(){
        this.changed=true;
        setTimeout(()=>{
            this.changed=false;
        }, 100);
    }
    
}

export {House};