import React, { useState } from "react";

import Button from '@tds/core-button'
import Input from '@tds/core-input'
import Box from '@tds/core-box'
import TextButton from '@tds/core-text-button'

import plant from '../../../icons/images/salal_branch.png';
import telus from '../../../icons/telus/purple.png';

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../redux/actions/userFrameActions'

function SignIn({}) {

  const userFrame = useSelector(state=>state.user_frame_reducer[0])
  const houses = useSelector(state=>state.house_reducer)
  const dispatch = useDispatch()


  function handleClick(){

    const frame = userFrame;
    frame.screenDepth = 2
    dispatch(updateUserFrame(frame))
    
  }


  return (
    
      <div style={{position: 'absolute', top:0, left:0, height:'100%', width:'100%', overflowY:'scroll'}}>

        {/* Header */}
        <div style={{position: 'absolute', top:0, left: 0, height:'15%', width:'100%', backgroundColor:'#4B286D', overflowY:'hidden'}}>
            <Box horizontal={7} vertical={2}>
                <img src={telus} width="90px" />
            </Box>
        </div>

        {/* Login Form */}
        <div style={{position: 'absolute', top:'18%', left: '0',height:'60%', width:'100%', backgroundColor:'white'}}>

            <div style={{position: 'absolute', top:'10%', left: '10%',height:'33%', width:'80%', }}>
            <Box between={3}>
                <Input label="Username" defaultValue="" />
                
                <Input type="password" label="Password" defaultValue="" />
                <br></br>
                <Button onClick={() => handleClick()}>
                    Login
                </Button>
                <br></br>
                <TextButton onClick={() => alert('Forgot Password Screen')}>
                    Forgot Password
                </TextButton>
            </Box>
            </div>
        </div>
        
        {/* Row Image */}
        <div style={{ position:'absolute', bottom: '0', right: '0', width:'100%'}}>
            <img src={plant} width="200px" style={{position:'absolute', bottom: '0', right: '0', zIndex:-1}}/>
        </div>

      </div>
  );
}

export default SignIn;