import React, { Component,useState, useEffect  } from 'react';
import Hamburger from 'hamburger-react'

import ButtonJoined from './ButtonJoined';
import ButtonBack from "./ButtonBack";

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../../redux/actions/userFrameActions'

function Header({}) {

  const userFrame = useSelector(state=>state.user_frame_reducer[0])
  const houses = useSelector(state=>state.house_reducer)
  const dispatch = useDispatch()

  function menuHasBeenClicked(){
    const frame = userFrame;
    frame.menu = !frame.menu
    dispatch(updateUserFrame(frame))
  }

  return (

    <div style = {{width: '100%',height:'100%',backgroundColor:userFrame.darkMode?'#2A2C2E':'white', display:'flex', alignItems:'center',justifyContent:'center'}}>
        {/*  Back Button  */}
        <div style={{ backgroundColor:userFrame.darkMode?'#2A2C2E':'white', height: '100%', overflowY:'hidden',width:'10%', display:'flex', alignItems:'center',justifyContent:'center'}}>
          {userFrame.menu?
              <></>
            :
              <ButtonBack/>
          }
        </div>

        {userFrame.menu?
          /* Show Menu */
          <div style={{ backgroundColor:userFrame.darkMode?'#2A2C2E':'white', height: '100%', overflowY:'hidden',width:'80%', display:'flex', alignItems:'center',justifyContent:'center'}}>
              <h1 style={{color:userFrame.darkMode?'white':'#4B286D', fontSize:'27px'}}>Menu</h1>
          </div>
        :
          /*  View Button Selection  */
          <div style={{ backgroundColor:userFrame.darkMode?'#2A2C2E':'white', height: '100%', overflowY:'hidden',width:'80%', display:'flex', alignItems:'center',justifyContent:'center'}}>
            <ButtonJoined />
          </div>
        }

        {/*  Menu Button  */}
        <div style={{ backgroundColor:userFrame.darkMode?'#2A2C2E':'white', height: '100%', overflow:'hidden', width:'10%', }}>
            <div style={{position:'absolute', top:0, right:'15px',width:'15%', height:'100%', backgroundColor:userFrame.darkMode?'#2A2C2E':'white',display:'flex', alignItems:'center',justifyContent:'center'}}>
              <Hamburger color={userFrame.darkMode?'white':'#4B286D'} toggle={menuHasBeenClicked} toggled={userFrame.menu}/>
            </div>
        </div>
         
        
    </div>

  );
}

export default Header;
