import React, { useEffect, useRef, useState } from "react";

import Card from '@tds/core-card'
import Box from "@tds/core-box";
import Heading from "@tds/core-heading";
import Paragraph from "@tds/core-paragraph";
import Button from "@tds/core-button";

import { useSelector, useDispatch } from 'react-redux';
import { updateHouse } from '../../../../redux/actions/houseActions'
import { updateUserFrame } from '../../../../redux/actions/userFrameActions'
import Hamburger from "hamburger-react";
import { AlarmClock, CallForward, Compass, Information, Watch, ArrowDown, ChatSupport, Check, Calendar, ServiceTruck, PhoneHome, LocationMap, Globe1, Phone, CallTalking, Fingerprint, Warranty, Mobility, HomeSecurity, SecurityHouse, Lightbulb, Camera, SecurityCamera, PrivateCloud, TVChoiceAndFlexibility, Speed, SpeedReduced, DataLimit, Internet, Devices, Channels, Diamond, Layers, Router, LockOpened, } from "@tds/core-decorative-icon";

function ProductDetails({ }) {

  const userFrame = useSelector(state => state.user_frame_reducer[0])
  const houses = useSelector(state => state.house_reducer)
  const dispatch = useDispatch()

  const [clickOutside, setClickOutside] = useState()
  const ref = useRef(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        exit()
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [ref]);

  function exit() {

    const u = userFrame
    u.showProductDetails = false;
    dispatch(updateUserFrame(u))

  }

  function switchIcon(iconName, size, inverted) {

    switch (iconName) {
      case 'clock':
        return <AlarmClock size={size} variant={inverted ? "inverted" : 'default'} />
      case 'support':
        return <ChatSupport size={size} variant={inverted ? "inverted" : 'default'} />
      case 'fall':
        return <ArrowDown size={size} variant={inverted ? "inverted" : 'default'} />
      case 'gps':
        return <Compass size={size} variant={inverted ? "inverted" : 'default'} />
      case 'info':
        return <Information size={size} variant={inverted ? "inverted" : 'default'} />
      case 'appleWatch':
        return <Watch size={size} variant={inverted ? "inverted" : 'default'} />
      case 'lanyard':
        return <Check size={size} variant={inverted ? "inverted" : 'default'} />
      case 'calendar':
        return <Calendar size={size} variant={inverted ? "inverted" : 'default'} />
      case 'van':
        return <ServiceTruck size={size} variant={inverted ? "inverted" : 'default'} />
      case 'homePhone':
        return <PhoneHome size={size} variant={inverted ? "inverted" : 'default'} />
      case 'country':
        return <Globe1 size={size} variant={inverted ? "inverted" : 'default'} />
      case 'phone':
        return <Phone size={size} variant={inverted ? "inverted" : 'default'} />
      case 'callTalking':
        return <CallTalking size={size} variant={inverted ? "inverted" : 'default'} />
      case 'iphone':
        return <Mobility size={size} variant={inverted ? "inverted" : 'default'} />
      case 'fingerprint':
        return <Fingerprint size={size} variant={inverted ? "inverted" : 'default'} />
      case 'tools':
        return <Warranty size={size} variant={inverted ? "inverted" : 'default'} />
      case 'checkBadge':
        return <HomeSecurity size={size} variant={inverted ? "inverted" : 'default'} />
      case 'boxUp':
        return <SecurityHouse size={size} variant={inverted ? "inverted" : 'default'} />
      case 'lightbulb':
        return <Lightbulb size={size} variant={inverted ? "inverted" : 'default'} />
      case 'camera':
        return <SecurityCamera size={size} variant={inverted ? "inverted" : 'default'} />
      case 'houseLock':
        return <PrivateCloud size={size} variant={inverted ? "inverted" : 'default'} />
      case 'control':
        return <TVChoiceAndFlexibility size={size} variant={inverted ? "inverted" : 'default'} />
      case 'location':
        return <LocationMap size={size} variant={inverted ? "inverted" : 'default'} />
      case 'speed':
        return <Speed size={size} variant={inverted ? "inverted" : 'default'} />
      case 'wifiArrows':
        return <Internet size={size} variant={inverted ? "inverted" : 'default'} />
      case 'data':
        return <DataLimit size={size} variant={inverted ? "inverted" : 'default'} />
      case 'devices':
        return <Devices size={size} variant={inverted ? "inverted" : 'default'} />
      case 'switch':
        return <LockOpened size={size} variant={inverted ? "inverted" : 'default'} />
      case 'receiver':
        return <Router size={size} variant={inverted ? "inverted" : 'default'} />
      case 'essentials':
        return <Channels size={size} variant={inverted ? "inverted" : 'default'} />
      case 'premium':
        return <Diamond size={size} variant={inverted ? "inverted" : 'default'} />
      case 'theme':
        return <Layers size={size} variant={inverted ? "inverted" : 'default'} />
      default:
        return <Check size={size} variant={inverted ? "inverted" : 'default'} />
    }

  }

  function getDetails() {

    const products = userFrame.products
    var offerToShow;
    var productToShow;
    products.filter((product) => {
      product.offers.filter((offer) => {
        if (offer.id == userFrame.offerDetailsToShow) {
          offerToShow = offer;
          productToShow = product;
        }
      })
    })

    const offerDetails = offerToShow.details.map((detail, i) => {
      return (
        <div key={i} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', marginTop: '20px', }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '30px', marginRight: '10px', }}>
            {switchIcon(detail.icon, 24, userFrame.darkMode ? true : false)}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', }}>
            <h1 style={{ fontSize: '17px', fontWeight: 'bold', color: userFrame.darkMode ? '#D8D8D8' : "#4B286D" }}>{detail.description}</h1>
            <h1 style={{ fontSize: '17px', color: userFrame.darkMode ? '#D8D8D8' : "#4B286D" }}>{detail.finePrint}</h1>
          </div>
        </div>
      )
    })

    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', }}>
        <div ref={ref} style={{ width: '55%', maxHeight: '70%', backgroundColor: userFrame.darkMode ? '#2A2C2E' : 'white', borderRadius: '5px', overflowY: 'scroll', overflowX: 'none' }}>
          
          { productToShow.type == 'Phone' &&
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', width: '90%', marginTop: '40px', marginLeft:'40px'}}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', }}>
              <h1 style={{ fontSize: '28px', color: userFrame.darkMode ? '#D8D8D8' : "#4B286D", fontWeight: 'bold' }}>
                {offerToShow.desc}
              </h1>
            </div>
          </div>}
          
          <div style={{ display: 'inline-block', width: '90%', height: '60px' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', width: '90%', height: '60px', paddingLeft: '40px', paddingTop: '40px' }}>
              <h1 style={{ fontSize: '28px', color: userFrame.darkMode ? '#D8D8D8' : "#4B286D" }}>
                What's included
              </h1>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '80%', marginLeft: '60px', marginBottom: '40px' }}>
            {offerDetails}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div style={{ position: 'absolute', left: '0', top: '0', width: '100%', height: '100%', zIndex: 100000, backgroundColor: 'rgba(125, 125, 125, 0.3)' }}>
      {getDetails()}
    </div>
  );
}

export default ProductDetails;