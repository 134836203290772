


import {House} from '../model/House'

export const house_reducer = (oldHouseList=[],action) =>{
    if(action.type === "CREATE_HOUSE"){
        
        return [...oldHouseList,action.payload.house]

    }else if(action.type === "DELETE_HOUSE"){
        
        oldHouseList = oldHouseList.filter((ele,index)=>action.payload.house.id !== ele.id);
        return oldHouseList
        
    }else if(action.type === "RESET"){
       
        oldHouseList = []
        return [...oldHouseList]

    }else if(action.type === "UPDATE_HOUSE"){
        
        oldHouseList[action.payload.house.houseId-1] = action.payload.house
        return [...oldHouseList]

    }
    return oldHouseList
}
