import React, { useState, useEffect,} from 'react';

import Box from '@tds/core-box'
import Input from '@tds/core-input'
import Heading from '@tds/core-heading'

import ButtonRectangle from '../SubComponents/ButtonRectangle';

import whiteArrowUp from '../../../../icons/arrow/up/white.png';
import whiteArrowDown from '../../../../icons/arrow/down/white.png';
import IconHouse from '../SubComponents/IconHouse';
import ButtonHouse from '../SubComponents/ButtonHouse';
import plant from '../../../../icons/images/salal_branch.png';
import ButtonMic from '../SubComponents/ButtonMic';
import HairlineDivider from '@tds/core-hairline-divider'
import ProductBar from '../SubComponents/ProductBarVariant';

import up from '../../../../icons/arrow/up/purple.png';
import down from '../../../../icons/arrow/down/purple.png';
import upW from '../../../../icons/arrow/up/white.png';
import downW from '../../../../icons/arrow/down/white.png';
import back from '../../../../icons/back/purple/back.png';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import ButtonPill from '../SubComponents/ButtonPill';

import { Internet, HomeSecurity,OnlineSecurity, Phone, Deals, Delivery, Clipboard, ArrowUp } from '@tds/core-decorative-icon'

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../../redux/actions/userFrameActions'
import FollowUp from '../SubComponents/FollowUp';
import OtherKnocks from '../SubComponents/OtherKnocks';
import ShowDetails from '../SubComponents/ShowDetails';

function BottomBar({}) {

  const userFrame = useSelector(state=>state.user_frame_reducer[0])
  const houses = useSelector(state=>state.house_reducer)
  const dispatch = useDispatch()

  const [click,setClicked] = useState(false)

  const expand = '145px'
  const collapse = '25px'

  const expanded = '5%'
  const collapsed = '5%'

  function notesText(event){
    
    const h = houses[userFrame.houseId-1]
    h.notes = event.target.value
    dispatch(updateHouse(h))

  }


  function setFollowUpButtonClicked(value){
    
    const frame = userFrame
    frame.followingUp=value
    dispatch(updateUserFrame(frame))
  }

    function setOrderButtonClicked(value) {
        //console.log(value)
    }

    function expandCollapse(value){
        setClicked(value)
    }

    function captureLead(value) {
        alert('Capture Lead Screen')
      }
    
      function collectDrop(value) {
        alert('Collect Drop Screen')
      }

    function placeOrder(value) {
        //const h = houses
        //h[userFrame.houseId-1].existingCustomer = !h[userFrame.houseId-1].existingCustomer
        //dispatch(updateHouse(h))  
        const u = userFrame
        u.showPCO = true;
        dispatch(updateUserFrame(u)) 
    }

    function otherKnocksPopup(){

        if(userFrame.loggingKnock && houses[userFrame.houseId-1].icon != 'DNK'){
    
            return(
                <OtherKnocks/>
            )
        }
        
    }

  function followUpPopup(){

    if(userFrame.followingUp && houses[userFrame.houseId-1].icon != 'DNK'){
 
      return(
        <FollowUp/>
      )
    }
      
  }

  function disablePremise() {
    if(houses[userFrame.houseId-1].icon == 'DNK'){
      return(
          <div style={{width:'100%', height:'100%'}}>                
            <div style={{position:'absolute', left:'0', top:'0', width:'100%', height:'100%', zIndex:6, backgroundColor:'rgba(125, 125, 125, 0.3)', boxShadow:`0 0 10px 10px rgba(125,125,125,0.7)`  }}>
                <div style={{marginTop:'25%', width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <h1 style={{color:userFrame.darkMode?'white':'#C12335', fontSize:'30px', fontWeight:'bold', textAlign:'center', textShadow:'0px 0px 15px #FFF'}}>Do Not Knock</h1>
                </div>
            </div>
          </div>

      )
    }
}

function showDetailsPopup(){

    if(userFrame.showDetails && houses[userFrame.houseId-1].icon != 'DNK'){
 
      return(
        <ShowDetails/>
      )
    }
      
  }

function showDetails() {
    const u = userFrame;
    u.showDetails = true;
    dispatch(updateUserFrame(u))
}


function isExpanded(nba){
    for(let i = 0; i < nba.length; i++){
      if(nba[i].expand == true){
        return true
      }
    }
    return false
  }

  function renderIcon(icon){
    switch(icon){
      case 'internet':
        return <Internet variant={userFrame.darkMode?'inverted':"default"} size={16} />
      case 'online':
        return <OnlineSecurity variant={userFrame.darkMode?'inverted':"default"} size={16} />
      case 'phone':
        return <Phone variant={userFrame.darkMode?'inverted':"default"} size={16} />
      case 'home':
        return <HomeSecurity variant={userFrame.darkMode?'inverted':"default"} size={16} />
      default:
        return <Internet variant={userFrame.darkMode?'inverted':"default"} size={16} />         
    }
  }

  function expandCollapseNBA(index) {
    
    const frame = userFrame
    let newDynamicNBA = frame.dynamicNBA
    if(newDynamicNBA[index].expand == false){
      for(let i = 0; i<newDynamicNBA.length; i++){
        if(index == i){
          newDynamicNBA[i].expand = true
        }else{
          newDynamicNBA[i].expand = false
        }
      }
    }else{
      newDynamicNBA[index].expand = false
    }

    if(isExpanded(newDynamicNBA)==true){
      frame.redeem = 'Redeem'
    }else{
      frame.redeem = 'Place Order'
    }

    frame.dynamicNBA = newDynamicNBA
    dispatch(updateUserFrame(frame))
  }

  return (

    
        <div style={{height:'100%', top:0, left:0,width:'100%', overflowY:'scroll'}}>
           
        <div>
        
        
        {otherKnocksPopup()}
        {followUpPopup()}
        {showDetailsPopup()}
        {disablePremise()}

        {/* Details Side Card - Drawer clicked */}

        <div style={{position:'absolute', top: '0', height:'100%', width:'100%',backgroundColor:userFrame.darkMode?'#2A2C2E':'white', overflowY:'scroll'}}>
        
            <div style={{ height:'115px',width:'100%',backgroundColor:userFrame.darkMode?'#2A2C2E':'white', display: 'block', overflowY:'hidden'}}>
                    
                    <div style={{position:'absolute', top:'0', left:'0',marginTop:'40px',marginLeft:'10%', width:'90%',height:'105px', backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}>
                        
                        <div style={{position:'absolute', top:'1em', left:'0', width:'60px', backgroundColor:userFrame.darkMode?'#2A2C2E':'white', height:'95px', }}>
                            <div style={{display:'flex', justifyConent:'center', alignItems:'center', height:'100%', width:"100%"}}>
                                <IconHouse icon={houses[userFrame.houseId-1].icon}/>
                            </div>
                        </div> 
                        <div style={{position:'absolute', top:'0', left:'60px',height:'105px', width:'70%',backgroundColor:userFrame.darkMode?'#2A2C2E':'white',overflowY:'scroll', }}>
                        <div style={{display:'flex', justifyConent:'center', alignItems:'center', height:'100%', width:"100%"}}>
                            <a onClick={()=>showDetails()}>
                                <h1 style={{color:userFrame.darkMode?'white':'#4B286D', fontWeight:'bold'}}>{houses[userFrame.houseId-1].address}&nbsp;{">"}</h1>
                                <h1 style={{color:userFrame.darkMode?'white':'#2B8000', fontSize:'12px',overflow:'hidden',textOverflow:'ellipsis', whiteSpace:'nowrap',}}>
                                {houses[userFrame.houseId-1].westernPremise==true ? 
                                    <>
                                    {houses[userFrame.houseId-1].copperAvail == false ? "" : (houses[userFrame.houseId-1].copperCurrent == false ? <span style={{color:userFrame.darkMode?'white':'#FFA500'}}>Copper Eligible</span> : "Existing Copper,")}
                                    {houses[userFrame.houseId-1].copperAvail == false && houses[userFrame.houseId-1].fibreAvail == false? (<span style={{color:userFrame.darkMode?'white':'#C12335'}}>Copper Not Available,</span>):""}
                                    {houses[userFrame.houseId-1].fibreAvail == false ? (<span style={{color:userFrame.darkMode?'white':'#C12335'}}>&nbsp;Fibre Not Available</span>) : (houses[userFrame.houseId-1].fibreCurrent == false ? <span style={{color:userFrame.darkMode?'white':'#FFA500'}}>&nbsp;Fibre Eligible</span> : " Fibre")}
                                    {houses[userFrame.houseId-1].dropRequired == true ? <span style={{color:userFrame.darkMode?'white':'#FFA500'}}>&nbsp;- Drop Required</span>:""}
                                    {houses[userFrame.houseId-1].selfInstall == true ? <span style={{color:userFrame.darkMode?'white':'#FFA500'}}>&nbsp;- FFH Self Install</span>:""}
                                    </>
                                :
                                    <>
                                    {Math.floor(Math.random() * 10) > 4 ? '5G':'LTE'}
                                    </>}
                                </h1> 
                                <h1 style={{color:userFrame.darkMode?'white':'#2B8000', fontSize:'12px',overflow:'hidden',textOverflow:'ellipsis', whiteSpace:'nowrap',}}>
                                    Next available due date -
                                    {" "+houses[userFrame.houseId-1].dueDate} 
                                </h1>
                            </a>
                        </div>
                        </div>
                    </div>
                    
            </div> 

            <div style={{height:click?expanded:collapsed, width:'100%', display:'block', backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}></div>    
            
            <div style={{ width: '80%', height:'5px', display:'block', marginLeft: '10%',backgroundColor:userFrame.darkMode?'#2A2C2E':'white', zIndex:1000}}>
                <HairlineDivider gradient />
            </div>

            <div style={{height:click?expanded:collapsed, width:'100%', display:'block', backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}></div>    

            <div style={{ display:'block',height:'155px',marginLeft: '10%', width:'90%',backgroundColor:userFrame.darkMode?'#2A2C2E':'white', display: 'block', overflowY:'hidden'}}>  
                <ProductBar />
            </div>
            
            <div style={{height:click?expanded:collapsed, width:'100%', display:'block', backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}></div>   

            <div style={{ width: '80%', height:'5px', display:'block', marginLeft: '10%',backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}>
                <HairlineDivider gradient />
            </div>
            
            <div style={{height:click?expanded:collapsed, width:'100%', display:'block', backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}></div>   

            
            <div style={{display:'block',height: '100px', width:'100%', backgroundColor:userFrame.darkMode?'#2A2C2E':'white', alignItems:'center', justifyContent:'center', overflowY:'hidden'}}>
           
              <div style={{ marginLeft:'10%', height: '100px', width: '80%', backgroundColor: userFrame.darkMode ? '#2A2C2E' : 'white', overflowY: 'hidden', display: 'inline-block' }}>
                <h1 style={{ marginTop: '15px', color: userFrame.darkMode ? '#D8D8D8' : '#4B286D', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontWeight: 'bold' }}>
                  Sell Decision
                </h1>
                <h1 style={{ color: userFrame.darkMode ? '#D8D8D8' : 'black', fontSize: '14px' }}>
                  <span style={{ color: userFrame.darkMode ? 'white' : '#2B8000' }}>1.</span> Qualified for Altima Bell upto Internet GB
                </h1>
                <h1 style={{ color: userFrame.darkMode ? '#D8D8D8' : 'black', fontSize: '14px' }}>
                  <span style={{ color: userFrame.darkMode ? 'white' : '#2B8000' }}>2.</span> M&H Bounty - Sell FFH
                </h1>
                <h1 style={{ color: userFrame.darkMode ? '#D8D8D8' : 'black', fontSize: '14px' }}>
                  <span style={{ color: userFrame.darkMode ? 'white' : '#2B8000' }}>3.</span> Offer to exchange ADT lawn sign to TELUS sign
                </h1>
              </div>
                {/*<h1 style={{top:'10%', marginLeft: '10%', display:'inline-block', color:userFrame.darkMode?'white':'black', fontWeight:'bold', zIndex:100}}>Sell Decision</h1>
                <div style={{ top:'15%', height:'270px', paddingBottom:'10px',backgroundColor:userFrame.darkMode?'#2A2C2E':'white', color:userFrame.darkMode?'white':'black', overflowY:'scroll',  zIndex:100}}>
                {/*<p style={{fontSize:'12px'}}>
                    1. Renew Internet and offer 1GB upgrade &nbsp;
                    <Internet variant={userFrame.darkMode?"inverted":"default"} size={24} />
                    <br></br>
                    2. Add Home Security for bundle discount &nbsp;
                    <HomeSecurity variant={userFrame.darkMode?"inverted":"default"} size={24} />
                    <br></br>
                    3. Offer TELUS Online Security &nbsp;
                    <OnlineSecurity variant={userFrame.darkMode?"inverted":"default"} size={24} />
                    <br></br>
                    4. Add mobile for bundle discount &nbsp;
                    <Phone variant={userFrame.darkMode?"inverted":"default"} size={24} />
                </p>
                {userFrame.dynamicNBA.map((offer,i)=>{
                        return(
                          <div key={i} onClick={()=>expandCollapseNBA(i)} style={{width:'90%', height:offer.expand?'100px':'50px', borderRadius:'5px',marginLeft:'5%',marginTop:'10px', boxShadow:userFrame.darkMode?'none':'1px 1px 3px 1px #9E9E9E', backgroundColor:userFrame.darkMode?'#54595F':'white'}}>
                            <div style={{display:'inline-block', width:'10%',height:'50px'}}>
                              <div style={{height:'100%', width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                {renderIcon(offer.icon)}
                              </div>
                            </div>
                            <div style={{display:'inline-block', width:'70%',height:'50px', verticalAlign:'top'}}>
                              <div style={{height:'50px', width:'100%',  display:'flex', justifyContent:'left', alignItems:'center',}}>
                                <p style={{fontSize:'14px', fontWeight:'bold'}}>
                                  {offer.title}
                                </p>
                              </div>
                            </div>
                            <div style={{display:'inline-block', width:'20%',height:'50px', verticalAlign:'top'}}>
                              <div style={{height:'100%', width:'100%', display:'flex', justifyContent:'left', alignItems:'center',}}>
                                <h1 style={{top:'10%', marginLeft: '5px', display:'inline-block', color:userFrame.darkMode?'white':'#4B286D', fontSize:'12px', cursor:'pointer'}}>
                                {offer.expand?"Less":"More"}
                                </h1>
                                <div style={{display:'inline-block', top:'10%',marginLeft: '1px',cursor:'pointer' }}>
                                  <img style={{ marginBottom:'-5px'}} src={offer.expand?(userFrame.darkMode?whiteArrowUp:up):(userFrame.darkMode?whiteArrowDown:down)} width="20px"/> 
                                </div>
                              </div>
                            </div>
                            {offer.expand && 
                            <div style={{height:'50px', width:'90%',  display:'flex', justifyContent:'left', alignItems:'start', paddingLeft:'15px'}}>
                                <p style={{fontSize:'14px'}}>
                                  {offer.details}
                                </p>
                            </div>}
                          </div>)
                      })}
                    </div>*/}
            </div>
           

            <div style={{height:click?expanded:collapsed, width:'100%', display:'block', backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}></div>    

            <div style={{ display:'block',height:'58px', width:'100%',backgroundColor:userFrame.darkMode?'#2A2C2E':'white', display: 'block', overflowY:'hidden'}}>  
                <div style={{marginLeft:'10%'}}>
                    <button
                            style={{ 
                            paddingTop: '7px', paddingBottom: '7px', paddingLeft: '45px', paddingRight: '45px',
                            color: 'white', fontWeight: 'bold', borderColor:userFrame.darkMode?'#54595F':'#2B8000', cursor:'pointer',
                            borderRadius:'5px', borderWidth: '1px', width:'90%', 
                            borderStyle: 'solid', backgroundColor: userFrame.darkMode?'#54595F':'#2B8000' }}
                            onClick={() => placeOrder(true)}
                        >
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', height:'100%'}}>
                        <Deals variant={'inverted'} size={32}/>
                        <h1 style={{marginLeft:'20px'}}>{userFrame.redeem}</h1>
                        </div>
                    </button> 
                    {/*<ButtonRectangle text='Capture Lead' color={userFrame.darkMode?'#D8D8D8':'#2B8000'} onButtonClicked = {captureLead}/>*/}
                </div>  
            </div>

            <div style={{height:click?expanded:collapsed, width:'100%', display:'block', backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}></div>    

            <div style={{ display:'block',height:'58px', width:'100%',backgroundColor:userFrame.darkMode?'#2A2C2E':'white', display: 'block', overflowY:'hidden'}}>  
                <div style={{marginLeft:'10%'}}>
                     <button
                          style={{ 
                          paddingTop: '7px', paddingBottom: '7px', paddingLeft: '45px', paddingRight: '45px',
                          color: userFrame.darkMode? '#54595F':'white', fontWeight: 'bold', borderColor:userFrame.darkMode?'#D8D8D8':'#4B286D', cursor:'pointer',
                          borderRadius:'5px', borderWidth: '1px', width:'90%', 
                          borderStyle: 'solid', backgroundColor: userFrame.darkMode?'#D8D8D8':'#4B286D' }}
                          onClick={() => setFollowUpButtonClicked(true)}
                        >
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', height:'100%'}}>
                          <ArrowUp variant={userFrame.darkMode?'alternative':'inverted'} size={32}/>
                          <h1 style={{marginLeft:'20px'}}>Schedule Follow Up</h1>
                        </div>
                      </button>
                   {/*} <ButtonRectangle text='Schedule Follow Up' color={userFrame.darkMode?'#54595F':'#4B286D'} onButtonClicked = {setFollowUpButtonClicked}/>*/}
                </div>  
            </div>

            <div style={{height:click?expanded:collapsed, width:'100%', display:'block', backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}></div>   

            <div style={{ width: '80%', height:'5px', display:'block', marginLeft: '10%',backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}>
                <HairlineDivider gradient />
            </div>
            <div style={{height:click?expanded:collapsed, width:'100%', display:'block', backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}></div>   

            <div style={{display:'block', height:'150px', width:'100%',backgroundColor:userFrame.darkMode?'#2A2C2E':'white', overflowY:'hidden', }}>
           
                <h1 style={{display:'block',marginLeft: '10%', width:'90%', color:userFrame.darkMode?'white':'black', fontWeight:'bold', zIndex:100}}>Knocks</h1>
              
                <div style={{ zIndex:100,marginLeft: '10%',}}>                
                    <h1 style={{ fontSize: '12px', color:userFrame.darkMode?'white':'black', display:'inline-block'}}>Last Knock: </h1>
                    <h1 style={{ fontSize: '12px', color:userFrame.darkMode?'white':'#2B8000', display:'inline-block'}}>&nbsp;{houses[userFrame.houseId-1].knockHistory[houses[userFrame.houseId-1].knockHistory.length - 1].value}, </h1>
                    <h1 style={{ fontSize: '12px', color:userFrame.darkMode?'white':'#4B286D', display:'inline-block'}}>&nbsp;{houses[userFrame.houseId-1].knockHistory[houses[userFrame.houseId-1].knockHistory.length - 1].date}&nbsp;{houses[userFrame.houseId-1].knockHistory[houses[userFrame.houseId-1].knockHistory.length - 1].time} </h1>
                </div>

                <div style={{ height:'100px', width:'90%', marginLeft:'5%', backgroundColor:userFrame.darkMode?'#2A2C2E':'white', overflowY:'hidden', display:'inline-block', }}>
                    <div style={{width:'100%', height:'100%',display:'inline-block', backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}>  
                        <div style={{width:'90%', marginLeft:'5%', height:'100%', display:'flex', alignItems:'center', justifyContent:'space-between'}}>

                            <div style={{width:'16.5%'}}>
                                <div>
                                <ButtonHouse title = {"No One Home"} icon = {'NOH'}   />
                                </div>
                            </div> 
                            <div style={{width:'16.5%'}}>
                                <div>
                                <ButtonHouse title = {"Soft No"} icon = {'SN'} extra={window.innerWidth>600?false:true}/>
                                </div>
                            </div>
                            <div style={{width:'16.5%'}}>
                                <div>
                                <ButtonHouse title = {"Hard No"} icon = {'HN'} extra={window.innerWidth>600?false:true} />
                                </div>
                            </div> 
                            <div style={{width:'16.5%'}}>
                                <div>
                                <ButtonHouse title = {"Wrong party"} icon = {'WP'} extra={window.innerWidth>600?false:true} />
                                </div>
                            </div>
                            <div style={{width:'16.5%'}}>
                                <div>
                                <ButtonHouse title = {"Not Interested"} icon = {'NI'} extra={window.innerWidth>600?false:true}  />
                                </div>
                            </div> 
                            <div style={{width:'16.5%'}}>   
                                <div>
                                <ButtonHouse title = {"Other Knocks"} icon = {'OK'} extra={window.innerWidth>600?false:true}  />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>

            
            <div style={{height:click?expanded:collapsed, width:'100%', display:'block', backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}></div>   
            <div style={{ width: '80%', height:'5px', display:'block', marginLeft: '10%',backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}>
                <HairlineDivider gradient />
            </div>
            <div style={{height:click?expanded:collapsed, width:'100%', display:'block', backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}></div>   

            <div style={{display:'block', height:'85px', width:'80%', marginLeft:'10%', overflow:'visible'}}>
                <label style={{width:'100%', display:'inline-block', color:userFrame.darkMode?'white':'black', fontWeight:'bold', overflow:'visible'}}>
                    Notes
                    <br></br>
                    <div style={{width:'85%', display:'inline-block',}}>
                    <textarea value={houses[userFrame.houseId-1].notes} onChange={notesText} 
                        style={{ width:'93%',height:'60px', resize:'none', outlineWidth:'1px', borderRadius:'5px',
                         borderColor:userFrame.darkMode?'#2A2C2E':'#4B286D', color:userFrame.darkMode?'white':'#4B286D', 
                         outlineColor:userFrame.darkMode?'#2A2C2E':'#4B286D', backgroundColor:userFrame.darkMode?'#54595F':'white', 
                         fontSize:'12px', fontWeight:'400', padding:'17px', outlineStyle:'none',}}/>
                    </div>
                    <div style={{width:'12%', display:'inline-block', overflow:'visible',}}>   
                        <div style={{display:'flex', alignItems:'center', justifyContent:'right'}}>
                            <ButtonMic/>
                        </div>   
                    </div>
                </label>
            </div>

            <div style={{height:click?expanded:collapsed, width:'100%', display:'block', backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}></div>   

            <div style={{ width: '80%', height:'5px', display:'block', marginLeft: '10%',backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}>
                <HairlineDivider gradient />
            </div>

            <div style={{height:click?expanded:collapsed, width:'100%', display:'block', backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}></div>   

            <div style={{ display:'block',height:'58px', width:'100%',backgroundColor:userFrame.darkMode?'#2A2C2E':'white', display: 'block', overflowY:'hidden'}}>  
            
               {/*  <h1 style={{position:'absolute', top:'0', left: '10%', color:userFrame.darkMode?'white':'black', fontWeight:'bold'}}>Contact</h1> */}

                <div style={{marginLeft:'10%'}}>
                    <button
                        style={{ 
                        paddingTop: '7px', paddingBottom: '7px', paddingLeft: '45px', paddingRight: '45px',
                        color: userFrame.darkMode? '#54595F':'white', fontWeight: 'bold', borderColor:userFrame.darkMode?'#D8D8D8':'#4B286D', cursor:'pointer',
                        borderRadius:'5px', borderWidth: '1px', width:'90%',
                        borderStyle: 'solid', backgroundColor: userFrame.darkMode?'#D8D8D8':'#4B286D' }}
                        onClick={() => collectDrop(true)}
                      >
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', height:'100%'}}>
                          <Delivery variant={userFrame.darkMode?'alternative':'inverted'} size={32}/>
                          <h1 style={{marginLeft:'20px'}}>Collect Drop</h1>
                        </div>
                      </button>
                    {/*<ButtonRectangle text='Collect Drop' color={userFrame.darkMode?'#54595F':'#4B286D'} onButtonClicked = {collectDrop}/>*/}
                </div>  
            </div>

            <div style={{height:click?expanded:collapsed, width:'100%', display:'block', backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}></div>    

            <div style={{ display:'block',height:'58px', width:'100%',backgroundColor:userFrame.darkMode?'#2A2C2E':'white', display: 'block', overflowY:'hidden'}}>  
            
               {/*  <h1 style={{position:'absolute', top:'0', left: '10%', color:userFrame.darkMode?'white':'black', fontWeight:'bold'}}>Contact</h1> */}

                <div style={{marginLeft:'10%'}}>
                      <button
                          style={{ 
                          paddingTop: '7px', paddingBottom: '7px', paddingLeft: '45px', paddingRight: '45px',
                          color: 'white', fontWeight: 'bold', borderColor:userFrame.darkMode?'#54595F':'#2B8000', cursor:'pointer',
                          borderRadius:'5px', borderWidth: '1px', width:'90%',
                          borderStyle: 'solid', backgroundColor: userFrame.darkMode?'#54595F':'#2B8000' }}
                          onClick={() => captureLead(true)}
                        >
                          <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', height:'100%'}}>
                            <Clipboard variant={'inverted'} size={32}/>
                            <h1 style={{marginLeft:'20px'}}>Capture Lead</h1>
                          </div>
                        </button>   
                    {/*<ButtonRectangle text='Place Order' color={userFrame.darkMode?'#D8D8D8':'#2B8000'} onButtonClicked = {placeOrder}/>*/}
                </div>  
            </div>


            <div style={{height:click?expanded:collapsed, width:'100%', display:'block', backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}></div>    

            {userFrame.darkMode?<></>:
            <div style={{display:'block', backgroundColor:userFrame.darkMode?'#2A2C2E':'white', width:'100%'}}>  
                <img src={plant} width="50%"  style={{ marginLeft:'50%',display:'inline-block', marginBottom:'-2%' }}/> 
            </div>}
          
            </div>
        </div>
    </div>
  );
}

export default BottomBar;
