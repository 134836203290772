import React, { useEffect, useState, useRef } from "react";

import checkMark from '../../../icons/checkmark/black.png';
import checkMarkWhite from '../../../icons/checkmark/white.png';

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../redux/actions/userFrameActions'

function CustomCheckBox ({title}) {

  const userFrame = useSelector(state=>state.user_frame_reducer[0])
  const houses = useSelector(state=>state.house_reducer)
  const dispatch = useDispatch()

  const [checked, setChecked] = useState(true)
  const [selected, setSelected] = useState(false)

  function functer() {

    setSelected(true)

    switch(title){
        case "Dark Mode":
            toggleDarkmode()
            break;
        case "Street View":
            toggleStreetView()
            break;
    }
  }

function toggleDarkmode() {
    
    const u = userFrame
    u.darkMode = !u.darkMode 
    dispatch(updateUserFrame(u))
}

function toggleStreetView() {
    
    const u = userFrame
    u.streetView = !u.streetView 
    dispatch(updateUserFrame(u))
}

/* Handles the clicks outside the div */
const refcheckbox = useRef(null)

useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
        document.removeEventListener('click', handleClickOutside, true);
    };
}, []);

const handleClickOutside = (event) => {
    if (refcheckbox.current && !refcheckbox.current.contains(event.target)) {
        setSelected(false);
    }
};

function showComponent() {
    switch(title){
        case "Dark Mode":
            return (
                    <a ref={refcheckbox} onClick={() => functer()}  style={{width:'230px',}}>
                    <div style={{display:'inline-block', width:'40px',verticalAlign:'top'}}>
                        <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'left', alignItems:'center'}}>
                            <div
                            style={{cursor: 'pointer',overflow:'visible', backgroundColor:userFrame.darkMode?(userFrame.darkMode?'#D8D8D8':'#2B8000'):(userFrame.darkMode?'#54595F':'white'), 
                            boxShadow:selected?'0px 0px 10px -3px #000000':'none', width:'23px', height:'23px', borderRadius:'4px', 
                            border:selected?'1px solid rgba(200,200,200,0.85)':(userFrame.darkMode?'1px solid white':'1px solid rgba(0,0,0,0.55)') }}>
                    
                                <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                    {userFrame.darkMode?<img src={userFrame.darkMode?checkMark:checkMarkWhite} width={'17px'} style={{overflow:'visible'}}/>:<></>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{display:'inline-block',width:'190px',verticalAlign:'top'}}>
                        <h1 style={{color:userFrame.darkMode?'white':'black'}}>
                            {title}
                        </h1>
                    </div>
                </a>
            )
        case "Street View":
            return (
                <a ref={refcheckbox} onClick={() => functer()}  style={{width:'230px',}}>
                    <div style={{display:'inline-block', width:'40px',verticalAlign:'top'}}>
                        <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'left', alignItems:'center'}}>
                            <div
                            style={{cursor: 'pointer',overflow:'visible', backgroundColor:userFrame.streetView?(userFrame.darkMode?'#D8D8D8':'#2B8000'):(userFrame.darkMode?'#54595F':'white'), 
                            boxShadow:selected?'0px 0px 10px -3px #000000':'none', width:'23px', height:'23px', borderRadius:'4px', 
                            border:selected?'1px solid rgba(200,200,200,0.85)':(userFrame.darkMode?'1px solid white':'1px solid rgba(0,0,0,0.55)') }}>
                    
                                <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                    {userFrame.streetView?<img src={userFrame.darkMode?checkMark:checkMarkWhite} width={'17px'} style={{overflow:'visible'}}/>:<></>}
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div style={{display:'inline-block',width:'190px',verticalAlign:'top'}}>
                        <h1 style={{color:userFrame.darkMode?'white':'black'}}>
                            {title}
                        </h1>
                    </div>
                </a>
            )
    }
  }
  

return (showComponent());
  
};

export default CustomCheckBox;