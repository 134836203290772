import React, { useEffect, useState } from "react";

import Card from '@tds/core-card'
import Box from "@tds/core-box";
import Heading from "@tds/core-heading";
import Paragraph from "@tds/core-paragraph";
import Button from "@tds/core-button";

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../../redux/actions/userFrameActions'

function ConfirmBox({}) {

  const userFrame = useSelector(state=>state.user_frame_reducer[0])
  const houses = useSelector(state=>state.house_reducer)
  const dispatch = useDispatch()


function updateOtherKnockHistory(knockType, reset){
      
    // add 'unselect' detail if applicable
    let knock = knockType
    if(reset){
        knock = "Unselect Knock: "+knockType
    }

    // add time stamp
    var dateTime = new Date().toLocaleString().split(" ")
    var date = dateTime[0]
    date = date.split(",")
    date = date[0]

    var time = dateTime[1].split(":")
    time = time[0]+":"+time[1]+" "+dateTime[2]

    // update state
    const h = houses[userFrame.houseId-1]
    h.knockHistory.push({value:knock,date:date,time:time})
    dispatch(updateHouse(h))

}

  function setOtherKnocks(index, knockType){

    // deep copy array to modify elements
    let array = houses[userFrame.houseId-1].loggedKnocks
    array[index] = !houses[userFrame.houseId-1].loggedKnocks[index]
    
    // check if any knocks are selected. if so, update quick knock with 3 dots
    let anyOtherKnockSelected = false
    
    for(let i = 0;i<array.length; i++){
        if(array[i] == true){
            anyOtherKnockSelected = true
        }
    }
    
    if(anyOtherKnockSelected){
        // theres at least 1 button selected here
        const h = houses[userFrame.houseId-1]
        h.icon='OK'
        dispatch(updateHouse(h))
    }else{
        // there arent any buttons selected in 'other knocks'
        const h = houses[userFrame.houseId-1]
        h.icon='house'
        dispatch(updateHouse(h))
    }

    const h = houses[userFrame.houseId-1]
    h.loggedKnocks = array
    dispatch(updateHouse(h))

    updateOtherKnockHistory(knockType, !houses[userFrame.houseId-1].loggedKnocks[index] )
    
}

function updateQuickKnockHistory(knockType){
      
    const h = houses[userFrame.houseId-1]

    switch(knockType){
      case 'NOH':
        knockType = "No One Home"
        h.noOneHomeCounter = h.noOneHomeCounter+1
        break;
      case 'SN':
        knockType = "Soft No"
        break;
      case 'HN':
        knockType = "Hard No"
        break;
      case 'WP':
        knockType = "Wrong Party"
        break;
      case 'NI':
        knockType = "Not Interested"
        break;
      case 'OK':
        knockType = "Other Knocks"
        break;
      case 'covid':
        knockType = "COVID"
        break;
      case 'multi':
        knockType = "Multi Dwelling Unit"
        break;
      case 'house':
        knockType = "Unselect Knock: "+userFrame.confirmKnockType
        break;
      case 'FU':
        knockType = "Follow Up"
        break;
      default:
        knockType = "Unselect Knock: "+userFrame.confirmKnockType
        break;


    }
  
    var dateTime = new Date().toLocaleString().split(" ")
    var date = dateTime[0]
    date = date.split(",")
    date = date[0]

    var time = dateTime[1].split(":")
    time = time[0]+":"+time[1]+" "+dateTime[2]

    
    h.knockHistory.push({value:knockType,date:date,time:time})
    dispatch(updateHouse(h))

}

function setQuickKnock(){

    if(houses[userFrame.houseId-1].icon != userFrame.confirmIcon){

        // select quick knock
        const h = houses[userFrame.houseId-1]
        if(userFrame.confirmIcon == "NOH" && h.noOneHomeCounter < 2){
          h.icon='house'
        }else{
          h.icon=userFrame.confirmIcon
        }
        dispatch(updateHouse(h))

        updateQuickKnockHistory(userFrame.confirmIcon)
    }else{
        // unselect quick knock
        const h = houses[userFrame.houseId-1]
        h.icon="house"
        dispatch(updateHouse(h))
        
        updateQuickKnockHistory('house')
    }

  }

  function setFollowUp(){

    // save scheduled follow up to log
    const u = userFrame

    // find current follow up and remove it to replace with new one
    const newFollowUps = u.followUps.filter((obj, index)=>{
      if(obj != undefined){
        if(obj.address != u.confirmAddress ){
          return obj
        }
      }
    })

    // check if already logged: if not logged yet then add new log, if already logged then only remove log (dont add new log)
    if(houses[userFrame.houseId-1].icon != userFrame.confirmIcon){
      
      // get the date
      let followUpDate
      // check that the date was entered, otherwise just grab today
      if(houses[u.houseId-1].followUpDate != null){
        followUpDate = houses[u.houseId-1].followUpDate.toString().split(" ")
      }else{
        followUpDate = new Date().toString().split(" ")
      }
      followUpDate = followUpDate[0]+", "+followUpDate[1]+" "+followUpDate[2]+", "+followUpDate[3]
      
      // get the time
      let followUpTime = houses[u.houseId-1].followUpTimeHour +":"+houses[u.houseId-1].followUpTimeMin+" "+houses[u.houseId-1].followUpTimeAMPM
      
      u.followUps = [...newFollowUps, {id: u.houseId, address: u.confirmAddress, name:houses[u.houseId-1].followUpName, email:houses[u.houseId-1].followUpEmail, phone:houses[u.houseId-1].followUpPhone, notes:houses[u.houseId-1].notes, date:followUpDate, time:followUpTime, complete:false}]
    
    }else{
      // delete folow up (dont re-add to array)
      
      // if the current follow up was complete, remove it from counter
      let counter = 0
      u.followUps.filter((obj, index)=>{
        if(obj != undefined){
          if(obj.address == u.confirmAddress){
            if(obj.complete == true){
                counter = 1;
            }
          }
        }
      })
      u.followUpsComplete = u.followUpsComplete-counter;

      u.followUps = newFollowUps;
      
    }

    dispatch(updateUserFrame(u))

    setQuickKnock()
  }

  function buttonSelection(choice) {

    const u = userFrame
    if(choice==true){
        // check if follow up selected
        if(u.confirmFollowUp == true){
            setFollowUp()
        }else{
          // check if 'other knocks' or 'quick knock'
          if(userFrame.confirmQuickKnock){
            setQuickKnock()
          }else{
            setOtherKnocks(u.confirmIndex, u.confirmKnockType)
          }
        }
    }
        
    // reset confirm box state variables
    u.confirmBox = false
    u.confirmAddress = ""
    u.confirmKnockType = ""
    u.confirmIndex = 0
    u.confirmQuickKnock = true;
    u.confirmIcon = "";
    u.confirmFollowUp = false;
    dispatch(updateUserFrame(u))
    
  }

  return (
    <div style={{position:'absolute', left:'0', top:'0', width:'100%', height:'100%', zIndex:100000, backgroundColor:'rgba(125, 125, 125, 0.3)' }}>
      <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'100%',}}>
        <div style={{width:'60%', backgroundColor:userFrame.darkMode?'#2A2C2E':'white', borderRadius:'5px'}}>
          <h1 style={{color:userFrame.darkMode?'white':'#4B286D', fontSize:'28px', marginTop:'60px', marginLeft:'5%'}}>
          {userFrame.confirmQuickKnock?
          userFrame.confirmIcon == houses[userFrame.houseId-1].icon?"Unselect Knock: "+userFrame.confirmKnockType:userFrame.confirmKnockType
          :
          houses[userFrame.houseId-1].loggedKnocks[userFrame.confirmIndex]?"Unselect Knock: "+userFrame.confirmKnockType:userFrame.confirmKnockType
          }
          </h1>

          <h1 style={{color:userFrame.darkMode?'white':'black', fontSize:'20px', marginTop:'20px', marginLeft:'5%',marginRight:'5%', fontWeight:'bold'}}>
            Confirm knock selection for: {userFrame.confirmAddress}
          </h1>
              <div style={{marginTop:'35px',marginBottom:'45px',width:'100%'}}>
              <div style={{display:'inline-block', width:'50%'}}>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                  <button onClick={()=>{buttonSelection(false)}} 
                  style={{width:'60%', height:'60px', backgroundColor:userFrame.darkMode?'#2A2C2E':'white', 
                  color:userFrame.darkMode?'white':'#C12335',fontWeight:'bold', borderColor:userFrame.darkMode?'white':'#C12335', 
                  border:'2px solid', borderRadius:'5px'}}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div style={{display:'inline-block', width:'50%'}}>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                  <button  onClick={()=>{buttonSelection(true)}} 
                  style={{width:'60%', height:'60px', backgroundColor:userFrame.darkMode?'#D8D8D8':'#2B8000', 
                  color:userFrame.darkMode?'#2A2C2E':'white',fontWeight:'bold', borderColor:userFrame.darkMode?'#D8D8D8':'#2B8000', 
                  border:'2px solid', borderRadius:'5px'}}>
                    Confirm
                  </button>
                </div>
              </div>
              </div>
          


          {/*<Card>
            <Box between={4}>
              <Box between={3}>
              {userFrame.confirmQuickKnock?
                <Heading level="h2">{userFrame.confirmIcon == houses[userFrame.houseId-1].icon?"Unselect Knock: "+userFrame.confirmKnockType:userFrame.confirmKnockType}</Heading>
               :
                <Heading level="h2">{houses[userFrame.houseId-1].loggedKnocks[userFrame.confirmIndex]?"Unselect Knock: "+userFrame.confirmKnockType:userFrame.confirmKnockType}</Heading>
              }
                <Heading level="h3">Confirm knock selection for: {userFrame.confirmAddress}</Heading>
              </Box>
              
            </Box>
            <div style={{marginTop:'30px'}}>
              
            <div style={{display:'inline-block', width:'50%'}}>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                  <Button onClick={()=>{buttonSelection(false)}} variant="danger">Cancel</Button>
                </div>
              </div>
              <div style={{display:'inline-block', width:'50%'}}>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                  <Button  onClick={()=>{buttonSelection(true)}} >Confirm</Button>
                </div>
              </div>
            </div>
            </Card>*/}
        </div>
      </div>
  </div>
  );
}

export default ConfirmBox;