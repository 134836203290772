import React, { useState, useEffect } from "react";

import Heading from '@tds/core-heading'

import back from '../../../../icons/back/purple/back.png';
import backWhite from '../../../../icons/back/white/back.png';

import ButtonPill from "./ButtonPill";
import ButtonMic from "./ButtonMic";

import Calendar from 'react-calendar';
import '../../../../Calendar.css';

import { useSelector,useDispatch } from "react-redux";
import {updateHouse} from '../../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../../redux/actions/userFrameActions'

function OtherKnocks({ }) {

  const userFrame = useSelector(state=>state.user_frame_reducer[0])
  const houses = useSelector(state=>state.house_reducer)
  const dispatch = useDispatch()

  function notesText(event){
    
    const h = houses[userFrame.houseId-1]
    h.notes = event.target.value
    dispatch(updateHouse(h))

  }

  function setLogAKnockButtonClicked(value){
    const frame = userFrame
    frame.loggingKnock = value
    dispatch(updateUserFrame(frame))
  } 


  return (
    
      //<div style={{position: 'absolute', top:0, left:0, height:'100%', width:'100%', overflowY:'scroll'}}>
         
        <div style={{ height: 'auto', width:'95%', marginLeft:'2.5%',backgroundColor:userFrame.darkMode?'#2A2C2E':'white', zIndex:100, borderRadius:'3px', paddingBottom:'10px',marginBottom:'10px', marginTop:'10px',boxShadow:userFrame.darkMode?'none':'3px 3px 3px 3px #9E9E9E', border:userFrame.darkMode?'1px solid #D8D8D8':'none',}}>
            <a onClick = {() => {setLogAKnockButtonClicked(false)}} style={{marginTop:'50px', marginLeft:'1%', height: '5%', width:'100px', backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}>
                <div style={{display:'inline-block', marginTop:'30px',marginLeft: '5px', zIndex:100,}}>
                    <img style={{ marginBottom:'-3px'}} src={userFrame.darkMode?backWhite:back}  width="20px"/> 
                </div>
                <h1 style={{marginTop:'1%', marginLeft: '1px', display:'inline-block', color:userFrame.darkMode?'white':'#4B286D', fontSize:'15px', zIndex:100}}>Back</h1>
            </a>

            <div style={{ marginTop:'30px',marginLeft: '10%',zIndex:100, }}>
                <h1 style={{color:userFrame.darkMode?'white':'#4B286D', fontSize:'28px'}}>
                    Log a knock
                </h1>     
            </div>

            <div style={{marginTop:'20px', marginLeft:'10%', width:'80%',zIndex:100,}}>
                
            <div style={{display:'flex', width:'100%', alignItems:'center', justifyContent:'center' }}>
                <div style={{width:'33%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <ButtonPill title={"Recently Knocked"} index={3} />
                </div>
                <div style={{width:'33%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <ButtonPill title={"No One Home"} index={4}/>
                </div>
                <div style={{width:'33%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <ButtonPill title={"Soft No"} index={5}/>
                </div>
                
            </div>

            <div style={{display:'flex', width:'100%', alignItems:'center', justifyContent:'center' }}>
                <div style={{width:'33%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <ButtonPill title={"Hard No"} index={6}/>
                </div>
                <div style={{width:'33%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <ButtonPill title={"Wrong Party"} index={7}/>
                </div>
                <div style={{width:'33%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <ButtonPill title={"Dangerous"} index={8}/>
                </div>
            </div>

            <div style={{display:'flex', width:'100%', alignItems:'center', justifyContent:'center' }}>
                <div style={{width:'33%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <ButtonPill title={"Address DNE"} index={9}/>
                </div>
                <div style={{width:'33%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <ButtonPill title={"Come Back"} index={10}/>
                </div>
                <div style={{width:'33%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <ButtonPill title={"Empty Lot"} index={11}/>
                </div>
            </div>

            <div style={{display:'flex', width:'100%', alignItems:'center', justifyContent:'center' }}>
                <div style={{width:'33%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <ButtonPill title={"Language Barrier"} index={12}/>
                </div>
                <div style={{width:'33%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <ButtonPill title={"Not Interested"} index={13}/>
                </div>
                <div style={{width:'33%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <ButtonPill title={"Vacant Home"} index={14}/>
                </div>
            </div>

            <div style={{display:'flex', width:'100%', alignItems:'center', justifyContent:'center' }}>
                <div style={{width:'33%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <ButtonPill title={"Drop Not Required"}  index={15}/>
                </div>
                <div style={{width:'33%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <ButtonPill title={"Drop Required"} index={16}/>
                </div>
                <div style={{width:'33%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <ButtonPill title={"Copper Technology"} index={17}/>
                </div>
            </div>

            <div style={{display:'flex', width:'100%', alignItems:'center', justifyContent:'center' }}>
                <div style={{width:'33%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <ButtonPill title={"Scheduled Follow Up"} index={18}/>
                </div>
                <div style={{width:'33%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <ButtonPill title={"Win Back Order"} index={19}/>
                </div>
                <div style={{width:'33%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <ButtonPill title={"Fiber Available"} index={20}/>
                </div>
            </div>

            <div style={{display:'flex', width:'100%', alignItems:'center', justifyContent:'center' }}>
                <div style={{width:'33%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <ButtonPill title={"COVID Do Not Knock"} index={21}/>
                </div>
                <div style={{width:'33%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <ButtonPill title={"COVID Escalation"} index={22}/>
                </div>
                <div style={{width:'33%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <ButtonPill title={"COVID Flyer"} index={23}/>
                </div>
            </div>


            </div>

            <div style={{marginTop:'30px', marginLeft:'10%', width:'80%',}}>
                <div style={{marginBottom:'20px'}}>
                    <h1 style={{color:userFrame.darkMode?'white':'#4B286D', fontSize:'28px'}}>
                        My Care
                    </h1>                
                </div>
                <div style={{display:'flex', width:'100%', alignItems:'center', justifyContent:'center' }}>
                <div style={{width:'33%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <ButtonPill title={"Positive Interaction"} index={0}/>
                </div>
                <div style={{width:'33%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <ButtonPill title={"Successful Download"} index={1}/>
                </div>
                <div style={{width:'33%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <ButtonPill title={"Negative Interaction"} index={2}/>
                </div>
                </div>
            
            </div>

            <div style={{marginTop:'30px', marginBottom:'10%'}}>
                <div style={{display:'block', height:'85px',backgroundColor:userFrame.darkMode?'#2A2C2E':'white',  width:'100%', paddingLeft:'10%', overflow:'visible'}}>
                    <label style={{width:'100%', display:'inline-block', color:userFrame.darkMode?'white':'black', fontWeight:'bold', overflow:'visible'}}>
                        Notes
                        <br></br>
                        <div style={{width:'80%', display:'inline-block'}}>
                            <textarea value={houses[userFrame.houseId-1].notes} onChange={notesText} 
                            style={{ width:'99%', height:'60px', resize:'none', outlineWidth:'1px',outlineStyle:'none', 
                            borderRadius:'5px', borderColor:userFrame.darkMode?'#2A2C2E':'#4B286D', color:userFrame.darkMode?'white':'#4B286D', 
                            outlineColor:userFrame.darkMode?'#2A2C2E':'#4B286D',  fontSize:'12px', fontWeight:'400',
                            backgroundColor:userFrame.darkMode?'#54595F':'white', padding:'17px'}}/>
                        </div>
                        <div style={{width:'10%', display:'inline-block', overflow:'visible'}}>     
                            <ButtonMic/>
                        </div>
                    </label>
                </div>
            </div>
            
        </div>
      
     // </div>
  );
}

export default OtherKnocks;
