import React, { useState, useEffect } from "react";

import Text from '@tds/core-text'

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../../redux/actions/userFrameActions'

import { PikTV, Internet, Tv, HomeSecurity,OnlineSecurity, SecurityHouse,CallTalking,Phone, Heartbeat } from '@tds/core-decorative-icon'

function ProductBar({}) {

    const userFrame = useSelector(state=>state.user_frame_reducer[0])
    const houses = useSelector(state=>state.house_reducer)
    const dispatch = useDispatch()

    const [optikSelected,setoptikSelected] = useState(false)
    const [pikSelected,setpikSelected] = useState(false)
    const [shsSelected,setshsSelected] = useState(false)
    const [intSelected,setintSelected] = useState(false)
    const [mobSelected,setmobSelected] = useState(false)
    const [hpSelected,sethpSelected] = useState(false)
    const [adtsSelected,setAdtsSelected] = useState(false)
    const [osSelected,setOsSelected] = useState(false)

  /*
  const handleCheck = event => {

    // disabled checkboxes
    if(false){
        if (event.target.value === 'pik') {
            setpikSelected(event.target.checked)
            houses[userFrame.houseId-1].existingProducts[0] = event.target.checked
        } else if (event.target.value === 'optik') {
            setoptikSelected(event.target.checked)
            houses[userFrame.houseId-1].existingProducts[1] = event.target.checked
        } else if (event.target.value === 'shs') {
            setshsSelected(event.target.checked)
            houses[userFrame.houseId-1].existingProducts[2] = event.target.checked
        } else if (event.target.value === 'hp') {
            sethpSelected(event.target.checked)
            houses[userFrame.houseId-1].existingProducts[3] = event.target.checked
        } else if (event.target.value === 'mob') {
            setmobSelected(event.target.checked)
            houses[userFrame.houseId-1].existingProducts[4] = event.target.checked
        }else if (event.target.value === 'int') {
            setintSelected(event.target.checked)
            houses[userFrame.houseId-1].existingProducts[5] = event.target.checked
        }else if (event.target.value === 'os') {
            setOsSelected(event.target.checked)
            houses[userFrame.houseId-1].existingProducts[6] = event.target.checked
        }else if (event.target.value === 'adts') {
            setAdtsSelected(event.target.checked)
            houses[userFrame.houseId-1].existingProducts[7] = event.target.checked
        }
    }

  }*/

function changeHighlight(params) {
    
}

  const iconWidth = '25px'

  return (
    <>
    
      
      {/* Row Middle */}
    <div style={{ top:'1%', left:0, height:'100%', width:'100%'}}>

        {/* Row Tech*/}
        <div style={{ top:'0', left:'10%', height:'100%', width:'80%',}}>
            <legend>
              <h1 style={{color:userFrame.darkMode?'white':'black', fontWeight:'bold', zIndex:100}}>Existing Products</h1>
            </legend>
           
            <div style={{display:'block', width:'100%', height:'50%'}}>
                
            <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>

                <a style={{display:'inline-block', width:'38px', cursor:'pointer'}} onClick={()=>{changeHighlight(0,houses[userFrame.houseId-1].houseId-1)}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                      <div style={{width:'38px',height:'38px', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:userFrame.darkMode?(houses[userFrame.houseId-1].existingProducts[0]=='no'||houses[userFrame.houseId-1].existingProducts[0]=='unavail'?'#2A2C2E':'white'):houses[userFrame.houseId-1].existingProducts[0]=='unavail'?'#71757B':(houses[userFrame.houseId-1].existingProducts[0]=='no'?'white':'#2B8000'), borderRadius:'30px'}}>
                          <Internet variant={houses[userFrame.houseId-1].existingProducts[0]=='no'?(userFrame.darkMode?'inverted':'default'):(userFrame.darkMode?(houses[userFrame.houseId-1].existingProducts[0]=='unavail'?'inverted':'alternative'):'inverted')} size={24}/>
                      </div>
                    </div>
                    <h1 style={{color:userFrame.darkMode?'white':houses[userFrame.houseId-1].existingProducts[0]=='unavail'?'#71757B':(houses[userFrame.houseId-1].existingProducts[0]=='no'?'#4B286D':'#2B8000'), display:'block', textAlign:'center', fontSize:'12px'}}>
                      {houses[userFrame.houseId-1].existingProducts[0]=='no'||houses[userFrame.houseId-1].existingProducts[0]=='unavail'?'Internet':houses[userFrame.houseId-1].existingProducts[0]}
                    </h1>                   
                </a>

                <a style={{display:'inline-block', width:'38px', cursor:'pointer'}} onClick={()=>{changeHighlight(2,houses[userFrame.houseId-1].houseId-1)}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                      <div style={{width:'38px',height:'38px',display:'flex', justifyContent:'center', alignItems:'center',backgroundColor:userFrame.darkMode?(houses[userFrame.houseId-1].existingProducts[1]=='no'||houses[userFrame.houseId-1].existingProducts[1]=='unavail'?'#2A2C2E':'white'):houses[userFrame.houseId-1].existingProducts[1]=='unavail'?'#71757B':(houses[userFrame.houseId-1].existingProducts[1]=='no'?'white':'#2B8000'), borderRadius:'30px'}}>
                          <PikTV variant={houses[userFrame.houseId-1].existingProducts[1]=='no'?(userFrame.darkMode?'inverted':'default'):(userFrame.darkMode?(houses[userFrame.houseId-1].existingProducts[1]=='unavail'?'inverted':'alternative'):'inverted')} size={24}/>
                      </div>                    
                    </div>  
                    <h1 style={{color:userFrame.darkMode?'white':houses[userFrame.houseId-1].existingProducts[1]=='unavail'?'#71757B':(houses[userFrame.houseId-1].existingProducts[1]=='no'?'#4B286D':'#2B8000'), display:'block', textAlign:'center', fontSize:'12px'}}>
                      {houses[userFrame.houseId-1].existingProducts[1]=='no'||houses[userFrame.houseId-1].existingProducts[1]=='unavail'?'TV':houses[userFrame.houseId-1].existingProducts[1]}
                    </h1>                   
                </a>

                <a style={{display:'inline-block', width:'38px', cursor:'pointer'}} onClick={()=>{changeHighlight(3,houses[userFrame.houseId-1].houseId-1)}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                      <div style={{width:'38px',height:'38px',display:'flex', justifyContent:'center', alignItems:'center',backgroundColor:userFrame.darkMode?(houses[userFrame.houseId-1].existingProducts[2]=='no'||houses[userFrame.houseId-1].existingProducts[2]=='unavail'?'#2A2C2E':'white'):houses[userFrame.houseId-1].existingProducts[2]=='unavail'?'#71757B':(houses[userFrame.houseId-1].existingProducts[2]=='no'?'white':'#2B8000'), borderRadius:'30px'}}>
                        <HomeSecurity variant={houses[userFrame.houseId-1].existingProducts[2]=='no'?(userFrame.darkMode?'inverted':'default'):(userFrame.darkMode?(houses[userFrame.houseId-1].existingProducts[2]=='unavail'?'inverted':'alternative'):'inverted')} size={24}/>
                      </div>                    
                     </div> 
                     <h1 style={{color:userFrame.darkMode?'white':houses[userFrame.houseId-1].existingProducts[2]=='unavail'?'#71757B':(houses[userFrame.houseId-1].existingProducts[2]=='no'?'#4B286D':'#2B8000'), display:'block', textAlign:'center', fontSize:'12px'}}>
                      {houses[userFrame.houseId-1].existingProducts[2]=='no'||houses[userFrame.houseId-1].existingProducts[2]=='unavail'?'SHS':houses[userFrame.houseId-1].existingProducts[2]}
                     </h1>                   
                </a>

                <a style={{display:'inline-block', width:'38px', cursor:'pointer'}} onClick={()=>{changeHighlight(5,houses[userFrame.houseId-1].houseId-1)}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                      <div style={{width:'38px',height:'38px',display:'flex', justifyContent:'center', alignItems:'center',backgroundColor:userFrame.darkMode?(houses[userFrame.houseId-1].existingProducts[3]=='no'||houses[userFrame.houseId-1].existingProducts[3]=='unavail'?'#2A2C2E':'white'):houses[userFrame.houseId-1].existingProducts[3]=='unavail'?'#71757B':(houses[userFrame.houseId-1].existingProducts[3]=='no'?'white':'#2B8000'), borderRadius:'30px'}}>
                          <CallTalking variant={houses[userFrame.houseId-1].existingProducts[3]=='no'?(userFrame.darkMode?'inverted':'default'):(userFrame.darkMode?(houses[userFrame.houseId-1].existingProducts[3]=='unavail'?'inverted':'alternative'):'inverted')} size={24}/>
                      </div>                    
                    </div>  
                    <h1 style={{color:userFrame.darkMode?'white':houses[userFrame.houseId-1].existingProducts[3]=='unavail'?'#71757B':(houses[userFrame.houseId-1].existingProducts[3]=='no'?'#4B286D':'#2B8000'), display:'block', textAlign:'center', fontSize:'12px'}}>
                      {houses[userFrame.houseId-1].existingProducts[3]=='no'||houses[userFrame.houseId-1].existingProducts[3]=='unavail'?'HP':houses[userFrame.houseId-1].existingProducts[3]}
                    </h1>                    
                </a>

            </div>
            </div>
           
            <div style={{display:'block', width:'100%', height:'50%'}}>
                
            <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
          
                <a style={{display:'inline-block', width:'38px', cursor:'pointer'}} onClick={()=>{changeHighlight(4,houses[userFrame.houseId-1].houseId-1)}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', }}>
                      <div style={{width:'38px',height:'38px',display:'flex', justifyContent:'center', alignItems:'center',backgroundColor:userFrame.darkMode?(houses[userFrame.houseId-1].existingProducts[4]=='no'||houses[userFrame.houseId-1].existingProducts[4]=='unavail'?'#2A2C2E':'white'):houses[userFrame.houseId-1].existingProducts[4]=='unavail'?'#71757B':(houses[userFrame.houseId-1].existingProducts[4]=='no'?'white':'#2B8000'), borderRadius:'30px'}}>
                        <Phone variant={houses[userFrame.houseId-1].existingProducts[4]=='no'?(userFrame.darkMode?'inverted':'default'):(userFrame.darkMode?(houses[userFrame.houseId-1].existingProducts[4]=='unavail'?'inverted':'alternative'):'inverted')} size={24}/>
                      </div>
                    </div>
                      <h1 style={{color:userFrame.darkMode?'white':houses[userFrame.houseId-1].existingProducts[4]=='unavail'?'#71757B':(houses[userFrame.houseId-1].existingProducts[4]=='no'?'#4B286D':'#2B8000'), display:'block', textAlign:'center', fontSize:'12px'}}>
                        {houses[userFrame.houseId-1].existingProducts[4]=='no'||houses[userFrame.houseId-1].existingProducts[4]=='unavail'?'MOB':houses[userFrame.houseId-1].existingProducts[4]}
                    </h1>                    
                  </a>

                <a style={{display:'inline-block', width:'38px', cursor:'pointer'}} onClick={()=>{changeHighlight(6,houses[userFrame.houseId-1].houseId-1)}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <div style={{width:'38px',height:'38px',display:'flex', justifyContent:'center', alignItems:'center',backgroundColor:userFrame.darkMode?(houses[userFrame.houseId-1].existingProducts[5]=='no'||houses[userFrame.houseId-1].existingProducts[5]=='unavail'?'#2A2C2E':'white'):houses[userFrame.houseId-1].existingProducts[5]=='unavail'?'#71757B':(houses[userFrame.houseId-1].existingProducts[5]=='no'?'white':'#2B8000'), borderRadius:'30px'}}>
                          <OnlineSecurity variant={houses[userFrame.houseId-1].existingProducts[5]=='no'?(userFrame.darkMode?'inverted':'default'):(userFrame.darkMode?(houses[userFrame.houseId-1].existingProducts[5]=='unavail'?'inverted':'alternative'):'inverted')} size={24}/>
                        </div>                      
                    </div>
                    <h1 style={{color:userFrame.darkMode?'white':houses[userFrame.houseId-1].existingProducts[5]=='unavail'?'#71757B':(houses[userFrame.houseId-1].existingProducts[5]=='no'?'#4B286D':'#2B8000'), display:'block', textAlign:'center', fontSize:'12px'}}>
                    {houses[userFrame.houseId-1].existingProducts[5]=='no'||houses[userFrame.houseId-1].existingProducts[5]=='unavail'?'TOS':houses[userFrame.houseId-1].existingProducts[5]}
                    </h1>                   
                </a>

                <a style={{display:'inline-block', width:'38px', cursor:'pointer'}} onClick={()=>{changeHighlight(7,houses[userFrame.houseId-1].houseId-1)}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}> 
                      <div style={{width:'38px',height:'38px',display:'flex', justifyContent:'center', alignItems:'center',backgroundColor:userFrame.darkMode?(houses[userFrame.houseId-1].existingProducts[6]=='no'||houses[userFrame.houseId-1].existingProducts[6]=='unavail'?'#2A2C2E':'white'):houses[userFrame.houseId-1].existingProducts[6]=='unavail'?'#71757B':(houses[userFrame.houseId-1].existingProducts[6]=='no'?'white':'#2B8000'), borderRadius:'30px'}}> 
                          <Heartbeat variant={houses[userFrame.houseId-1].existingProducts[6]=='no'?(userFrame.darkMode?'inverted':'default'):(userFrame.darkMode?(houses[userFrame.houseId-1].existingProducts[6]=='unavail'?'inverted':'alternative'):'inverted')} size={24}/>
                      </div>                    
                    </div>
                    <h1 style={{color:userFrame.darkMode?'white':houses[userFrame.houseId-1].existingProducts[6]=='unavail'?'#71757B':(houses[userFrame.houseId-1].existingProducts[6]=='no'?'#4B286D':'#2B8000'),  display:'block', textAlign:'center', fontSize:'12px'}}>
                      {houses[userFrame.houseId-1].existingProducts[6]=='no'||houses[userFrame.houseId-1].existingProducts[6]=='unavail'?'LWC':houses[userFrame.houseId-1].existingProducts[6]}
                    </h1>   
                </a>

                <a style={{display:'inline-block', width:'38px', cursor:'pointer'}} onClick={()=>{changeHighlight(7,houses[userFrame.houseId-1].houseId-1)}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center',borderRadius:'30px'}}> 
                        <div style={{height:'38px', width:'38px', display:'flex',justifyContent:'center', alignItems:'center'}}>
                        {/* <Heartbeat variant={houses[userFrame.houseId-1].existingProducts[7]==false?'default':'inverted'} size={24}/>*/}  
                        </div>                    
                    </div>
                    {/*  <h1 style={{color:houses[userFrame.houseId-1].existingProducts[7]==false?'#4B286D':'#2B8000', textDecoration:houses[userFrame.houseId-1].existingProducts[7]==false?'none':'none', display:'block', textAlign:'center', fontSize:'12px'}}>ADT</h1> */}                
                </a>
                
                </div>

            </div>
       
        </div>
    </div>

    </>
  );
}

export default ProductBar;