import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../../redux/actions/userFrameActions'

function ButtonJoined({}) {

  const userFrame = useSelector(state=>state.user_frame_reducer[0])
  const houses = useSelector(state=>state.house_reducer)
  const dispatch = useDispatch()

  function handleClick(buttonNumber){
      
     // save button selection to redux state
    const frame = userFrame;
    frame.tabView = buttonNumber
    dispatch(updateUserFrame(frame))
    
  }

  const width = window.innerWidth>600?'150px':'70px';
  const height = window.innerWidth>600?'40px':'35px';

  return (
    <div>
      <button
          style={{ 
          height:height, width:width, overflow:'hidden',
          cursor: 'pointer',display:'inline-block',
            color: userFrame.tabView == 1 ? 'white' : (userFrame.darkMode?'#2A2C2E':'#4B286D'), borderColor:(userFrame.darkMode?'#2A2C2E':'#4B286D'), 
            borderRadius:'3px', borderWidth:'1px',
            borderStyle: 'solid', backgroundColor:userFrame.tabView == 1 ? (userFrame.darkMode?'#54595F':'#4B286D') : (userFrame.darkMode?'#D8D8D8':'white'),boxShadow: userFrame.darkMode?'none':"1px 3px 1px #9E9E9E" }}
          onClick={() => handleClick(1)}
        >
        <h1 style={{ color: userFrame.tabView == 1 ? 'white' : (userFrame.darkMode?'#2A2C2E':'#4B286D'), display:'flex', alignItems:'center', justifyContent:'center',}}>List</h1>
        </button>

        <button
        style={{ 
        height:height, width:width, overflow:'hidden',
          margin: '4px',cursor: 'pointer',display:'inline-block',
          color: userFrame.tabView == 2 ? 'white' : (userFrame.darkMode?'#2A2C2E':'#4B286D'), borderColor:(userFrame.darkMode?'#2A2C2E':'#4B286D'), 
          borderRadius:'3px', borderWidth:'1px',
          borderStyle: 'solid', backgroundColor:userFrame.tabView == 2 ? (userFrame.darkMode?'#54595F':'#4B286D') : (userFrame.darkMode?'#D8D8D8':'white'),boxShadow: userFrame.darkMode?'none':"1px 3px 1px #9E9E9E" }}
        onClick={() => handleClick(2)}
        >
        <h1 style={{ color: userFrame.tabView == 2 ? 'white' : (userFrame.darkMode?'#2A2C2E':'#4B286D'), display:'flex', alignItems:'center', justifyContent:'center',}}>Map</h1>
        </button>

        
        <button
        style={{ 
        height:height, width:width, overflow:'hidden',
        cursor: 'pointer',display:'inline-block', borderWidth:'1px',
          color: userFrame.tabView == 3 ? 'white' : (userFrame.darkMode?'#2A2C2E':'#4B286D'), borderColor:(userFrame.darkMode?'#2A2C2E':'#4B286D'), 
          borderRadius:'3px', backgroundColor:userFrame.tabView == 3 ? (userFrame.darkMode?'#54595F':'#4B286D') : (userFrame.darkMode?'#D8D8D8':'white'),
          boxShadow: userFrame.darkMode?'none':"1px 3px 1px #9E9E9E",borderStyle: 'solid',  }}
        onClick={() => handleClick(3)}
        >
          <h1 style={{ color: userFrame.tabView == 3 ? 'white' : (userFrame.darkMode?'#2A2C2E':'#4B286D'), display:'flex', alignItems:'center', justifyContent:'center',}}>Search</h1>
        </button>
    </div>
  );
}

export default ButtonJoined;