import React, { useState, useEffect, useRef } from "react";

import up from '../../icons/arrow/up/purple.png';
import down from '../../icons/arrow/down/purple.png';
import whiteArrowUp from '../../icons/arrow/up/white.png';
import whiteArrowDown from '../../icons/arrow/down/white.png';
import right from '../../icons/arrow/right/purple.png';
import leftArrow from '../../icons/arrow/left/black.png';
import rightArrow from '../../icons/arrow/right/black.png';
import upC from '../../icons/arrow/up/circledp.png'
import downC from '../../icons/arrow/down/circledp.png'
import whiteUp from '../../icons/arrow/up/white-circle.png'
import whiteDown from '../../icons/arrow/down/white-down.png'

import IconHouse from "./SubComponents/Phone/IconHouseTwo";
import ButtonCustom from "./SubComponents/Phone/Button";
import ButtonHouseFilter from "./SubComponents/Phone/ButtonHouseFilter";
import ButtonHouse from "./SubComponents/Phone/ButtonHouse";
import FollowUp from "./SubComponents/Phone/FollowUp";
import OtherKnocks from "./SubComponents/Phone/OtherKnocks";
import ButtonRectangle from "../MapView/Phone/SubComponents/ButtonRectangle";

import { PikTV, Internet, Tv, HomeSecurity, OnlineSecurity, SecurityHouse, CallTalking, Phone, Heartbeat, Delivery, Deals, ArrowUp, Clipboard } from '@tds/core-decorative-icon'
import { Times } from '@tds/core-feedback-icon'
import HairlineDivider from "@tds/core-hairline-divider";

import { useSelector, useDispatch } from "react-redux";
import { updateHouse } from '../../redux/actions/houseActions'
import { updateUserFrame } from '../../redux/actions/userFrameActions'

function ListViewPhone({ }) {

  const userFrame = useSelector(state => state.user_frame_reducer[0])
  const houses = useSelector(state => state.house_reducer)
  const dispatch = useDispatch()

  const [page, setPage] = useState(1)

  const [width, setWidth] = useState(window.innerWidth)

  function navigatePage(increment) {
    setPage(page + increment)
  }

  function setFollowUpButtonClicked(value, index) {
    const frame = userFrame
    frame.followingUp = value
    dispatch(updateUserFrame(frame))
  }

  function setLogAKnockButtonClicked(value) {
    const frame = userFrame
    frame.loggingKnock = value
    dispatch(updateUserFrame(frame))
  }

  function clearSearch() {
    const u = userFrame
    u.searchString = ""
    u.searchStringPreSearch = ""
    u.houseOutsideWalklist = false
    dispatch(updateUserFrame(u))
  }

  function expandCollapse(idx, onLoad) {

    // keep track of which premise is selected
    const u = userFrame
    if (houses[idx].expand == false) {
      // setup the drawer for map view 
      u.houseId = idx + 1
      u.drawer = 1
    } else {
      u.houseId = 0
      u.drawer = 0
    }

    dispatch(updateUserFrame(u))


    // iterate through each houses
    for (let i = 0; i < houses.length; i++) {
      const h = houses[i]
      if (i == idx) {
        // expand/collapse the premise selected
        h.expand = !h.expand
      } else {
        // collpase the rest of the premises
        h.expand = false
      }
      dispatch(updateHouse(h))
    }

    // set view values to false
    setFollowUpButtonClicked(false)
    setLogAKnockButtonClicked(false)

  }

  function changeHighlight(prodIdx, idx) {

    /* disabled buttons + also redux now installed
    if(false){
    houses[idx].existingProducts[prodIdx] = !houses[idx].existingProducts[prodIdx]

     // copy whole array of data
     let newData = data
     // deep copy the object at the particular array index
     let update = newData[idx]
     // mutate the color variable in the object
     update.products[prodIdx] = !update.products[prodIdx]
     // update the array at the index with the new object
     newData[idx] = update
     // save the updated array
     setData([...newData])
    }*/
  }

  function captureLead(value, index) {
    alert('Capture Lead Screen')
  }

  function collectDrop(value, index) {
    alert('Collect Drop Screen')
  }

  function placeOrder(value, index) {
    //const h = houses
    //h[index].existingCustomer = !h[index].existingCustomer
    //dispatch(updateHouse(h))
    const u = userFrame
    u.houseId = index + 1;
    u.showPCO = true;
    dispatch(updateUserFrame(u))
  }

  function getKnocks(object) {
    const clonedArray = object.knockHistory.map(a => { return { ...a } })
    const listOfKnocks = clonedArray.reverse().map((val, i) =>
      <div key={i} style={{ marginBottom: '10px', width: '80%', marginLeft: '10%' }}>
        <h1 style={{ display: 'block', color: userFrame.darkMode ? 'white' : '#2B8000', fontSize: 14 }}>{val.value}</h1>
        <div>
          <h1 style={{ display: 'inline-block', color: userFrame.darkMode ? 'white' : '#4B286D', fontSize: 14 }}>{val.date} -&nbsp;</h1>
          <h1 style={{ display: 'inline-block', color: userFrame.darkMode ? 'white' : '#4B286D', fontSize: 14 }}>{val.time} </h1>
        </div>
        <div style={{ width: '100%', marginTop: '10px' }}>
          <HairlineDivider />
        </div>
      </div>)

    return listOfKnocks
  }


  function getDetails(object) {
    const listOfDetails = object.details.map((val, i) =>
      <div key={i} style={{ marginBottom: '10px', width: '80%', marginLeft: '10%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h1 style={{ fontSize: 14, fontWeight: 'bold' }}>{val.key}</h1>
          <h1 style={{ fontSize: 14, textAlign: 'right' }}>{val.value}</h1>
        </div>
        <div style={{ width: '100%', marginTop: '10px' }}>
          <HairlineDivider />
        </div>
      </div>)

    return listOfDetails
  }


  function followUpPopup() {

    if (userFrame.followingUp) {

      return (
        <FollowUp />
      )
    }

  }



  function otherKnocksPopup() {

    if (userFrame.loggingKnock) {

      return (
        <OtherKnocks />
      )
    }

  }

  function disablePremise(index) {
    if (houses[index].icon == 'DNK') {
      return (
        <div style={{ position: 'relative', left: '0', top: '0', width: '100%', height: '100%', zIndex: 5, backgroundColor: 'rgba(125, 125, 125, 0.3)', borderRadius: '5px' }}>
          {/*
              <div style={{marginTop:'35%', width:'100%',marginLeft:'-5%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                  <IconHouse icon={houses[userFrame.houseId-1].icon}/>
              </div>*/}
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h1 style={{ marginTop: '50px', color: userFrame.darkMode ? 'white' : '#C12335', fontSize: '30px', fontWeight: 'bold', textAlign: 'center', textShadow: '0px 0px 15px #FFF' }}>Do Not Knock</h1>
          </div>
        </div>
      )
    }
  }

  function getList(searchString) {

    var dataFiltered = houses
    if (searchString != "") {

      dataFiltered = dataFiltered.filter(house => house.address.includes(searchString))
    }

    if (userFrame.filterSelection != 'none') {
      dataFiltered = dataFiltered.filter(house => house.icon.includes(userFrame.filterSelection))
    }

    if (userFrame.oddEven != 'none') {

      if (userFrame.oddEven == 'odd') {
        dataFiltered = dataFiltered.sort(function (a, b) {
          // if both are odd or both even, return 0, no difference
          if ((parseFloat(a.address.split(" ")[0]) % 2 == 0
            && parseFloat(b.address.split(" ")[0]) % 2 == 0)
            || (parseFloat(a.address.split(" ")[0]) % 2 != 0
              && parseFloat(b.address.split(" ")[0]) % 2 != 0)) {

            return 0

            // if a is odd return 1
          } else if (parseFloat(a.address.split(" ")[0]) % 2 != 0) {

            return -1

            // if b is odd return -1
          } else if (parseFloat(b.address.split(" ")[0]) % 2 != 0) {

            return 1

          }
        })
      }

      if (userFrame.oddEven == 'even') {
        dataFiltered = dataFiltered.sort(function (a, b) {
          // if both are odd or both even, return 0, no difference
          if ((parseFloat(a.address.split(" ")[0]) % 2 == 0
            && parseFloat(b.address.split(" ")[0]) % 2 == 0)
            || (parseFloat(a.address.split(" ")[0]) % 2 != 0
              && parseFloat(b.address.split(" ")[0]) % 2 != 0)) {

            return 0

            // if a is odd return 1
          } else if (parseFloat(a.address.split(" ")[0]) % 2 == 0) {

            return -1

            // if b is odd return -1
          } else if (parseFloat(b.address.split(" ")[0]) % 2 == 0) {

            return 1

          }
        })
      }
    } else {
      // sort high to low
      dataFiltered = dataFiltered.sort(function (a, b) {
        return parseFloat(a.address.split(" ")[0]) - parseFloat(b.address.split(" ")[0])
      })
    }

    const listItems = dataFiltered.map((d, idx) =>
      <div key={idx} style={{ height: d.expand ? 'auto': '355px', width: '90%', display: 'block', marginLeft: '5%', marginRight: '5%', marginTop: '2.5%', boxShadow: userFrame.darkMode ? 'none' : '3px 3px 3px 3px #9E9E9E', borderRadius: '3px', overflow: 'hidden' }}>
        {disablePremise(d.houseId - 1)}
        {/* transition:'all 0.25s ease-in-out'  */}
        <div style={{ position: 'relative', top: houses[d.houseId - 1].icon == 'DNK' ? '-100%' : 0, left: 0, height: '100%', width: '100%' }}>
          <div style={{ display: 'block', width: '100%', height: '100%', backgroundColor: userFrame.darkMode ? '#2A2C2E' : 'white', borderRadius: '3px', zIndex: 2 }}>

            <div style={{ display: 'block', width: '100%', height: '100px', backgroundColor: userFrame.darkMode ? '#2A2C2E' : 'white', overflowY: 'hidden', borderRadius: '3px', zIndex: 3 }}>


              <div style={{ width: '100%', height: '100px', paddingTop: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: userFrame.darkMode ? '#2A2C2E' : 'white', overflow: 'hidden' }}>

                <div style={{ width: '40px', height: '100%', backgroundColor: userFrame.darkMode ? '#2A2C2E' : 'white', paddingTop: '10px' }}>
                  <IconHouse index={idx} icon={d.icon} />
                </div>

                <div style={{ width: '60%', height: '100%', backgroundColor: userFrame.darkMode ? '#2A2C2E' : 'white', paddingLeft: '5px' }}>
                  <div style={{ width: '100%', backgroundColor: userFrame.darkMode ? '#2A2C2E' : 'white' }}>
                    <h1 style={{ color: userFrame.darkMode ? 'white' : '#4B286D', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',fontWeight: 'bold' }}>
                      {d.address}
                    </h1>
                  </div>
                  <div style={{ width: '100%', backgroundColor: userFrame.darkMode ? '#2A2C2E' : 'white', }}>
                    <h1 style={{ color: userFrame.darkMode ? 'white' : '#2B8000', fontSize: '12px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', }}>
                      {d.westernPremise == true ?
                        <>
                          {d.copperAvail == false ? "" : (d.copperCurrent == false ? <span style={{ color: userFrame.darkMode ? 'white' : '#FFA500' }}>Copper Eligible</span> : "Existing Copper,")}
                          {d.copperAvail == false && d.fibreAvail == false ? (<span style={{ color: userFrame.darkMode ? 'white' : '#C12335' }}>Copper Not Available,</span>) : ""}
                          {d.fibreAvail == false ? (<span style={{ color: userFrame.darkMode ? 'white' : '#C12335' }}>&nbsp;Fibre Not Available</span>) : (d.fibreCurrent == false ? <span style={{ color: userFrame.darkMode ? 'white' : '#FFA500' }}>&nbsp;Fibre Eligible</span> : " Fibre")}
                          {d.dropRequired == true ? <span style={{ color: userFrame.darkMode ? 'white' : '#FFA500' }}>&nbsp;- Drop Required</span> : ""}
                          {d.selfInstall == true ? <span style={{ color: userFrame.darkMode ? 'white' : '#FFA500' }}>&nbsp;- FFH Self Install</span> : ""}
                        </>
                        :
                        <>
                          {Math.floor(Math.random() * 10) > 4 ? '5G' : 'LTE'}
                        </>}
                    </h1>
                  </div>
                  <div style={{ width: '100%', backgroundColor: userFrame.darkMode ? '#2A2C2E' : 'white', }}>
                    <h1 style={{ color: userFrame.darkMode ? 'white' : '#2B8000', fontSize: '12px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', }}>
                      Next available due date -
                      {" " + d.dueDate}
                    </h1>
                  </div>
                  <div style={{ width: '140%', backgroundColor: userFrame.darkMode ? '#2A2C2E' : 'white', zIndex: 3, marginLeft: '-40px' }}>
                    <h1 style={{ fontSize: '12px', color: userFrame.darkMode ? 'white' : 'black', display: 'inline-block' }}>Last Knock: </h1>
                    <h1 style={{ fontSize: '12px', color: userFrame.darkMode ? 'white' : '#2B8000', display: 'inline-block' }}>&nbsp;{houses[idx].knockHistory[houses[idx].knockHistory.length - 1].value}, </h1>
                    <h1 style={{ fontSize: '12px', color: userFrame.darkMode ? 'white' : '#4B286D', display: 'inline-block' }}>&nbsp;{houses[idx].knockHistory[houses[idx].knockHistory.length - 1].date}&nbsp;{houses[idx].knockHistory[houses[idx].knockHistory.length - 1].time} </h1>
                  </div>
                </div>


                <div style={{ height: '70px', width: '25%', borderRadius: '3px', paddingTop: '10px', display: 'flex', justifyContent: 'center', zIndex: 3 }}>
                  <a onClick={() => { expandCollapse(d.houseId - 1, false) }} >
                    {d.expand ?
                      <div style={{ display: 'inline-block', }}>
                        <div style={{ display: 'inline-block', zIndex: 3, }}>
                          <img style={{ marginBottom: '-5px' }} src={userFrame.darkMode ? whiteArrowUp : up} width="20px" />
                        </div>
                        <h1 style={{ marginLeft: '1px', display: 'inline-block', color: userFrame.darkMode ? 'white' : '#4B286D', fontSize: '15px', zIndex: 4, cursor: 'pointer' }}>Collapse</h1>
                      </div>
                      :
                      <div style={{ display: 'inline-block', }}>
                        <div style={{ display: 'inline-block', zIndex: 3, }}>
                          <img style={{ marginBottom: '-5px' }} src={userFrame.darkMode ? whiteArrowDown : down} width="20px" />
                        </div>
                        <h1 style={{ marginLeft: '1px', display: 'inline-block', color: userFrame.darkMode ? 'white' : '#4B286D', fontSize: '15px', zIndex: 4, cursor: 'pointer' }}>Expand</h1>
                      </div>
                    }
                  </a>
                </div>

              </div>

            </div>

            <div style={{ height:'auto', backgroundColor: userFrame.darkMode ? '#2A2C2E' : 'white', borderRadius: '3px', paddingBottom:'20px' }}>

              <div style={{ display: 'block', marginTop: '5px', marginLeft: '5%', width: '90%', height: '55px', backgroundColor: userFrame.darkMode ? '#2A2C2E' : 'white', zIndex: 5, borderRadius: '3px' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                  <a style={{ display: 'inline-block', width: '12%', cursor: 'pointer' }} onClick={() => { changeHighlight(5, d.houseId - 1) }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <div style={{ height: '40px', width: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: userFrame.darkMode ? (d.existingProducts[0] != 'no' && d.existingProducts[0] != 'unavail' ? 'white' : '#2A2C2E') : (d.existingProducts[0] == 'unavail' ? '#71757B' : (d.existingProducts[0] == 'no' ? 'white' : '#2B8000')), borderRadius: '30px' }}>
                        <Internet variant={d.existingProducts[0] == 'no' ? (userFrame.darkMode ? 'inverted' : 'default') : (userFrame.darkMode ? (d.existingProducts[0] == 'unavail' ? 'inverted' : 'alternative') : 'inverted')} size={24} />
                      </div>
                    </div>
                    <h1 style={{ color: userFrame.darkMode ? 'white' : d.existingProducts[0] == 'unavail' ? '#71757B' : (d.existingProducts[0] == 'no' ? '#4B286D' : '#2B8000'), display: 'block', textAlign: 'center', fontSize: '12px' }}>
                      {d.existingProducts[0] == 'no' || d.existingProducts[0] == 'unavail' ? 'Internet' : d.existingProducts[0]}
                    </h1>
                  </a>

                  <a style={{ display: 'inline-block', width: '12%', cursor: 'pointer' }} onClick={() => { changeHighlight(0, d.houseId - 1) }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <div style={{ height: '40px', width: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: userFrame.darkMode ? (d.existingProducts[1] != 'no' && d.existingProducts[1] != 'unavail' ? 'white' : '#2A2C2E') : d.existingProducts[1] == 'unavail' ? '#71757B' : (d.existingProducts[1] == 'no' ? 'white' : '#2B8000'), borderRadius: '30px' }}>
                        <PikTV variant={d.existingProducts[1] == 'no' ? (userFrame.darkMode ? 'inverted' : 'default') : (userFrame.darkMode ? (d.existingProducts[1] == 'unavail' ? 'inverted' : 'alternative') : 'inverted')} size={24} />
                      </div>
                    </div>
                    <h1 style={{ color: userFrame.darkMode ? 'white' : d.existingProducts[1] == 'unavail' ? '#71757B' : (d.existingProducts[1] == 'no' ? '#4B286D' : '#2B8000'), display: 'block', textAlign: 'center', fontSize: '12px' }}>
                      {d.existingProducts[1] == 'no' || d.existingProducts[1] == 'unavail' ? 'TV' : d.existingProducts[1]}
                    </h1>
                  </a>

                  <a style={{ display: 'inline-block', width: '12%', cursor: 'pointer' }} onClick={() => { changeHighlight(2, d.houseId - 1) }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <div style={{ height: '40px', width: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: userFrame.darkMode ? (d.existingProducts[2] != 'no' && d.existingProducts[2] != 'unavail' ? 'white' : '#2A2C2E') : d.existingProducts[2] == 'unavail' ? '#71757B' : (d.existingProducts[2] == 'no' ? 'white' : '#2B8000'), borderRadius: '30px' }}>
                        <HomeSecurity variant={d.existingProducts[2] == 'no' ? (userFrame.darkMode ? 'inverted' : 'default') : (userFrame.darkMode ? (d.existingProducts[2] == 'unavail' ? 'inverted' : 'alternative') : 'inverted')} size={24} />
                      </div>
                    </div>
                    <h1 style={{ color: userFrame.darkMode ? 'white' : d.existingProducts[2] == 'unavail' ? '#71757B' : (d.existingProducts[2] == 'no' ? '#4B286D' : '#2B8000'), display: 'block', textAlign: 'center', fontSize: '12px' }}>
                      {d.existingProducts[2] == 'no' || d.existingProducts[2] == 'unavail' ? 'SHS' : d.existingProducts[2]}
                    </h1>
                  </a>

                  <a style={{ display: 'inline-block', width: '12%', cursor: 'pointer' }} onClick={() => { changeHighlight(3, d.houseId - 1) }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <div style={{ height: '40px', width: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: userFrame.darkMode ? (d.existingProducts[3] != 'no' && d.existingProducts[3] != 'unavail' ? 'white' : '#2A2C2E') : d.existingProducts[3] == 'unavail' ? '#71757B' : (d.existingProducts[3] == 'no' ? 'white' : '#2B8000'), borderRadius: '30px' }}>
                        <CallTalking variant={d.existingProducts[3] == 'no' ? (userFrame.darkMode ? 'inverted' : 'default') : (userFrame.darkMode ? (d.existingProducts[3] == 'unavail' ? 'inverted' : 'alternative') : 'inverted')} size={24} />
                      </div>
                    </div>
                    <h1 style={{ color: userFrame.darkMode ? 'white' : d.existingProducts[3] == 'unavail' ? '#71757B' : (d.existingProducts[3] == 'no' ? '#4B286D' : '#2B8000'), display: 'block', textAlign: 'center', fontSize: '12px' }}>
                      {d.existingProducts[3] == 'no' || d.existingProducts[3] == 'unavail' ? 'HP' : d.existingProducts[3]}
                    </h1>
                  </a>

                  <a style={{ display: 'inline-block', width: '12%', cursor: 'pointer' }} onClick={() => { changeHighlight(4, d.houseId - 1) }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                      <div style={{ height: '40px', width: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: userFrame.darkMode ? (d.existingProducts[4] != 'no' && d.existingProducts[4] != 'unavail' ? 'white' : '#2A2C2E') : d.existingProducts[4] == 'unavail' ? '#71757B' : (d.existingProducts[4] == 'no' ? 'white' : '#2B8000'), borderRadius: '30px' }}>
                        <Phone variant={d.existingProducts[4] == 'no' ? (userFrame.darkMode ? 'inverted' : 'default') : (userFrame.darkMode ? (d.existingProducts[4] == 'unavail' ? 'inverted' : 'alternative') : 'inverted')} size={24} />
                      </div>
                    </div>
                    <h1 style={{ color: userFrame.darkMode ? 'white' : d.existingProducts[4] == 'unavail' ? '#71757B' : (d.existingProducts[4] == 'no' ? '#4B286D' : '#2B8000'), display: 'block', textAlign: 'center', fontSize: '12px' }}>
                      {d.existingProducts[4] == 'no' || d.existingProducts[4] == 'unavail' ? 'MOB' : d.existingProducts[4]}
                    </h1>
                  </a>

                  <a style={{ display: 'inline-block', width: '12%', cursor: 'pointer' }} onClick={() => { changeHighlight(6, d.houseId - 1) }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <div style={{ height: '40px', width: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: userFrame.darkMode ? (d.existingProducts[5] != 'no' && d.existingProducts[5] != 'unavail' ? 'white' : '#2A2C2E') : d.existingProducts[5] == 'unavail' ? '#71757B' : (d.existingProducts[5] == 'no' ? 'white' : '#2B8000'), borderRadius: '30px' }}>
                        <OnlineSecurity variant={d.existingProducts[5] == 'no' ? (userFrame.darkMode ? 'inverted' : 'default') : (userFrame.darkMode ? (d.existingProducts[5] == 'unavail' ? 'inverted' : 'alternative') : 'inverted')} size={24} />
                      </div>
                    </div>
                    <h1 style={{ color: userFrame.darkMode ? 'white' : d.existingProducts[5] == 'unavail' ? '#71757B' : (d.existingProducts[5] == 'no' ? '#4B286D' : '#2B8000'), display: 'block', textAlign: 'center', fontSize: '12px' }}>
                      {d.existingProducts[5] == 'no' || d.existingProducts[5] == 'unavail' ? 'TOS' : d.existingProducts[5]}
                    </h1>
                  </a>

                  <a style={{ display: 'inline-block', width: '12%', cursor: 'pointer' }} onClick={() => { changeHighlight(7, d.houseId - 1) }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <div style={{ height: '40px', width: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: userFrame.darkMode ? (d.existingProducts[6] != 'no' && d.existingProducts[6] != 'unavail' ? 'white' : '#2A2C2E') : d.existingProducts[6] == 'unavail' ? '#71757B' : (d.existingProducts[6] == 'no' ? 'white' : '#2B8000'), borderRadius: '30px' }}>
                        <Heartbeat variant={d.existingProducts[6] == 'no' ? (userFrame.darkMode ? 'inverted' : 'default') : (userFrame.darkMode ? (d.existingProducts[6] == 'unavail' ? 'inverted' : 'alternative') : 'inverted')} size={24} />
                      </div>
                    </div>
                    <h1 style={{ color: userFrame.darkMode ? 'white' : d.existingProducts[6] == 'unavail' ? '#71757B' : (d.existingProducts[6] == 'no' ? '#4B286D' : '#2B8000'), display: 'block', textAlign: 'center', fontSize: '12px' }}>
                      {d.existingProducts[6] == 'no' || d.existingProducts[6] == 'unavail' ? 'LWC' : d.existingProducts[6]}
                    </h1>
                  </a>

                </div>
              </div>

              <div style={{ display: 'block', width: '95%', marginTop: '5px', marginLeft: '2.5%', backgroundColor: userFrame.darkMode ? '#2A2C2E' : 'white', zIndex: 3 }}>
                <HairlineDivider gradient />
              </div>
              
              {width > 650 ?
                <div style={{ display: 'block', height: '100px', backgroundColor: userFrame.darkMode ? '#2A2C2E' : 'white',  borderRadius: '3px', zIndex: 6 }}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>

                    <div style={{ marginLeft:'2.5%', height: '100px', width: '38%', backgroundColor: userFrame.darkMode ? '#2A2C2E' : 'white', overflowY: 'hidden', display: 'inline-block' }}>
                      <h1 style={{ marginTop: '15px', color: userFrame.darkMode ? '#D8D8D8' : '#4B286D', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontWeight: 'bold' }}>
                        Sell Decision
                      </h1>
                      <h1 style={{ color: userFrame.darkMode ? '#D8D8D8' : 'black', fontSize: '14px' }}>
                        <span style={{ color: userFrame.darkMode ? 'white' : '#2B8000' }}>1.</span> Qualified for Altima Bell upto Internet GB
                      </h1>
                      <h1 style={{ color: userFrame.darkMode ? '#D8D8D8' : 'black', fontSize: '14px' }}>
                        <span style={{ color: userFrame.darkMode ? 'white' : '#2B8000' }}>2.</span> M&H Bounty - Sell FFH
                      </h1>
                      <h1 style={{ color: userFrame.darkMode ? '#D8D8D8' : 'black', fontSize: '14px' }}>
                        <span style={{ color: userFrame.darkMode ? 'white' : '#2B8000' }}>3.</span> Offer to exchange ADT lawn sign to TELUS sign
                      </h1>
                    </div>
                    <div style={{ height: '90px', width: '53%', marginLeft: '2%',marginTop: '20px', backgroundColor: userFrame.darkMode ? '#2A2C2E' : 'white', display: 'inline-block', borderRadius: '3px' }}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '100%', width: '100%' }}>
                          <div style={{ width: '16.5%' }}>
                            <div>
                              <ButtonHouse title={"No One Home"} icon={'NOH'} index={d.houseId - 1} extra={false}/>
                            </div>
                          </div>
                          <div style={{ width: '16.5%' }}>
                            <div>
                              <ButtonHouse title={"Soft No"} icon={'SN'} index={d.houseId - 1} extra={false} />
                            </div>
                          </div>
                          <div style={{ width: '16.5%' }}>
                            <div>
                              <ButtonHouse title={"Hard No"} icon={'HN'} index={d.houseId - 1} extra={false} />
                            </div>
                          </div>
                          <div style={{ width: '16.5%' }}>
                            <div>
                              <ButtonHouse title={"Wrong Party"} icon={'WP'} index={d.houseId - 1} extra={false} />
                            </div>
                          </div>
                          <div style={{ width: '16.5%' }}>
                            <div>
                              <ButtonHouse title={"Not Interested"} icon={'NI'} index={d.houseId - 1} extra={false} />
                            </div>
                          </div>
                          <div style={{ width: '16.5%' }}>
                            <div>
                              <ButtonHouse title={"Other Knocks"} icon={'OK'} index={d.houseId - 1} extra={false} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                :
                <div style={{ display: 'block', height: '90px', backgroundColor: userFrame.darkMode ? '#2A2C2E' : 'white', borderRadius: '3px', zIndex: 6 }}>
                  <div style={{ height: '90px', width: '90%', marginLeft: '5%', backgroundColor: userFrame.darkMode ? '#2A2C2E' : 'white', display: 'inline-block', borderRadius: '3px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <div style={{ width: '16.5%',}}>
                        <div style={{ }}>
                          <ButtonHouse title={"No One Home"} icon={'NOH'} index={d.houseId - 1} />
                        </div>
                      </div>
                      <div style={{ width: '16.5%' }}>
                        <div>
                          <ButtonHouse title={"Soft No"} icon={'SN'} index={d.houseId - 1} extra={window.innerWidth > 600 ? false : true} />
                        </div>
                      </div>
                      <div style={{ width: '16.5%' }}>
                        <div>
                          <ButtonHouse title={"Hard No"} icon={'HN'} index={d.houseId - 1} extra={window.innerWidth > 600 ? false : true} />
                        </div>
                      </div>
                      <div style={{ width: '16.5%' }}>
                        <div>
                          <ButtonHouse title={"Wrong Party"} icon={'WP'} index={d.houseId - 1} extra={window.innerWidth > 600 ? false : true} />
                        </div>
                      </div>
                      <div style={{ width: '16.5%' }}>
                        <div>
                          <ButtonHouse title={"Not Interested"} icon={'NI'} index={d.houseId - 1} extra={window.innerWidth > 600 ? false : true} />
                        </div>
                      </div>
                      <div style={{ width: '16.5%' }}>
                        <div>
                          <ButtonHouse title={"Other Knocks"} icon={'OK'} index={d.houseId - 1} extra={window.innerWidth > 600 ? false : true} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }


              {!d.expand &&
                <div style={{ width: '95%', marginLeft: '2.5%',marginTop: '20px', height: '55px', cursor: 'pointer', display: 'inline-block', backgroundColor: userFrame.darkMode ? '#2A2C2E' : 'white', zIndex: 3, overflow: 'hidden', borderRadius: '3px' }}>

                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', borderRadius: '3px' }}>
                    <button
                      style={{
                        borderColor: userFrame.darkMode ? '#2A2C2E' : '#2B8000', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center',
                        borderRadius: '5px', borderWidth: '1px', width: '100%', height: '40px', color: userFrame.darkMode ? 'black' : 'white',
                        borderStyle: 'solid', backgroundColor: userFrame.darkMode ? '#D8D8D8' : '#2B8000', textAlign: 'center'
                      }}
                      onClick={() => { placeOrder(true, d.houseId - 1) }}>
                      {"Place Order"}
                    </button>
                  </div>

                </div>
              }

              {d.expand &&

                (userFrame.loggingKnock ?
                  otherKnocksPopup()
                  :
                  (userFrame.followingUp ?
                    followUpPopup()
                    :

                    <div style={{ display: 'block', width: '100%', height: 'auto', backgroundColor: userFrame.darkMode ? '#2A2C2E' : 'white', borderRadius: '3px' }}>
                      {width < 650 &&
                      <div style={{ display: 'block', width: '100%', height: '300px', padding: '15px', backgroundColor: userFrame.darkMode ? '#2A2C2E' : 'white', }}>
                        <div style={{ height: '100%', width: '100%', backgroundColor: userFrame.darkMode ? '#2A2C2E' : 'white', boxShadow: userFrame.darkMode?'none':'1px 1px 3px 1px #9E9E9E',border:userFrame.darkMode?'1px solid #D8D8D8':'none', borderRadius: '3px' }}>
                          <h1 style={{ color: userFrame.darkMode ? 'white' : '#4B286D', marginLeft: '25px', paddingTop: '15px' }}>Sell Decision</h1>
                          <div style={{ marginTop: '30px', paddingBottom: '5px', marginLeft: '10%', width: '80%', height: '100px', backgroundColor: userFrame.darkMode ? '#2A2C2E' : 'white', color: userFrame.darkMode ? 'white' : 'black', overflowY: 'scroll', zIndex: 100 }}>
                            {/*<p style={{fontSize:'14px', textIndent:'-1.1em', paddingLeft:'1.1em'}}>
                          1. Renew Internet and offer a 1GB upgrade &nbsp;
                       
                        <Internet variant={userFrame.darkMode?"inverted":"default"} size={16} />
                      </p>
                      <p style={{fontSize:'14px', textIndent:'-1.1em', paddingLeft:'1.1em'}}>
                      
                          2. Add Home Security for a bundle discount &nbsp;
                       
                        <HomeSecurity variant={userFrame.darkMode?"inverted":"default"} size={16} />
                      </p>
                      <p style={{fontSize:'14px', textIndent:'-1.1em', paddingLeft:'1.1em'}}>
                         
                          3. Offer TELUS Online Security &nbsp;
                        
                          <OnlineSecurity variant={userFrame.darkMode?"inverted":"default"} size={16} />
                      </p>
                      <p style={{fontSize:'14px', textIndent:'-1.1em', paddingLeft:'1.1em'}}>
                         
                          4. Add mobile plan for a bundle discount &nbsp;
                       
                        <Phone variant={userFrame.darkMode?"inverted":"default"} size={16} />
                      </p>
                            {userFrame.dynamicNBA.map((offer, i) => {
                              return (
                                <div key={i} onClick={() => expandCollapseNBA(i)} style={{ width: '90%', height: offer.expand ? '100px' : '50px', borderRadius: '5px', marginLeft: '5%', marginTop: '10px', boxShadow: userFrame.darkMode ? 'none' : '1px 1px 3px 1px #9E9E9E', backgroundColor: userFrame.darkMode ? '#54595F' : 'white' }}>
                                  <div style={{ display: 'inline-block', width: '10%', height: '50px' }}>
                                    <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                      {renderIcon(offer.icon)}
                                    </div>
                                  </div>
                                  <div style={{ display: 'inline-block', width: '70%', height: '50px', verticalAlign: 'top' }}>
                                    <div style={{ height: '50px', width: '100%', display: 'flex', justifyContent: 'left', alignItems: 'center', }}>
                                      <p style={{ fontSize: '14px', fontWeight: 'bold' }}>
                                        {offer.title}
                                      </p>
                                    </div>
                                  </div>
                                  <div style={{ display: 'inline-block', width: '20%', height: '50px', verticalAlign: 'top' }}>
                                    <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'left', alignItems: 'center', }}>
                                      <h1 style={{ top: '10%', marginLeft: '5px', display: 'inline-block', color: userFrame.darkMode ? 'white' : '#4B286D', fontSize: '12px', cursor: 'pointer' }}>
                                        {offer.expand ? "Less" : "More"}
                                      </h1>
                                      <div style={{ display: 'inline-block', top: '10%', marginLeft: '1px', cursor: 'pointer' }}>
                                        <img style={{ marginBottom: '-5px' }} src={offer.expand ? (userFrame.darkMode ? whiteArrowUp : up) : (userFrame.darkMode ? whiteArrowDown : down)} width="20px" />
                                      </div>
                                    </div>
                                  </div>
                                  {offer.expand &&
                                    <div style={{ height: '50px', width: '100%', display: 'flex', justifyContent: 'left', alignItems: 'start', paddingLeft: '15px' }}>
                                      <p style={{ fontSize: '14px' }}>
                                        {offer.details}
                                      </p>
                                    </div>}
                                </div>)
                            })}*/}
                            <h1 style={{ color: userFrame.darkMode ? '#D8D8D8' : 'black', fontSize: '14px' }}>
                              <span style={{ color: userFrame.darkMode ? 'white' : '#2B8000' }}>1.</span> Qualified for Altima Bell upto Internet GB
                            </h1>
                            <h1 style={{ color: userFrame.darkMode ? '#D8D8D8' : 'black', fontSize: '14px' }}>
                              <span style={{ color: userFrame.darkMode ? 'white' : '#2B8000' }}>2.</span> M&H Bounty - Sell FFH
                            </h1>
                            <h1 style={{ color: userFrame.darkMode ? '#D8D8D8' : 'black', fontSize: '14px' }}>
                              <span style={{ color: userFrame.darkMode ? 'white' : '#2B8000' }}>3.</span> Offer to exchange ADT lawn sign to TELUS sign
                            </h1>
                          </div>

                          <div style={{ marginTop: '30px' }}>
                            <button
                              style={{
                                paddingTop: '7px', paddingBottom: '7px', paddingLeft: '45px', paddingRight: '45px',
                                color: 'white', fontWeight: 'bold', borderColor: userFrame.darkMode ? '#54595F' : '#2B8000', cursor: 'pointer',
                                borderRadius: '5px', borderWidth: '1px', width: '90%', marginLeft: '5%',
                                borderStyle: 'solid', backgroundColor: userFrame.darkMode ? '#54595F' : '#2B8000'
                              }}
                              onClick={() => placeOrder(true, d.houseId - 1)}
                            >
                              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                                <Deals variant={'inverted'} size={32} />
                                <h1 style={{ marginLeft: '20px' }}>{userFrame.redeem}</h1>
                              </div>
                            </button>

                            {/*<ButtonCustom color={userFrame.darkMode?'#54595F':'#2B8000'} text={"Place Order"} onButtonClicked={placeOrder} index={d.houseId-1} />*/}
                          </div>
                        </div>
                      </div>}

                      <div style={{ display: 'block', width: '100%', height: '480px', padding: '15px', backgroundColor: userFrame.darkMode ? '#2A2C2E' : 'white' }}>
                        <div style={{ height: '100%', width: '100%', backgroundColor: userFrame.darkMode ? '#2A2C2E' : 'white', boxShadow: userFrame.darkMode?'none':'1px 1px 3px 1px #9E9E9E', border:userFrame.darkMode?'1px solid #D8D8D8':'none', borderRadius: '3px' }}>
                          <h1 style={{ color: userFrame.darkMode ? 'white' : '#4B286D', marginLeft: '25px', paddingTop: '15px' }}>Premise Details</h1>
                          <div style={{ marginTop: '30px', marginLeft: '5%', height: '210px', width: '90%', backgroundColor: userFrame.darkMode ? '#2A2C2E' : 'white', color: userFrame.darkMode ? 'white' : 'black', overflowY: 'scroll', }}>
                            {getDetails(houses[d.houseId - 1])}
                          </div>
                          <div style={{ marginTop: '30px' }}>
                            <button
                              style={{
                                paddingTop: '7px', paddingBottom: '7px', paddingLeft: '45px', paddingRight: '45px',
                                color: userFrame.darkMode ? '#54595F' : 'white', fontWeight: 'bold', borderColor: userFrame.darkMode ? '#D8D8D8' : '#4B286D', cursor: 'pointer',
                                borderRadius: '5px', borderWidth: '1px', width: '90%', marginLeft: '5%',
                                borderStyle: 'solid', backgroundColor: userFrame.darkMode ? '#D8D8D8' : '#4B286D'
                              }}
                              onClick={() => setFollowUpButtonClicked(true, d.houseId - 1)}
                            >
                              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                                <ArrowUp variant={userFrame.darkMode ? 'alternative' : 'inverted'} size={32} />
                                <h1 style={{ marginLeft: '20px' }}>Schedule Follow Up</h1>
                              </div>
                            </button>
                            {/*<ButtonCustom color={userFrame.darkMode?'#D8D8D8':'#4B286D'}  text={"Schedule Follow Up"} onButtonClicked={setFollowUpButtonClicked} index={d.houseId-1} />*/}
                          </div>
                          <div style={{ marginTop: '30px' }}>
                            <button
                              style={{
                                paddingTop: '7px', paddingBottom: '7px', paddingLeft: '45px', paddingRight: '45px',
                                color: 'white', fontWeight: 'bold', borderColor: userFrame.darkMode ? '#54595F' : '#2B8000', cursor: 'pointer',
                                borderRadius: '5px', borderWidth: '1px', width: '90%', marginLeft: '5%',
                                borderStyle: 'solid', backgroundColor: userFrame.darkMode ? '#54595F' : '#2B8000'
                              }}
                              onClick={() => captureLead(true, d.houseId - 1)}
                            >
                              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                                <Clipboard variant={'inverted'} size={32} />
                                <h1 style={{ marginLeft: '20px' }}>Capture Lead</h1>
                              </div>
                            </button>
                            {/*<ButtonCustom color={userFrame.darkMode?'#54595F':'#2B8000'} text={"Capture Lead"} onButtonClicked={captureLead} index={d.houseId-1} />*/}
                          </div>
                        </div>
                      </div>

                      <div style={{ display: 'block', width: '100%', height: width<650?'380px':'430px', padding: '15px', backgroundColor: userFrame.darkMode ? '#2A2C2E' : 'white',  }}>
                        <div style={{ height: '100%', width: '100%', backgroundColor: userFrame.darkMode ? '#2A2C2E' : 'white', boxShadow: userFrame.darkMode?'none':'1px 1px 3px 1px #9E9E9E',border:userFrame.darkMode?'1px solid #D8D8D8':'none', borderRadius: '3px' }}>
                          <h1 style={{ color: userFrame.darkMode ? 'white' : '#4B286D', marginLeft: '25px', paddingTop: '15px' }}>Knock History</h1>
                          <div style={{ marginTop: '30px', marginLeft: '5%', height: '185px', width: '90%', backgroundColor: userFrame.darkMode ? '#2A2C2E' : 'white', color: userFrame.darkMode ? 'white' : 'black', overflowY: 'scroll', }}>
                            {getKnocks(houses[d.houseId - 1])}
                          </div>
                          
                          <div style={{ marginTop: '30px' }}>
                            <button
                              style={{
                                paddingTop: '7px', paddingBottom: '7px', paddingLeft: '45px', paddingRight: '45px',
                                color: userFrame.darkMode ? '#54595F' : 'white', fontWeight: 'bold', borderColor: userFrame.darkMode ? '#D8D8D8' : '#4B286D', cursor: 'pointer',
                                borderRadius: '5px', borderWidth: '1px', width: '90%', marginLeft: '5%',
                                borderStyle: 'solid', backgroundColor: userFrame.darkMode ? '#D8D8D8' : '#4B286D'
                              }}
                              onClick={() => collectDrop(true, d.houseId - 1)}
                            >
                              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                                <Delivery variant={userFrame.darkMode ? 'alternative' : 'inverted'} size={32} />
                                <h1 style={{ marginLeft: '20px' }}>Collect Drop</h1>
                              </div>
                            </button>
                            {/*<ButtonCustom color={userFrame.darkMode?'#D8D8D8':'#4B286D'}  text={"Collect Drop"} onButtonClicked={collectDrop} index={d.houseId-1}/>*/}
                          </div>
                          
                          {width>650 &&
                          <div style={{ marginTop: '10px' }}>
                          <button
                            style={{
                              paddingTop: '7px', paddingBottom: '7px', paddingLeft: '45px', paddingRight: '45px',
                              color: 'white', fontWeight: 'bold', borderColor: userFrame.darkMode ? '#54595F' : '#2B8000', cursor: 'pointer',
                              borderRadius: '5px', borderWidth: '1px', width: '90%', marginLeft: '5%',
                              borderStyle: 'solid', backgroundColor: userFrame.darkMode ? '#54595F' : '#2B8000'
                            }}
                            onClick={() => placeOrder(true, d.houseId - 1)}
                          >
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                              <Clipboard variant={'inverted'} size={32} />
                              <h1 style={{ marginLeft: '20px' }}>Order</h1>
                            </div>
                          </button>
                        </div>}
                        </div>
                      </div>




                    </div>
                  ))
                }
            </div>
          </div>
        </div>
      </div>
    )

    return listItems

  }

  function isExpanded(nba) {
    for (let i = 0; i < nba.length; i++) {
      if (nba[i].expand == true) {
        return true
      }
    }
    return false
  }

  function renderIcon(icon) {
    switch (icon) {
      case 'internet':
        return <Internet variant={userFrame.darkMode ? 'inverted' : "default"} size={16} />
      case 'online':
        return <OnlineSecurity variant={userFrame.darkMode ? 'inverted' : "default"} size={16} />
      case 'phone':
        return <Phone variant={userFrame.darkMode ? 'inverted' : "default"} size={16} />
      case 'home':
        return <HomeSecurity variant={userFrame.darkMode ? 'inverted' : "default"} size={16} />
      default:
        return <Internet variant={userFrame.darkMode ? 'inverted' : "default"} size={16} />
    }
  }

  function expandCollapseNBA(index) {

    const frame = userFrame
    let newDynamicNBA = frame.dynamicNBA
    if (newDynamicNBA[index].expand == false) {
      for (let i = 0; i < newDynamicNBA.length; i++) {
        if (index == i) {
          newDynamicNBA[i].expand = true
        } else {
          newDynamicNBA[i].expand = false
        }
      }
    } else {
      newDynamicNBA[index].expand = false
    }

    if (isExpanded(newDynamicNBA) == true) {
      frame.redeem = 'Redeem'
    } else {
      frame.redeem = 'Place Order'
    }

    frame.dynamicNBA = newDynamicNBA
    dispatch(updateUserFrame(frame))
  }

  function sortOddEven(value) {
    const u = userFrame
    if (value == true) {
      if (u.oddEven == 'odd') {
        u.oddEven = 'none'
      } else {
        u.oddEven = 'odd'
      }
    } else {
      if (u.oddEven == 'even') {
        u.oddEven = 'none'
      } else {
        u.oddEven = 'even'
      }
    }
    dispatch(updateUserFrame(u))
  }

  function removeFilter() {
    const u = userFrame
    u.filterSelection = 'none'
    dispatch(updateUserFrame(u))
  }

  const refClickOutside = useRef(null)

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    setWidth(window.innerWidth);
  });

  const handleClickOutside = (event) => {
    if (refClickOutside.current && !refClickOutside.current.contains(event.target)) {
      const u = userFrame
      u.filterPrompt = false
      dispatch(updateUserFrame(u))
    }
  };

  function showFilters() {

    return (
      <div style={{ height: '70px', width: '90%', marginLeft: '5%', }}>
        <div ref={refClickOutside}>
          {/* Filter by Knock */}
          <div style={{ position: 'absolute', top: '15px', left: '7%', width: '50px', height: '50px', backgroundColor: userFrame.darkMode ? '#54595F' : 'white' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', }}>
              <ButtonHouseFilter title={"Filter"} icon={userFrame.filterSelection != 'none' ? userFrame.filterSelection : 'unfiltered'} main={true} />
            </div>
          </div>
          <div style={{ position: 'absolute', top: '15px', left: '7%', marginLeft: '46px', height: '50px', backgroundColor: userFrame.darkMode ? '#54595F' : 'white' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', }}>
              {userFrame.filterSelection != 'none' ?
                <a onClick={() => { removeFilter() }}>
                  <Times />
                </a>
                : <></>}
            </div>
          </div>
          {userFrame.filterPrompt ?
            <>

              <div style={{ position: 'absolute', top: '55px', left: '7%', marginLeft: '50px', transform: 'translateY(-100%) translateX(-50%)', width: '30px', height: '30px', zIndex: 5, overflow: 'hidden' }}>
                <div style={{ position: 'relative', transform: 'translateX(30%) rotate(45deg)', width: '30px', height: '30px', backgroundColor: userFrame.darkMode ? '#2A2C2E' : 'white', zIndex: 5, boxShadow: '0 1px 2px 2px #9E9E9E' }}>
                </div>
              </div>

              <div style={{ position: 'absolute', top: '15px', left: '7%', marginLeft: '140px', transform: 'translateX(-50%)', width: '180px', height: '540px', backgroundColor: userFrame.darkMode ? '#2A2C2E' : 'white', borderRadius: '3px', zIndex: 4, boxShadow: '0 1px 2px 2px #9E9E9E' }}>
              </div>

              <div style={{ position: 'absolute', top: '15px', left: '7%', marginLeft: '140px', transform: 'translateX(-50%)', width: '180px', height: '540px', backgroundColor: userFrame.darkMode ? '#2A2C2E' : 'white', borderRadius: '3px', zIndex: 6, }}>
                <div style={{ width: '180px', height: '90px', display: 'block' }}>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <ButtonHouseFilter title={"Un-Knocked"} icon={'house'} main={false} />
                    <ButtonHouseFilter title={"No One Home"} icon={'NOH'} main={false} />
                  </div>
                </div>

                <div style={{ width: '180px', height: '90px', display: 'block' }}>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <ButtonHouseFilter title={"Soft No"} icon={'SN'} main={false} />
                    <ButtonHouseFilter title={"Hard No"} icon={'HN'} main={false} />
                  </div>
                </div>

                <div style={{ width: '180px', height: '90px', display: 'block' }}>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <ButtonHouseFilter title={"Wrong Party"} icon={'WP'} main={false} />
                    <ButtonHouseFilter title={"Not Interested"} icon={'NI'} main={false} />
                  </div>
                </div>

                <div style={{ width: '180px', height: '90px', display: 'block' }}>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <ButtonHouseFilter title={"Other Knocks"} icon={'OK'} main={false} />
                    <ButtonHouseFilter title={"Follow Up"} icon={'FU'} main={false} />
                  </div>
                </div>

                <div style={{ width: '180px', height: '90px', display: 'block' }}>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <ButtonHouseFilter title={"Multi Dwelling Unit"} icon={'multi'} main={false} />
                    <ButtonHouseFilter title={"Successful Knock"} icon={'SK'} main={false} />
                  </div>
                </div>
                <div style={{ width: '180px', height: '90px', display: 'block', }}>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <ButtonHouseFilter title={"Hot Houses"} icon={'hot'} main={false} />
                  </div>
                </div>
              </div>
            </>
            :
            <></>}
        </div>

        {/* Filter by Knock */}
        <div style={{ position: 'absolute', top: '10px', right: '5%', width: '250px', height: '70px', }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', }}>
            {/* Sort Buttons - row */}
            <div style={{ marginRight: '10px' }}>
              <a onClick={() => { sortOddEven(true) }} style={{ cursor: 'pointer' }}>

                <div style={{ display: 'block', }}>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <img src={userFrame.darkMode ? whiteDown : downC} style={{ backgroundColor: userFrame.darkMode ? '#54595F' : 'white', borderRadius: '24px', width: '28px' }} />

                  </div>
                </div>
                <div style={{ display: 'block', }}>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <h1 style={{ fontSize: '14px', color: userFrame.darkMode ? 'white' : '#4B286D', textAlign: 'center', textDecorationLine: userFrame.oddEven == 'odd' ? 'underline' : 'none' }}>Odd/Even</h1>
                  </div>
                </div>
              </a>

            </div>

            {/* Sort Buttons - row */}
            <div style={{ marginLeft: '10px' }}>
              <a onClick={() => { sortOddEven(false) }} style={{ cursor: 'pointer' }}>

                <div style={{ display: 'block', }}>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <img src={userFrame.darkMode ? whiteUp : upC} style={{ backgroundColor: userFrame.darkMode ? '#54595F' : 'white', borderRadius: '24px', width: '28px', }} />
                  </div>
                </div>
                <div style={{ display: 'block', }}>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <h1 style={{ fontSize: '14px', color: userFrame.darkMode ? 'white' : '#4B286D', textAlign: 'center', textDecorationLine: userFrame.oddEven == 'even' ? 'underline' : 'none' }}>Even/Odd</h1>
                  </div>
                </div>
              </a>

            </div>

            <button style={{ borderRadius: '5px', backgroundColor: userFrame.darkMode ? '#2A2C2E' : '#4B286D', color: 'white', fontWeight: 'bold', border: 'none', marginLeft: '10px' }}>
              New
            </button>

          </div>
        </div>
      </div>
    )
  }

  return (

    <div style={{ position: 'absolute', top: '10%', left: 0, height: '90%', width: '100%', overflowY: 'scroll', backgroundColor: userFrame.darkMode ? '#54595F' : 'white' }}>

      {showFilters()}

      {userFrame.searchString != "" ?
        <div style={{ width: '90%', paddingTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '5%', marginBottom: '20px' }}>

          <button onClick={() => { clearSearch() }}
            style={{
              width: '100%', height: '60px', backgroundColor: userFrame.darkMode ? '#2A2C2E' : 'white',
              color: userFrame.darkMode ? 'white' : '#C12335', fontWeight: 'bold', borderColor: '#C12335',
              border: '2px solid', borderRadius: '5px'
            }}>
            Clear Search
          </button>

        </div>
        :
        <></>
      }

      {getList(userFrame.searchString)}

      <div style={{ height: '10%', marginTop: '40px', }}>
        <div style={{ width: "100%", height: '70%', display: 'flex', justifyContent: 'center', }}>

          <button
            disabled={page > 1 ? false : true}
            onClick={() => { navigatePage(-1) }}
            style={{ backgroundColor: '#F2EFF4', borderRadius: '30px', height: '30px', width: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: '1px 1px 3px 1px #9E9E9E', borderStyle: 'none' }}>
            <img src={leftArrow} width={'20px'} />
          </button>
          <div style={{ marginRight: '7px', marginLeft: '7px', backgroundColor: '#F2EFF4', borderRadius: '30px', height: '30px', width: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: '1px 1px 3px 1px #9E9E9E', }}>
            {page}
          </div>
          <button
            disabled={page < 10 ? false : true}
            onClick={() => { navigatePage(1) }}
            style={{ backgroundColor: '#F2EFF4', borderRadius: '30px', height: '30px', width: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: '1px 1px 3px 1px #9E9E9E', borderStyle: 'none' }}>
            <img src={rightArrow} width={'20px'} />
          </button>

        </div>
      </div>
    </div>
  );
}

export default ListViewPhone;
