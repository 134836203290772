import React, { useEffect, useState, useRef } from "react";

import arrowDown from '../../../../icons/arrow/down/dgreen.png';
import arrowDownWhite from '../../../../icons/arrow/down/black.png';
import checkWhite from '../../../../icons/checkmark/white.png';
import checkBlack from '../../../../icons/checkmark/black.png';

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../../redux/actions/userFrameActions'

function ProvinceDropDownMenus () {

    const userFrame = useSelector(state=>state.user_frame_reducer[0])
    //const houses = useSelector(state=>state.house_reducer)
    const dispatch = useDispatch()

    const [provinceClicked, setprovinceClicked] = useState(false)
    const [hoverProvince,sethoverProvince] = useState('0')
    const [province, setprovince] = useState('AB')

    /* Handles the dropdown button */
    function dropDownprovince(val){
        sethoverProvince(province)
        setprovinceClicked(!provinceClicked) 
    }
    /* Handles the dropdown value selection */
    function setprovinces(provinces){
        setprovince(provinces)
        dropDownprovince(false)

        /* save to redux store
        const h = houses[userFrame.houseId-1]
        h.province = provinces
        dispatch(updateHouse(h))*/
    }
    /* Handles the hover effect */
    function hoverProvinceIsHovered(val){
        sethoverProvince(val)
    }
    /* Handles the un-hover effect */
    function unhoverProvince(){
        sethoverProvince('0')
    }


    /* Handles the clicks outside the div */
    const refprovince = useRef(null)

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (refprovince.current && !refprovince.current.contains(event.target)) {
            setprovinceClicked(false);
        }
    };
    
return (
    <div style={{width:'100%', display:'flex', justifyContent:'left', alignItems:'center', overflow:'visible'}}>

        <div ref={refprovince} style={{backgroundColor:userFrame.darkMode?'#54595F':'white', overflow:'visible',width:'100px',position:'relative' }}>
            
            {/*<h1 style={{fontWeight:'bold', fontSize:'16px', color:userFrame.darkMode?'white':'black'}}>
                Select
            </h1>*/}
            
            <button 
            onClick={() => dropDownprovince()}
            style={{backgroundColor:userFrame.darkMode?'#D8D8D8':'white', color:'black', border:'1px solid black', borderRadius:'4px',cursor:'pointer', 
            width:provinceClicked?'152px':'150px', height:provinceClicked?'52px':'50px', marginBottom:provinceClicked?'-2px':0  }}>

                <div style={{width:'100%',display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div style={{width:'100px',display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <h1>{province}</h1>
                    </div>
                    <div style={{width:'35px',display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <img src={userFrame.darkMode?arrowDownWhite:arrowDown} style={{width:'25px',}}/>
                    </div>
                </div>
            </button>
            
            <div style={{position:'absolute',top:'0',left:'-20%', visibility:provinceClicked?'visible':'hidden', }}>
            <div style={{width:'150px', backgroundColor:'rgb(228,229,229,0.95)', borderRadius:'5px', cursor:'pointer', boxShadow:'0px 0px 23px 3px rgba(0,0,0,0.2)', border:'1px solid rgb(210,210,210)'}}>
                <div style={{marginLeft:'5%',}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <h1 style={{fontWeight:'bold', textAlign:'left', color:'grey', marginLeft:'17px',  width:'70%'}}>
                            Province
                        </h1>
                    </div>
                </div>
                <a onClick={()=>setprovinces('AB')} onMouseEnter={()=>hoverProvinceIsHovered('AB')} onMouseLeave={()=>unhoverProvince()} 
                style={{display:'block', backgroundColor:   hoverProvince=='AB'?'#3996F9':'rgb(228,229,229,0.0)', margin:'5%', 
                width:'90%', height:'90%', borderRadius:'5px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {province=='AB'?<img src={ hoverProvince=='AB'? checkWhite : checkBlack} style={{width:'12px'}} />:<div style={{width:'12px'}}></div>}
                        <h1 style={{marginLeft:'5px',width:'70%', textAlign:'left', color: hoverProvince=='AB'? 'white':'black', fontWeight:'500'}}>AB</h1>
                    </div>
                </a>
                <a onClick={()=>setprovinces('BC')} onMouseEnter={()=>hoverProvinceIsHovered('BC')} onMouseLeave={()=>unhoverProvince()} 
                style={{display:'block', backgroundColor:   hoverProvince=='BC'?'#3996F9':'rgb(228,229,229,0.0)', margin:'5%', 
                width:'90%', height:'90%', borderRadius:'5px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {province=='BC'?<img src={ hoverProvince=='BC'? checkWhite : checkBlack} style={{width:'12px'}} />:<div style={{width:'12px'}}></div>}
                        <h1 style={{marginLeft:'5px',width:'70%', textAlign:'left', color: hoverProvince=='BC'? 'white':'black', fontWeight:'500'}}>BC</h1>
                    </div>
                </a>
                <a onClick={()=>setprovinces("MB")} onMouseEnter={()=>hoverProvinceIsHovered('MB')} onMouseLeave={()=>unhoverProvince()} 
                style={{display:'block', backgroundColor:   hoverProvince=='MB'?'#3996F9':'rgb(228,229,229,0.0)', margin:'5%', 
                width:'90%', height:'90%', borderRadius:'5px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {province=='MB'?<img src={ hoverProvince=='MB'? checkWhite : checkBlack} style={{width:'12px'}} />:<div style={{width:'12px'}}></div>}
                        <h1 style={{marginLeft:'5px',width:'70%', textAlign:'left', color: hoverProvince=='MB'? 'white':'black', fontWeight:'500'}}>MB</h1>
                    </div>
                </a>
                <a onClick={()=>setprovinces("NB")} onMouseEnter={()=>hoverProvinceIsHovered('NB')} onMouseLeave={()=>unhoverProvince()} 
                style={{display:'block', backgroundColor:   hoverProvince=='NB'?'#3996F9':'rgb(228,229,229,0.0)', margin:'5%', 
                width:'90%', height:'90%', borderRadius:'5px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {province=='NB'?<img src={ hoverProvince=='NB'? checkWhite : checkBlack} style={{width:'12px'}} />:<div style={{width:'12px'}}></div>}
                        <h1 style={{marginLeft:'5px',width:'70%', textAlign:'left', color: hoverProvince=='NB'? 'white':'black', fontWeight:'500'}}>NB</h1>
                    </div>
                </a>
                <a onClick={()=>setprovinces("NL")} onMouseEnter={()=>hoverProvinceIsHovered('NL')} onMouseLeave={()=>unhoverProvince()} 
                style={{display:'block', backgroundColor:   hoverProvince=='NL'?'#3996F9':'rgb(228,229,229,0.0)', margin:'5%', 
                width:'90%', height:'90%', borderRadius:'5px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {province=='NL'?<img src={ hoverProvince=='NL'? checkWhite : checkBlack} style={{width:'12px'}} />:<div style={{width:'12px'}}></div>}
                        <h1 style={{marginLeft:'5px',width:'70%', textAlign:'left', color: hoverProvince=='NL'? 'white':'black', fontWeight:'500'}}>NL</h1>
                    </div>
                </a>
                <a onClick={()=>setprovinces("NS")} onMouseEnter={()=>hoverProvinceIsHovered('NS')} onMouseLeave={()=>unhoverProvince()} 
                style={{display:'block', backgroundColor:   hoverProvince=='NS'?'#3996F9':'rgb(228,229,229,0.0)', margin:'5%', 
                width:'90%', height:'90%', borderRadius:'5px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {province=='NS'?<img src={ hoverProvince=='NS'? checkWhite : checkBlack} style={{width:'12px'}} />:<div style={{width:'12px'}}></div>}
                        <h1 style={{marginLeft:'5px',width:'70%', textAlign:'left', color: hoverProvince=='NS'? 'white':'black', fontWeight:'500'}}>NS</h1>
                    </div>
                </a>
                <a onClick={()=>setprovinces("NT")} onMouseEnter={()=>hoverProvinceIsHovered('NT')} onMouseLeave={()=>unhoverProvince()} 
                style={{display:'block', backgroundColor:   hoverProvince=='NT'?'#3996F9':'rgb(228,229,229,0.0)', margin:'5%', 
                width:'90%', height:'90%', borderRadius:'5px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {province=='NT'?<img src={ hoverProvince=='NT'? checkWhite : checkBlack} style={{width:'12px'}} />:<div style={{width:'12px'}}></div>}
                        <h1 style={{marginLeft:'5px',width:'70%', textAlign:'left', color: hoverProvince=='NT'? 'white':'black', fontWeight:'500'}}>NT</h1>
                    </div>
                </a>
                <a onClick={()=>setprovinces("NU")} onMouseEnter={()=>hoverProvinceIsHovered('NU')} onMouseLeave={()=>unhoverProvince()} 
                style={{display:'block', backgroundColor:   hoverProvince=='NU'?'#3996F9':'rgb(228,229,229,0.0)', margin:'5%', 
                width:'90%', height:'90%', borderRadius:'5px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {province=='NU'?<img src={ hoverProvince=='NU'? checkWhite : checkBlack} style={{width:'12px'}} />:<div style={{width:'12px'}}></div>}
                        <h1 style={{marginLeft:'5px',width:'70%', textAlign:'left', color: hoverProvince=='NU'? 'white':'black', fontWeight:'500'}}>NU</h1>
                    </div>
                </a>
                <a onClick={()=>setprovinces("ON")} onMouseEnter={()=>hoverProvinceIsHovered('ON')} onMouseLeave={()=>unhoverProvince()} 
                style={{display:'block', backgroundColor:   hoverProvince=='ON'?'#3996F9':'rgb(228,229,229,0.0)', margin:'5%', 
                width:'90%', height:'90%', borderRadius:'5px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {province=='ON'?<img src={ hoverProvince=='ON'? checkWhite : checkBlack} style={{width:'12px'}} />:<div style={{width:'12px'}}></div>}
                        <h1 style={{marginLeft:'5px',width:'70%', textAlign:'left', color: hoverProvince=='ON'? 'white':'black', fontWeight:'500'}}>ON</h1>
                    </div>
                </a>
                <a onClick={()=>setprovinces("PE")} onMouseEnter={()=>hoverProvinceIsHovered('PE')} onMouseLeave={()=>unhoverProvince()} 
                style={{display:'block', backgroundColor:   hoverProvince=='PE'?'#3996F9':'rgb(228,229,229,0.0)', margin:'5%', 
                width:'90%', height:'90%', borderRadius:'5px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {province=='PE'?<img src={ hoverProvince=='PE'? checkWhite : checkBlack} style={{width:'12px'}} />:<div style={{width:'12px'}}></div>}
                        <h1 style={{marginLeft:'5px',width:'70%', textAlign:'left', color: hoverProvince=='PE'? 'white':'black', fontWeight:'500'}}>PE</h1>
                    </div>
                </a>
                <a onClick={()=>setprovinces("SK")} onMouseEnter={()=>hoverProvinceIsHovered('SK')} onMouseLeave={()=>unhoverProvince()} 
                style={{display:'block', backgroundColor:   hoverProvince=='SK'?'#3996F9':'rgb(228,229,229,0.0)', margin:'5%', 
                width:'90%', height:'90%', borderRadius:'5px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {province=='SK'?<img src={ hoverProvince=='SK'? checkWhite : checkBlack} style={{width:'12px'}} />:<div style={{width:'12px'}}></div>}
                        <h1 style={{marginLeft:'5px',width:'70%', textAlign:'left', color: hoverProvince=='SK'? 'white':'black', fontWeight:'500'}}>SK</h1>
                    </div>
                </a>
                <a onClick={()=>setprovinces("YT")} onMouseEnter={()=>hoverProvinceIsHovered('YT')} onMouseLeave={()=>unhoverProvince()} 
                style={{display:'block', backgroundColor:   hoverProvince=='YT'?'#3996F9':'rgb(228,229,229,0.0)', margin:'5%', 
                width:'90%', height:'90%', borderRadius:'5px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {province=='YT'?<img src={ hoverProvince=='YT'? checkWhite : checkBlack} style={{width:'12px'}} />:<div style={{width:'12px'}}></div>}
                        <h1 style={{marginLeft:'5px',width:'70%', textAlign:'left', color: hoverProvince=='YT'? 'white':'black', fontWeight:'500'}}>YT</h1>
                    </div>
                </a>
            </div>
            </div>
        </div>
    
    </div>
);
  
};

  export default ProvinceDropDownMenus;