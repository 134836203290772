import React, { useEffect, useState } from "react";

import Box from '@tds/core-box'
import Button from '@tds/core-button'
import Heading from '@tds/core-heading'
import HairlineDivider from "@tds/core-hairline-divider";

import CustomCheckBox from "./CustomCheckBox";

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../redux/actions/userFrameActions'

function Settings() {

    const userFrame = useSelector(state=>state.user_frame_reducer[0])
    const houses = useSelector(state=>state.house_reducer)
    const dispatch = useDispatch()


    function handleLogoutClick(){

        const frame = userFrame;
        frame.logoutHandle = true
        dispatch(updateUserFrame(frame))
        
      }


  return (
      
        <div style={{display:'block', zIndex:10, width:'100%', height:'100%',backgroundColor:userFrame.darkMode?'#54595F':'white', }}>

                {/* Title */}
                <div style={{display:'block', marginLeft:'10%',  }}>
                    
                    <h1 style={{color:userFrame.darkMode?'white':'#4B286D', fontSize:'24px', marginLeft:'2%'}}>Settings</h1>
                    
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'90%', marginTop:'10px',marginBottom:'20px'}}>
                        <HairlineDivider/>
                    </div>

                    <Box tag="fieldset" between={2}>
                        <CustomCheckBox title={"Dark Mode"}/>
                    
                        <CustomCheckBox title={"Street View"}/>
                    </Box> 

                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'90%', marginTop:'20px'}}>
                        <HairlineDivider/>
                    </div>
                </div>
                
                {/* Title */}
                <div style={{display:'block', marginLeft:'10%', marginTop:'5%', width:'80%', height:'45%' }}>
                    <h1 style={{color:userFrame.darkMode?'white':'#4B286D', fontSize:'24px', marginLeft:'2%'}}>Submit Feedback</h1>
                    <textarea style={{width:'100%', height:'150px', resize:'none', padding:'15px', borderRadius:'3px', borderColor:userFrame.darkMode?'white':'#4B286D', color:userFrame.darkMode?'white':'#4B286D', outlineColor:userFrame.darkMode?'white':'#4B286D', backgroundColor:userFrame.darkMode?'#54595F':'white',}}/>
                    <button onClick={()=>{}} 
                        style={{width:'100%', height:'60px',marginTop:'25px', backgroundColor:userFrame.darkMode?'#D8D8D8':'#2B8000', 
                        color:userFrame.darkMode?'black':'white',fontWeight:'bold', borderColor:userFrame.darkMode?'#D8D8D8':'#2B8000', 
                        borderWidth:'2px', borderStyle:'solid', borderRadius:'5px'}}>
                    Submit
                    </button>
                </div>

                <div style={{display:'block', marginLeft:'10%', marginTop:'5%', width:'80%', height:'10%' }}>
                    <button onClick={()=>{handleLogoutClick()}} 
                    style={{width:'100%', height:'60px', backgroundColor:userFrame.darkMode?'#54595F':'white', 
                    color:userFrame.darkMode?'white':'#C12335',fontWeight:'bold', borderColor:userFrame.darkMode?'white':'#C12335', 
                    borderWidth:'2px', borderStyle:'solid', borderRadius:'5px'}}>
                    Logout
                    </button>
                </div>

                <div style={{display:'block',width:'100%', height:'15%', backgroundColor:userFrame.darkMode?'#54595F':'white' }}>

                </div>
                {/* Row Image 
                <div style={{ position:'absolute', bottom: '-200px', right: '0', width:'100%'}}>
                    <img src={plant} width="300px" style={{position:'absolute', bottom: '0', right: '0', zIndex:-1}}/>
                </div> */}
            
        </div>
  
  );
}

export default Settings;