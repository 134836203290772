import React, { Component, useState, useEffect } from 'react';

import ButtonIcon from './ButtonIcon';

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../../redux/actions/userFrameActions'

function SearchAndFilter({}) {

  const userFrame = useSelector(state=>state.user_frame_reducer[0])
  const houses = useSelector(state=>state.house_reducer)
  const dispatch = useDispatch()

  function search(event){
    
      const u = userFrame

      let houseExistsInSearch = false
      
      // upon key changes, check if the houses exists for this walklist
      houses.forEach(house => {
        if(house.address.includes(event.target.value) || house.address == ""){
            houseExistsInSearch = true
        }
      });
      // show banner if no houses match search
      if(houseExistsInSearch == false){
        u.houseOutsideWalklist = true
      }else{
        u.houseOutsideWalklist = false
      }
      
      u.searchStringPreSearch = event.target.value
      dispatch(updateUserFrame(u))

      
  }

  return (

    <div style = {{width: '100%',height:'100%',backgroundColor:userFrame.darkMode?'#54595F':'white', display:'flex', alignItems:'center',justifyContent:'center',  }}>
       
        {/*  View Button Selection  */}
        <div style={{ backgroundColor:userFrame.darkMode?'#54595F':'white', height: '100%', overflowY:'hidden',width:'100%', display:'flex', alignItems:'center',justifyContent:'center'}}>
            {/*<ButtonIcon icon = {'Search'}/>*/}
            <input name="Name" placeholder={"Search"} value={userFrame.searchStringPreSearch} onChange={search} 
            style={{height:'50px',width:'80%', borderRadius:'5px', outlineWidth:'1px', outlineColor:userFrame.darkMode?'#54595F':'#4B286D', backgroundColor:userFrame.darkMode?'#2A2C2E':'white',
            borderStyle:'solid',borderWidth:'1px', borderColor:userFrame.darkMode?'#54595F':'#4B286D', color:userFrame.darkMode?'white':'#4B286D',padding:'17px', outlineStyle:'none'}}/>
        </div>
    </div>

  );
}

export default SearchAndFilter;
