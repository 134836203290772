import React, { useEffect, useState, useRef } from "react";

import arrowDown from '../../../icons/arrow/down/dgreen.png';
import arrowDownWhite from '../../../icons/arrow/down/black.png';
import checkWhite from '../../../icons/checkmark/white.png';
import checkBlack from '../../../icons/checkmark/black.png';

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../redux/actions/userFrameActions'

function WalklistDropDownMenus () {

    const userFrame = useSelector(state=>state.user_frame_reducer[0])
    const houses = useSelector(state=>state.house_reducer)
    const dispatch = useDispatch()

    const [WalklistClicked, setWalklistClicked] = useState(false)
    const [hoverWalklist,sethoverWalklist] = useState('0')
    const [Walklist, setWalklist] = useState('AB7')

    /* Handles the dropdown button */
    function dropDownWalklist(val){
        sethoverWalklist(Walklist)
        setWalklistClicked(!WalklistClicked) 
    }
    /* Handles the dropdown value selection */
    function setWalklists(Walklists){
        setWalklist(Walklists)
        dropDownWalklist(false)

        /* save to redux store
        const h = houses[userFrame.houseId-1]
        h.Walklist = Walklists
        dispatch(updateHouse(h))*/
    }
    /* Handles the hover effect */
    function hoverWalklistIsHovered(val){
        sethoverWalklist(val)
    }
    /* Handles the un-hover effect */
    function unhoverWalklist(){
        sethoverWalklist('0')
    }


    /* Handles the clicks outside the div */
    const refWalklist = useRef(null)

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (refWalklist.current && !refWalklist.current.contains(event.target)) {
            setWalklistClicked(false);
        }
    };
    
return (
    <div style={{width:'100%', display:'flex', justifyContent:'left', alignItems:'center', overflow:'visible'}}>

        <div ref={refWalklist} style={{backgroundColor:userFrame.darkMode?'#2A2C2E':'white', overflow:'visible',width:'100px',position:'relative' }}>
            
            {/*<h1 style={{fontWeight:'bold', fontSize:'16px', color:userFrame.darkMode?'white':'black'}}>
                Select
            </h1>*/}
            
            <button 
            onClick={() => dropDownWalklist()}
            style={{backgroundColor:userFrame.darkMode?'#D8D8D8':'white', color:'black', border:'1px solid black', borderRadius:'4px',cursor:'pointer', 
            width:WalklistClicked?'152px':'150px', height:WalklistClicked?'52px':'50px', marginBottom:WalklistClicked?'-2px':'0'  }}>

                <div style={{width:'100%',display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div style={{width:'100px',display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <h1>{Walklist}</h1>
                    </div>
                    <div style={{width:'35px',display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <img src={userFrame.darkMode?arrowDownWhite:arrowDown} style={{width:'25px',}}/>
                    </div>
                </div>
            </button>
            
            <div style={{position:'absolute',top:'0',left:'-20%', visibility:WalklistClicked?'visible':'hidden', }}>
            <div style={{width:'150px', backgroundColor:'rgb(228,229,229,0.95)', borderRadius:'5px', cursor:'pointer', boxShadow:'0px 0px 23px 3px rgba(0,0,0,0.2)', border:'1px solid rgb(210,210,210)'}}>
                <div style={{marginLeft:'5%',}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <h1 style={{fontWeight:'bold', textAlign:'left', color:'grey', marginLeft:'17px',  width:'70%'}}>
                            Walklists
                        </h1>
                    </div>
                </div>
                <a onClick={()=>setWalklists('AB7')} onMouseEnter={()=>hoverWalklistIsHovered('AB7')} onMouseLeave={()=>unhoverWalklist()} 
                style={{display:'block', backgroundColor:   hoverWalklist=='AB7'?'#3996F9':'rgb(228,229,229,0.0)', margin:'5%', 
                width:'90%', height:'90%', borderRadius:'5px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {Walklist=='AB7'?<img src={ hoverWalklist=='AB7'? checkWhite : checkBlack} style={{width:'12px'}} />:<div style={{width:'12px'}}></div>}
                        <h1 style={{marginLeft:'5px',width:'70%', textAlign:'left', color: hoverWalklist=='AB7'? 'white':'black', fontWeight:'500'}}>AB7</h1>
                    </div>
                </a>
                <a onClick={()=>setWalklists('DC2')} onMouseEnter={()=>hoverWalklistIsHovered('DC2')} onMouseLeave={()=>unhoverWalklist()} 
                style={{display:'block', backgroundColor:   hoverWalklist=='DC2'?'#3996F9':'rgb(228,229,229,0.0)', margin:'5%', 
                width:'90%', height:'90%', borderRadius:'5px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {Walklist=='DC2'?<img src={ hoverWalklist=='DC2'? checkWhite : checkBlack} style={{width:'12px'}} />:<div style={{width:'12px'}}></div>}
                        <h1 style={{marginLeft:'5px',width:'70%', textAlign:'left', color: hoverWalklist=='DC2'? 'white':'black', fontWeight:'500'}}>DC2</h1>
                    </div>
                </a>
                <a onClick={()=>setWalklists("E94")} onMouseEnter={()=>hoverWalklistIsHovered('E94')} onMouseLeave={()=>unhoverWalklist()} 
                style={{display:'block', backgroundColor:   hoverWalklist=='E94'?'#3996F9':'rgb(228,229,229,0.0)', margin:'5%', 
                width:'90%', height:'90%', borderRadius:'5px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {Walklist=='E94'?<img src={ hoverWalklist=='E94'? checkWhite : checkBlack} style={{width:'12px'}} />:<div style={{width:'12px'}}></div>}
                        <h1 style={{marginLeft:'5px',width:'70%', textAlign:'left', color: hoverWalklist=='E94'? 'white':'black', fontWeight:'500'}}>E94</h1>
                    </div>
                </a>
                
           
               
            </div>
            </div>
        </div>
    
    </div>
);
  
};

  export default WalklistDropDownMenus;