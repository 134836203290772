import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../../redux/actions/userFrameActions'

function ButtonJoined({}) {

  const userFrame = useSelector(state=>state.user_frame_reducer[0])
  const houses = useSelector(state=>state.house_reducer)
  const dispatch = useDispatch()

  function handleClick(buttonNumber){
    
     // save button selection to redux state
    const frame = userFrame;
    frame.tabView = buttonNumber
    dispatch(updateUserFrame(frame))
    
  }

  function getTitle(value){
    
    if(value == 1){
      return 'List'
    }

    if(value == 2){
      return 'Map'
    }

    if(value == 3){
      return 'Search'
    }
    
  }


  return (
    <div>

      {userFrame.drawer == 1 && userFrame.tabView == 2? 
        <div style={{ marginLeft:userFrame.leftHandedDrawer?'-35%':'25%',width:'100%', backgroundColor:userFrame.darkMode?'#2A2C2E':'white', overflow:'hidden'}}>
            <h1 style={{color:userFrame.darkMode?'white':'#4B286D', fontSize:24, textAlign:'center',}}>{getTitle(userFrame.tabView)}</h1>
        </div>
      :
        <div style={{backgroundColor:userFrame.darkMode?'#2A2C2E':'white', height:'100%', width:'470px', display:'flex', alignItems:'center', justifyContent:'center'}}>
        <button
            style={{ 
            height:'40px', width:'150px', overflow:'hidden',
            cursor: 'pointer',display:'inline-block',
            color: userFrame.tabView == 1 ? 'white' : (userFrame.darkMode?'#2A2C2E':'#4B286D'), borderColor:(userFrame.darkMode?'#2A2C2E':'#4B286D'), 
            borderRadius:'5px', borderWidth:'1px',
            borderStyle: 'solid', backgroundColor:userFrame.tabView == 1 ? (userFrame.darkMode?'#54595F':'#4B286D') : (userFrame.darkMode?'#D8D8D8':'white'),boxShadow: userFrame.darkMode?'none':"1px 3px 1px #9E9E9E" }}
            onClick={() => handleClick(1)}
          >List View</button>

          <button
          style={{ 
          height:'40px', width:'150px', overflow:'hidden',
          margin: '4px',cursor: 'pointer',display:'inline-block',
          color: userFrame.tabView == 2 ? 'white' : (userFrame.darkMode?'#2A2C2E':'#4B286D'), borderColor:(userFrame.darkMode?'#2A2C2E':'#4B286D'), 
          borderRadius:'5px', borderWidth:'1px',
          borderStyle: 'solid', backgroundColor:userFrame.tabView == 2 ? (userFrame.darkMode?'#54595F':'#4B286D') : (userFrame.darkMode?'#D8D8D8':'white'),boxShadow: userFrame.darkMode?'none':"1px 3px 1px #9E9E9E" }}
          onClick={() => handleClick(2)}
          >Map View</button>

      
          <button
          style={{ 
          height:'40px', width:'150px', overflow:'hidden',
          cursor: 'pointer',display:'inline-block', borderWidth:'1px',
          color: userFrame.tabView == 3 ? 'white' : (userFrame.darkMode?'#2A2C2E':'#4B286D'), borderColor:(userFrame.darkMode?'#2A2C2E':'#4B286D'), 
          borderRadius:'5px', backgroundColor:userFrame.tabView == 3 ? (userFrame.darkMode?'#54595F':'#4B286D') : (userFrame.darkMode?'#D8D8D8':'white'),
          boxShadow: userFrame.darkMode?'none':"1px 3px 1px #9E9E9E",borderStyle: 'solid',  }}
          onClick={() => handleClick(3)}
          >Search</button> 
          </div>
      }
      
    </div>
  );
}

export default ButtonJoined;