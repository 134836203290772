import React, { useState } from "react";

import plant from '../../../../icons/images/salal_branch.png';

import { PikTV, Internet, Tv, HomeSecurity,OnlineSecurity, SecurityHouse,CallTalking,Phone, ArrowUp, Clipboard, Deals } from '@tds/core-decorative-icon'

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../../redux/actions/userFrameActions'

import up from '../../../../icons/arrow/up/purple.png';
import down from '../../../../icons/arrow/down/purple.png';
import whiteArrowUp from '../../../../icons/arrow/up/white.png';
import whiteArrowDown from '../../../../icons/arrow/down/white.png';

function HiddenRightSideBar({setOrderButtonClicked, setFollowUpButtonClicked}) {

    const userFrame = useSelector(state=>state.user_frame_reducer[0])
    const houses = useSelector(state=>state.house_reducer)
    const dispatch = useDispatch()

    const [size,setSize] = useState('45px')

    const [click,setClicked] = useState(false)

    const expand = '100px'
    const collapse = '25px'

    const expanded = '20px'
    const collapsed = '20px'

    function expandCollapse(value){
        setClicked(value)
    }

    function setters(hover){
        if(hover){
        setSize('55px')
        }else{
        setSize('45px')
        }
    }

    function captureLead(value) {
        alert('Capture Lead Screen')
    }

    function isExpanded(nba){
        for(let i = 0; i < nba.length; i++){
          if(nba[i].expand == true){
            return true
          }
        }
        return false
    }

    function placeOrder(value) {
        //const h = houses
        //h[userFrame.houseId-1].existingCustomer = !h[userFrame.houseId-1].existingCustomer
        const u = userFrame
        u.showPCO = true;
        dispatch(updateUserFrame(u))  
    }

    function renderIcon(icon){
        switch(icon){
            case 'internet':
            return <Internet variant={userFrame.darkMode?'inverted':"default"} size={16} />
            case 'online':
            return <OnlineSecurity variant={userFrame.darkMode?'inverted':"default"} size={16} />
            case 'phone':
            return <Phone variant={userFrame.darkMode?'inverted':"default"} size={16} />
            case 'home':
            return <HomeSecurity variant={userFrame.darkMode?'inverted':"default"} size={16} />
            default:
            return <Internet variant={userFrame.darkMode?'inverted':"default"} size={16} />         
        }
    }
    
    function expandCollapseNBA(index) {
    
        const frame = userFrame
        let newDynamicNBA = frame.dynamicNBA
        if(newDynamicNBA[index].expand == false){
            for(let i = 0; i<newDynamicNBA.length; i++){
            if(index == i){
                newDynamicNBA[i].expand = true
            }else{
                newDynamicNBA[i].expand = false
            }
            }
        }else{
            newDynamicNBA[index].expand = false
        }

        if(isExpanded(newDynamicNBA)==true){
            frame.redeem = 'Redeem'
        }else{
            frame.redeem = 'Place Order'
        }

        frame.dynamicNBA = newDynamicNBA
        dispatch(updateUserFrame(frame))
    }

  return (
    <>
    {/* Row Title */}
    <div style={{ position:'absolute', top:0, left:0, height:'80%', width:'100%', overflowY:'hidden',}}>
        <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
        <div style={{height:'450px',width:'100%',}}>
        <div style={{ marginLeft:'10%', height: '100px', width: '80%', backgroundColor: userFrame.darkMode ? '#2A2C2E' : 'white', overflowY: 'hidden', display: 'inline-block' }}>
                      <h1 style={{ marginTop: '15px', color: userFrame.darkMode ? '#D8D8D8' : 'black', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontWeight: 'bold' }}>
                        Sell Decision
                      </h1>
                      <h1 style={{ color: userFrame.darkMode ? '#D8D8D8' : 'black', fontSize: '14px' }}>
                        <span style={{ color: userFrame.darkMode ? 'white' : '#2B8000' }}>1.</span> Qualified for Altima Bell upto Internet GB
                      </h1>
                      <h1 style={{ color: userFrame.darkMode ? '#D8D8D8' : 'black', fontSize: '14px' }}>
                        <span style={{ color: userFrame.darkMode ? 'white' : '#2B8000' }}>2.</span> M&H Bounty - Sell FFH
                      </h1>
                      <h1 style={{ color: userFrame.darkMode ? '#D8D8D8' : 'black', fontSize: '14px' }}>
                        <span style={{ color: userFrame.darkMode ? 'white' : '#2B8000' }}>3.</span> Offer to exchange ADT lawn sign to TELUS sign
                      </h1>
                    </div>
        {/*<div style={{marginTop:'10%',marginLeft:'8%'}}>
            <h1 style={{color:userFrame.darkMode?'white':'#4B286D', fontSize:24 }}>Sell Decision</h1>
        </div>
        <div style={{ marginTop:'10%',paddingTop:'5px', height:'250px',backgroundColor:userFrame.darkMode?'#2A2C2E':'white', color:userFrame.darkMode?'white':'black', overflowY:'scroll',  zIndex:100}}>
            {userFrame.dynamicNBA.map((offer,i)=>{
                return(
                    <div key={i} onClick={()=>expandCollapseNBA(i)} style={{width:'90%', height:offer.expand?'100px':'50px', borderRadius:'5px',marginLeft:'5%',marginBottom:'10px', boxShadow:userFrame.darkMode?'none':'1px 1px 3px 1px #9E9E9E', backgroundColor:userFrame.darkMode?'#54595F':'white'}}>
                    <div style={{display:'inline-block', width:'10%',height:'50px'}}>
                        <div style={{height:'100%', width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {renderIcon(offer.icon)}
                        </div>
                    </div>
                    <div style={{display:'inline-block', width:'70%',height:'50px', verticalAlign:'top'}}>
                        <div style={{height:'50px', width:'100%',  display:'flex', justifyContent:'left', alignItems:'center',}}>
                        <p style={{fontSize:'14px', fontWeight:'bold'}}>
                            {offer.title}
                        </p>
                        </div>
                    </div>
                    <div style={{display:'inline-block', width:'20%',height:'50px', verticalAlign:'top'}}>
                        <div style={{height:'100%', width:'100%', display:'flex', justifyContent:'left', alignItems:'center',}}>
                        <h1 style={{top:'10%', marginLeft: '5px', display:'inline-block', color:userFrame.darkMode?'white':'#4B286D', fontSize:'12px', cursor:'pointer'}}>
                        {offer.expand?"Less":"More"}
                        </h1>
                        <div style={{display:'inline-block', top:'10%',marginLeft: '1px',cursor:'pointer' }}>
                            <img style={{ marginBottom:'-5px'}} src={offer.expand?(userFrame.darkMode?whiteArrowUp:up):(userFrame.darkMode?whiteArrowDown:down)} width="20px"/> 
                        </div>
                        </div>
                    </div>
                    {offer.expand && 
                    <div style={{height:'50px', width:'100%',  display:'flex', justifyContent:'left', alignItems:'start', paddingLeft:'15px', paddingRight:'5px'}}>
                        <p style={{fontSize:'14px'}}>
                            {offer.details}
                        </p>
                    </div>}
                    </div>)
                })}
           {/*</div> <p style={{fontSize:'14px'}}>
                1. Renew Internet and offer 1GB upgrade &nbsp;
              
               <Internet variant={userFrame.darkMode?"inverted":"default"} size={24} />
                <br></br>
                2. Add Home Security for bundle discount &nbsp;
               
               <HomeSecurity variant={userFrame.darkMode?"inverted":"default"} size={24} />
                <br></br>
                3. Offer TELUS Online Security &nbsp;
               
                <OnlineSecurity variant={userFrame.darkMode?"inverted":"default"} size={24} />

                <br></br>
                4. Add mobile for bundle discount &nbsp;
               
               <Phone variant={userFrame.darkMode?"inverted":"default"} size={24} />
            </p>
        </div>*/}
        
        <div style={{height:click?expanded:collapsed, width:'100%', display:'block', backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}></div>    


        <div style={{ display:'block',height:'50px', width:'100%',backgroundColor:userFrame.darkMode?'#2A2C2E':'white', display: 'block', overflowY:'hidden'}}>  
            <div style={{marginLeft:'10%'}}>
                <button
                    style={{ 
                    paddingTop: '7px', paddingBottom: '7px', paddingLeft: '45px', paddingRight: '45px',
                    color: 'white', fontWeight: 'bold', borderColor:userFrame.darkMode?'#54595F':'#2B8000', cursor:'pointer',
                    borderRadius:'5px', borderWidth: '1px', width:'90%', 
                    borderStyle: 'solid', backgroundColor: userFrame.darkMode?'#54595F':'#2B8000' }}
                    onClick={() => placeOrder(true)}
                    >
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', height:'100%'}}>
                        <Deals variant={'inverted'} size={32}/>
                        <h1 style={{marginLeft:'20px'}}>{userFrame.redeem}</h1>
                    </div>
                </button>
                {/*<ButtonRectangle text='Capture Lead' color={userFrame.darkMode?'#D8D8D8':'#2B8000'} onButtonClicked = {captureLead}/>*/}
            </div>  
        </div>

        <div style={{height:'10px', width:'100%', display:'block', backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}></div>    

        <div style={{ display:'block',height:'50px', width:'100%',backgroundColor:userFrame.darkMode?'#2A2C2E':'white', display: 'block', overflowY:'hidden'}}>  
            <div style={{marginLeft:'10%'}}>
                <button
                    style={{ 
                    paddingTop: '7px', paddingBottom: '7px', paddingLeft: '45px', paddingRight: '45px',
                    color: userFrame.darkMode? '#54595F':'white', fontWeight: 'bold', borderColor:userFrame.darkMode?'#D8D8D8':'#4B286D', cursor:'pointer',
                    borderRadius:'5px', borderWidth: '1px', width:'90%',
                    borderStyle: 'solid', backgroundColor: userFrame.darkMode?'#D8D8D8':'#4B286D' }}
                    onClick={() => setFollowUpButtonClicked(true)}
                >
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', height:'100%'}}>
                        <ArrowUp variant={userFrame.darkMode?'alternative':'inverted'} size={32}/>
                        <h1 style={{marginLeft:'20px'}}>Follow Up</h1>
                    </div>
                </button>
                {/*<ButtonRectangle text='Schedule Follow Up' color={userFrame.darkMode?'#54595F':'#4B286D'} onButtonClicked = {setFollowUpButtonClicked}/>*/}
            </div>  
        </div>
       
        </div>
        </div>
    </div>

    {/* Row Image */}
    {userFrame.darkMode?<></>:
    <div style={{cursor: 'pointer', position:'absolute', bottom: '0', left: '0',display:'flex',alignItems:'center',justifyContent:'center'}}>
            <img src={plant} width="100%"/>
    </div>}
    </>
  );
}

export default HiddenRightSideBar;