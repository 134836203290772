import House from './House'

/*
This object holds all the state variables globally (similar to object oriented programming - but for javascript!). 
It keeps track of the user and where they are in the app, which buttons have been clicked and so on. Throughout the lifetime of 
the app, there will only be 1 UserFrame object for simplicity, even though in redux it is in an array. Each time a change is dispatched 
to the redux store, it forces a re-render (using the useSelector) so that everything looks and runs smoothly.
*/
class UserFrame{

    drawer; // Integer - Map View drawer setting. 3 possible states: 0 (no drawer), 1 (large drawer), or 2 (small drawer) 
    tabNav; // Deprecated
    tabView; // Integer - Header Tab View Buttons. 3 possible states: 1 (List view), 2 (Map view), or 3 (Search view) 
    screenDepth; // Integer - Manages the pages that the app shows. 3 possible states: 1 (login screen), 2 (walk list selection screen), or 3 (map view/rest of app).
    followingUp; //  True or false - when true, shows the follow up screen
    loggingKnock; //  True or false - when true, shows the 'other knocks' screen (various knock selections)
    settings; // True or false - when true shows the settings screen over top of the walk list selection screen
    houseId; // Integer - keeps track of which houses we are looking at in the map view
    housesInitialized; // True or false - Prevents initializing the houses more than once
    houseVariablesInitialized; // True or false - Prevents initializing the houses variables more than once
    menu; // True or false - when true shows the menu screen over the map view (and list/search views)
    menuTab; // Integer - Manages the menu tab. 3 possible states: 1 (progress tab), 2 (follow-ups tab), or 3 (settings tab)
    searchTab; // Integer - Manages which search tab we are looking at. 2 possible states: 1 (search tab), 2 (advanced search tab),
    searchString; // String - if not empty, the list view will auto filter the array of houses by address
    searchStringPreSearch; // String - Holds the text input characters
    searchFields; // Array of Strings - holds the advnaced search fields

    confirmBox; // True or false - if true, shows the confirm box from the map view. Set via the HouseButtons.js
    confirmKnockType; // String - Tells the confirm box the knock type. It is set via the HouseButtons.js
    confirmAddress;  // String - Address for the confirm box. It is set via the HouseButtons.js
    confirmIndex; // Integer - HouseId for the confirm box. It is set via the HouseButtons.js
    confirmQuickKnock; // True or false - tells the confirm box if this is a quick knock or an 'other knock'. Set via the ButtonPill.js
    confirmIcon; // String
    confirmFollowUp; // True or false
    
    filteredHouses = [] // Array of House objects, filtered by icon
    followUps = [] // Array of follow up objects, initialized and defined in ConfirmBox.js under the setFollowUp() function
    followUpsComplete; // Deprecated - kept tallies of how many follow ups that have been completed
    followUpsTotal; // Integer - keeps track of total number of follow ups
    
    houseOutsideWalklist; // True or false - keeps track of whether the address being searched is in the walklist or not
    leftHandedDrawer; // True or false - toggles the left handed drawer. default is right sided drawer (false)

    softNoOverlay; // True or false - when true displays the overlay buttons for when 'soft no' quick knock is selected
    softNoOverlayIndex; // Integer - Keeps track of which house id the soft no was selected for

    filterPrompt; // True or False - when true displays the prompt that shows which houses to filter the list view by
    filterSelection; // String - keeps track of which house we want to filter by. If no filter, set to 'none'

    newTag; // Deprecated - made the new tag disappear
    darkMode; // True or false - toggles the dark mode
    showDetails; // True or false - when true, shows the details screen over the map view drawer

    showLegend;

    territory;
    zoom; // zoom level on walk list selection screen
    centerSelectionLat; // center map on walk list selection screen
    centerSelectionLng;
    locationLat; // center location for both maps
    locationLng;
    locationButtonClicked; // location button clicked
    zoomMap; // zoom level on map view (inside current walklist)
    defaultMapLat; //center map on map view (inside current walklist)
    defaultMapLng;
    houseLat; // current house coordinates
    houseLng;

    errorLogin; // Boolean - show error msg
    errorWalklistSelection; // Boolean - show error msg

    streetView; // Boolean - settings checkbox variables

    logoutHandle;
    dynamicNBA; // Object - next best actions data to be mapped to divs
    redeem;     // String - show the redeem/place order msg

    showPCO; //  Boolean - Shows products, commitments, and offers
    products; // Object containing list of proucts, commitments and offers

    viewCartDetails; // Boolean - expands drawer

    showProductDetails; // Boolean - shows the modal with offer details
    offerDetailsToShow; // Integer - offer id

    orderFlowPages; // Integer - (0, 1 or 2) 0 is unselected, 1 is selected, 2 indicates progression
    page; // quick access, in sync with array above

    /*
    Initialize the variables
    */
    constructor(){
        this.drawer = false;
        this.tabNav = 1;
        this.tabView = 2;
        this.screenDepth = 1
        this.followingUp = false;
        this.loggingKnock = false;
        this.settings = false;
        this.houseId=0;
        this.houseLat = null;
        this.houseLng = null;
        this.housesInitialized = false;
        this.houseVariablesInitialized = false;

        this.menu=false;
        this.menuTab = 1;
        this.searchTab = 1;
        this.searchString = ""
        this.searchStringPreSearch = ""
        this.searchFields = []
        
        this.confirmBox = false;
        this.confirmKnockType = "";
        this.confirmAddress = "";
        this.confirmIndex = 0;
        this.confirmIcon = "";
        this.confirmQuickKnock = true;
        this.confirmFollowUp = false;

        this.filteredHouses = [];

        this.followUps=[{
            "id": 3,
            "address": "125 Address Road, Vancouver, BC (MDU)",
            "name": "John Doe",
            "email": "email@address.com",
            "phone": "9055555555",
            "notes": "No notes",
            "date": "Fri, Mar 18, 2022",
            "time": "1:00 PM",
            "complete": false
        },];
        this.followUpsTotal = 0;
        this.followUpsComplete = 0;

        this.houseOutsideWalklist = false;

        this.leftHandedDrawer = false;
        this.softNoOverlay = false;
        this.softNoOverlayIndex = -1;
        this.filterPrompt = false;
        this.filterSelection = 'none'
        this.oddEven = 'none'
        this.newTag = 0;
        this.darkMode = false;
        this.showDetails = false;
        this.showLegend = true;
        
        this.territory = 1;
        this.zoom = 9;
        this.centerSelectionLat = 49.23;
        this.centerSelectionLng = -123.0;
        this.zoomMap = 18;
        this.defaultMapLat = 49.23;
        this.defaultMapLng = -123.16;
        this.locationLat = null;
        this.locationLng = null;
        this.locationButtonClicked = false;

        this.errorLogin = false;
        this.errorWalklistSelection = false;
        this.errorMainApp = false;
        this.streetView = false;

        this.logoutHandle = false;

        this.dynamicNBA = [
            {title:"Avenger Bounty", details:"Huge savings on internet and TV (up to $100/month)", icon:'internet', expand: false},
            {title:"Lawn Sign Exchange", details:"Offer to exchange ADT lawn sign(s) for TELUS lawn sign(s) free of charge", icon:'home', expand: false},
            {title:"Bundle up with security to save", details:"Offer TELUS Online Security", icon:'online', expand: false},
            {title:"Add mobile plan for a bundle discount", details:"", icon:'phone', expand: false}
        ]

        this.redeem = 'Place Order';
        this.showPCO = false;

        this.products = [
            {type:'TV', 
                secondaryIcon:"Channels",
                description:'The best in home entertainment is just a click away with your favourite channels and specialty On Demand content.', 
                commitments:[
                    {term: 'No term', desc: 'No commitment'},
                    {term:'12', desc:'Big savings for the first 6 months when you sign up for a 1 year commitment.'},
                    {term:'24', desc:'Get a special offer when you sign up for a 2 year commitment.'},
                    {term:'36', desc:'Big savings for the first 36 months when you sign up for a 3 year commitment.'}], 
                offers: [
                    {id: 20,price:'43',priceNoTerm:'58', desc:'Essentials + 1 Premium Content.', finePrint:'For the first 24 months, and $58/mo. thereafter', finePrintPromo:'For the first 24 months, and $48/mo. thereafter', promoDiscount:'10', pricePromo:'33',
                        details: [
                            {icon: 'essentials',description:'Essentials', finePrint:'36 HD channels to choose from, including: abc HD, AMI tele, aptn, Meteo Media, CBC News, CBC, CBS, ICI tele, Global BC, K:, unis tv, CHEK, OMNI, Joy tv, CTV 2, CTV, Miracle Channel, Citytv, cpac, FOX, HOPE TV, KCTS9, NBC, THE CW Seattle, Television, Alberta Assembly TV, ABC Seattle HD, game tv, Global Calgary, yes tv, FOX Seattle HD, FOX Seattle, PBS Spokane, PBS Spokane HD, OMNI Calgary, OMNI Prairies, Stingray, ICI RDI, Daystar Canada, TV5, TVA (West), The Weather Network, Vision TV, AMI-audio, Prime Asia, WOW HDTV, ONE, and inuit TV'},
                            {icon: 'premium',description:'Premium Options', finePrint:'Choose 1 premium option: Crave, Netflix, WWE Network HD, Abu Dhabi TV, Sportsnet World Pack, Chinese Super Pack, Sportsnet World Pack, beIN Sports Pack, South Asian Premium, Ultimate Sports, Odyssey 1, The Filipino Channel, GMA Pinoy TV, Abu Dhabi TV, All TV, All TV-K, RTVi, VGNtv, and TV Japan HD'},
                        ],
                        gift:'Get a FREE 55” Samsung 4K HDR TV when bundled with Optik TV and Internet',
                        giftIcon:'PikTV',
                    }, 
                    {id: 19,price:'63',priceNoTerm:'78', desc:'Essentials + 4 Theme Packs + 1 Premium Content.', finePrint:'For the first 24 months, and $78/mo. thereafter', finePrintPromo:'For the first 24 months, and $68/mo. thereafter', promoDiscount:'10', pricePromo:'53',
                        details: [
                            {icon: 'essentials',description:'Essentials', finePrint:'36 HD channels to choose from, including: abc HD, AMI tele, aptn, Meteo Media, CBC News, CBC, CBS, ICI tele, Global BC, K:, unis tv, CHEK, OMNI, Joy tv, CTV 2, CTV, Miracle Channel, Citytv, cpac, FOX, HOPE TV, KCTS9, NBC, THE CW Seattle, Television, Alberta Assembly TV, ABC Seattle HD, game tv, Global Calgary, yes tv, FOX Seattle HD, FOX Seattle, PBS Spokane, PBS Spokane HD, OMNI Calgary, OMNI Prairies, Stingray, ICI RDI, Daystar Canada, TV5, TVA (West), The Weather Network, Vision TV, AMI-audio, Prime Asia, WOW HDTV, ONE, and inuit TV'},
                            {icon: 'premium',description:'Premium Options', finePrint:'Choose 1 premium option: Crave, Netflix, WWE Network HD, Abu Dhabi TV, Sportsnet World Pack, Chinese Super Pack, Sportsnet World Pack, beIN Sports Pack, South Asian Premium, Ultimate Sports, Odyssey 1, The Filipino Channel, GMA Pinoy TV, Abu Dhabi TV, All TV, All TV-K, RTVi, VGNtv, and TV Japan HD'},
                            {icon: 'theme',description:'Theme Packs', finePrint:'Choose 4 Theme Packs, including: Prime Time, Living, TSN & Beyond, Sportsnet & Beyond, Blockbusters, Disney Time, World & Beyond, Lifestyle & Beyond, Explore, Time Shift, Laughs and Cheers, Amazon Prime, Super Channel, Hollywood Suite, Favourite Films, Adrenaline, South Asian Cricket Pack, Documentary, Superstations, Rural Outdoors, Family Time, Kids Time, Openfit, Calm, Hayu, Knowledge, Nature & Civilization, News, More News, Laughs and Cheers, Medley, Variety, and International Packs (Arabic, German, Italian, Vietnamese, Ukranian, South Asian, Chinese, French, Filipino, and Portuguese)'},
                        ],
                        gift:'Get a FREE 55” Samsung 4K HDR TV when bundled with Optik TV and Internet',
                        giftIcon:'PikTV',
                    }, 
                    {id: 18,price:'48',priceNoTerm:'58', desc:'Essentials + 3 Theme Packs.', finePrint:'For the first 24 months, and $58/mo. thereafter', finePrintPromo:'For the first 24 months, and $48/mo. thereafter', promoDiscount:'10', pricePromo:'38',
                        details: [
                            {icon: 'essentials',description:'Essentials', finePrint:'36 HD channels to choose from, including: abc HD, AMI tele, aptn, Meteo Media, CBC News, CBC, CBS, ICI tele, Global BC, K:, unis tv, CHEK, OMNI, Joy tv, CTV 2, CTV, Miracle Channel, Citytv, cpac, FOX, HOPE TV, KCTS9, NBC, THE CW Seattle, Television, Alberta Assembly TV, ABC Seattle HD, game tv, Global Calgary, yes tv, FOX Seattle HD, FOX Seattle, PBS Spokane, PBS Spokane HD, OMNI Calgary, OMNI Prairies, Stingray, ICI RDI, Daystar Canada, TV5, TVA (West), The Weather Network, Vision TV, AMI-audio, Prime Asia, WOW HDTV, ONE, and inuit TV'},
                            {icon: 'theme',description:'Theme Packs', finePrint:'Choose 3 Theme Packs, including: Prime Time, Living, TSN & Beyond, Sportsnet & Beyond, Blockbusters, Disney Time, World & Beyond, Lifestyle & Beyond, Explore, Time Shift, Laughs and Cheers, Amazon Prime, Super Channel, Hollywood Suite, Favourite Films, Adrenaline, South Asian Cricket Pack, Documentary, Superstations, Rural Outdoors, Family Time, Kids Time, Openfit, Calm, Hayu, Knowledge, Nature & Civilization, News, More News, Laughs and Cheers, Medley, Variety, and International Packs (Arabic, German, Italian, Vietnamese, Ukranian, South Asian, Chinese, French, Filipino, and Portuguese)'},
                        ],
                        gift:'Get a FREE 55” Samsung 4K HDR TV when bundled with Optik TV and Internet',
                        giftIcon:'PikTV',
                    },
                ],
                product: false, commitment: '', offer: 0, promoTab: 'offer', promoActivated: false, expand: true, 
                terms: [
                    {icon: 'switch',description:'Change these options in your My TELUS account every 30 days', },
                    {icon: 'receiver',description:'Includes one 4K PVR and one 4K digital box', },
                    {icon: 'location',description:"Moving? We’ll transfer you for free", },
                    {icon: 'tools',description:"Self install, in-person install, or video call installation", },

                ]
            },

            {type:'Internet', 
                secondaryIcon:'Data',
                description:'Stay connected with TELUS PureFibre, the #1 Internet technology for speed and reliability.', 
                commitments:[
                    {term: 'No term', desc: 'No commitment'},
                    {term:'12', desc:'Big savings for the first 6 months when you sign up for a 1 year commitment.'},
                    {term:'24', desc:'Get a special offer when you sign up for a 2 year commitment.'},
                    {term:'36', desc:'Big savings for the first 36 months when you sign up for a 3 year commitment.'}
                ], 
                offers: [
                    {id: 11, price:'99', priceNoTerm:'135', desc:'PureFibre 940 Mbps Upload/Download', finePrint:'For the first 24 months, and $135/mo. thereafter', finePrintPromo:'For the first 24 months, and $125/mo. thereafter', promoDiscount:'10', pricePromo:'89',
                        details: [
                            {icon: 'speed',description:'Up to 940 Mbps', finePrint:'Upload Speed'},
                            {icon: 'speed',description:'Up to 940 Mbps', finePrint:'Download Speed'},
                            {icon: 'wifiArrows',description:'The fastest Wi-Fi, powered by Wi-Fi 6', finePrint:'Wi-Fi equipment rental included'},
                            {icon: 'data',description:'Unlimited data on a 2 year term, then $20/month', finePrint:'Otherwise, a 1 TB data limit applies'},
                            {icon: 'devices',description:'20-30 devices', finePrint:'Streaming or gaming'},
                        ],
                        gift:'Get a FREE 55” Samsung 4K HDR TV when bundled with Optik TV and Internet',
                        giftIcon:'PikTV',
                    }, 
                    {id: 12,price:'155', priceNoTerm:'175', desc:'2.5 Gbps Upload/Download', finePrint:'Get unlimited usage for 24 mo. on a 2 year term. 1TB/mo. with no term.', finePrintPromo:'Get unlimited usage for 24 mo. on a 2 year term. 1TB/mo. with no term.', promoDiscount:'10', pricePromo:'145',
                        details: [
                            {icon: 'speed',description:'Up to 2.5 Gbps', finePrint:'Upload Speed'},
                            {icon: 'speed',description:'Up to 2.5 Gbps', finePrint:'Download Speed'},
                            {icon: 'wifiArrows',description:'The fastest Wi-Fi, powered by Wi-Fi 6', finePrint:'Wi-Fi equipment rental included'},
                            {icon: 'data',description:'Unlimited data on a 2 year term, then $20/month', finePrint:'Otherwise, a 1 TB data limit applies'},
                            {icon: 'devices',description:'30+ devices', finePrint:'Streaming or gaming'},
                        ],
                        gift:'Get a FREE 55” Samsung 4K HDR TV when bundled with Optik TV and Internet',
                        giftIcon:'PikTV',
                    },  
                    {id: 13,price:'119',priceNoTerm:'155', desc:'940 Mbps Upload/1.5 Gbps Download', finePrint:'For the first 24 months, and $155/mo. thereafter', finePrintPromo:'For the first 24 months, and $145/mo. thereafter', promoDiscount:'10', pricePromo:'109',
                        details: [
                            {icon: 'speed',description:'Up to 940 Mbps', finePrint:'Upload Speed'},
                            {icon: 'speed',description:'Up to 1.5 Gbps', finePrint:'Download Speed'},
                            {icon: 'wifiArrows',description:'The fastest Wi-Fi, powered by Wi-Fi 6', finePrint:'Wi-Fi equipment rental included'},
                            {icon: 'data',description:'Unlimited data on a 2 year term, then $20/month', finePrint:'Otherwise, a 1 TB data limit applies'},
                            {icon: 'devices',description:'20-30 devices', finePrint:'Streaming or gaming'},
                        ],
                        gift:'Get a FREE 55” Samsung 4K HDR TV when bundled with Optik TV and Internet',
                        giftIcon:'PikTV',
                    }, 
                    {id: 14,price:'83',priceNoTerm:'115',  desc:'750 Mbps Upload/Download', finePrint:'For the first 24 months, and $115/mo. thereafter', finePrintPromo:'For the first 24 months, and $105/mo. thereafter', promoDiscount:'10', pricePromo:'73',
                        details: [
                            {icon: 'speed',description:'Up to 750 Mbps', finePrint:'Upload Speed'},
                            {icon: 'speed',description:'Up to 750 Mbps', finePrint:'Download Speed'},
                            {icon: 'wifiArrows',description:'The fastest Wi-Fi, powered by Wi-Fi 6', finePrint:'Wi-Fi equipment rental included'},
                            {icon: 'data',description:'Unlimited data on a 2 year term, then $20/month', finePrint:'Otherwise, a 750 GB data limit applies'},
                            {icon: 'devices',description:'20-30 devices', finePrint:'Streaming or gaming'},
                        ],
                        gift:'Get a FREE 55” Samsung 4K HDR TV when bundled with Optik TV and Internet',
                        giftIcon:'PikTV',
                    }, 
                    {id: 15,price:'80',priceNoTerm:'110', desc:'500 Mbps Upload/Download', finePrint:'For the first 24 months, and $110/mo. thereafter', finePrintPromo:'For the first 24 months, and $100/mo. thereafter', promoDiscount:'10', pricePromo:'70',
                        details: [
                            {icon: 'speed',description:'Up to 500 Mbps', finePrint:'Upload Speed'},
                            {icon: 'speed',description:'Up to 500 Mbps', finePrint:'Download Speed'},
                            {icon: 'wifiArrows',description:'The fastest Wi-Fi, powered by Wi-Fi 6', finePrint:'Wi-Fi equipment rental included'},
                            {icon: 'data',description:'Unlimited data on a 2 year term, then $20/month', finePrint:'Otherwise, a 500 GB data limit applies'},
                            {icon: 'devices',description:'20-30 devices', finePrint:'Streaming or gaming'},
                        ],
                        gift:'Get a FREE 55” Samsung 4K HDR TV when bundled with Optik TV and Internet',
                        giftIcon:'PikTV',
                    },
                    {id: 16,price:'75',priceNoTerm:'105',  desc:'150 Mbps Upload/Download', finePrint:'For the first 24 months, and $105/mo. thereafter',finePrintPromo:'For the first 24 months, and $105/mo. thereafter', promoDiscount:'10', pricePromo:'65',
                        details: [
                            {icon: 'speed',description:'Up to 150 Mbps', finePrint:'Upload Speed'},
                            {icon: 'speed',description:'Up to 150 Mbps', finePrint:'Download Speed'},
                            {icon: 'wifiArrows',description:'The fastest Wi-Fi, powered by Wi-Fi 6', finePrint:'Wi-Fi equipment rental included'},
                            {icon: 'data',description:'Unlimited data on a 2 year term, then $20/month', finePrint:'Otherwise, a 500 GB data limit applies'},
                            {icon: 'devices',description:'1-10 devices', finePrint:'Streaming or gaming'},
                        ],
                        gift:'Get a FREE 55” Samsung 4K HDR TV when bundled with Optik TV and Internet',
                        giftIcon:'PikTV',
                    },
                    {id: 17,price:'70',priceNoTerm:'90',  desc:'50 Mbps Upload/Download', finePrint:'For the first 24 months, and $90/mo. thereafter',finePrintPromo:'For the first 24 months, and $80/mo. thereafter', promoDiscount:'10', pricePromo:'60',
                        details: [
                            {icon: 'speed',description:'Up to 50 Mbps', finePrint:'Upload Speed'},
                            {icon: 'speed',description:'Up to 50 Mbps', finePrint:'Download Speed'},
                            {icon: 'wifiArrows',description:'The fastest Wi-Fi, powered by Wi-Fi 6', finePrint:'Wi-Fi equipment rental included'},
                            {icon: 'data',description:'Unlimited data on a 2 year term, then $20/month', finePrint:'Otherwise, a 500 GB data limit applies'},
                            {icon: 'devices',description:'1-10 devices', finePrint:'Streaming or gaming'},
                        ],
                        gift:'Get a FREE 55” Samsung 4K HDR TV when bundled with Optik TV and Internet',
                        giftIcon:'PikTV',
                    },
                ], 
                product: false, commitment: '', offer: 0, promoTab: 'offer',promoActivated: false, expand: true, 
                terms: [
                    {icon: 'clock',description:'30 day money-back guarantee*', },
                    {icon: 'tools',description:'Flexible installation options', },
                    {icon: 'location',description:"Moving? We’ll transfer you for free.", }
                ]
            },

            {type:'SHS', 
                secondaryIcon:"Camera",
                description:'Getting Smart Home Security is the safest way to live in the 21st century.', 
                commitments:[
                    {term: 'No term', desc: 'No commitment'},
                    {term:'36', desc:'Big savings for the first 36 months when you sign up for a 3 year commitment.'},
                    {term:'60', desc:'Get a special offer when you sign up for a 5 year commitment.'}
                ],
                offers: [
                    {id: 10,price:'15',priceNoTerm:'15', desc:'1 Camera + Smartphone Monitoring', finePrint:'Requires Telus internet', promoDiscount:'none',
                        details: [
                            {icon: 'iphone',description:'Self Monitoring', finePrint:'From the SmartHome app'},
                            {icon: 'camera',description:'1 Camera of your choice', finePrint:'Indoor, Outdoor, Doorbell camera'},
                            {icon: 'houseLock',description:'Online Security', finePrint:'Basic version'},
                        ],
                        gift:'1 month free with self install',
                        giftIcon:'PiggyBank',
                    },  
                    {id: 9,price:'18',priceNoTerm:'33', desc:'1 Camera + Smartphone Monitoring + 2 Automation devices', finePrint:'For the first 36 months, and $33/mo. thereafter', finePrintPromo:'For the first 24 months, and $23/mo. thereafter', promoDiscount:'10', pricePromo:'8',
                        details: [
                            {icon: 'iphone',description:'Self Monitoring', finePrint:'From the SmartHome app'},
                            {icon: 'lightbulb',description:'2 Home Automation Devices of your choice', finePrint:'Smart Garage Door Opener, Smart Lock, Smart Light Bulb, Smart Plus, Smart Thermostat, Carbon Monoxide Detector, Flood Sensor, Glass Break Sensor, Smart Smoke Detector, Amazon Echo Dot, and Amazon Echo Show'},
                            {icon: 'camera',description:'1 Camera of your choice', finePrint:'Indoor, Outdoor, Doorbell camera'},
                            {icon: 'houseLock',description:'Online Security', finePrint:'Basic version'},
                            {icon: 'control',description:'1 Control Panel', finePrint:'For your home security system'},
                        ],
                        gift:'1 month free with self install',
                        giftIcon:'PiggyBank',
                    },  
                    {id: 8,price:'32', priceNoTerm:'47', desc:'24/7 Professional Monitoring/Response + Security Sensor Kit', finePrint:'For the first 36 months, and $47/mo. thereafter', finePrintPromo:'For the first 24 months, and $47/mo. thereafter', promoDiscount:'10', pricePromo:'22',
                        details: [
                            {icon: 'checkBadge',description:'Professional Monitoring', finePrint:'With 24/7 alarm response'},
                            {icon: 'boxUp',description:'1 Security Starter Kit', finePrint:'With 2 door/window sensors & 1 motion sensor'},
                            {icon: 'houseLock',description:'Online Security', finePrint:'Standard version'},
                            {icon: 'control',description:'1 Control Panel', finePrint:'For your home security system'},
                        ],
                        gift:'$200 bill credit with this plan',
                        giftIcon:'PiggyBank',
                    }, 
                    {id: 7,price:'45',priceNoTerm:'67', desc:'24/7 Professional Monitoring/Response + Security Sensor Kit + 3 Automation Devices + Security Sensor Kit', finePrint:'For the first 36 months, and $67/mo. thereafter',finePrintPromo:'For the first 24 months, and $67/mo. thereafter', promoDiscount:'10', pricePromo:'35',
                        details: [
                            {icon: 'checkBadge',description:'Professional Monitoring', finePrint:'With 24/7 alarm response'},
                            {icon: 'boxUp',description:'1 Security Starter Kit', finePrint:'With 2 door/window sensors & 1 motion sensor'},
                            {icon: 'lightbulb',description:'3 Home Automation Devices of your choice', finePrint:'Smart Garage Door Opener, Smart Lock, Smart Light Bulb, Smart Plus, Smart Thermostat, Carbon Monoxide Detector, Flood Sensor, Glass Break Sensor, Smart Smoke Detector, Amazon Echo Dot, and Amazon Echo Show'},
                            {icon: 'camera',description:'1 Camera of your choice', finePrint:'Indoor, Outdoor, Doorbell camera'},
                            {icon: 'houseLock',description:'Online Security', finePrint:'Standard version'},
                            {icon: 'control',description:'1 Control Panel', finePrint:'For your home security system'},
                        ],
                        gift:'$200 bill credit with this plan',
                        giftIcon:'PiggyBank',
                    },
                ],  
                product: false, commitment: '', offer: 0, promoTab: 'offer',promoActivated: false, expand: true, 
                terms: [
                    {icon: 'iphone',description:'Monitor your home from anywhere', },
                    {icon: 'fingerprint',description:'Safeguard your identity', },
                    {icon: 'tools',description:"Flexible, easy installation", }
                ]
            },

            {type:'Phone', 
                secondaryIcon:"CallOut",
                description:"Add Home Phone service to take advantage of great savings on Canada's most reliable phone service.", 
                commitments:[
                    {term: 'No term', desc: 'No commitment'},
                    {term:'12', desc:'Big savings for the first 6 months when you sign up for a 1 year commitment.'},
                    {term:'24', desc:'Get a special offer when you sign up for a 2 year commitment.'},
                    {term:'36', desc:'Big savings for the first 36 months when you sign up for a 3 year commitment.'}], 
                offers: [
                    {id: 5,price:'20',priceNoTerm:'30', desc:'Unlimited local calling + 1 feature', finePrint:'For the first 24 months, and $30/mo. thereafter',finePrintPromo:'For the first 24 months, and $20/mo. thereafter', promoDiscount:'10', pricePromo:'10',
                        details: [
                            {icon: 'phone',description:'1 Calling Feature of your Choice', finePrint:'Including: Call Display, Call Screening, Call Waiting, Call Return, Call Forwarding, Voicemail, 3 Way Calling'},
                            {icon: 'callTalking',description:'Unlimited Local Calling', finePrint:'Extra charge for long distance: 7¢ per minute to over 60 international destinations as part of your Home Phone plan, including Canada and the US'},
                            {icon: 'homePhone',description:'Basic Call Control', finePrint:'Limits robo-calls'},
                        ],
                        gift:'none',
                        
                    }, 
                    {id: 6,price:'30',priceNoTerm:'40', desc:'Unlimited local calling + 9 features', finePrint:'For the first 24 months, and $40/mo. thereafter', finePrintPromo:'For the first 24 months, and $30/mo. thereafter', promoDiscount:'10', pricePromo:'20',
                        details: [
                            {icon: 'phone',description:'9 calling features', finePrint:'Including: Call Display, Call Screening, Call Waiting, Call Return, Call Forwarding, Voicemail, 3 Way Calling, Call Reveal, Smart Ring'},
                            {icon: 'callTalking',description:'Unlimited local calling', finePrint:'Extra charge for long distance: 7¢ per minute to over 60 international destinations as part of your Home Phone plan, including Canada and the US'},
                            {icon: 'homePhone',description:'Basic call control', finePrint:'Limits robo-calls'},
                        ],
                        gift:'none',
                    },
                    {id: 35,price:'25',priceNoTerm:'25', desc:'World 60 Unlimited Long Distance', finePrint:'Including US and Canada', promoDiscount:'none',
                        details: [
                            {icon: 'country',description:'60 international countries', finePrint:'Unlimited calls terminating on landline or on mobile to Andorra, Argentina, Australia, Austria, Bangladesh, Belgium, Brazil, Brunei Darussalam, Bulgaria, Chile, China, Colombia, Costa Rica, Croatia, Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hong Kong, Hungary, Iceland, India, Indonesia, Ireland, Israel, Italy, Japan, Jordan, Kazakhstan, Lithuania, Luxembourg, Malaysia, Mexico, Netherlands, New Zealand, Norway, Panama, Peru, Poland, Portugal, Puerto Rico, Russia, Singapore, Slovakia, Slovenia, South Africa, South Korea, Spain, Sweden, Switzerland, Taiwan, Thailand, Turkey, United Kingdom, Venezuela, Vietnam'},
                        ],
                        gift:'none',
                    },
                    {id: 36,price:'15',priceNoTerm:'15', desc:'Unlimited US and Canada', finePrint:'Long Distance Calling', promoDiscount:'none',
                        details: [
                            {icon: 'country',description:'60 international countries not included', finePrint:'7¢/minute to Andorra, Argentina, Australia, Austria, Bangladesh, Belgium, Brazil, Brunei Darussalam, Bulgaria, Chile, China, Colombia, Costa Rica, Croatia, Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hong Kong, Hungary, Iceland, India, Indonesia, Ireland, Israel, Italy, Japan, Jordan, Kazakhstan, Lithuania, Luxembourg, Malaysia, Mexico, Netherlands, New Zealand, Norway, Panama, Peru, Poland, Portugal, Puerto Rico, Russia, Singapore, Slovakia, Slovenia, South Africa, South Korea, Spain, Sweden, Switzerland, Taiwan, Thailand, Turkey, United Kingdom, Venezuela, Vietnam'},
                        ],
                        gift:'none',
                    },
                    {id: 37,price:'9',priceNoTerm:'9', desc:'300 minutes in U.S. and Canada Long Distance', finePrint:'7¢/minute overtime', promoDiscount:'none',
                        details: [
                            {icon: 'country',description:'60 international countries not included', finePrint:'7¢/minute to Andorra, Argentina, Australia, Austria, Bangladesh, Belgium, Brazil, Brunei Darussalam, Bulgaria, Chile, China, Colombia, Costa Rica, Croatia, Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hong Kong, Hungary, Iceland, India, Indonesia, Ireland, Israel, Italy, Japan, Jordan, Kazakhstan, Lithuania, Luxembourg, Malaysia, Mexico, Netherlands, New Zealand, Norway, Panama, Peru, Poland, Portugal, Puerto Rico, Russia, Singapore, Slovakia, Slovenia, South Africa, South Korea, Spain, Sweden, Switzerland, Taiwan, Thailand, Turkey, United Kingdom, Venezuela, Vietnam'},
                            {icon: 'country',description:'Puerto Rico not included'}
                        ],
                        gift:'none',
                    },
                    {id: 38,price:'7',priceNoTerm:'7', desc:'Asia Long Distance', finePrint:'Unlimited to Hong Kong, China, Singapore, Taiwan', promoDiscount:'none',
                        details: [
                            {icon: 'country',description:'Most international countries not included', finePrint:'7¢/minute to Andorra, Argentina, Australia, Austria, Bangladesh, Belgium, Brazil, Brunei Darussalam, Bulgaria, Chile, Colombia, Costa Rica, Croatia, Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Iceland, India, Indonesia, Ireland, Israel, Italy, Japan, Jordan, Kazakhstan, Lithuania, Luxembourg, Malaysia, Mexico, Netherlands, New Zealand, Norway, Panama, Peru, Poland, Portugal, Puerto Rico, Russia, Slovakia, Slovenia, South Africa, South Korea, Spain, Sweden, Switzerland, Thailand, Turkey, United Kingdom, Venezuela, Vietnam'},
                            {icon: 'country',description:'Canada and US not included', finePrint:'7¢/minute'}
                        ],
                        gift:'none',
                    },
                    {id: 39,price:'10',priceNoTerm:'10', desc:'Unlimited India', finePrint:'7¢/minute otherwise', promoDiscount:'none',
                        details: [
                            {icon: 'country',description:'60 international countries not included', finePrint:'7¢/minute to Andorra, Argentina, Australia, Austria, Bangladesh, Belgium, Brazil, Brunei Darussalam, Bulgaria, Chile, China, Colombia, Costa Rica, Croatia, Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hong Kong, Hungary, Iceland, Indonesia, Ireland, Israel, Italy, Japan, Jordan, Kazakhstan, Lithuania, Luxembourg, Malaysia, Mexico, Netherlands, New Zealand, Norway, Panama, Peru, Poland, Portugal, Puerto Rico, Russia, Singapore, Slovakia, Slovenia, South Africa, South Korea, Spain, Sweden, Switzerland, Taiwan, Thailand, Turkey, United Kingdom, Venezuela, Vietnam'},
                            {icon: 'country',description:'Canada and US not included', finePrint:'7¢/minute'}
                        ],
                        gift:'none',
                    },
                    {id: 40,price:'15',priceNoTerm:'15', desc:'Long Distance Philippines 100', finePrint:'300 minutes in U.S. and Canada Long Distance', promoDiscount:'none',
                        details: [
                            {icon: 'country',description:'60 international countries not included', finePrint:'7¢/minute to Andorra, Argentina, Australia, Austria, Bangladesh, Belgium, Brazil, Brunei Darussalam, Bulgaria, Chile, China, Colombia, Costa Rica, Croatia, Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hong Kong, Hungary, Iceland, India, Indonesia, Ireland, Israel, Italy, Japan, Jordan, Kazakhstan, Lithuania, Luxembourg, Malaysia, Mexico, Netherlands, New Zealand, Norway, Panama, Peru, Poland, Portugal, Puerto Rico, Russia, Singapore, Slovakia, Slovenia, South Africa, South Korea, Spain, Sweden, Switzerland, Taiwan, Thailand, Turkey, United Kingdom, Venezuela, Vietnam'},
                            {icon: 'country',description:'Overtime charge on calls to Philipines', finePrint:'15¢/minute'},
                            {icon: 'country',description:'Overtime charge on calls to US/Canada', finePrint:'7¢/minute'}
                        ],
                        gift:'none',
                    },
                ], 
                product: false, commitment: '', offer: 0, promoTab: 'offer',promoActivated: false, expand: true, 
                terms: [
                    {icon: 'calendar',description:'Cancel anytime', },
                    {icon: 'van',description:'2 hour installer arrival window', },
                    {icon: 'support',description:"24/7 technical support", },
                    {icon: 'homePhone',description:"Landline and home phone not provided",},
                    {icon: 'country', description:'60 long distance countries include: Andorra, Argentina, Australia, Austria, Bangladesh, Belgium, Brazil, Brunei Darussalam, Bulgaria, Chile, China, Colombia, Costa Rica, Croatia, Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hong Kong, Hungary, Iceland, India, Indonesia, Ireland, Israel, Italy, Japan, Jordan, Kazakhstan, Lithuania, Luxembourg, Malaysia, Mexico, Netherlands, New Zealand, Norway, Panama, Peru, Poland, Portugal, Puerto Rico, Russia, Singapore, Slovakia, Slovenia, South Africa, South Korea, Spain, Sweden, Switzerland, Taiwan, Thailand, Turkey, United Kingdom, Venezuela, Vietnam' }
                ]
            },

            {type:'LivingWell', 
                secondaryIcon:"LivingWell",
                description:"Explore LivingWell Companion Plans. Products include up to: 24/7 emergency support, 2-way voice call via Apple watch, Automatic fall detection, Canada-wide GPS coverage and Apple Watch SE.", 
                commitments:[
                    {term: 'No term', desc: 'No commitment'},
                    {term:'12', desc:'Big savings for the first 6 months when you sign up for a 1 year commitment.'},
                    {term:'24', desc:'Get a special offer when you sign up for a 2 year commitment.'},
                    {term:'36', desc:'Big savings for the first 36 months when you sign up for a 3 year commitment.'}], 
                offers: [
                    {id: 1, price:'20',priceNoTerm:'40', pricePromo:'10', desc:'In the Home (without fall detection)', finePrint:'For the first 24 months, and $40/mo. thereafter',finePrintPromo:'For the first 24 months, and $20/mo. thereafter', promoDiscount:'10',
                        details: [
                            {icon: 'clock',description:'24/7 emergency support', finePrint:'Live emergency operators'},
                            {icon: 'support',description:'2-way voice call via base station', finePrint:'Emergency help button'},
                            {icon: 'info',description:'Waterproof', finePrint:'Can be used in Shower. Not suitable for extended and continuous water submersion'},
                            {icon: 'lanyard',description:'Wrist Band and Lanyard', finePrint:'included'}
                        ],
                        gift:'$35 activation fee waived',
                        giftIcon:'PiggyBank',
                    }, 
                    {id: 2,price:'35',priceNoTerm:'50', pricePromo: '25',desc:'In the Home (with fall detection)', finePrint:'For the first 24 months, and $50/mo. thereafter', finePrintPromo:'For the first 24 months, and $40/mo. thereafter', promoDiscount:'10',
                        details: [
                            {icon: 'clock',description:'24/7 emergency support', finePrint:'Live emergency operators'},
                            {icon: 'support',description:'2-way voice call via base station', finePrint:'Emergency help button'},
                            {icon: 'fall',description:'Automatic fall detection', finePrint:'Do not rely on call'},
                            {icon: 'info',description:'Water Resistant', finePrint:'Not suitable for inside shower. Can be placed beside shower, within reach.'},
                            {icon: 'lanyard',description:'Lanyard', finePrint:'included'}
                        ],
                        gift:'$35 activation fee waived',
                        giftIcon:'PiggyBank',
                    },
                        
                    {id: 3,price:'45',priceNoTerm:'60', pricePromo: '35', desc:'On the Go', finePrint:'For the first 24 months, and $60/mo. thereafter',finePrintPromo:'For the first 24 months, and $50/mo. thereafter', promoDiscount:'10',
                        details: [
                            {icon: 'clock',description:'24/7 emergency support', finePrint:'Live emergency operators'},
                            {icon: 'support',description:'2-way voice call via base station', finePrint:'Emergency help button'},
                            {icon: 'fall',description:'Automatic fall detection', finePrint:'Do not rely on call'},
                            {icon: 'gps', description:'Canada-wide GPS coverage', finePrint:'Only where cellular coverage is available'},
                            {icon: 'info',description:'Waterproof', finePrint:'Can be used in Shower. Not suitable for extended and continuous water submersion'},
                            {icon: 'lanyard',description:'Belt Clip and Lanyard', finePrint:'included'}
                        ],
                        gift:'$35 activation fee waived',
                        giftIcon:'PiggyBank',
                    }, 
                    {id: 4,price:'44',priceNoTerm:'59', desc:'On Apple Watch', finePrint:'For the first 24 months, and $59/mo. thereafter',finePrintPromo:'For the first 24 months, and $49/mo. thereafter', promoDiscount:'10', pricePromo:'34',
                        details: [
                            {icon: 'clock',description:'24/7 emergency support', finePrint:'Live emergency operators'},
                            {icon: 'support',description:'2-way voice call via base station', finePrint:'Emergency help button'},
                            {icon: 'fall',description:'Automatic fall detection', finePrint:'Do not rely on call'},
                            {icon: 'gps', description:'Canada-wide GPS coverage', finePrint:'Only where cellular coverage is available'},
                            {icon: 'info',description:'Waterproof', finePrint:'Can be used in Shower. Not suitable for extended and continuous water submersion'},
                            {icon: 'appleWatch',description:'Apple Watch SE included with 2-year TELUS Easy Payment® smartwatch plan (1GB Cellular data)', finePrint:'Requires iPhone 6s or later, with latest iOS6'}
                        ],
                        gift:'Save $360 over 2 years on an Apple Wach SE',
                        giftIcon:'Watch',
                    },
                ], 
                product: false, commitment: '', offer: 0, promoTab: 'offer',promoActivated: false, expand: true, 
                terms: [
                    {icon: '',description:'', },
                ]
            },
        ];

        this.viewCartDetails = false;

        this.showProductDetails = false;
        this.offerDetailsToShow = 0;

        this.orderFlowPages = [1,0,0,0,0,0]; // Integer 0, 1 or 2 -> 0 is unselected, 1 is selected, 2 indicates progression
        this.page = 0;
    }

}

export {UserFrame};