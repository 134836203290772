import React, { useState } from "react";

import left from '../../../../icons/drawer/purple/triangle/left.png';
import leftW from '../../../../icons/drawer/white/triangle/left.png';
import right from '../../../../icons/drawer/purple/triangle/right.png';
import rightW from '../../../../icons/drawer/white/triangle/right.png';

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../../redux/actions/userFrameActions'

function ButtonDrawerCircle({ }) {
  
    const userFrame = useSelector(state=>state.user_frame_reducer[0])
    const houses = useSelector(state=>state.house_reducer)
    const dispatch = useDispatch()


  function clickDrawer(){

    
    if(userFrame.drawer==1){
      const frame = userFrame;
      frame.drawer = 2;
      dispatch(updateUserFrame(frame))
    }else{
      const frame = userFrame;
      frame.drawer = 1;
      dispatch(updateUserFrame(frame))
    }
    
  }

  return (
    
      <button
        style={{ 
        position:'absolute', left: '-30px', 
        height:'55px', width:'55px', display:'flex', justifyContent:'center', alignItems:'center',
        cursor: 'pointer', zIndex:11,
        color: (userFrame.darkMode?'white':'#4B286D'), fontWeight: 'bold', borderColor:(userFrame.darkMode?'white':'#4B286D'), 
        borderRadius:'70px', borderWidth: '2px', 
        borderStyle: 'solid', backgroundColor: (userFrame.darkMode?'#2A2C2E':'white') }}

        onClick={() => clickDrawer()}
      >
      {userFrame.leftHandedDrawer?
        (userFrame.drawer == 2 ? 
          <img src={userFrame.darkMode ? rightW : right} width='45px' style={{}}/> 
        : 
          <img src={userFrame.darkMode ? leftW : left} width='45px' style={{}}/>
        )
      :
        (userFrame.drawer == 2 ? 
          <img src={userFrame.darkMode ? leftW : left} width='45px' style={{}}/> 
        : 
          <img src={userFrame.darkMode ? rightW : right} width='45px' style={{}}/>
        )
      }
        </button>
  );
}

export default ButtonDrawerCircle;