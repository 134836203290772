import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import CSSReset from '@tds/core-css-reset'

import {Provider} from 'react-redux';
import {createStore} from 'redux';
import {store} from './redux/reducers/globalReducers.js'

ReactDOM.render(

  <>
  
  <CSSReset />
  <Provider store={store}>
    <App />
  </Provider>,
  </>,
  
  document.getElementById('root')
);

