import React, { useState } from "react";

import micFilled from '../../../../icons/microphone/filled/mic.png';
import micOutline from '../../../../icons/microphone/outline/mic.png';
import micOutlineWhite from '../../../../icons/microphone/outline/mic-white.png';
import micFilledWhite from '../../../../icons/microphone/filled/mic-white.png';

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../../redux/actions/userFrameActions'

function ButtonMic() {

  const userFrame = useSelector(state=>state.user_frame_reducer[0])
  const houses = useSelector(state=>state.house_reducer)
  const dispatch = useDispatch()

  const [filled,setFill] = useState(false)

  function handleClick(){

    setFill(!filled)
    
  }


  return (
    
      <button
        style={{ 
        height:'55px', width:'55px', overflow:'visible',
        backgroundColor:userFrame.darkMode?'#2A2C2E':'white', border: 'none',cursor: 'pointer', }}
        onClick={() => handleClick()}
      >
        <div style={{paddingTop:'5px', overflow:'visible' }}>
        {filled ? 
        <img style={{paddingBottom:'5px', overflow:'visible' }} src={userFrame.darkMode?micFilledWhite:micFilled} width="45px"/> 
        : 
        <img style={{paddingBottom:'5px', overflow:'visible' }} src={userFrame.darkMode?micOutlineWhite:micOutline} width="45px"/> }
        </div>
        
      </button>
  );
}

export default ButtonMic;