import HairlineDivider from "@tds/core-hairline-divider";
import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../redux/actions/userFrameActions'

import up from '../../../icons/arrow/up/circled.png'
import down from '../../../icons/arrow/down/circled.png'
import expandDown from '../../../icons/arrow/down/black.png'
import expandUp from '../../../icons/arrow/up/black.png'
import info from '../../../icons/info/info.png'
import upC from '../../../icons/arrow/up/circledp.png'
import downC from '../../../icons/arrow/down/circledp.png'
import whiteUp from '../../../icons/arrow/up/white-circle.png'
import whiteDown from '../../../icons/arrow/down/white-down.png'

import { Offices } from "@tds/core-decorative-icon";
import ReactTooltip from 'react-tooltip';

import purpleHouse from '../../../icons/house/purple/house.png';
import purpleNOH from '../../../icons/house/purple/not-home.png';
import purpleSN from '../../../icons/house/purple/soft-no.png';
import purpleHN from '../../../icons/house/purple/hard-no.png';
import purpleWP from '../../../icons/house/purple/wrong-party.png';
import purpleNI from '../../../icons/house/purple/not-interested.png';
import purpleOK from '../../../icons/house/purple/other-knock.png';
import purpleSK from '../../../icons/house/purple/successful-knock.png';
import purpleFU from '../../../icons/house/purple/follow-up.png';
import purpleHot from '../../../icons/house/purple/hot.png';
import purpleDNK from '../../../icons/house/red/do-not-knock.png';
import FollowUp from "./FollowUp";

import whiteHouse from '../../../icons/house/white/house.png';
import whiteNOH from '../../../icons/house/white/not-home.png';
import whiteSN from '../../../icons/house/white/soft-no.png';
import whiteHN from '../../../icons/house/white/hard-no.png';
import whiteWP from '../../../icons/house/white/wrong-party.png';
import whiteNI from '../../../icons/house/white/not-interested.png';
import whiteOK from '../../../icons/house/white/other-knock.png';
import whiteSK from '../../../icons/house/white/successful-knock.png';
import whiteFU from '../../../icons/house/white/follow-up.png';
import whiteHot from '../../../icons/house/white/hot.png';
import whiteDNK from '../../../icons/house/white/not-interested-old.png';


function Progress() {

  const userFrame = useSelector(state=>state.user_frame_reducer[0])
  const houses = useSelector(state=>state.house_reducer)
  const dispatch = useDispatch()

  function doSearch(address) {
  
    
      const u = userFrame
      u.tabView = 1
      u.menu = false
      u.searchString = address
      dispatch(updateUserFrame(u))
    
    
  }

  // component did mount 
  useEffect(() => {
    let filteredHouses = []
    const h = houses

    // get knocks into arrays organized by kncok type
    let house = ['Un-Knocked', false]
    house = [...house, h.filter(house => house.icon.includes('house'))]

    let followUp = ['Follow Up', false]
    followUp = [...followUp, h.filter(fu => fu.icon.includes('FU'))]

    let success = ['Successful Knock', false]
    success = [...success, h.filter(s => s.icon.includes('SK'))]

    let multi = ['Multi Dwelling Unit', false]
    multi = [...multi, h.filter(multi => multi.icon.includes('multi'))]

    let doNotKnock = ['Do Not Knock', false]
    doNotKnock = [...doNotKnock, h.filter(dnk => dnk.icon.includes('DNK'))]
    
    let noOneHome = ['No One Home', false]
    noOneHome = [...noOneHome, h.filter(noh => noh.icon.includes('NOH'))]

    let softNo = ['Soft No', false]
    softNo = [...softNo, h.filter(sn => sn.icon.includes('SN'))]

    let hardNo = ['Hard No', false]
    hardNo = [...hardNo, h.filter(hn => hn.icon.includes('HN'))]

    let wrongParty = ['Wrong Party', false]
    wrongParty = [...wrongParty, h.filter(wp => wp.icon.includes('WP'))]

    let notInterested = ['Not Interested', false]
    notInterested = [...notInterested, h.filter(ni => ni.icon.includes('NI'))]

    let otherKnocks = ['Other Knocks', false]
    otherKnocks = [...otherKnocks, h.filter(ok => ok.icon.includes('OK'))]

    let hotHouses = ['Hot Houses', false]
    hotHouses = [...hotHouses, h.filter(hot => hot.icon.includes('hot'))]
    
    // append the new organized knock types to the superset of all knocks (3D array) 
    filteredHouses = [...filteredHouses, house]
    filteredHouses = [...filteredHouses, followUp]
    filteredHouses = [...filteredHouses, success]
    filteredHouses = [...filteredHouses, multi]
    filteredHouses = [...filteredHouses, doNotKnock]

    filteredHouses = [...filteredHouses, noOneHome]
    filteredHouses = [...filteredHouses, softNo]
    filteredHouses = [...filteredHouses, hardNo]
    filteredHouses = [...filteredHouses, wrongParty]
    filteredHouses = [...filteredHouses, notInterested]
    filteredHouses = [...filteredHouses, otherKnocks]
    filteredHouses = [...filteredHouses, hotHouses]

    const u = userFrame
    u.filteredHouses = filteredHouses
    dispatch(updateUserFrame(u))

    // sort them knocks from most knocks to least
    sortKnockTypes(true)

  }, []);

  function getSum(){

    let sum = 0
    houses.filter(h=>{
      if(h.icon != 'house'){
        sum = sum + 1
      }
    })
    return sum
  }

  function expand(knock) {
    
    // get redux store state variables
    let u = userFrame
    // get all filtered houses
    let filteredHouses = u.filteredHouses
    // find the subset houses that have this knock type
    let filteredHouse = filteredHouses.filter(knockType => knockType.includes(knock))
    // get the array index of these subset houses
    let index = filteredHouses.findIndex((i)=>{i.includes(knock)})
    // change the expand value for this subset of houses
    filteredHouse[0][1] = !filteredHouse[0][1]
    // update the array of all filtered houses
    filteredHouses[index] = filteredHouse
    // update the redux store state variable
    u.filteredHouses = filteredHouses 
    dispatch(updateUserFrame(u))

  }

  const iconWidth = '36px';
  function renderIcon(selection) {
    switch(selection) {
      case 'No One Home':
        if(userFrame.darkMode){
          return <img src={whiteNOH} width={iconWidth} />;
        }else{
          return <img src={purpleNOH} width={iconWidth} />;
        }
      case 'Follow Up':
        if(userFrame.darkMode){
          return <img src={whiteFU} width={iconWidth} />;
        }else{
          return <img src={purpleFU} width={iconWidth} />;
        }  
      case 'Hard No':
        if(userFrame.darkMode){
          return <img src={whiteHN} width={iconWidth} />;
        }else{
          return <img src={purpleHN} width={iconWidth} />;
        }        
      case 'Wrong Party':
        if(userFrame.darkMode){
          return <img src={whiteWP} width={iconWidth} />;
        }else{
          return <img src={purpleWP} width={iconWidth} />;
        }
      case 'Not Interested':
        if(userFrame.darkMode){
          return <img src={whiteNI} width={iconWidth} />;
        }else{
          return <img src={purpleNI} width={iconWidth} />;
        }    
      case 'Other Knocks':
        if(userFrame.darkMode){
          return <img src={whiteOK} width={iconWidth} />;
        }else{
          return <img src={purpleOK} width={iconWidth} />;
        }    
      case 'Un-Knocked':
        if(userFrame.darkMode){
          return <img src={whiteHouse} width={iconWidth} />;
        }else{
          return <img src={purpleHouse} width={iconWidth} />;
        }
      case 'Successful Knock':
        if(userFrame.darkMode){
          return <img src={whiteSK} width={iconWidth} />;
        }else{
          return <img src={purpleSK} width={iconWidth} />;
        }
      case 'Soft No':
        if(userFrame.darkMode){
          return <img src={whiteSN} width={iconWidth} />;
        }else{
          return <img src={purpleSN} width={iconWidth} />;
        }
      case 'Do Not Knock':
        if(userFrame.darkMode){
          return <img src={whiteDNK} width={iconWidth} />;
        }else{
          return <img src={purpleDNK} width={iconWidth} />;
        }
      case 'Hot Houses':
        if(userFrame.darkMode){
          return <img src={whiteHot} width={iconWidth} />;
        }else{
          return <img src={purpleHot} width={iconWidth} />;
        }
      case 'Multi Dwelling Unit':
        return <Offices variant={userFrame.darkMode?'inverted':'default'} size={32}/>
      default:
        if(userFrame.darkMode){
          return <img src={whiteHouse} width={iconWidth} />;
        }else{
          return <img src={purpleHouse} width={iconWidth} />;
        } 
      }
  }


  function sortKnockTypes(highToLow) {
    
    // get redux store state variables
    let u = userFrame
    // get all filtered houses
    let filteredHouses = u.filteredHouses
    let sortedHouses = []

    if(highToLow){
        // find the subset houses that have this knock type
        sortedHouses = filteredHouses.sort(function(a,b){
          return b[2].length - a[2].length
        })
        
    }else{
        // find the subset houses that have this knock type
        sortedHouses = filteredHouses.sort(function(a,b){
          return a[2].length - b[2].length
        })
    }

    // update the redux store state variable
    u.filteredHouses = sortedHouses 
    dispatch(updateUserFrame(u))

  }

  function getFilteredAddresses(houses) {
    
    const rows = houses.map((house, index) => 
    
      <div key={index} style={{display:'flex', justifyContent:'center', alignItems:'center', width:'90%', marginTop:'20px',marginBottom:'20px', height:'20px', marginLeft:'5%' }}>
                  
        {/* Street Number */}
        <div style={{display:'inline-block', width:'33%', height:'100%',height:'20px' }}>
          <div style={{display:'block', width:'100%', }}>
            <h1 style={{fontSize:'15px',  color:'black', width:'100%', }}>
            {house.address.split(',')[0].split(" ")[0]}
            </h1>
          </div>
        </div>

        {/* Street Name */}
        <div  style={{display:'inline-block', width:'33%', height:'20px'  }}>
          <div style={{display:'flex', alignItems:'center', justifyContent:'Left', width:'100%'}}>      
            <h1 style={{fontSize:'15px',  color:'black', width:'100%', }}>
              {house.address.split(',')[0].split(" ")[1]}&nbsp;{house.address.split(',')[0].split(" ")[2]}
            </h1>
          </div>
        </div>

        {/* Column addresses */}
        <div style={{display:'inline-block', width:'33%', height:'20px' }}>
          <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
            <button onClick={()=>{doSearch(house.address)}} style={{borderRadius:'5px', backgroundColor:'#4B286D', color:'white', fontWeight:'bold', border:'none'}}>
              View
            </button>
          </div>
        </div>

      </div>
    )

    return rows

  }


  function doFilter(list) {

    const u = userFrame
    u.tabView = 1
    u.menu = false
    u.filterSelection = list[0].icon
    dispatch(updateUserFrame(u))
  }

  function getKnockRows() {

    const filteredHouses = userFrame.filteredHouses

    /* Map the filtered array of different knock types to each row */
    const rows = filteredHouses.map((house, index) =>

      /* Row of Column Data */
      <div key={index} style={{width:'100%', height:house[1] ==  true ? '100%':'60px'  }}>

      <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', marginTop:'10px', padding:'1%', paddingLeft:'2%',paddingRight:'2%', height:'60px'  }}>

            {/* Column knock icon */}
            <div style={{display:'inline-block', width:'20%', height:'100%',height:'60px' }}>
              
            {house[0] == 'No One Home'?
              <div style={{display:'inline-block', verticalAlign:'top' }}>
                <div style={{display:'flex', alignItems:'center', justifyContent:'left', marginLeft:'-30px', marginRight:'6px', marginTop:'7px'}}>
                 
                  <div>
                    <p data-multiline={true} data-tip='When "No One Home" is logged 3 times, <br/> the premise icon will change to this.'>
                      <img src={info} style={{width:'24px'}}/>
                    </p>
                    <ReactTooltip />   
                  </div>
                </div>
              </div>
              :<></>}

              <div style={{display:'inline-block', }}>
                <div style={{display:'flex', alignItems:'center', justifyContent:'left'}}>
                  {renderIcon(house[0])}
                </div>
              </div>
              
            </div>

            {/* Column knock Type */}
            <div  style={{display:'inline-block', width:'30%', height:'60px'  }}>
              <div style={{display:'flex', alignItems:'center', justifyContent:'Left', width:'100%'}}>      
                <h1 style={{fontSize:'17px', color:userFrame.darkMode?'white':'black', width:'100%', }}>
                  {house[0]}
                </h1>
              </div>
            </div>

            {/* Column Data */}
            <div style={{display:'inline-block', width:'25%', height:'60px', marginLeft:'10px'}}>
              <h1 style={{fontSize:'17px', color:userFrame.darkMode?'white':'black', width:'100%', textAlign:'center'}}>
                  {parseFloat(((house[2].length/houses.length)*100).toFixed(1))}% (<span style={{color:userFrame.darkMode?'white':'#2B8000'}}>{house[2].length}</span>/{houses.length})
              </h1>
            </div>

            {/* Column addresses */}
            <div style={{display:'inline-block', width:'25%', height:'60px' }}>
              {house[2].length>0?
              
              <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                  <button onClick={()=>{doFilter(house[2])}} style={{borderRadius:'5px', backgroundColor:userFrame.darkMode?'#D8D8D8':'#4B286D', color:userFrame.darkMode?'black':'white', fontWeight:'bold', border:'none', width:'100%', height:'30px'}}>
                    View  {house[2].length}
                  </button>
              </div> 
              
              /* <a onClick={()=>{expand(house[0])}} style={{cursor:'pointer'}}>
                
                <div style={{display:'inline-block',verticalAlign:'bottom'}}>
                  <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <img src={house[1] ==  false ? expandDown : expandUp} style={{width:'23px',paddingBottom:'2px'}}/>
                  </div>
                </div>

                <div style={{display:'inline-block', }}> 
                  <h1 style={{fontSize:'17px', color:'black', width:'100%', textAlign:'left'}}>
                    {house[1] ==  false ? `View ${house[2].length}` : 'View Less'} 
                  </h1>
                </div>
                
              </a> */
              :
              <h1 style={{fontSize:'17px', color:'black', width:'100%', textAlign:'center'}}>
                None
              </h1>}
            </div>
          </div>

          {/* if expand is true */}
          {house[1] == true?
            <>
            <div style={{width:'90%', padding:'1%', marginLeft:'5%',marginRight:'5%', }}>

            {/* Divider - row*/}
            <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', marginTop:'10px',}}>
                  <HairlineDivider/>
            </div>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', marginTop:'10px', padding:'1%', paddingLeft:'5%',paddingRight:'5%', }}>
                        
              {/* Street Number Column Header */}
              <div  style={{display:'inline-block', width:'33%', height:'15px' }}>
                <div style={{display:'flex', alignItems:'center', justifyContent:'Left', width:'100%'}}>      
                  <h1 style={{fontSize:'15px', fontWeight:'bold', color:userFrame.darkMode?'white':'black', width:'100%', }}>
                    House Number
                  </h1>
                </div>
              </div>

              {/* Street Name Column Header */}
              <div style={{display:'inline-block', width:'33%', height:'100%',height:'15px' }}>
                <div style={{display:'block', width:'100%', }}>
                  <h1 style={{fontSize:'15px', fontWeight:'bold', color:userFrame.darkMode?'white':'black', width:'100%', }}>
                    Street Name
                  </h1>
                </div>
              </div>

              {/* Column addresses */}
              <div style={{display:'inline-block', width:'33%', height:'15px' }}>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center' }}>
                  <h1 style={{fontSize:'15px', fontWeight:'bold', color:userFrame.darkMode?'white':'black', width:'100%',textAlign:'center' }}>
                      Find Premise
                  </h1>
                </div>
              </div>
            </div>

            {getFilteredAddresses(house[2])}

            <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', marginTop:'10px', marginBottom:'40px',}}>
                  <HairlineDivider/>
            </div>

            </div>
            </>
          :<></>}
      </div>
    )

    return(
          /* Array of Rows */
          rows
    )
    
  }


  return (
       
      <div style={{ height:'100%', width:'100%', backgroundColor:userFrame.darkMode?'#54595F':'white'}}>


          <div style={{width:'80%', display:'block',verticalAlign:'top', marginLeft:'10%', }}>
           
            <h1 style={{color:userFrame.darkMode?'white':'#4B286D', fontSize:'24px', marginLeft:'2%'}}>
              Progress View
            </h1>
            
            <div style={{width:'100%', height:'90%', display:'inline-block',  borderRadius:'5px',}}>

                {/* Divider - row*/}
                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', marginTop:'10px'}}>
                  <HairlineDivider/>
                </div>

                {/* Title - row */}
                <div style={{display:'flex', justifyContent:'left', alignItems:'center', width:'96%', marginTop:'10px', marginLeft:'2%' }}>
                  <div style={{display:'inline-block'}}>
                    <h1 style={{fontSize:'36px', fontWeight:'bold', color:userFrame.darkMode?'white':'#4B286D', }}>
                      {houses.length}&nbsp;
                    </h1>
                  </div>
                  <div style={{display:'inline-block'}}>
                    <h1 style={{fontSize:'18px', color:userFrame.darkMode?'white':'#4B286D', display:'inline-block', }}>
                        premises in the
                        <span style={{fontWeight:'bold'}}> ABD247 </span>
                        walklist      
                    </h1>
                  </div>
                </div>

                {/* Divider - row */}
                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', marginTop:'10px'}}>
                  <HairlineDivider/>
                </div>

                 {/* Progress bar title - row */}
                <div style={{display:'flex', justifyContent:'left', alignItems:'center', width:'96%', marginTop:'10px', marginLeft:'2%' }}>
                  
                  {/* "Walklist x knocks captured" */}
                  <div style={{display:'inline-block', width:'70%'}}>
                    <h1 style={{fontSize:'18px', color:userFrame.darkMode?'white':'black', }}>
                      Walklist
                      <span style={{fontWeight:'bold'}}> ABD247 </span> 
                      knocks captured
                    </h1>
                  </div>
                  
                  {/* Percentage complete | # of knocks */}
                  <div style={{ width:'30%',display:'inline-block',}}>
                    <div style={{display:'flex', justifyContent:'right', alignItems:'center', width:'100%'}}>
                      <div>
                        <h1 style={{fontSize:'18px', color:userFrame.darkMode?'white':'black', }}>{parseFloat(((getSum()/houses.length)*100).toFixed(0))}%</h1>
                      </div>
                      <div style={{borderLeft:`2px solid ${userFrame.darkMode?'white':'black'}`, height:'20px', marginLeft:'5px',marginRight:'5px'}}></div>
                      <div>
                        <h1 style={{fontSize:'18px', color:userFrame.darkMode?'white':'#66CC00', fontWeight:'bold' }}>{getSum()}</h1>
                      </div>
                    </div>
                  </div>

                </div>

                {/* Progress bar - row */}
                <div style={{display:'flex', justifyContent:'left', alignItems:'center', width:'100%', height:'20px', backgroundColor:userFrame.darkMode?'lightgrey':'#F2EFF4', marginTop:'10px' }}>
                    <div style={{display:'flex', justifyContent:'left', alignItems:'center', width:`${parseFloat(((getSum()/houses.length)*100).toFixed(0))}%`, height:'20px', backgroundColor:userFrame.darkMode?'grey':'#66CC00'}}></div>
                </div>

                {/* Click directive - row */}
                <div style={{display:'flex', justifyContent:'left', alignItems:'center', width:'96%', marginTop:'10px', marginLeft:'2%' }}>
                    <h1 style={{fontSize:'17px', color:userFrame.darkMode?'white':'#4B286D', }}>
                      Click on addresses to see premise details
                    </h1>
                </div>

                {/* Sort directive - row */}
                <div style={{display:'flex', justifyContent:'left', alignItems:'center', width:'100%', marginTop:'10px', padding:'1%', paddingLeft:'2%',paddingRight:'2%', backgroundColor:userFrame.darkMode?'#2A2C2E':'#F2EFF4', }}>
                  <div>
                  {/* Sort Description - row */}
                  <div style={{display:'block', }}>
                      <h1 style={{fontSize:'17px', color:userFrame.darkMode?'white':'black', }}>
                        Sort&nbsp;
                        <span style={{fontWeight:'bold'}}>premise</span>&nbsp;
                        knock&nbsp;distribution:&nbsp; &nbsp; 
                      </h1>
                  </div>
                  
                  {/* Sort Buttons - row */}
                  <div style={{ display:'inline-block',}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
                        <h1 style={{fontSize:'18px', color:userFrame.darkMode?'white':'black', }}>high/low&nbsp;</h1>
                    </div>
                  </div>
                  <div style={{ display:'inline-block',}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
                    <a onClick={()=>{sortKnockTypes(true)}} style={{cursor:'pointer'}}>
                      {userFrame.darkMode?
                      <img src={userFrame.darkMode?whiteDown:downC} style={{backgroundColor:'#2A2C2E', borderRadius:'24px', width:'28px'}}/>
                      :
                      <img src={down} style={{backgroundColor:'white', borderRadius:'24px', width:'28px'}}/>}
                      </a>&nbsp; &nbsp;
                    </div>
                  </div>

                  {/* Sort Buttons - row */}
                  <div style={{ display:'inline-block',}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
                        <h1 style={{fontSize:'18px', color:userFrame.darkMode?'white':'black', }}>low/high&nbsp;</h1>
                    </div>
                  </div>
                  <div style={{ display:'inline-block',}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
                    <a onClick={()=>{sortKnockTypes(false)}} style={{cursor:'pointer'}}>
                      {userFrame.darkMode?
                      <img src={userFrame.darkMode?whiteUp:upC} style={{backgroundColor:'#2A2C2E', borderRadius:'24px', width:'28px',}}/>
                      :
                      <img src={up} style={{backgroundColor:'white', borderRadius:'24px', width:'28px',}}/>}
                      </a>
                    </div>
                  </div>
                  </div>
                </div>

                {/* Column Names - row */}
                <div style={{display:'flex', justifyContent:'left', alignItems:'center', width:'100%', marginTop:'10px', padding:'1%', paddingLeft:'2%',paddingRight:'2%', }}>
                  
                  {/* Column Name - row */}
                  <div style={{display:'inline-block', width:'20%' }}>
                      <h1 style={{fontSize:'17px', color:userFrame.darkMode?'white':'black', fontWeight:'bold', width:'100%', textAlign:'left'}}>
                          Icon 
                      </h1>
                  </div>

                  {/* Column Name - row */}
                  <div style={{display:'inline-block', width:'30%' }}>
                      <h1 style={{fontSize:'17px', color:userFrame.darkMode?'white':'black', fontWeight:'bold', width:'100%', textAlign:'left'}}>
                          Knock type 
                      </h1>
                  </div>

                  {/* Column Name - row */}
                  <div style={{display:'inline-block', width:'25%', marginLeft:'10px' }}>
                      <h1 style={{fontSize:'17px', color:userFrame.darkMode?'white':'black', fontWeight:'bold', width:'100%', textAlign:'center'}}>
                          Data
                      </h1>
                  </div>
                  
                  {/* Column Name - row */}
                  <div style={{display:'inline-block', width:'25%' }}>
                      <h1 style={{fontSize:'17px', color:userFrame.darkMode?'white':'black', fontWeight:'bold', width:'100%', textAlign:'center'}}>
                          Addresses
                      </h1>
                  </div>

                </div>

                {getKnockRows()}

            </div>
          </div>
      
          <div style={{width:'100%', height:'10%'}}>

          </div>

      </div>
  );
}

export default Progress;