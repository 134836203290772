import React, { useEffect, useState, useCallback } from 'react';
import { GoogleMap, useJsApiLoader, OverlayView,} from '@react-google-maps/api';
import darkMap from './DarkMap'

import TerritoryMarker from './TerritoryMarker';
import LocationMarker from './LocationMarker';

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../redux/actions/userFrameActions'

function MapContainer({}) {
  
  const userFrame = useSelector(state=>state.user_frame_reducer[0])
  //const houses = useSelector(state=>state.house_reducer)
  const dispatch = useDispatch()

  const [reload,setReload] = useState(0)

  const [map, setMap] = useState(null)

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDcnTc4mx5B7g1H0r9gW_g2of0yEzarZW4"
  })

  
  /*
  Load current location live feed
  */
  useEffect(()=>{

    if(userFrame.locationButtonClicked){
      let isMounted = true

      // load current location
      navigator?.geolocation.getCurrentPosition(({coords: {latitude: lat, longitude: lng}}) => {
        if(isMounted){
          const pos = {lat, lng}
          const u = userFrame
          u.centerSelectionLat = pos.lat
          u.centerSelectionLng = pos.lng
          u.locationLat = pos.lat
          u.locationLng = pos.lng
          dispatch(updateUserFrame(u))
        }
      })
      return () => { isMounted = false }
    }
  })

  /*
  Zoom into vancouver
  */
  useEffect(()=>{
    setTimeout(()=>{
        const u = userFrame
        u.zoom = 10.5
        dispatch(updateUserFrame(u))
    }, 1000);    
  }, [])

  
  function showTerritories() {

    /* Surrey = 1 */
    /* Vancouver = 2 */
    /* Richmond = 3 */
    /* Burnaby = 4 */
      return(
        <>
          <OverlayView 
            position={{lat:49.1913,
            lng:-122.8490}}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
              <TerritoryMarker id={1} />
          </OverlayView>

          <OverlayView 
            position={{lat:49.23,
            lng:-123.16}}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
                <TerritoryMarker id={2}/>
          </OverlayView> 

          <OverlayView 
            position={{lat:49.1666,
            lng:-123.1336}}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
              <TerritoryMarker id={3}/>
          </OverlayView>

  
          <OverlayView 
            position={{lat:49.2488,
            lng:-122.9805}}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
              <TerritoryMarker id={4} />
          </OverlayView>
        </>
      )
  }

  function showLocation() {
    
    return(
      <OverlayView 
        position={{lat: userFrame.locationLat, lng: userFrame.locationLng}}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
          <LocationMarker/> 
      </OverlayView>
    )
  }

    return isLoaded ? (
      <div style={{ height: '125%', width: '100%'}}>
          <GoogleMap
            mapContainerStyle={{width:'45vw', height:'80vh'}}
            center={{lat:userFrame.centerSelectionLat, lng:userFrame.centerSelectionLng}}
            zoom={userFrame.zoom}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{
              styles: userFrame.darkMode ? darkMap.dark : [],
              streetViewControl: userFrame.streetView?true:false,
              mapTypeControl: false,
            }}
          >
          <>
            {showTerritories()}

            {showLocation()}
          </>
        </GoogleMap>
      </div> ) : <></>;
  
}

export default MapContainer;