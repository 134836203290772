


import {UserFrame} from '../model/UserFrame'

export const user_frame_reducer = (oldUserFrameList=[],action) =>{
    if(action.type === "CREATE_USER_FRAME"){
        
        return [...oldUserFrameList,action.payload.userFrame]

    }else if(action.type === "DELETE_USER_FRAME"){
        
        oldUserFrameList = oldUserFrameList.filter((ele,index)=>action.payload.userFrame.id !== ele.id);
        return oldUserFrameList
        
    }else if(action.type === "RESET"){
       
        oldUserFrameList = []
        return [...oldUserFrameList]

    }else if(action.type === 'UPDATE_USER_FRAME'){
        oldUserFrameList[0] = action.payload.userFrame
        return [...oldUserFrameList]
    }
    return oldUserFrameList
}
