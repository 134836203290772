
import MapListSearchTablet from './ProjectComponents/WalkListSelected/TabletDesktop/ListMapSearch';
import MapListSearchPhone from './ProjectComponents/WalkListSelected/Phone/ListMapSearch';
import Responsive from '@tds/core-responsive'
import { useEffect, useState } from 'react';
import SignInTablet from './ProjectComponents/SignIn/TabletDesktop/SignIn';
import SignInPhone from './ProjectComponents/SignIn/Phone/SignIn';
import WalkListSelectionTablet from './ProjectComponents/WalkListSelection/TabletDesktop/WalkListSelection';
import WalkListSelectionPhone from './ProjectComponents/WalkListSelection/Phone/WalkListSelection';

import {connect} from 'react-redux';
import {createUserFrame} from './redux/actions/userFrameActions.js';
import {UserFrame} from './redux/model/UserFrame.js';
import {createHouse} from './redux/actions/houseActions.js';
import {House} from './redux/model/House.js';

function App(props) {

  useEffect(()=>{
    document.title = "Telus D2C1"
    document.body.style.overflow='hidden'
  })


  function showApp(){

    return (
      <div>
       
        <Responsive maxWidth="lg" render={() => 
        <MapListSearchPhone  /> } />
        
        <Responsive minWidth="lg" render={() => 
        <MapListSearchTablet /> } />
      </div>
    )
  }

  function showWalklistSelection(){

      return(
        <div>
          
          <Responsive maxWidth="lg" render={() => 
          <WalkListSelectionPhone />} />
          
          <Responsive minWidth="lg" render={() => 
          <WalkListSelectionTablet />} />
        </div>
      )
  }

  function showLogin(){
      return(
        <div>
         
          <Responsive maxWidth="lg" render={() => <SignInPhone/>} />
          <Responsive minWidth="lg" render={() => <SignInTablet/>} />
        </div>
      )
  }

  function showPages(){

    if(props.userFrame.length > 0){
      switch(props.userFrame[0].screenDepth){
        case 1:
          return showLogin()
        case 2:
          return showWalklistSelection()
        case 3:
          return showApp()
        default:
          return showLogin()
      }
    }else{
      
      // create new redux user frame object/model - userFrame will keep track of what app frame the user is in
      const user = new UserFrame();
      // add this new object to the redux store (saved as item in array) with this redux action
      props.createUserFrame(user);
    }
    
  }

  /*
  Always show login first, then if logged in show either the walklist 
  selection page or the main app. 
  */
  return (
    <div style={{overflow:'hidden'}}>
    {showPages()}
    </div>
    
  );
}

const mapStateToProps = (state) =>{

  return{
    userFrame: state.user_frame_reducer,
    house: state.house_reducer,
  };
}

export default connect(mapStateToProps, {
createUserFrame,
createHouse,
})(App);
