import React, { useEffect, useState } from 'react';

import ButtonHouseLarge from '../SubComponents/ButtonHouseLarge';
import IconHouse from '../SubComponents/IconHouse';

import HairlineDivider from '@tds/core-hairline-divider'

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../../redux/actions/userFrameActions'

function SmallerRightSideBar({}) {

    const userFrame = useSelector(state=>state.user_frame_reducer[0])
    const houses = useSelector(state=>state.house_reducer)
    const dispatch = useDispatch()

    const expanded = window.innerHeight>900?'2.3%':'2.3%'

    function disablePremise() {
        if(houses[userFrame.houseId-1].icon == 'DNK'){
          return(
            <div style={{width:'100%', height:'100%'}}>                
            <div style={{position:'absolute', left:'0', top:'2%', width:'100%', height:'98%', zIndex:11, backgroundColor:'rgba(125, 125, 125, 0.3)', boxShadow:`0 0 10px 10px rgba(125,125,125,0.3)`}}>
                {/*
                <div style={{marginTop:'35%', width:'100%',marginLeft:'-5%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <IconHouse icon={houses[userFrame.houseId-1].icon}/>
                </div>
                <div style={{marginTop:'35%', width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <h1 style={{color:'#C12335', fontSize:'30px', fontWeight:'bold', textAlign:'center'}}>Do Not Knock</h1>
                </div>*/}
                <div style={{marginTop:'95%', width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <h1 style={{color:userFrame.darkMode?'white':'#C12335', fontSize:'30px', fontWeight:'bold', textAlign:'center', textShadow:'0px 0px 15px #FFF'}}>Do Not Knock</h1>
                </div>
            </div>

            </div>
          )
        }
    }

    function showPremiseDetails(){

        const u = userFrame
        u.drawer = 1;
        u.showDetails = true;
        dispatch(updateUserFrame(u))

    }

    return (

        <div>
        
        {disablePremise()}

        {/* Quick Log-Knock Side Card */}        
        <div style={{position:'absolute', top: '0', right:0, height:'100%', width:'100%',backgroundColor:(userFrame.darkMode?'#2A2C2E':'white'), overflowY:'hidden', zIndex:10}}>
            
            
             <div style={{ width: '80%', height:'1%', display:'block', marginLeft: '10%',backgroundColor:(userFrame.darkMode?'#2A2C2E':'white')}}>
                
            </div>
    

            <div onClick={()=>showPremiseDetails()} style={{ height:'20%', width:'98%', marginLeft:'1%',backgroundColor:(userFrame.darkMode?'#2A2C2E':'white'), display: 'block', overflowY:'hidden', cursor:'pointer'}}>
                    <div>
                        <div style={{display:'block', marginLeft:'5%', width:'90%', height:'40%', backgroundColor:(userFrame.darkMode?'#2A2C2E':'white')}}>
                            <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                            <IconHouse icon={houses[userFrame.houseId-1].icon}/>
                            </div>
                        </div> 
                        <div style={{display:'block', marginTop: '5%',  marginLeft:'5%', width:'90%', height:'40%',backgroundColor:(userFrame.darkMode?'#2A2C2E':'white')}}>
                            <h1 style={{color:(userFrame.darkMode?'white':'#4B286D'), textAlign:'center'}}>{houses[userFrame.houseId-1].address}</h1>
                        </div>
                    </div>
            </div> 

            <div style={{ width: '80%', height:'1%', display:'block', marginLeft: '10%',backgroundColor:(userFrame.darkMode?'#2A2C2E':'white')}}>
                <HairlineDivider gradient />
            </div>

            <div style={{height:expanded, width:'90%', marginLeft:'5%', display:'block', backgroundColor:(userFrame.darkMode?'#2A2C2E':'white')}}></div>    

            <div style={{ display:'block', height:'74%', width:'80%', marginLeft:'10%', backgroundColor:(userFrame.darkMode?'#2A2C2E':'white'), overflowY:'hidden',}}>
                <div style={{width:'100%', display:'block'}}>
                    <div>
                    <ButtonHouseLarge title = {"No One Home"} icon = {'NOH'} />
                    </div>
                </div> 
                <div style={{width:'100%', display:'block'}}>
                    <div>
                    <ButtonHouseLarge title = {"Soft No"} icon = {'SN'} />
                    </div>
                </div>
                <div style={{width:'100%', display:'block'}}>
                    <div>
                    <ButtonHouseLarge title = {"Hard No"} icon = {'HN'} />
                    </div>
                </div> 
                <div style={{width:'100%'}}>
                    <div>
                    <ButtonHouseLarge title = {"Wrong Party"} icon = {'WP'} />
                    </div>
                </div>
                <div style={{width:'100%'}}>
                    <div>
                    <ButtonHouseLarge title = {"Not Interested"} icon = {'NI'} />
                    </div>
                </div> 
                <div style={{width:'100%'}}>   
                    <div>
                    <ButtonHouseLarge title = {"Other Knocks"} icon = {'OK'} />
                    </div>
                </div>               
            </div>
            
            <div style={{height:expanded, width:'100%', display:'block', backgroundColor:(userFrame.darkMode?'#2A2C2E':'white')}}></div>           
            
            </div>
        </div>
    
        );
}

export default SmallerRightSideBar;



