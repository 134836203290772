import React, { useState } from "react";

import backW from '../../../../icons/back/white/back.png';
import backP from '../../../../icons/back/purple/back.png';

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../../redux/actions/userFrameActions'

function ButtonIcon({}) {

  const userFrame = useSelector(state=>state.user_frame_reducer[0])
  const houses = useSelector(state=>state.house_reducer)
  const dispatch = useDispatch()

  const [move, setMove] = useState('0px')

  function setters(hover){
    if(hover){
      setMove('-5px')
    }else{
      setMove('0px')
    }
  }

  function handleClick(){

    const frame = userFrame;
    frame.screenDepth = 2;
    frame.zoom=10.1;
    dispatch(updateUserFrame(frame))
  }

  

  return (
    
      <button
        style={{ position:'absolute', left:'15px', top:'0', height:'100%',
        paddingLeft:'20px', overflow:'hidden',
        backgroundColor:userFrame.darkMode?'#2A2C2E':'white', border: 'none' }}
        onMouseEnter={() => setters(true)}
        onMouseLeave={() => setters(false)}
        onClick={() => handleClick()}
      > 
        <div style={{cursor: 'pointer', display:'flex',alignItems:'center',justifyContent:'center', width: '100%', height:'100%',}}>
          <img src={userFrame.darkMode?backW:backP} width="25px" style={{display:'inline-block', marginLeft:move}}/>
          <h1 style={{color:userFrame.darkMode?'white':'#4B286D',display:'inline-block',}}>Back</h1>
        </div>
        
      </button>
  );
}

export default ButtonIcon;