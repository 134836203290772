

export const updateHouse = house =>{
    return{
        type:'UPDATE_HOUSE',
        payload:{
            house: house
        }
    }
}

export const createHouse = house =>{
    return{
        type:'CREATE_HOUSE',
        payload:{
            house: house
        }
    }
}

export const deleteHouse = house =>{
    return{
        type:'DELETE_HOUSE',
        payload:{
            house
        }
    }
}

export const resetHouse = house =>{
    return{
        type:'RESET',
        payload:{
            house
        }
    }
}