import React, { useEffect, useState, } from "react";
import { useSelector, useDispatch } from "react-redux";

import Header from './SubComponents/Header';
import ListViewTablet from "../../ListView/ListViewTablet"
import MapViewTablet from "../../MapView/TabletDesktop/MapViewTablet";
import ProductsCommitmentsOffers from "../../PCOs/TabletDesktop/ProductsCommitmentsOffersMultiSelect";
import Search from "../../Search/TabletDesktop/Search";
import ButtonJoinedMenu from "./SubComponents/ButtonJoinedMenu";
import Progress from "../../ProgressPage/TabletDesktop/Progress";
import FollowUp from "../../ProgressPage/TabletDesktop/FollowUp";
import Settings from "../../Settings/TabletDesktop/Settings";
import ErrorHandle from "./SubComponents/ErrorHandle";
import LogoutHandle from "./SubComponents/LogoutHandle";
import ConfirmBox from "./SubComponents/ConfirmBox";

import {updateHouse} from '../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../redux/actions/userFrameActions'
import ProductDetails from "./SubComponents/ProductDetails";

function ListMapSearch({}) {

  const userFrame = useSelector(state=>state.user_frame_reducer[0])
  const houses = useSelector(state=>state.house_reducer)
  const dispatch = useDispatch()


  useEffect(()=>{
    setTimeout(()=>{
      //toggleErrorHandler()
    },3000)
  },[])

  function toggleErrorHandler() {
    const frame = userFrame;
    frame.errorMainApp = true
    dispatch(updateUserFrame(frame))
  }

  function showErrorHandler(){
    if(userFrame.errorMainApp){
      return(
        <ErrorHandle/>  
      )
    }
  }

  function showLogoutHandler(){
    if(userFrame.logoutHandle){
      return(
        <LogoutHandle/>  
      )
    }
}

  function showConfirmationBox(){
    if(userFrame.confirmBox){
       return( <ConfirmBox/> )
    }
  }

  function showProductDetails(){
    if(userFrame.showProductDetails){
      return( <ProductDetails/> )
    }
  }

  function switchMenuTab() {
    switch(userFrame.menuTab){

        case 1:
          return <Progress/>
        case 2:      
          return <Settings/>    
        case 3: 
          return <FollowUp/> 
        default:
          return <Progress/>
    
    }
  }

  function showMenu() {
    if(userFrame.menu){
      return(
        <div style={{position:'absolute', top:'10%', left:0, height:'90%', width:'100%', backgroundColor:userFrame.darkMode?'#54595F':'white', zIndex:15, overflow:'scroll',}}>
            <div style={{width:'100%', height:'15%', display:'block', }}>
              <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'100%', width:'100%'}}>
                <ButtonJoinedMenu />
              </div>
            </div>
            <div style={{width:'100%', height:'85%', display:'block' }}>
                {switchMenuTab()}
            </div>
        </div>
      )
    }

  }

  function switchMainTab(){

      switch(userFrame.tabView){
          case 1:
             /* LIST VIEW */
            return ( <ListViewTablet /> )

          case 2:
            /* MAP VIEW */
            return ( <MapViewTablet/> )
            
          case 3:
             /* SEARCH */
            return ( <Search /> )
            
          default:
            /* MAP VIEW */
            return ( <MapViewTablet/> )
                 
        }
  }

  function showProductsCommitmentsOffers(){

      return(
        <ProductsCommitmentsOffers/>
      )

  }


  function showWholePage(){

    return(
      
      <>
      {/* Overlay Logout Handler*/}
      {showLogoutHandler()}
      
      {/* Overlay Error Handler*/}
      {showErrorHandler()}

      {/* Overlay Confirmation Box */}
      {showConfirmationBox()}

      {/* Overlay Confirmation Box */}
      {showProductDetails()}

      {/* Overlay Menu */}
      {showMenu()}

      <div style={{height:'100%', width:'100%', position:'absolute', top:0, left:0, overflowY:'hidden'}}>

      { userFrame.showPCO ?
        /* Show products, commitments, and offers */
        showProductsCommitmentsOffers()
        
        :
        <>
        {/* Show list, map, and search views (with header tabs) */}
        {/* Header */}
        ({userFrame.leftHandedDrawer?
            <>
            {/* Header: dynamic width based on drawer click, house selection, and tab view */}
            <div style={{width: (userFrame.drawer==1 && userFrame.tabView == 2 ?'30%':'100%'), height:'10%', top:0, right:0, position: 'absolute', backgroundColor:'white',zIndex:16, }}>
            <Header />
            </div> 

            {/* Box Shadow */}
            <div style={{width: userFrame.drawer==2 && userFrame.tabView ==2 && !userFrame.menu ? "85%" : (userFrame.drawer==1 && userFrame.tabView == 2 ? '30%':'100%'), height:'10%', top:0, right:0, position: 'absolute', backgroundColor:'white',boxShadow:userFrame.darkMode?'none':'0 4px 2px -2px #9E9E9E',zIndex:15,}}>
            </div>
            </>
        :
            <>
            {/* Header: dynamic width based on drawer click, house selection, and tab view */}
            <div style={{width: (userFrame.drawer==1 && userFrame.tabView == 2 ? '30%':'100%'), height:'10%', top:0, left:0, position: 'absolute', backgroundColor:'white',zIndex:16,}}>
            <Header />
            </div> 

            {/* Box Shadow*/}
            <div style={{width: userFrame.drawer==2 && userFrame.tabView ==2 && !userFrame.menu ? "85%" : (userFrame.drawer==1 && userFrame.tabView == 2 ? '30%':'100%'), height:'10%', top:0, left:0, position: 'absolute', backgroundColor:'white',boxShadow:userFrame.darkMode?'none':'0 4px 2px -2px #9E9E9E',zIndex:15,}}>
            </div>
            </>
        })

        {/* Show Body (List/Map/Search Tab Views) */}
        {switchMainTab()}
        </>
      }
      </div>
        
      </>
      
    )
  }

  return (
    <div >
      {showWholePage()}
    </div>
  );
}

export default ListMapSearch;
