import React, { useState } from "react";

import up from '../../../../icons/drawer/purple/triangle/up.png';
import down from '../../../../icons/drawer/purple/triangle/down.png';
import upW from '../../../../icons/arrow/up/white.png';
import downW from '../../../../icons/arrow/down/white.png';

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../../redux/actions/userFrameActions'

function ButtonDrawerCircle({}) {
  
  const userFrame = useSelector(state=>state.user_frame_reducer[0])
  const houses = useSelector(state=>state.house_reducer)
  const dispatch = useDispatch()

  function clickDrawer(){

    if(userFrame.drawer==1){
      const frame = userFrame;
      frame.drawer = 2;
      dispatch(updateUserFrame(frame))
    }else{
      const frame = userFrame;
      frame.drawer = 1;
      dispatch(updateUserFrame(frame))
    }
    
  }

  return (
    
      <button
        style={{ 
        position:'absolute', top: '-23px', 
        height:'55px', width:'55px',
        display:'flex', alignItems:'center', justifyContent:'center',
        color: userFrame.darkMode?'white':'#4B286D', fontWeight: 'bold', borderColor:userFrame.darkMode?'white':'#4B286D', 
        borderRadius:'70px', borderWidth: '2px', 
        borderStyle: 'solid', backgroundColor: userFrame.darkMode?'#2A2C2E':'white' }}

        onClick={() => clickDrawer()}
      >{
      userFrame.drawer ==  2 ?
      <img src={userFrame.darkMode ?upW:up} width="45px" style={{display:'flex', justifyContent:'center', alignItems:'center'}}/> 
      : 
      <img src={userFrame.darkMode ?downW:down} width="45px" style={{display:'flex', justifyContent:'center', alignItems:'center'}}/>
      }</button>
  );
}

export default ButtonDrawerCircle;