import HairlineDivider from "@tds/core-hairline-divider";
import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../redux/actions/userFrameActions'

import up from '../../../icons/arrow/up/circled.png'
import down from '../../../icons/arrow/down/circled.png'
import expandDown from '../../../icons/arrow/down/black.png'
import expandUp from '../../../icons/arrow/up/black.png'
import info from '../../../icons/info/info.png'

import { Check, Email, Mobility, Offices, } from "@tds/core-decorative-icon";
import ReactTooltip from 'react-tooltip';

import purpleHouse from '../../../icons/house/purple/house.png';
import purpleNOH from '../../../icons/house/purple/not-home.png';
import purpleSN from '../../../icons/house/purple/soft-no.png';
import purpleHN from '../../../icons/house/purple/hard-no.png';
import purpleWP from '../../../icons/house/purple/wrong-party.png';
import purpleNI from '../../../icons/house/purple/not-interested.png';
import purpleOK from '../../../icons/house/purple/other-knock.png';
import purpleSK from '../../../icons/house/purple/successful-knock.png';
import purpleFU from '../../../icons/house/purple/follow-up.png';
import purpleDNK from '../../../icons/house/red/do-not-knock.png';
import { Delete, Edit } from "@tds/core-interactive-icon";


function FollowUp() {

  const userFrame = useSelector(state=>state.user_frame_reducer[0])
  const houses = useSelector(state=>state.house_reducer)
  const dispatch = useDispatch()

  // component did mount 
  useEffect(() => {
    // sort the follow ups by closest date
    const u = userFrame
    // find current follow up and modify it to replace with new one
    const newFollowUps = u.followUps.sort((a,b)=>{
      
      // TODO:
      // Sort by month first
      
      // if day is the same
      if(a.date.split(',')[1].split(' ')[2] == b.date.split(',')[1].split(' ')[2]){
        
        // sort by time

        // check if am/pm are equal between two times
        if(a.time.split(' ')[1] == b.time.split(' ')[1]){
          // check if hour is equal b/w two dates
          if(a.time.split(' ')[0].split(':')[0] == b.time.split(' ')[0].split(':')[0]){
            // sort by minute
            return a.time.split(' ')[0].split(':')[1] - b.time.split(' ')[0].split(':')[1]
          }else{
            // sort by hour
            return a.time.split(' ')[0].split(':')[0] - b.time.split(' ')[0].split(':')[0]
          }
        }else{
          // sort by am/pm
          if(a.time.split(' ')[1] == "AM"){
            return -1
          }else{
            return 1
          }
        }

      }else{
        // sort by day
        return a.date.split(',')[1].split(' ')[2] - b.date.split(',')[1].split(' ')[2]
      }
        
    })
    u.followUps = newFollowUps
    dispatch(updateUserFrame(u))

  }, []);

  function editSchedule(id) {
    const u = userFrame
    u.tabView = 2;
    u.drawer = 1;
    u.menu = false;
    u.followingUp = true;
    u.houseId = id;
    dispatch(updateUserFrame(u))
  }

  function deleteSchedule(id) {
    
      const u = userFrame
      u.confirmBox = true
      u.confirmAddress = houses[id-1].address
      u.confirmKnockType = 'Follow Up'
      u.confirmIcon = 'FU';
      u.confirmFollowUp = true;
      u.houseId = id;
      dispatch(updateUserFrame(u))

  }


  function completeSchedule(id) {
    
    const u = userFrame
    // find current follow up and modify it to replace with new one
    const newFollowUps = u.followUps.filter((obj, index)=>{
        if(obj.id == id){
          obj.complete = true
          return obj
        }else{
          return obj
        }
    })
    u.followUps = newFollowUps
    u.followUpsComplete = u.followUpsComplete + 1;
    dispatch(updateUserFrame(u))
  }


  function getFollowUps() {

    const followUpHouses = userFrame.followUps
  
    /* Map the filtered array of follow ups to each row */
    const rows = followUpHouses.map((date, index) =>

      /* Row of Column Data */
      <div key={index} style={{width:'100%', height:'80px',  }}>

      <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', marginTop:'10px', padding:'1%', paddingLeft:'2%',paddingRight:'2%', height:'80px',   }}>

            {/* Column buttons */}
            <div  style={{display:'inline-block', width:'7%', height:'80px', overflow:'hidden' }}>
                <a onClick={()=>{editSchedule(date.id)}}>
                  <div style={{display:'flex', alignItems:'center', justifyContent:'center', width:'90%', }}>      
                    <div style={{width:'37px',height:'37px', margin:'5px', borderRadius:'30px', display:'flex', alignItems:'center', justifyContent:'center',}}>
                      <Edit size={20} color={userFrame.darkMode?'white':(date.complete?'greyShark':"telusPurple")}/>
                    </div>
                  </div>
                </a>
            </div>
            

            {/* Column address/name */}
            <div  style={{display:'inline-block', width:'20%', height:'80px', overflow:'hidden' }}>
              <div style={{display:'flex', alignItems:'center', justifyContent:'Left', width:'90%', }}>      
                <div>
                <h1 style={{fontSize:'17px', color:date.complete?'grey':(userFrame.darkMode?'white':'black'), width:'140px',overflow:'hidden',textOverflow:'ellipsis', whiteSpace:'nowrap' }}>
                  {date.name == undefined || date.name == "" ? "N/A" : date.name }
                </h1>
                <h1 style={{fontSize:'17px', color:date.complete?'grey':(userFrame.darkMode?'white':'black'), width:'140px',overflow:'hidden',textOverflow:'ellipsis', whiteSpace:'nowrap' }}>
                  {date.address}
                </h1>
                
                </div>
              </div>
            </div>

            {/* Column contact info */}
            <div style={{display:'inline-block', width:'20%', height:'100%',height:'80px', overflow:'hidden' }}>
            <div style={{display:'flex', alignItems:'center', justifyContent:'Left', width:'90%'}}>      
                <div>
                {date.phone == undefined || date.phone == "" ?
                  <div style={{display:'flex', justifyContent:'center', alignItems:'center',textDecoration:userFrame.darkMode?'none':'underline' }}>
                    <Mobility size={16} variant={userFrame.darkMode?'inverted':'default'}/>
                    <h1 style={{fontSize:'17px', color:date.complete?'grey':(userFrame.darkMode?'white':'black'), width:'140px',overflow:'hidden',textOverflow:'ellipsis', whiteSpace:'nowrap', textDecoration:'none', marginLeft:'10px'  }}>
                      N/A
                    </h1>
                  </div>
                :
                  <a href={"tel:+"+date.phone} style={{display:'flex', justifyContent:'center', alignItems:'center',textDecoration:userFrame.darkMode?'none':'underline' }}>
                    <Mobility size={16} variant={userFrame.darkMode?'inverted':'default'}/>
                    <h1 style={{fontSize:'17px', color:date.complete?'grey':(userFrame.darkMode?'white':'black'), width:'140px',overflow:'hidden',textOverflow:'ellipsis', whiteSpace:'nowrap', textDecoration:'none', marginLeft:'10px'  }}>
                       {date.phone}
                    </h1>
                  </a>
                }

                {date.email == undefined || date.email == "" ? 
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center',textDecoration:userFrame.darkMode?'none':'underline'}}>
                      <Email size={16} variant={userFrame.darkMode?'inverted':'default'}/>
                      <h1 style={{fontSize:'17px', color:date.complete?'grey':(userFrame.darkMode?'white':'black'), width:'140px',overflow:'hidden',textOverflow:'ellipsis', whiteSpace:'nowrap', textDecoration:'none',marginLeft:'10px' }}>
                        N/A
                      </h1>
                    </div>
                  :
                    <a href={"mailto:"+date.email} style={{display:'flex', justifyContent:'center', alignItems:'center',textDecoration:userFrame.darkMode?'none':'underline'}}>
                      <Email size={16} variant={userFrame.darkMode?'inverted':'default'}/>
                      <h1 style={{fontSize:'17px', color:date.complete?'grey':(userFrame.darkMode?'white':'black'), width:'140px',overflow:'hidden',textOverflow:'ellipsis', whiteSpace:'nowrap', textDecoration:'none',marginLeft:'10px' }}>
                        { date.email }
                      </h1>
                    </a>
                  }
                </div>
              </div>
            </div>

            {/* Column notes */}
            <div style={{display:'inline-block', width:'20%', height:'80px', overflow:'hidden'}}>
              <h1 style={{fontSize:'17px', color:date.complete?'grey':(userFrame.darkMode?'white':'black'), width:'140px', textAlign:'left',overflow:'hidden',textOverflow:'ellipsis', whiteSpace:'nowrap'}}>
                  {date.notes}
              </h1>
            </div>

            {/* Column follow up date and time */}
            <div style={{display:'inline-block', width:'20%', height:'80px', overflow:'hidden' }}>
              <div style={{display:'flex', alignItems:'center', justifyContent:'Left', width:'100%'}}>      
                <div>
                <h1 style={{fontSize:'17px', color:date.complete?'grey':(userFrame.darkMode?'white':'black'), width:'100%',overflow:'hidden',textOverflow:'ellipsis', whiteSpace:'nowrap' }}>
                  {date.date}
                </h1>
                <h1 style={{fontSize:'17px', color:date.complete?'grey':(userFrame.darkMode?'white':'black'), width:'100%',overflow:'hidden',textOverflow:'ellipsis', whiteSpace:'nowrap' }}>
                  {date.time}
                </h1>
                </div>
              </div>
            </div>

          {/* Column delete/complete buttons */}
          <div  style={{display:'inline-block', width:'14%', height:'80px', overflow:'hidden' }}>
              <div style={{display:'flex', alignItems:'center', justifyContent:'center', width:'90%', }}>      
                <a onClick={()=>{deleteSchedule(date.id)}}>
                  <div style={{width:'37px', height:'37px',margin:'5px', borderRadius:'30px', display:'flex', alignItems:'center', justifyContent:'center', }}>
                  {date.complete==false?
                    <Delete size={20} color={userFrame.darkMode?'white':(date.complete?'greyShark':"telusPurple")}/>
                  :<></>}
                  </div>
                </a>
                
                <a onClick={()=>{completeSchedule(date.id)}}>
                  <div style={{width:'37px',height:'37px', margin:'5px', borderRadius:'30px', display:'flex', alignItems:'center', justifyContent:'center',}}>
                  {date.complete==false?
                  <Check variant={userFrame.darkMode?'inverted':'default'} size={20} />
                  :<></>}
                  </div>
                </a>
              </div>
            </div>
          </div>
      </div>
    )

    return rows
    
  }


  return (


          <div style={{width:'80%', display:'block',verticalAlign:'top',marginLeft:'10%', }}>
            <h1 style={{color:userFrame.darkMode?'white':'#4B286D', fontSize:'24px', marginLeft:'2%'}}>Follow Up</h1>
            <div style={{width:'100%', height:'90%', display:'inline-block',  borderRadius:'5px',}}>

                {/* Divider - row*/}
                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', marginTop:'10px'}}>
                  <HairlineDivider/>
                </div>

                {/* Title - row */}
                <div style={{display:'flex', justifyContent:'left', alignItems:'center', width:'96%', marginTop:'10px', marginLeft:'2%' }}>
                  <div style={{display:'inline-block'}}>
                    <h1 style={{fontSize:'36px', fontWeight:'bold', color:userFrame.darkMode?'white':'#4B286D', }}>
                      {userFrame.followUps.length}&nbsp;
                    </h1>
                  </div>
                  <div style={{display:'inline-block'}}>
                    <h1 style={{fontSize:'18px', color:userFrame.darkMode?'white':'#4B286D', display:'inline-block', }}>
                        scheduled {userFrame.followUps.length>1?"follow-ups":"follow-up"} in the
                        <span style={{fontWeight:'bold'}}> ABD247 </span>
                        walklist      
                    </h1>
                  </div>
                </div>

                {/* Divider - row */}
                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', marginTop:'10px'}}>
                  <HairlineDivider/>
                </div>

                 {/* Progress bar title - row 
                <div style={{display:'flex', justifyContent:'left', alignItems:'center', width:'96%', marginTop:'10px', marginLeft:'2%' }}>
                  
                  {/* "Walklist x knocks captured" 
                  <div style={{display:'inline-block', width:'70%'}}>
                    <h1 style={{fontSize:'18px', color:'black', }}>
                      Walklist
                      <span style={{fontWeight:'bold'}}> ABD247 </span> 
                      follow-ups complete
                    </h1>
                  </div>
                  
                  {/* Percentage complete | # of knocks 
                  <div style={{ width:'30%',display:'inline-block',}}>
                    <div style={{display:'flex', justifyContent:'right', alignItems:'center', width:'100%'}}>
                      <div>
                        <h1 style={{fontSize:'18px', color:'black', }}>{userFrame.followUps.length==0?0:parseFloat((userFrame.followUpsComplete/userFrame.followUps.length)*100).toFixed(1)}%</h1>
                      </div>
                      <div style={{borderLeft:'2px solid black', height:'20px', marginLeft:'5px',marginRight:'5px'}}></div>
                      <div>
                        <h1 style={{fontSize:'18px', color:'#66CC00', fontWeight:'bold' }}>{userFrame.followUps.length}</h1>
                      </div>
                    </div>
                  </div>

                </div>
                

                {/* Progress bar - row 
                <div style={{display:'flex', justifyContent:'left', alignItems:'center', width:'100%', height:'20px', backgroundColor:'#F2EFF4', marginTop:'10px' }}>
                    <div style={{display:'flex', justifyContent:'left', alignItems:'center', width:`${userFrame.followUps.length==0?0:parseFloat((userFrame.followUpsComplete/userFrame.followUps.length)*100).toFixed(1)}%`, height:'20px', backgroundColor:'#66CC00' }}></div>
                </div>
                */}

                {/* Column Names - row */}
                <div style={{display:'flex', justifyContent:'left', alignItems:'center', width:'100%', marginTop:'10px', padding:'1%', paddingLeft:'2%',paddingRight:'2%', }}>
                  

                  {/* Column Name - row */}
                  <div style={{display:'inline-block', width:'7%' }}>
                      
                  </div>

                  {/* Column Name - row */}
                  <div style={{display:'inline-block', width:'20%' }}>
                      <h1 style={{fontSize:'17px', color:userFrame.darkMode?'white':'black', fontWeight:'bold', width:'100%', textAlign:'left'}}>
                          Premise 
                      </h1>
                  </div>

                  {/* Column Name - row */}
                  <div style={{display:'inline-block', width:'20%' }}>
                      <h1 style={{fontSize:'17px', color:userFrame.darkMode?'white':'black', fontWeight:'bold', width:'100%', textAlign:'left'}}>
                          Contact 
                      </h1>
                  </div>

                  {/* Column Name - row */}
                  <div style={{display:'inline-block', width:'20%' }}>
                      <h1 style={{fontSize:'17px', color:userFrame.darkMode?'white':'black', fontWeight:'bold', width:'100%', textAlign:'left'}}>
                          Notes
                      </h1>
                  </div>
                  
                  {/* Column Name - row */}
                  <div style={{display:'inline-block', width:'20%' }}>
                      <h1 style={{fontSize:'17px', color:userFrame.darkMode?'white':'black', fontWeight:'bold', width:'100%', textAlign:'left'}}>
                          Follow Up
                      </h1>
                  </div>

                </div>

                {getFollowUps()}

            </div>
          </div>

  );
}

export default FollowUp;