import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../../redux/actions/userFrameActions'

function ButtonPill({title, index,}) {

  const userFrame = useSelector(state=>state.user_frame_reducer[0])
  const houses = useSelector(state=>state.house_reducer)
  const dispatch = useDispatch()

  function confirmSelection() {

    const u = userFrame
    u.confirmBox = true
    u.confirmAddress = houses[userFrame.houseId-1].address
    u.confirmKnockType = title
    u.confirmIndex = index
    u.confirmQuickKnock = false;
    dispatch(updateUserFrame(u))
  
}

  

  return (
    <div>
        <button
        style={{ 
        height:'40px', width:'100px', overflow:'hidden',
        cursor: 'pointer', borderWidth:'1px',
        backgroundColor: houses[userFrame.houseId-1].loggedKnocks[index]?(userFrame.darkMode?'#54595F':'#4B286D'):(userFrame.darkMode?'lightgrey':'white'), borderColor:userFrame.darkMode?'#54595F':'#4B286D', 
        borderRadius:'50px',borderStyle: 'solid', 
        color:houses[userFrame.houseId-1].loggedKnocks[index]?'white':(userFrame.darkMode?'#54595F':'#4B286D'), margin:'5px'}}
        
        onClick={() => confirmSelection()}
        >
          <h1 style={{color: houses[userFrame.houseId-1].loggedKnocks[index]?'white':(userFrame.darkMode?'black':'#4B286D'), display:'flex', alignItems:'center', justifyContent:'center', fontSize:'11px'}}>{title}</h1>
        </button>
    </div>
  );
}

export default ButtonPill;