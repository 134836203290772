import React, { useState, useEffect } from "react";

import HairlineDivider from "@tds/core-hairline-divider";
import back from '../../../../icons/back/purple/back.png';
import backWhite from '../../../../icons/back/white/back.png';

import { useSelector,useDispatch } from "react-redux";
import {updateHouse} from '../../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../../redux/actions/userFrameActions'

function ShowDetails({ }) {

  const userFrame = useSelector(state=>state.user_frame_reducer[0])
  const houses = useSelector(state=>state.house_reducer)
  const dispatch = useDispatch()

function setBackButtonClicked(){
    const frame = userFrame
    frame.showDetails=false
    dispatch(updateUserFrame(frame))
}

function getDetails(house){
    const listOfDetails = house.details.map((val, i) => 
    <div key={i} style={{marginBottom:'10px',width:'80%', marginLeft:'10%'}}>
      <div style={{display:'flex', justifyContent:'space-between'}}>
        <h1 style={{fontSize:14, fontWeight:'bold', color:userFrame.darkMode?'white':'black'}}>{val.key}</h1> 
        <h1 style={{fontSize:14, textAlign:'right',color:userFrame.darkMode?'white':'black'}}>{val.value}</h1> 
      </div>
      <div style={{width:'100%', marginTop:'10px'}}>
        <HairlineDivider/>
      </div>
    </div> )

    return listOfDetails
  }


  return (
             
      <div style={{position:'absolute', top:'0', left:'0', height: '100%', width:'100%', backgroundColor:userFrame.darkMode?'#2A2C2E':'white', zIndex:10,  overflowY:'scroll'}}>
      <a onClick = {() => setBackButtonClicked()} style={{position:'absolute',top:'30px', left:'15px', height: '5%', width:'30%', backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}>
          <div style={{display:'inline-block', top:'10%',marginLeft: '5px', zIndex:10,}}>
              <img style={{ marginBottom:'-3px'}} src={userFrame.darkMode?backWhite:back} width="20px"/> 
          </div>
          <h1 style={{top:'10%', marginLeft: '1px', display:'inline-block', color:userFrame.darkMode?'white':'#4B286D', fontSize:'15px', zIndex:10}}>Back</h1>
      </a>

      <div style={{position:'absolute', top:'80px', left: '10%', }}>
          <h1 style={{color:userFrame.darkMode?'white':'#4B286D', fontSize:'28px'}}>Premise Details</h1>
      </div>

      <div style={{position:'absolute', top:'150px', left:'10%', width:'80%', overflowY:'scroll', backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}>
          <div style={{marginBottom:'20px',width:'80%', marginLeft:'10%'}}>
            <h1 style={{color:userFrame.darkMode?'white':'#2B8000', fontSize:'20px',}}>
              {houses[userFrame.houseId-1].address}
            </h1>
          </div>
          {getDetails(houses[userFrame.houseId-1])}
          <div style={{width:'100%', height:'50px', }}></div>
      </div>
    
    </div>
      
  );
}

export default ShowDetails;
