

export const updateUserFrame = userFrame =>{
    return{
        type:'UPDATE_USER_FRAME',
        payload:{
            userFrame: userFrame
        }
    }
}

export const createUserFrame = userFrame =>{
    return{
        type:'CREATE_USER_FRAME',
        payload:{
            userFrame: userFrame
        }
    }
}

export const deleteUserFrame = userFrame =>{
    return{
        type:'DELETE_USER_FRAME',
        payload:{
            userFrame
        }
    }
}

export const resetUserFrame = userFrame =>{
    return{
        type:'RESET',
        payload:{
            userFrame
        }
    }
}