import React, { useEffect, useState } from "react";
import { ChevronRight, ChevronLeft} from '@tds/core-interactive-icon'
import { Visible, Invisible, Time, Settings, Offices } from '@tds/core-decorative-icon'

import greenHouse from '../../../../icons/house/green/house.png';
import purpleHouse from '../../../../icons/house/purple/house.png';
import whiteHouse from '../../../../icons/house/white/house.png';
import greyHouse from '../../../../icons/house/grey/house.png';

import greenNOH from '../../../../icons/house/green/not-home.png';
import purpleNOH from '../../../../icons/house/purple/not-home.png';
import whiteNOH from '../../../../icons/house/white/not-home.png';
import greyNOH from '../../../../icons/house/grey/not-home.png';

import greenSN from '../../../../icons/house/green/soft-no.png';
import purpleSN from '../../../../icons/house/purple/soft-no.png';
import whiteSN from '../../../../icons/house/white/soft-no.png';
import greySN from '../../../../icons/house/grey/soft-no.png';

import greenHN from '../../../../icons/house/green/hard-no.png';
import purpleHN from '../../../../icons/house/purple/hard-no.png';
import whiteHN from '../../../../icons/house/white/hard-no.png';
import greyHN from '../../../../icons/house/grey/hard-no.png';

import greenWP from '../../../../icons/house/green/wrong-party.png';
import purpleWP from '../../../../icons/house/purple/wrong-party.png';
import whiteWP from '../../../../icons/house/white/wrong-party.png';
import greyWP from '../../../../icons/house/grey/wrong-party.png';

import greenNI from '../../../../icons/house/green/not-interested.png';
import purpleNI from '../../../../icons/house/purple/not-interested.png';
import whiteNI from '../../../../icons/house/white/not-interested.png';
import greyNI from '../../../../icons/house/grey/not-interested.png';

import greenOK from '../../../../icons/house/green/other-knock.png';
import purpleOK from '../../../../icons/house/purple/other-knock.png';
import whiteOK from '../../../../icons/house/white/other-knock.png';
import greyOK from '../../../../icons/house/grey/other-knock.png';

import greenSK from '../../../../icons/house/green/successful-knock.png';
import purpleSK from '../../../../icons/house/purple/successful-knock.png';
import whiteSK from '../../../../icons/house/white/successful-knock.png';
import greySK from '../../../../icons/house/grey/successful-knock.png';

import greenFU from '../../../../icons/house/green/follow-up.png';
import purpleFU from '../../../../icons/house/purple/follow-up.png';
import whiteFU from '../../../../icons/house/white/follow-up.png';
import greyFU from '../../../../icons/house/grey/follow-up.png';

import blue from '../../../../icons/house/blue/pending-order.png';
import red from '../../../../icons/house/red/do-not-knock.png';
import whiteDNK from '../../../../icons/house/white/not-interested-old.png' 

import greenHot from '../../../../icons/house/green/hot.png';
import purpleHot from '../../../../icons/house/purple/hot.png';
import whiteHot from '../../../../icons/house/white/hot.png';

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../../redux/actions/userFrameActions'

function ButtonHouseFilter({icon, extra,title, main}) {

    const userFrame = useSelector(state=>state.user_frame_reducer[0])
    const houses = useSelector(state=>state.house_reducer)
    const dispatch = useDispatch()

  function confirmSelection() {
    const u = userFrame

    if(main){
        u.filterPrompt = !u.filterPrompt; 
    }else{
        u.filterPrompt = false;
        u.filterSelection = icon;
    }

    dispatch(updateUserFrame(u))
  }


  const iconWidth = '30px'

  function renderIcon(selection) {
    switch(selection) {
      case 'NOH':
        if(userFrame.darkMode){
          return <img src={whiteNOH} width={iconWidth} />;
        }else{
          return <img src={purpleNOH} width={iconWidth} />;
        }
      case 'FU':
        if(userFrame.darkMode){
          return <img src={whiteFU} width={iconWidth} />;
        }else{
          return <img src={purpleFU} width={iconWidth} />;
        }  
      case 'HN':
        if(userFrame.darkMode){
          return <img src={whiteHN} width={iconWidth} />;
        }else{
          return <img src={purpleHN} width={iconWidth} />;
        }        
      case 'WP':
        if(userFrame.darkMode){
          return <img src={whiteWP} width={iconWidth} />;
        }else{
          return <img src={purpleWP} width={iconWidth} />;
        }
      case 'NI':
        if(userFrame.darkMode){
          return <img src={whiteNI} width={iconWidth} />;
        }else{
          return <img src={purpleNI} width={iconWidth} />;
        }    
      case 'OK':
        if(userFrame.darkMode){
          return <img src={whiteOK} width={iconWidth} />;
        }else{
          return <img src={purpleOK} width={iconWidth} />;
        }    
      case 'house':
        if(userFrame.darkMode){
          return <img src={whiteHouse} width={iconWidth} />;
        }else{
          return <img src={purpleHouse} width={iconWidth} />;
        }
      case 'SK':
        if(userFrame.darkMode){
          return <img src={whiteSK} width={iconWidth} />;
        }else{
          return <img src={purpleSK} width={iconWidth} />;
        }
      case 'SN':
        if(userFrame.darkMode){
          return <img src={whiteSN} width={iconWidth} />;
        }else{
          return <img src={purpleSN} width={iconWidth} />;
        }
      case 'DNK':
        if(userFrame.darkMode){
          return <img src={whiteDNK} width={iconWidth} />;
        }else{
          return <img src={red} width={iconWidth} />;
        }
      case 'hot':
        if(userFrame.darkMode){
          return <img src={whiteHot} width={iconWidth} />;
        }else{
          return <img src={purpleHot} width={iconWidth} />;
        }
      case 'multi':
        return <Offices variant={userFrame.darkMode?'inverted':'default'} size={32}/>
      case 'unfiltered':
        return <Settings variant={userFrame.darkMode?'inverted':'default'} size={32}/>
      default:
        if(userFrame.darkMode){
          return <img src={whiteHouse} width={iconWidth} />;
        }else{
          return <img src={purpleHouse} width={iconWidth} />;
        } 
      }
  }


  return (
    <>
      <button
        style={{ 
        height:'100%', width:'100%', paddingTop:'10px',
        color: userFrame.darkMode?'white':'#4B286D', fontWeight: '400', borderColor:userFrame.darkMode?(main?'#54595F':'#2A2C2E'):'white', 
        borderRadius:'3px', borderWidth: '3px', cursor: 'pointer',
        borderStyle: 'solid', backgroundColor: userFrame.darkMode?(main?'#54595F':'#2A2C2E'):'white', alignItems:'center', justifyContent:'center', display:'flex' }}
        onClick={() => confirmSelection()}
      >
        <div>
        {renderIcon(icon)} 
        <h1 style={{fontSize:main?'14px':'11px', marginTop:'-3px'}}>{title}</h1>
        </div>
        
      </button>
      </>
  );
}

export default ButtonHouseFilter;