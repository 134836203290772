import React, { useState, useEffect } from "react";

import ButtonRectangle from './ButtonRectangle'
import ButtonMic from "./ButtonMic";
import TimeDropDownMenus from "./TimeDropDownMenus";

import Box from '@tds/core-box'
import { ArrowUp } from "@tds/core-decorative-icon";

import back from '../../../../icons/back/purple/back.png';
import backWhite from '../../../../icons/back/white/back.png';

import Calendar from 'react-calendar';
import '../../../../Calendar.scss';
import '../../../../CalendarDark.scss';

import { useSelector,useDispatch } from "react-redux";
import {updateHouse} from '../../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../../redux/actions/userFrameActions'


function FollowUp({ }) {

  const userFrame = useSelector(state=>state.user_frame_reducer[0])
  const houses = useSelector(state=>state.house_reducer)
  const dispatch = useDispatch()


  function setTimeHour(event){
    
    const hour = event.target.value    
    let h = houses[userFrame.houseId-1]
    h.followUpTimeHour = hour
    dispatch(updateHouse(h))

  }

  function setTimeMin(event){
    
    const min = event.target.value    
    let h = houses[userFrame.houseId-1]
    h.followUpTimeMin = min
    dispatch(updateHouse(h))

  }

  function setTimeAMPM(event){
    
    const ampm = event.target.value    
    let h = houses[userFrame.houseId-1]
    h.followUpTimeAMPM = ampm
    dispatch(updateHouse(h))

  }

  function notesText(event){
    
    const h = houses[userFrame.houseId-1]
    h.notes = event.target.value
    dispatch(updateHouse(h))

  }

  function followupName(event){
    
    const h = houses[userFrame.houseId-1]
    h.followUpName = event.target.value
    dispatch(updateHouse(h))
}

function followupEmail(event){
    
    const h = houses[userFrame.houseId-1]
    h.followUpEmail = event.target.value
    dispatch(updateHouse(h))
}

function followupPhone(event){
    
    const h = houses[userFrame.houseId-1]
    h.followUpPhone = event.target.value
    dispatch(updateHouse(h))
}

function followupDate(value){
    
    const h = houses[userFrame.houseId-1]
    h.followUpDate = value
    dispatch(updateHouse(h))
}

function setFollowUpButtonClicked(value){
    const frame = userFrame
    frame.followingUp=value
    dispatch(updateUserFrame(frame))
}

function saveToSchedule() {
    
    const u = userFrame
    u.confirmBox = true
    u.confirmAddress = houses[userFrame.houseId-1].address
    u.confirmKnockType = 'Follow Up'
    u.confirmIcon = 'FU';
    u.confirmFollowUp = true;
    dispatch(updateUserFrame(u))

}

  return (
             
      <div style={{position:'absolute', top:'0', left:'0',boxShadow:'3px 3px 3px 3px #9E9E9E', height: '100%', width:'100%', backgroundColor:userFrame.darkMode?'#2A2C2E':'white', zIndex:6, overflowY:'scroll'}}>
      <a onClick = {() => {setFollowUpButtonClicked(false)}} style={{position:'absolute',top:'30px', left:'15px', height: '5%', width:'30%', backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}>
          <div style={{display:'inline-block', top:'10%',marginLeft: '5px', zIndex:6,}}>
              <img style={{ marginBottom:'-3px'}} src={userFrame.darkMode?backWhite:back} width="20px"/> 
          </div>
          <h1 style={{top:'10%', marginLeft: '1px', display:'inline-block', color:userFrame.darkMode?'white':'#4B286D', fontSize:'15px', zIndex:6}}>Back</h1>
      </a>

      <div style={{position:'absolute', top:'80px', left: '10%', }}>
      <h1 style={{color:userFrame.darkMode?'white':'#4B286D', fontSize:'28px'}}>
            Add details to follow up
        </h1>              <div style={{marginBottom:'20px',}}>
            <h1 style={{color:userFrame.darkMode?'white':'#2B8000', fontSize:'20px',}}>
              {houses[userFrame.houseId-1].address}
            </h1>
          </div> 
      </div>

      <div style={{position:'absolute', top:'170px', left:'10%', width:'80%', overflowX:'hidden'}}>
          <Box inset={1}>
          <h1 style={{fontWeight:'bold', color:userFrame.darkMode?'white':'black'}}>
                 Name 
              </h1>
              <input onChange={followupName} value={houses[userFrame.houseId-1].followUpName} 
              style={{width:'100%', height:'50px', borderRadius:'6px', backgroundColor:userFrame.darkMode?'#54595F':'white', 
              color:userFrame.darkMode?'white':'black', padding:'17px', outlineColor:userFrame.darkMode?'#2A2C2E':'black', outlineWidth:'0px',border:userFrame.darkMode?'1px solid #2A2C2E':'1px solid black'}}/>
              
              <h1 style={{fontWeight:'bold', color:userFrame.darkMode?'white':'black', marginTop:'20px'}}>
                 Email 
              </h1>
              <input onChange={followupEmail} value={houses[userFrame.houseId-1].followUpEmail} 
              style={{width:'100%', height:'50px', borderRadius:'6px', backgroundColor:userFrame.darkMode?'#54595F':'white', 
              color:userFrame.darkMode?'white':'black', padding:'17px', outlineColor:userFrame.darkMode?'#2A2C2E':'black', outlineWidth:'0px', border:userFrame.darkMode?'1px solid #2A2C2E':'1px solid black'}}/>
              
              <h1 style={{fontWeight:'bold', color:userFrame.darkMode?'white':'black', marginTop:'20px'}}>
                Phone
              </h1>
              <input onChange={followupPhone} value={houses[userFrame.houseId-1].followUpPhone} 
              style={{width:'100%', height:'50px', borderRadius:'6px', backgroundColor:userFrame.darkMode?'#54595F':'white', 
              color:userFrame.darkMode?'white':'black', padding:'17px', outlineColor:userFrame.darkMode?'#2A2C2E':'black', outlineWidth:'0px',border:userFrame.darkMode?'1px solid #2A2C2E':'1px solid black'}}/>
          </Box>
      </div>

      <div className='calendar-container' style={{position:'absolute', top:'470px', left:0, display:'flex', alignItems:'center', justifyContent:'center', width:'100%', height:'350px' }}>
         <div style={{width:'80%', display:'flex', alignItems:'center', justifyContent:'center',}}>
          <Calendar
              onChange={followupDate}
              value={houses[userFrame.houseId-1].followUpDate == null ? new Date() : houses[userFrame.houseId-1].followUpDate}
              className={userFrame.darkMode ? 'react-calendar-dark':'react-calendar-light'}
              />
          </div>
      </div>

      <div style={{position:'absolute', top:'820px', left:0, display:'flex', alignItems:'center', justifyContent:'center', width:'100%', height:'100px', zIndex:6 }}>
        
      <TimeDropDownMenus/>
      </div>

      <div style={{position:'absolute', top:'970px', left:'5%', width:'90%', display:'flex', alignItems:'center', justifyContent:'center'}}>
      <div style={{display:'block', height:'85px',backgroundColor:userFrame.darkMode?'#2A2C2E':'white',  width:'100%', paddingLeft:'10%', overflow:'visible'}}>
      <label style={{width:'100%', display:'inline-block', color:userFrame.darkMode?'white':'black', fontWeight:'bold', overflow:'visible'}}>
                    Notes
                    <br></br>
                    <div style={{width:'80%', display:'inline-block'}}>
                    <textarea value={houses[userFrame.houseId-1].notes} onChange={notesText} 
                            style={{ width:'99%', height:'60px', resize:'none', outlineWidth:'1px', borderRadius:'5px', borderColor:userFrame.darkMode?'#2A2C2E':'#4B286D', 
                            color:userFrame.darkMode?'white':'#4B286D', outlineColor:userFrame.darkMode?'#2A2C2E':'#4B286D',  
                            fontSize:'12px', fontWeight:'400',backgroundColor:userFrame.darkMode?'#54595F':'white',padding:'17px', outlineStyle:'none',}}/>
                    </div>
                    <div style={{width:'10%', display:'inline-block', overflow:'visible'}}>     
                        <ButtonMic/>
                    </div>
                </label>
         </div>
      </div>


      <div style={{position:'absolute', top:'1120px', left:'10%', width:'80%', display:'flex', paddingBottom:"100px", alignItems:'center', justifyContent:'center',}}>
                <button
                    style={{ 
                    paddingTop: '7px', paddingBottom: '7px', paddingLeft: '45px', paddingRight: '45px',
                    color: userFrame.darkMode? '#54595F':'white', fontWeight: 'bold', borderColor:userFrame.darkMode?'#D8D8D8':'#4B286D', cursor:'pointer',
                    borderRadius:'5px', borderWidth: '1px', width:'100%', 
                    borderStyle: 'solid', backgroundColor: userFrame.darkMode?'#D8D8D8':'#4B286D' }}
                    onClick={() => saveToSchedule()}
                >
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', height:'100%'}}>
                        <ArrowUp variant={userFrame.darkMode?'alternative':'inverted'} size={32}/>
                        <h1 style={{marginLeft:'20px'}}>{houses[userFrame.houseId-1].icon == 'FU'?'Un-Log Follow Up':'Log Follow Up'}</h1>
                    </div>
                </button>
            {/*<ButtonRectangle color={userFrame.darkMode?'#D8D8D8':'#4B286D'} text={houses[userFrame.houseId-1].icon == 'FU'?'Un-Log Follow Up':'Log Follow Up'} onButtonClicked={saveToSchedule}/> */}
      </div>

  </div>
      
      //</div>
  );
}

export default FollowUp;
