import React, { useState } from "react";

import Button from '@tds/core-button'
import Input from '@tds/core-input'
import Box from '@tds/core-box'
import TextButton from '@tds/core-text-button'
import Heading from '@tds/core-heading'

import loveBirds from '../../../icons/images/love-birds.png';
import telus from '../../../icons/telus/purple.png';

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../redux/actions/userFrameActions'

function ErrorHandle() {

  const userFrame = useSelector(state=>state.user_frame_reducer[0])
  const houses = useSelector(state=>state.house_reducer)
  const dispatch = useDispatch()

  function handleClick(){

    const frame = userFrame;
    frame.errorWalklistSelection = false
    dispatch(updateUserFrame(frame))
    
  }


  return (
    
     <div style={{width:'100%', height:'100%', position:'absolute', top:0, left:0, backgroundColor:'rgba(125, 125, 125, 0.3)', zIndex:11}}>
      
       { /* Error Form */}
        <div style={{position: 'absolute', top:'20%', left: '25%',height:'70%', width:'50%', boxShadow:userFrame.darkMode?'none':'3px 3px 3px 3px #9E9E9E',overflowY:'scroll', backgroundColor:userFrame.darkMode?'#2A2C2E':'white', borderRadius:userFrame.darkMode?'4px':'0px' }}>

            <div style={{position: 'absolute', top:'10%', left: '20%',height:'33%', width:'60%', }}>
            <Box between={3}>
                <h1 style={{color:userFrame.darkMode?'white':'#4B286D', fontSize:'28px'}}>
                  Error:
                </h1>
                <h1 style={{color:userFrame.darkMode?'white':'black', fontSize:'20px',  fontWeight:'bold'}}>
                  You must be assigned a walklist for that territory
                </h1>
                <br></br>
                <button  onClick={()=>{handleClick()}} 
                  style={{width:'100%', height:'60px', backgroundColor:userFrame.darkMode?'#D8D8D8':'#2B8000', 
                  color:userFrame.darkMode?'black':'white',fontWeight:'bold', borderColor:userFrame.darkMode?'#D8D8D8':'#2B8000', 
                  border:'2px solid', borderRadius:'5px'}}>
                    Try Again
                  </button>
                <br></br>
                {userFrame.darkMode?
                <a onClick={() => alert('Support Screen')}>
                  <h1 style={{width:'100%', textAlign:'center', textDecoration:'underline', color:'white', fontSize:'17px'}}>
                    Contact Support
                  </h1>
                </a>
                :
                <TextButton onClick={() => alert('Support Screen')}>
                    Contact Support
                </TextButton>}
            </Box>
            </div>
            {/* Row Image */}
            {userFrame.darkMode?<></>:
            <div style={{ position:'absolute', bottom: '0', right: '40px', width:'100%'}}>
                <img src={loveBirds} width="150px" style={{position:'absolute', bottom: '0', right: '0',}}/>
            </div>}
        </div>

        
        
      </div>
        
  );
}

export default ErrorHandle;