import React, { useEffect, useState, useRef } from "react";

import arrowDown from '../../../../icons/arrow/down/dgreen.png';
import arrowDownWhite from '../../../../icons/arrow/down/black.png';
import checkWhite from '../../../../icons/checkmark/white.png';
import checkBlack from '../../../../icons/checkmark/black.png';

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../../redux/actions/userFrameActions'

function TimeDropDownMenus () {

    const userFrame = useSelector(state=>state.user_frame_reducer[0])
    const houses = useSelector(state=>state.house_reducer)
    const dispatch = useDispatch()

    const [hourClicked, setHourClicked] = useState(false)
    const [hoverHour,setHoverHour] = useState('0')
    const [hour, setHour] = useState(houses[userFrame.houseId-1].followUpTimeHour)

    const [minClicked, setMinClicked] = useState(false)
    const [hoverMin,setHoverMin] = useState('0')
    const [min, setMin] = useState(houses[userFrame.houseId-1].followUpTimeMin)

    const [AMPMClicked, setAMPMClicked] = useState(false)
    const [hoverAMPM,setHoverAMPM] = useState('0')
    const [ampm, setAMPM] = useState(houses[userFrame.houseId-1].followUpTimeAMPM)

    /* Handles the dropdown button */
    function dropDownHour(val){
        setHoverHour(hour)
        setHourClicked(!hourClicked) 
    }
    /* Handles the dropdown value selection */
    function setHours(hours){
        setHour(hours)
        dropDownHour(false)

        // save to redux store
        const h = houses[userFrame.houseId-1]
        h.followUpTimeHour = hours
        dispatch(updateHouse(h))
    }
    /* Handles the hover effect */
    function hoverHourIsHovered(val){
        setHoverHour(val)
    }
    /* Handles the un-hover effect */
    function unHoverHour(){
        setHoverHour('0')
    }


    /* Handles the dropdown button */
    function dropDownMin(){
        setHoverMin(min)
        setMinClicked(!minClicked)
    }
    /* Handles the dropdown value selection */
    function setMins(mins){
        setMin(mins)
        dropDownMin(false)

        // save to redux store
        const h = houses[userFrame.houseId-1]
        h.followUpTimeMin = mins
        dispatch(updateHouse(h))
    }
    /* Handles the hover effect */
    function hoverMinIsHovered(val){
        setHoverMin(val)
    }
    /* Handles the un-hover effect */
    function unHoverMin(){
        setHoverMin('0')
    }


    /* Handles the dropdown button */
    function dropDownAMPM(){
        setHoverAMPM(ampm)
        setAMPMClicked(!AMPMClicked)
    }
    /* Handles the dropdown value selection */
    function setAMPMs(ampms){
        setAMPM(ampms)
        dropDownAMPM(false)

        // save to redux store
        const h = houses[userFrame.houseId-1]
        h.followUpTimeAMPM = ampms
        dispatch(updateHouse(h))
    }
    /* Handles the hover effect */
    function hoverAMPMIsHovered(val){
        setHoverAMPM(val)
    }
    /* Handles the un-hover effect */
    function unHoverAMPM(){
        setHoverAMPM('0')
    }

    /* Handles the clicks outside the div */
    const refHour = useRef(null)
    const refMin = useRef(null)
    const refAMPM = useRef(null)

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (refHour.current && !refHour.current.contains(event.target)) {
            setHourClicked(false);
        }

        if (refMin.current && !refMin.current.contains(event.target)) {
            setMinClicked(false);
        }

        if (refAMPM.current && !refAMPM.current.contains(event.target)) {
            setAMPMClicked(false);
        }
    };
    
return (
    <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center', overflow:'visible',}}>

        <div ref={refHour} style={{backgroundColor:userFrame.darkMode?'#2A2C2E':'white', overflow:'visible',width:'100px',position:'relative' }}>
            
            <h1 style={{fontWeight:'bold', fontSize:'16px', color:userFrame.darkMode?'white':'black'}}>Hour</h1>
            
            <button 
            onClick={() => dropDownHour()}
            style={{backgroundColor:userFrame.darkMode?'#D8D8D8':'white', color:'black', border:'1px solid black', borderRadius:'4px',cursor:'pointer', 
            width:hourClicked?'102px':'100px', height:hourClicked?'52px':'50px',  }}>

                <div style={{width:'100%',display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div style={{width:'65px',display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <h1>{houses[userFrame.houseId-1].followUpTimeHour}</h1>
                    </div>
                    <div style={{width:'35px',display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <img src={userFrame.darkMode?arrowDownWhite:arrowDown} style={{width:'25px',}}/>
                    </div>
                </div>
            </button>
            
            <div style={{position:'absolute',top:'0',left:'-20%', visibility:hourClicked?'visible':'hidden', }}>
            <div style={{width:'100px', backgroundColor:'rgb(228,229,229,0.95)', borderRadius:'5px', cursor:'pointer', boxShadow:'0px 0px 23px 3px rgba(0,0,0,0.2)', border:'1px solid rgb(210,210,210)'}}>
                <div style={{marginLeft:'5%',}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <h1 style={{fontWeight:'bold', textAlign:'left', color:'grey', marginLeft:'17px',  width:'70%'}}>
                            Hour
                        </h1>
                    </div>
                </div>
                <a onClick={()=>setHours("12")} onMouseEnter={()=>hoverHourIsHovered('12')} onMouseLeave={()=>unHoverHour()} 
                style={{display:'block', backgroundColor:   hoverHour=='12'?'#3996F9':'rgb(228,229,229,0.0)', margin:'5%', 
                width:'90%', height:'90%', borderRadius:'5px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {hour=='12'?<img src={ hoverHour=='12'? checkWhite : checkBlack} style={{width:'12px'}} />:<div style={{width:'12px'}}></div>}
                        <h1 style={{marginLeft:'5px',width:'70%', textAlign:'left', color: hoverHour=='12'? 'white':'black', fontWeight:'500'}}>12</h1>
                    </div>
                </a>
                <a onClick={()=>setHours("1")} onMouseEnter={()=>hoverHourIsHovered('1')} onMouseLeave={()=>unHoverHour()} 
                style={{display:'block', backgroundColor:   hoverHour=='1'?'#3996F9':'rgb(228,229,229,0.0)', margin:'5%', 
                width:'90%', height:'90%', borderRadius:'5px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {hour=='1'?<img src={ hoverHour=='1'? checkWhite : checkBlack} style={{width:'12px'}} />:<div style={{width:'12px'}}></div>}
                        <h1 style={{marginLeft:'5px',width:'70%', textAlign:'left', color: hoverHour=='1'? 'white':'black', fontWeight:'500'}}>1</h1>
                    </div>
                </a>
                <a onClick={()=>setHours("2")} onMouseEnter={()=>hoverHourIsHovered('2')} onMouseLeave={()=>unHoverHour()} 
                style={{display:'block', backgroundColor:   hoverHour=='2'?'#3996F9':'rgb(228,229,229,0.0)', margin:'5%', 
                width:'90%', height:'90%', borderRadius:'5px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {hour=='2'?<img src={ hoverHour=='2'? checkWhite : checkBlack} style={{width:'12px'}} />:<div style={{width:'12px'}}></div>}
                        <h1 style={{marginLeft:'5px',width:'70%', textAlign:'left', color: hoverHour=='2'? 'white':'black', fontWeight:'500'}}>2</h1>
                    </div>
                </a>
                <a onClick={()=>setHours(" 3")} onMouseEnter={()=>hoverHourIsHovered(' 3')} onMouseLeave={()=>unHoverHour()} 
                style={{display:'block', backgroundColor:   hoverHour==' 3'?'#3996F9':'rgb(228,229,229,0.0)', margin:'5%', 
                width:'90%', height:'90%', borderRadius:'5px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {hour==' 3'?<img src={ hoverHour==' 3'? checkWhite : checkBlack} style={{width:'12px'}} />:<div style={{width:'12px'}}></div>}
                        <h1 style={{marginLeft:'5px',width:'70%', textAlign:'left', color: hoverHour==' 3'? 'white':'black', fontWeight:'500'}}>3</h1>
                    </div>
                </a>
                <a onClick={()=>setHours("4")} onMouseEnter={()=>hoverHourIsHovered('4')} onMouseLeave={()=>unHoverHour()} 
                style={{display:'block', backgroundColor:   hoverHour=='4'?'#3996F9':'rgb(228,229,229,0.0)', margin:'5%', 
                width:'90%', height:'90%', borderRadius:'5px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {hour=='4'?<img src={ hoverHour=='4'? checkWhite : checkBlack} style={{width:'12px'}} />:<div style={{width:'12px'}}></div>}
                        <h1 style={{marginLeft:'5px',width:'70%', textAlign:'left', color: hoverHour=='4'? 'white':'black', fontWeight:'500'}}>4</h1>
                    </div>
                </a>
                <a onClick={()=>setHours("5")} onMouseEnter={()=>hoverHourIsHovered('5')} onMouseLeave={()=>unHoverHour()} 
                style={{display:'block', backgroundColor:   hoverHour=='5'?'#3996F9':'rgb(228,229,229,0.0)', margin:'5%', 
                width:'90%', height:'90%', borderRadius:'5px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {hour=='5'?<img src={ hoverHour=='5'? checkWhite : checkBlack} style={{width:'12px'}} />:<div style={{width:'12px'}}></div>}
                        <h1 style={{marginLeft:'5px',width:'70%', textAlign:'left', color: hoverHour=='5'? 'white':'black', fontWeight:'500'}}>5</h1>
                    </div>
                </a>
                <a onClick={()=>setHours("6")} onMouseEnter={()=>hoverHourIsHovered('6')} onMouseLeave={()=>unHoverHour()} 
                style={{display:'block', backgroundColor:   hoverHour=='6'?'#3996F9':'rgb(228,229,229,0.0)', margin:'5%', 
                width:'90%', height:'90%', borderRadius:'5px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {hour=='6'?<img src={ hoverHour=='6'? checkWhite : checkBlack} style={{width:'12px'}} />:<div style={{width:'12px'}}></div>}
                        <h1 style={{marginLeft:'5px',width:'70%', textAlign:'left', color: hoverHour=='6'? 'white':'black', fontWeight:'500'}}>6</h1>
                    </div>
                </a>
                <a onClick={()=>setHours("7")} onMouseEnter={()=>hoverHourIsHovered('7')} onMouseLeave={()=>unHoverHour()} 
                style={{display:'block', backgroundColor:   hoverHour=='7'?'#3996F9':'rgb(228,229,229,0.0)', margin:'5%', 
                width:'90%', height:'90%', borderRadius:'5px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {hour=='7'?<img src={ hoverHour=='7'? checkWhite : checkBlack} style={{width:'12px'}} />:<div style={{width:'12px'}}></div>}
                        <h1 style={{marginLeft:'5px',width:'70%', textAlign:'left', color: hoverHour=='7'? 'white':'black', fontWeight:'500'}}>7</h1>
                    </div>
                </a>
                <a onClick={()=>setHours("8")} onMouseEnter={()=>hoverHourIsHovered('8')} onMouseLeave={()=>unHoverHour()} 
                style={{display:'block', backgroundColor:   hoverHour=='8'?'#3996F9':'rgb(228,229,229,0.0)', margin:'5%', 
                width:'90%', height:'90%', borderRadius:'5px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {hour=='8'?<img src={ hoverHour=='8'? checkWhite : checkBlack} style={{width:'12px'}} />:<div style={{width:'12px'}}></div>}
                        <h1 style={{marginLeft:'5px',width:'70%', textAlign:'left', color: hoverHour=='8'? 'white':'black', fontWeight:'500'}}>8</h1>
                    </div>
                </a>
                <a onClick={()=>setHours("9")} onMouseEnter={()=>hoverHourIsHovered('9')} onMouseLeave={()=>unHoverHour()} 
                style={{display:'block', backgroundColor:   hoverHour=='9'?'#3996F9':'rgb(228,229,229,0.0)', margin:'5%', 
                width:'90%', height:'90%', borderRadius:'5px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {hour=='9'?<img src={ hoverHour=='9'? checkWhite : checkBlack} style={{width:'12px'}} />:<div style={{width:'12px'}}></div>}
                        <h1 style={{marginLeft:'5px',width:'70%', textAlign:'left', color: hoverHour=='9'? 'white':'black', fontWeight:'500'}}>9</h1>
                    </div>
                </a>
                <a onClick={()=>setHours("10")} onMouseEnter={()=>hoverHourIsHovered('10')} onMouseLeave={()=>unHoverHour()} 
                style={{display:'block', backgroundColor:   hoverHour=='10'?'#3996F9':'rgb(228,229,229,0.0)', margin:'5%', 
                width:'90%', height:'90%', borderRadius:'5px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {hour=='10'?<img src={ hoverHour=='10'? checkWhite : checkBlack} style={{width:'12px'}} />:<div style={{width:'12px'}}></div>}
                        <h1 style={{marginLeft:'5px',width:'70%', textAlign:'left', color: hoverHour=='10'? 'white':'black', fontWeight:'500'}}>10</h1>
                    </div>
                </a>
                <a onClick={()=>setHours("11")} onMouseEnter={()=>hoverHourIsHovered('11')} onMouseLeave={()=>unHoverHour()} 
                style={{display:'block', backgroundColor:   hoverHour=='11'?'#3996F9':'rgb(228,229,229,0.0)', margin:'5%', 
                width:'90%', height:'90%', borderRadius:'5px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {hour=='11'?<img src={ hoverHour=='11'? checkWhite : checkBlack} style={{width:'12px'}} />:<div style={{width:'12px'}}></div>}
                        <h1 style={{marginLeft:'5px',width:'70%', textAlign:'left', color: hoverHour=='11'? 'white':'black', fontWeight:'500'}}>11</h1>
                    </div>
                </a>
            </div>
            </div>
        </div>
        

        <div ref={refMin} style={{backgroundColor:userFrame.darkMode?'#2A2C2E':'white', overflow:'visible',width:'100px',marginLeft:'20px',marginRight:'20px',position:'relative' }}>
            
            <h1 style={{fontWeight:'bold', fontSize:'16px', color:userFrame.darkMode?'white':'black'}}>Min</h1>
            
            <button 
            onClick={() => dropDownMin()}
            style={{backgroundColor:userFrame.darkMode?'#D8D8D8':'white', color:'black', border:'1px solid black', borderRadius:'4px',cursor:'pointer', 
            width:minClicked?'102px':'100px', height:minClicked?'52px':'50px',}}>

                <div style={{width:'100%',display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div style={{width:'65px',display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <h1 style={{color:'black'}}>{houses[userFrame.houseId-1].followUpTimeMin}</h1>
                    </div>
                    <div style={{width:'35px',display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <img src={userFrame.darkMode?arrowDownWhite:arrowDown} style={{width:'25px',}}/>
                    </div>
                </div>
            </button>
            
            <div style={{position:'absolute',top:'0',left:'-20%', visibility:minClicked?'visible':'hidden', }}>
            <div style={{width:'100px', backgroundColor:'rgb(228,229,229,0.95)', borderRadius:'5px', cursor:'pointer', boxShadow:'0px 0px 23px 3px rgba(0,0,0,0.2)', border:'1px solid rgb(210,210,210)'}}>
                <div style={{marginLeft:'5%',}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <h1 style={{fontWeight:'bold', textAlign:'left', color:'grey', marginLeft:'17px',  width:'70%'}}>
                            Min
                        </h1>
                    </div>
                </div>
                <a onClick={()=>setMins("00")} onMouseEnter={()=>hoverMinIsHovered('00')} onMouseLeave={()=>unHoverMin()} 
                style={{display:'block', backgroundColor:   hoverMin=='00'?'#3996F9':'rgb(228,229,229,0.0)', margin:'5%', 
                width:'90%', height:'90%', borderRadius:'5px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {min=='00'?<img src={ hoverMin=='00'? checkWhite : checkBlack} style={{width:'12px'}} />:<div style={{width:'12px'}}></div>}
                        <h1 style={{marginLeft:'5px',width:'70%', textAlign:'left', color: hoverMin=='00'? 'white':'black', fontWeight:'500'}}>00</h1>
                    </div>
                </a>
                <a onClick={()=>setMins("15")} onMouseEnter={()=>hoverMinIsHovered('15')} onMouseLeave={()=>unHoverMin()} 
                style={{display:'block', backgroundColor:   hoverMin=='15'?'#3996F9':'rgb(228,229,229,0.0)', margin:'5%', 
                width:'90%', height:'90%', borderRadius:'5px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {min=='15'?<img src={ hoverMin=='15'? checkWhite : checkBlack} style={{width:'12px'}} />:<div style={{width:'12px'}}></div>}
                        <h1 style={{marginLeft:'5px',width:'70%', textAlign:'left', color: hoverMin=='15'? 'white':'black', fontWeight:'500'}}>15</h1>
                    </div>
                </a>
                <a onClick={()=>setMins("30")} onMouseEnter={()=>hoverMinIsHovered('30')} onMouseLeave={()=>unHoverMin()} 
                style={{display:'block', backgroundColor:   hoverMin=='30'?'#3996F9':'rgb(228,229,229,0.0)', margin:'5%', 
                width:'90%', height:'90%', borderRadius:'5px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {min=='30'?<img src={ hoverMin=='30'? checkWhite : checkBlack} style={{width:'12px'}} />:<div style={{width:'12px'}}></div>}
                        <h1 style={{marginLeft:'5px',width:'70%', textAlign:'left', color: hoverMin=='30'? 'white':'black', fontWeight:'500'}}>30</h1>
                    </div>
                </a>
                <a onClick={()=>setMins("45")} onMouseEnter={()=>hoverMinIsHovered('45')} onMouseLeave={()=>unHoverMin()} 
                style={{display:'block', backgroundColor:   hoverMin=='45'?'#3996F9':'rgb(228,229,229,0.0)', margin:'5%', 
                width:'90%', height:'90%', borderRadius:'5px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {min=='45'?<img src={ hoverMin=='45'? checkWhite : checkBlack} style={{width:'12px'}} />:<div style={{width:'12px'}}></div>}
                        <h1 style={{marginLeft:'5px',width:'70%', textAlign:'left', color: hoverMin=='45'? 'white':'black', fontWeight:'500'}}>45</h1>
                    </div>
                </a>
                
            </div>
            </div>
        </div>

        <div ref={refAMPM} style={{backgroundColor:userFrame.darkMode?'#2A2C2E':'white', overflow:'visible',width:'100px',position:'relative' }}>
            
            <h1 style={{fontWeight:'bold', fontSize:'16px', color:userFrame.darkMode?'white':'black'}}>AM/PM</h1>
            
            <button 
            onClick={() => dropDownAMPM()}
            style={{backgroundColor:userFrame.darkMode?'#D8D8D8':'white', color:'black', border:'1px solid black', borderRadius:'4px',cursor:'pointer', 
            width:AMPMClicked?'102px':'100px', height:AMPMClicked?'52px':'50px',}}>

                <div style={{width:'100%',display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div style={{width:'65px',display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <h1>{houses[userFrame.houseId-1].followUpTimeAMPM}</h1>
                    </div>
                    <div style={{width:'35px',display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <img src={userFrame.darkMode?arrowDownWhite:arrowDown} style={{width:'25px',}}/>
                    </div>
                </div>
            </button>
            
            <div style={{position:'absolute',top:'0',left:'-20%', visibility:AMPMClicked?'visible':'hidden', }}>
            <div style={{width:'100px', backgroundColor:'rgb(228,229,229,0.95)', borderRadius:'5px', cursor:'pointer', boxShadow:'0px 0px 23px 3px rgba(0,0,0,0.2)', border:'1px solid rgb(210,210,210)'}}>
                <div style={{marginLeft:'5%',}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <h1 style={{fontWeight:'bold', textAlign:'left', color:'grey', marginLeft:'17px',  width:'70%'}}>
                            AM/PM
                        </h1>
                    </div>
                </div>
                <a onClick={()=>setAMPMs("AM")} onMouseEnter={()=>hoverAMPMIsHovered('AM')} onMouseLeave={()=>unHoverAMPM()} 
                style={{display:'block', backgroundColor: hoverAMPM=='AM'?'#3996F9':'rgb(228,229,229,0.0)', margin:'5%', 
                width:'90%', height:'90%', borderRadius:'5px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {ampm=='AM'?<img src={ hoverAMPM=='AM'? checkWhite : checkBlack} style={{width:'12px'}} />:<div style={{width:'12px'}}></div>}
                        <h1 style={{marginLeft:'5px',width:'70%', textAlign:'left', color: hoverAMPM=='AM'? 'white':'black', fontWeight:'500'}}>AM</h1>
                    </div>
                </a>
                <a onClick={()=>setAMPMs("PM")} onMouseEnter={()=>hoverAMPMIsHovered('PM')} onMouseLeave={()=>unHoverAMPM()} 
                style={{display:'block', backgroundColor: hoverAMPM=='PM'?'#3996F9':'rgb(228,229,229,0.0)', margin:'5%', 
                width:'90%', height:'90%', borderRadius:'5px'}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {ampm=='PM'?<img src={ hoverAMPM=='PM'? checkWhite : checkBlack} style={{width:'12px'}} />:<div style={{width:'12px'}}></div>}
                        <h1 style={{marginLeft:'5px',width:'70%', textAlign:'left', color: hoverAMPM=='PM'? 'white':'black', fontWeight:'500'}}>PM</h1>
                    </div>
                </a>
                
            </div>
            </div>
        </div>
    </div>
);
  
};

  export default TimeDropDownMenus;