
import React, { useState, useEffect } from 'react';

import Box from '@tds/core-box'

import Input from '@tds/core-input'
import ButtonGroup from '@tds/core-button-group'

import Button from '../../ListView/SubComponents/Phone/Button';
import SearchAndFilter from './SubComponents/SearchAndFilter';

import warning from '../../../icons/warning/warning.png'
import warningWhite from '../../../icons/warning/warning-white.png'

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../redux/actions/userFrameActions'

import ProvinceDropDownMenus from './SubComponents/ProvinceDropDownMenus';

function Search({}) {

  const userFrame = useSelector(state=>state.user_frame_reducer[0])
  const houses = useSelector(state=>state.house_reducer)
  const dispatch = useDispatch()

  const [search,setSearch] = useState("")

  const [unitNum,setUnitNum] = useState("")
  const [streetName,setStreetName] = useState("")
  const [houseNum,setHouseNum] = useState("")
  const [city,setCity] = useState("")
  const [province,setProvince] = useState('BC')
  const [postalCode,setPostalCode] = useState("")

  function handleClick(buttonNumber){
    
    const u = userFrame
    u.searchTab = buttonNumber
    dispatch(updateUserFrame(u))
    
  }

  function onChangeSearch(event){
    setSearch(event.target.value)
}

  function onChangeUnitNum(event) {
    setUnitNum(event.target.value)

  }

function onChangeStreetName(event) {
  setStreetName(event.target.value)
  
}

function onChangeProvince(event) {
  setProvince(event.target.value)
  
}

function onChangeCity(event) {
  setCity(event.target.value)
 
}

function onChangeHouseNum(event) {
  setHouseNum(event.target.value)
 
}

function onChangePostalCode(event) {
  setPostalCode(event.target.value)
  
}

function doAdvancedSearch(){
  
  let fields = []
  
  if(postalCode!=""){
    fields.push(postalCode)
  }
  if(houseNum!=""){
    fields.push(houseNum)
  }
  if(city!=""){
    fields.push(city)
  }
  if(province!=""){
    fields.push(province)
  }
  if(streetName!=""){
    fields.push(streetName)
  }
  if(unitNum!=""){
    fields.push(unitNum)
  }

  if(fields.length>0){
    const u = userFrame
    u.tabView = 1
    u.advancedSearch = fields
    dispatch(updateUserFrame(u))
  }
}

function doSearch() {
  
  if(userFrame.searchStringPreSearch!=""){
    const u = userFrame
    u.tabView = 1
    u.searchString = userFrame.searchStringPreSearch
    dispatch(updateUserFrame(u))
  }

}

function clearSearch() {
  const u = userFrame
  u.searchString = ""
  u.searchStringPreSearch = ""
  u.houseOutsideWalklist = false
  dispatch(updateUserFrame(u))
}

  return (

   
    <div style={{height:'100%', width:'100%',overflow:'scroll', backgroundColor:userFrame.darkMode?'#54595F':'white'}}>
    <div style={{display:'block',height:'39px', width:'80%',marginTop:'150px', marginLeft:'10%', backgroundColor:userFrame.darkMode?'#54595F':'white', borderBottom:'solid 4px', borderColor:'lightgrey', zIndex:8, }}>
        <a onClick={() => handleClick(1)} style={{cursor:'pointer',padding:'6px', borderBottom:'solid 4px', borderColor:userFrame.searchTab==1?(userFrame.darkMode?'white':'#4B286D'):'lightgrey'}} >
          <h1 style={{display:'inline-block', fontSize:'23px',  color:userFrame.searchTab==1?(userFrame.darkMode?'white':'#4B286D'):'grey' }}>Search</h1>
        </a>
       
        <a onClick={() => handleClick(2)} style={{cursor:'pointer',padding:'6px',marginLeft:'15px',marginRight:'15px', borderBottom:'solid 4px', borderColor:userFrame.searchTab==2?(userFrame.darkMode?'white':'#4B286D'):'lightgrey'}}>
          <h1 style={{display:'inline-block', fontSize:'23px',  color:userFrame.searchTab==2?(userFrame.darkMode?'white':'#4B286D'):'grey' }}>Advanced Search</h1>
        </a>
    </div>

    {userFrame.searchTab == 2 ?
    
    <>

      {/*userFrame.houseOutsideWalklist?:<></>*/}
      <div style={{width:'80%', marginLeft:'10%', height:'60px', marginTop:'20px',display:'flex', justifyContent:'center', alignItems:'center' }}>
        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
        <img src={userFrame.darkMode?warningWhite:warning} style={{width:'23px'}}/>
        </div>
        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
            <h1 style={{color:userFrame.darkMode?'white':'#FFA500', fontSize:'17px', fontWeight:'bold', marginLeft:'20px'}}>
            Warning: Searching for a premise outside of the ABD247 walklist
            </h1>
        </div>
      </div>

     <div style={{display:'block', zIndex:8}}>
      <div style={{ marginLeft:'10%', width:'38%', height:'10%', display:'inline-block', backgroundColor:userFrame.darkMode?'#54595F':'white'}}>
      <Box between={2}>
       {/*<Input label="Unit Number" type="text" value={unitNum} onChange={onChangeUnitNum} />*/}
       <h1 style={{fontWeight:'bold', color:userFrame.darkMode?'white':'black', }}>
                    Unit Number
                </h1>
                <input onChange={onChangeUnitNum} value={unitNum} placeholder={'e.g. 4'}
                style={{width:'100%', height:'50px', borderRadius:'6px', backgroundColor:userFrame.darkMode?'#2A2C2E':'white', borderColor:userFrame.darkMode?'#54595F':'black', 
                color:userFrame.darkMode?'white':'black', padding:'17px', outlineColor:userFrame.darkMode?'#54595F':'black', outlineWidth:'0px',borderWidth:'1px',borderStyle:'solid',}}/>

              {/*<Input label="Street Name *" type="text" value={streetName} onChange={onChangeStreetName} />*/}
                <h1 style={{fontWeight:'bold', color:userFrame.darkMode?'white':'black', }}>
                    Street Name *
                </h1>
                <input onChange={onChangeStreetName} value={streetName} placeholder={'e.g. Address Road'}
                style={{width:'100%', height:'50px', borderRadius:'6px', backgroundColor:userFrame.darkMode?'#2A2C2E':'white', borderColor:userFrame.darkMode?'#54595F':'black', 
                color:userFrame.darkMode?'white':'black', padding:'17px', outlineColor:userFrame.darkMode?'#54595F':'black', outlineWidth:'0px',borderWidth:'1px',borderStyle:'solid',}}/>
      </Box>
      </div>
      <div style={{ marginLeft:'2%', width:'40%', height:'10%', display:'inline-block', backgroundColor:userFrame.darkMode?'#54595F':'white'}}>
      <Box between={2}>
        {/*<Input label="Unit Number" type="text" value={unitNum} onChange={onChangeUnitNum} />*/}
        <h1 style={{fontWeight:'bold', color:userFrame.darkMode?'white':'black', }}>
                    Unit Number
                </h1>
                <input onChange={onChangeUnitNum} value={unitNum} placeholder={'e.g. 4'}
                style={{width:'100%', height:'50px', borderRadius:'6px', backgroundColor:userFrame.darkMode?'#2A2C2E':'white', borderColor:userFrame.darkMode?'#54595F':'black', 
                color:userFrame.darkMode?'white':'black', padding:'17px', outlineColor:userFrame.darkMode?'#54595F':'black', outlineWidth:'0px',borderWidth:'1px',borderStyle:'solid',}}/>

              {/*<Input label="Street Name *" type="text" value={streetName} onChange={onChangeStreetName} />*/}
                <h1 style={{fontWeight:'bold', color:userFrame.darkMode?'white':'black', }}>
                    Street Name *
                </h1>
                <input onChange={onChangeStreetName} value={streetName} placeholder={'e.g. Address Road'}
                style={{width:'100%', height:'50px', borderRadius:'6px', backgroundColor:userFrame.darkMode?'#2A2C2E':'white', borderColor:userFrame.darkMode?'#54595F':'black', 
                color:userFrame.darkMode?'white':'black', padding:'17px', outlineColor:userFrame.darkMode?'#54595F':'black', outlineWidth:'0px',borderWidth:'1px',borderStyle:'solid',}}/>
      </Box>
      </div>
    </div>
  
    <div style={{display:'block'}}>
    <div style={{ marginLeft:'10%',marginTop:'1%', width:'83%', height:'10%', display:'inline-block', backgroundColor:userFrame.darkMode?'#54595F':'white'}}>
      <Box between={2}>
          <h1 style={{fontWeight:'bold', color:userFrame.darkMode?'white':'black', }}>
              Province *
          </h1>
          <ProvinceDropDownMenus/>
       
      </Box>
      
      </div>
      <div style={{ marginLeft:'10%',marginTop:'1%', width:'80%', height:'10%', display:'inline-block', backgroundColor:userFrame.darkMode?'#54595F':'white'}}>
      <Box between={2}>
        {/*<Input label="Postal Code" type="text" value={postalCode} onChange={onChangePostalCode} />*/}
        <h1 style={{fontWeight:'bold', color:userFrame.darkMode?'white':'black', }}>
            Postal Code
        </h1>
        <input onChange={onChangePostalCode} value={postalCode} placeholder={'e.g. ABD247'}
        style={{width:'100%', height:'50px', borderRadius:'6px', backgroundColor:userFrame.darkMode?'#2A2C2E':'white', borderColor:userFrame.darkMode?'#2A2C2E':'black', 
        color:userFrame.darkMode?'white':'black', padding:'17px', outlineColor:userFrame.darkMode?'#2A2C2E':'black', outlineWidth:'0px',borderWidth:'1px',borderStyle:'solid',}}/>
      </Box>
      
      </div>
      <div style={{width:'80%', marginLeft:'10%', marginTop:'5%', marginBottom:'10%'}}>
              <button onClick={()=>{doSearch()}} 
                style={{width:'100%', height:'60px', backgroundColor:userFrame.darkMode?'#D8D8D8':'#2B8000', 
                color:userFrame.darkMode?'#54595F':'white',fontWeight:'bold', borderColor:userFrame.darkMode?'#D8D8D8':'#2B8000', 
                border:'2px solid', borderRadius:'5px'}}>
                  Search Address{/*<ButtonIcon icon = {'Search'}/>*/}
              </button>
            </div>
    </div>
    </>

    :

    <>

      {/*userFrame.houseOutsideWalklist?:<></>*/}
      <div style={{width:'80%', marginLeft:'10%', height:'60px', marginTop:'20px',display:'flex', justifyContent:'center', alignItems:'center' }}>
        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
        <img src={userFrame.darkMode?warningWhite:warning} style={{width:'23px'}}/>
        </div>
        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
            <h1 style={{color:userFrame.darkMode?'white':'#FFA500', fontSize:'17px', fontWeight:'bold', marginLeft:'20px'}}>
            Warning: Searching for a premise outside of the ABD247 walklist
            </h1>
        </div>
      </div>
      

    <div style={{width:'100%',  marginTop: userFrame.houseOutsideWalklist?'20px':'20px',}}>
      <SearchAndFilter/>
    </div>
    
    <div style={{width:'80%', marginLeft:'10%', marginTop:'5%'}}>
      <button onClick={()=>{doSearch()}} 
      style={{width:'100%', height:'60px', backgroundColor:userFrame.darkMode?'#D8D8D8':'#2B8000', 
      color:userFrame.darkMode?'#54595F':'white',fontWeight:'bold', borderColor:userFrame.darkMode?'#D8D8D8':'#2B8000', 
      border:'2px solid', borderRadius:'5px'}}>
          Search Address
      </button>
    </div>
            
    {userFrame.searchStringPreSearch != "" ?
      <div style={{width:'80%', marginLeft:'10%', marginTop:'5%'}}>
        <button onClick={()=>{clearSearch()}} 
        style={{width:'100%', height:'60px', backgroundColor:'white', 
        color:'#C12335',fontWeight:'bold', borderColor:'#C12335', 
        border:'2px solid', borderRadius:'5px'}}>
            Clear Search
        </button>
      </div>
    :<></>}
    </>
    
    }

   
  </div>

  );
}

export default Search;




