import React, { useState } from "react";

function Button({color, index, text, onButtonClicked}) {
  const [buttonColour, setButtonColour] = useState('1px');
  const [padH, setPadH] = useState('45px');
  const [padV, setPadV] = useState('7px');


  return (
    
      <button
        style={{ 
        paddingTop: padV, paddingBottom: padV, paddingLeft: padH, paddingRight: padH,
        color: color == '#D8D8D8' ? '#54595F':'white', fontWeight: 'bold', borderColor:color, cursor:'pointer',
        borderRadius:'5px', borderWidth: buttonColour, width:'90%', marginLeft:'5%',
        borderStyle: 'solid', backgroundColor: color }}
        onClick={() => onButtonClicked(true, index)}
      >{text}</button>
  );
}

export default Button;