import React, { useEffect, useState } from "react";

import greenHouse from '../../../../icons/house/green/house.png';
import purpleHouse from '../../../../icons/house/purple/house.png';
import whiteHouse from '../../../../icons/house/white/house.png';
import greyHouse from '../../../../icons/house/grey/house.png';

import greenNOH from '../../../../icons/house/green/not-home.png';
import purpleNOH from '../../../../icons/house/purple/not-home.png';
import whiteNOH from '../../../../icons/house/white/not-home.png';
import greyNOH from '../../../../icons/house/grey/not-home.png';

import greenSN from '../../../../icons/house/green/soft-no.png';
import purpleSN from '../../../../icons/house/purple/soft-no.png';
import whiteSN from '../../../../icons/house/white/soft-no.png';
import greySN from '../../../../icons/house/grey/soft-no.png';

import greenHN from '../../../../icons/house/green/hard-no.png';
import purpleHN from '../../../../icons/house/purple/hard-no.png';
import whiteHN from '../../../../icons/house/white/hard-no.png';
import greyHN from '../../../../icons/house/grey/hard-no.png';

import greenWP from '../../../../icons/house/green/wrong-party.png';
import purpleWP from '../../../../icons/house/purple/wrong-party.png';
import whiteWP from '../../../../icons/house/white/wrong-party.png';
import greyWP from '../../../../icons/house/grey/wrong-party.png';

import greenNI from '../../../../icons/house/green/not-interested.png';
import purpleNI from '../../../../icons/house/purple/not-interested.png';
import whiteNI from '../../../../icons/house/white/not-interested.png';
import greyNI from '../../../../icons/house/grey/not-interested.png';

import greenOK from '../../../../icons/house/green/other-knock.png';
import purpleOK from '../../../../icons/house/purple/other-knock.png';
import whiteOK from '../../../../icons/house/white/other-knock.png';
import greyOK from '../../../../icons/house/grey/other-knock.png';

import greenSK from '../../../../icons/house/green/successful-knock.png';
import purpleSK from '../../../../icons/house/purple/successful-knock.png';
import whiteSK from '../../../../icons/house/white/successful-knock.png';
import greySK from '../../../../icons/house/grey/successful-knock.png';

import greenFU from '../../../../icons/house/green/follow-up.png';
import purpleFU from '../../../../icons/house/purple/follow-up.png';
import whiteFU from '../../../../icons/house/white/follow-up.png';
import greyFU from '../../../../icons/house/grey/follow-up.png';

import blue from '../../../../icons/house/blue/pending-order.png';
import red from '../../../../icons/house/red/do-not-knock.png';
import whiteDNK from '../../../../icons/house/white/not-interested-old.png';

import greenHot from '../../../../icons/house/green/hot.png';
import purpleHot from '../../../../icons/house/purple/hot.png';
import whiteHot from '../../../../icons/house/white/hot.png';

import { Offices } from '@tds/core-decorative-icon'

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../../redux/actions/userFrameActions'

function Marker ({ id, icon, }) {

  const userFrame = useSelector(state=>state.user_frame_reducer[0])
  const houses = useSelector(state=>state.house_reducer)
  const dispatch = useDispatch()

  // click effect, change icons size/premise selection
  function clickPremise(){
  
    
    if(userFrame.houseId != id){
      
      // collapse all houses in list view
      for(var i = 0; i<9; i++){
        const h = houses[i]
        h.expand = false
        dispatch(updateHouse(h))
      }
  
      // only expand this premise in list view
      const h = houses[id-1]
      h.houseId = id
      h.icon = icon
      h.expand = true
      dispatch(updateHouse(h))
      
      // change size of icons, draw drawer
      const u = userFrame
      u.houseId = id
      // always open drawer fully on premise click
      u.drawer = 1;
      
      // Deprecated - give drawer memory of whether user had it fully open/on preview
      /*if(u.drawer == 2){
        u.drawer = 2;
      }else{
        u.drawer = 1;
      }*/

      // re center map
      u.defaultMapLat = houses[id-1].latitude;
      u.defaultMapLng = houses[id-1].longitude;
      dispatch(updateUserFrame(u))

    }else{
      // collapse this premise in list view
      const h = houses[id-1]
      h.expand = false
      dispatch(updateHouse(h))

      // change size of icons, draw drawer
      const u = userFrame
      u.houseId = 0
      u.drawer = 0;
      dispatch(updateUserFrame(u))
    }
    
  }

  function renderIcon(selection) {
    switch(selection) {
      case 'NOH':
        if(userFrame.darkMode){
          return <img src={whiteNOH} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)',  overflow:'visible'}}/>;
        }else{
          if(houses[id-1].existingCustomer){
            return <img src={greenNOH} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)',  overflow:'visible'}}/>;
          }else{
            return <img src={purpleNOH} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)',  overflow:'visible'}}/>;
          }
        }
      case 'SN':
        if(userFrame.darkMode){
          return <img src={whiteSN} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)',  overflow:'visible'}}/>;
        }else{
          if(houses[id-1].existingCustomer){
            return <img src={greenSN} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)', overflow:'visible'}}/>;
          }else{
            return <img src={purpleSN} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)', overflow:'visible'}}/>;
          }
        }
      case 'HN':
        if(userFrame.darkMode){
          return <img src={whiteHN} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)',  overflow:'visible'}}/>;
        }else{
          if(houses[id-1].existingCustomer){
            return <img src={greenHN} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)', overflow:'visible'}}/>;
          }else{
            return <img src={purpleHN} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)', overflow:'visible'}}/>;
          } 
        }     
      case 'WP':
        if(userFrame.darkMode){
          return <img src={whiteWP} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)',  overflow:'visible'}}/>;
        }else{
          if(houses[id-1].existingCustomer){
            return <img src={greenWP} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)', overflow:'visible'}}/>;
          }else{
            return <img src={purpleWP} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)', overflow:'visible'}}/>;
          }
        }
      case 'NI':
        if(userFrame.darkMode){
          return <img src={whiteNI} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)',  overflow:'visible'}}/>;
        }else{
          if(houses[id-1].existingCustomer){
            return <img src={greenNI} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)', overflow:'visible'}}/>;
          }else{
            return <img src={purpleNI} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)', overflow:'visible'}}/>;
          } 
        }     
      case 'OK':
        if(userFrame.darkMode){
          return <img src={whiteOK} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)',  overflow:'visible'}}/>;
        }else{
          if(houses[id-1].existingCustomer){
            return <img src={greenOK} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)', overflow:'visible'}}/>;
          }else{
            return <img src={purpleOK} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)', overflow:'visible'}}/>;
          }
        }      
      case 'house':
        if(userFrame.darkMode){
          return <img src={whiteHouse} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)',  overflow:'visible'}}/>;
        }else{
          if(houses[id-1].existingCustomer){
            return <img src={greenHouse} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)', overflow:'visible'}}/>;
          }else{
            return <img src={purpleHouse} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)', overflow:'visible'}}/>;
          }
        }
      case 'SK':
        if(userFrame.darkMode){
          return <img src={whiteSK} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)',  overflow:'visible'}}/>;
        }else{
          if(houses[id-1].existingCustomer){
            return <img src={greenSK} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)', overflow:'visible'}}/>;
          }else{
            return <img src={purpleSK} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)', overflow:'visible'}}/>;
          }
        }
      case 'FU':
        if(userFrame.darkMode){
          return <img src={whiteFU} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)',  overflow:'visible'}}/>;
        }else{
          if(houses[id-1].existingCustomer){
            return <img src={greenFU} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)', overflow:'visible'}}/>;
          }else{
            return <img src={purpleFU} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)', overflow:'visible'}}/>;
          }
        }  
      case 'hot':
        if(userFrame.darkMode){
          return <img src={whiteHot} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)',  overflow:'visible'}}/>;
        }else{
          if(houses[id-1].existingCustomer){
            return <img src={greenHot} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)', overflow:'visible'}}/>;
          }else{
            return <img src={purpleHot} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)', overflow:'visible'}}/>;
          }
        }  
      case 'pending': 
        return <img src={blue} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)', overflow:'visible'}}/>;
      case 'DNK':
        if(userFrame.darkMode){
          return <img src={whiteDNK} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)',  overflow:'visible'}}/>;
        }else{
          return <img src={red} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)', overflow:'visible'}}/>;
        }
      case 'multi':
        return (
        <div style={{transform: 'translateX(-50%) translateY(-50%)', overflow:'visible'}}>
          <Offices variant={userFrame.darkMode?'inverted':'default'} size={userFrame.houseId == id ? 48 : 32}/>
        </div>)
      default:
        if(userFrame.darkMode){
          return <img src={whiteHouse} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)',  overflow:'visible'}}/>;
        }else{
          if(houses[id-1].existingCustomer){
            return <img src={greenHouse} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)', overflow:'visible'}}/>;
          }else{
            return <img src={purpleHouse} width={userFrame.houseId == id ? '64px' : '37px'} style={{transform: 'translateX(-50%) translateY(-50%)', overflow:'visible'}}/>;
          }
        }   
      }
  }

  return (
      <div style={{cursor: 'pointer', overflow:'visible'}}>
        <a onClick={() => clickPremise()}>
          {renderIcon(houses[id-1].icon)}
        </a> 
      </div>
  );
  
};

  export default Marker;