import React, { useEffect, useState } from "react";
import { UserFrame } from "../../../redux/model/UserFrame";

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../redux/actions/userFrameActions'

function TerritoryMarker ({id}) {

    const userFrame = useSelector(state=>state.user_frame_reducer[0])
    const houses = useSelector(state=>state.house_reducer)
    const dispatch = useDispatch()

// click effect, maintains color change
function clickMarker(){

    const u = userFrame
    u.territory = id
    dispatch(updateUserFrame(u))

}

return (
      <div style={{cursor: 'pointer', width:'100px', height:'100px' }}>
        <button
            style={{ height:'100%', width:'100%', cursor:'pointer',
            backgroundColor:userFrame.darkMode?(userFrame.territory == id ?'rgba(230, 230, 230, 0.6)':'rgba(150, 150, 150, 0.6)'):(userFrame.territory == id ? 'rgba(43, 128, 0, 0.6)':'rgba(75, 40, 109, 0.6)'), 
            borderStyle:'none', marginLeft:'-50%', marginTop:'-50%', borderRadius:'300px' }}
            onClick={() => clickMarker()}
        >
        </button> 
      </div>
);
  
};

  export default TerritoryMarker;