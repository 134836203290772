import React, { useState, useEffect } from "react";

import Box from '@tds/core-box'

import plant from '../../../icons/images/salal_branch.png';
import telus from '../../../icons/telus/purple.png';
import telusWhite from '../../../icons/telus/icon.png';
import MapContainer from "./MapContainer";
import CustomCheckBox from "./CustomCheckBox";
import ErrorHandle from "./ErrorHandle";
import LogoutHandle from "./LogoutHandle";
import WalklistDropDownMenus from "./WalklistDropDownMenus";
import locationWhite from "../../../icons/current-location/location-white.png"
import location from "../../../icons/current-location/location1.png"

import Hamburger from 'hamburger-react'

import { useSelector, useDispatch } from 'react-redux';
import {createHouse} from '../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../redux/actions/userFrameActions'
import {House} from '../../../redux/model/House.js';


function WalkListSelection({}) {

  const userFrame = useSelector(state=>state.user_frame_reducer[0])
  const houses = useSelector(state=>state.house_reducer)
  const dispatch = useDispatch()

  const [walklist,setWalklist] = useState('AB7')
  const [territory,setTerritory] = useState(3)
  const [locationButton,setLocationButton] = useState(false)
  const [french, setFrench] = useState(false)
  
  useEffect(()=>{
    setTimeout(()=>{
      //toggleErrorHandler()
    },3000)
  },[])

  function toggleErrorHandler() {
    const frame = userFrame;
    frame.errorWalklistSelection = true
    dispatch(updateUserFrame(frame))
  }

  /*
  initialize variables that bring user to main app (map view, list view, etc)
  */
  function setShowApp(){

    // initialize the userFrame variables upon app entry
    const frame = userFrame;
    frame.drawer = false
    frame.tabNav = 1
    frame.tabView = 2
    frame.loggingKnock = false
    frame.followingUp = false
    frame.screenDepth = 3
    frame.settings = false
    frame.houseId = 0
   
    // hardcoded - create 4 houses/premises in redux store (when api calls to walklists are avail, get houses and save locally to redux store)
    if(userFrame.housesInitialized == false){
      
      for(var i=0; i<11; i++){
        
        const house = new House(i);
        dispatch(createHouse(house));
      }
    }
    frame.housesInitialized = true
    dispatch(updateUserFrame(frame))
  }

  function handleLogoutClick(){

    const frame = userFrame;
    frame.logoutHandle = true
    dispatch(updateUserFrame(frame))
    
  }

  function switchTerritories(area){

    switch (area) {
      case 1:
        return 'Surrey, BC'
      case 2:
        return 'Vancouver, BC'
      case 3:
        return 'Richmond, BC'
      case 4:
        return 'Burnaby, BC'
      default:
        return 'Vancouver, BC'

    }
  }

  function locationClicked(){
   
    // if button is not clicked, click it, set the location  
    if(!userFrame.locationButtonClicked){
      // check if we already have the location (fast)
      if(userFrame.locationLat != null){
        const u = userFrame
        u.zoom = 17;
        u.locationButtonClicked = true
        u.centerSelectionLat = u.locationLat
        u.centerSelectionLng = u.locationLng
        dispatch(updateUserFrame(u))
      }else{
        // get the location
        navigator?.geolocation.getCurrentPosition(({coords: {latitude: lat, longitude: lng}}) => {
          const u = userFrame
          u.zoom = 17;
          u.centerSelectionLat = lat
          u.centerSelectionLng = lng
          u.locationLat = lat
          u.locationLng = lng
          u.locationButtonClicked = true
          dispatch(updateUserFrame(u))
        })
      }
      
    }else{
        // reset variables back to route
        const u = userFrame
        u.locationButtonClicked = false
        u.centerSelectionLat = 49.23;
        u.centerSelectionLng = -123.16;
        u.zoom = 10;
        dispatch(updateUserFrame(u))
        
    }
    
    }

  function setLanguage(value){
      setFrench(value)
  }

  function dropDown(){
    const frame = userFrame;
    frame.settings = !frame.settings
    dispatch(updateUserFrame(frame))
  }

  function showLocationButton() {
    return(
      <button 
      onClick={() => {locationClicked()}}
      style={{position: 'absolute', top:'9px', right:'9px', height:'42px', width:'42px', borderStyle: 'none', 
      backgroundColor:userFrame.locationButtonClicked?(userFrame.darkMode?'#71757B':'#4B286D'):(userFrame.darkMode?'#D8D8D8':'white'), borderRadius:'3px', boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)', 
      cursor:'pointer', zIndex:6, display:'flex', justifyContent:'center', alignItems:'center',}}>
        <img src={userFrame.locationButtonClicked?locationWhite:location} width="25px" />
      </button>
    )
  }

  function showErrorHandler(){
      if(userFrame.errorWalklistSelection){
        return(
          <ErrorHandle/>  
        )
      }
  }

  function showLogoutHandler(){
    if(userFrame.logoutHandle){
      return(
        <LogoutHandle/>  
      )
    }
}

function dropDownSettings() {
  if(userFrame.settings){
    return(
      /* Dropdown/Settings */
    
      <div style={{position:'absolute', top:'15%', right: '0', zIndex:10,
      width:'100%', height:'100%', backgroundColor:userFrame.darkMode?'#54595F':'white', overflow:'scroll' }}>
           
              {/* Title */}
              <div style={{display:'block', marginLeft:'10%', marginTop:'5%' }}>
                  <h1 style={{color:userFrame.darkMode?'white':'#4B286D', fontSize:'24px', marginLeft:'2%'}}>Settings</h1>
                  <Box tag="fieldset" between={2}>
                      <CustomCheckBox title={"Dark Mode"}/>

                      <CustomCheckBox title={"Street View"}/>
                  </Box>    
              </div>

              {/* Title */}
              <div style={{display:'block', marginLeft:'10%', marginTop:'3%', width:'80%', height:'40%' }}>
              <h1 style={{color:userFrame.darkMode?'white':'#4B286D', fontSize:'24px', marginLeft:'2%'}}>Submit Feedback</h1>
                  <textarea style={{width:'100%', height:'150px', resize:'none', padding:'15px', borderRadius:'3px', borderColor:userFrame.darkMode?'white':'#4B286D', color:userFrame.darkMode?'white':'#4B286D', outlineColor:userFrame.darkMode?'white':'#4B286D', backgroundColor:userFrame.darkMode?'#54595F':'white',}}/>
                  <button onClick={()=>{}} 
                      style={{width:'100%', height:'60px',marginTop:'25px', backgroundColor:userFrame.darkMode?'#D8D8D8':'#2B8000', 
                      color:userFrame.darkMode?'black':'white',fontWeight:'bold', borderColor:userFrame.darkMode?'#D8D8D8':'#2B8000', 
                      borderWidth:'2px', borderStyle:'solid', borderRadius:'5px'}}>
                  Submit
                  </button>
              </div>

              <div style={{display:'block', marginLeft:'10%', marginTop:'5%', width:'80%', height:'10%' }}>
                  <button onClick={()=>{handleLogoutClick()}} 
                    style={{width:'100%', height:'60px', backgroundColor:userFrame.darkMode?'#54595F':'white', 
                    color:userFrame.darkMode?'white':'#C12335',fontWeight:'bold', borderColor:userFrame.darkMode?'white':'#C12335', 
                    borderWidth:'2px', borderStyle:'solid', borderRadius:'5px'}}>
                      Logout
                  </button>
              </div>

              <div style={{display:'block',width:'100%', height:'25%', backgroundColor:userFrame.darkMode?'#54595F':'white' }}>
              </div>           
      </div>
    )
  }
}

  return (
    
    <div style={{position: 'absolute', top:0, left:0, height:'100%', width:'100%', overflowY:'hidden'}}>

    {/* Header */}
    <div style={{position: 'absolute', top:0, left: 0, height:'15%', width:'100%', backgroundColor:userFrame.darkMode?'#2A2C2E':'#4B286D', overflowY:'hidden', zIndex:1}}>
        
        <Box horizontal={7} vertical={2}>
        <img src={userFrame.darkMode?telusWhite:telus} width="90px" style={{marginTop:userFrame.darkMode?'30px':0, marginLeft:userFrame.darkMode?'10px':0}} />
        </Box>

        <div style={{position: 'absolute', top:0, right: 0, height:'100%', width:'40%', overflowY:'hidden'}}>

            <div style={{position: 'absolute', top:'40%', right: '45%',  overflowY:'hidden'}}>
                <a onClick={()=>{setLanguage(false)}}><h1 style={{cursor:'pointer',display:'inline-block', color:'white', fontWeight:french?'none':'bold', textDecoration:french?'none':'underline'}}>EN</h1></a>
                <h1 style={{display:'inline-block',color:'white',}}>&nbsp;&nbsp;/&nbsp;&nbsp;</h1>
                <a onClick={()=>{setLanguage(true)}}><h1 style={{cursor:'pointer',display:'inline-block',color:'white', fontWeight:!french?'none':'bold', textDecoration:!french?'none':'underline'}}>FR</h1></a>
            </div>
            <div style={{position: 'absolute', top:'30%', right: '10%',  overflow:'hidden', cursor:'pointer'}}>
            <Hamburger color={"white"} toggle={()=>dropDown()} toggled={userFrame.settings}/>
            </div>

        </div>
        
    </div>

    {/* Overlay - Error Handler */}
    {showLogoutHandler()}

    {/* Overlay - Error Handler */}
    {showErrorHandler()}

    {/* Overlay - Settings */}
    {dropDownSettings()}

    {/* Body */}
    <div style={{position: 'absolute', top:'15%', left:0, height:'85%', width:'100%', overflowY:'scroll', backgroundColor:userFrame.darkMode?'#54595F':'white'}}>
        
        {/* Row */}
        <div tyle={{position: 'absolute', top:0, left:0, height:'50%', width:'100%'}}>
           
           {/* Map */}
            <div style={{position: 'absolute', top:0, left:0, height:'40%', width:'100%', backgroundColor:userFrame.darkMode?'#54595F':'white'}}>
                
                {showLocationButton()}
                <MapContainer/>
            </div>

            {/* Selection Form */}
            <div style={{position: 'absolute', top:'40%', left:0, height:'60%', width:'100%', backgroundColor:userFrame.darkMode?'#54595F':'white', overflowY:'scroll', flex: 1, justifyContent: 'center'}}>
                {/* Title */}
                <div style={{position:'absolute', top:"10%", left: '10%', }}>
                    <h1 style={{color:userFrame.darkMode?'white':'#4B286D', fontSize:'28px', }}>
                      Walk List Selection
                    </h1>
                </div>

                <div style={{marginTop:'20%',marginBottom:'15%',zIndex:2, backgrouundColor:'blue'}}>
                    <Box between={4} horizontal={7} >
                    <div style={{marginTop:"15px"}}>
                      <h1 style={{display:'inline-block', fontWeight:'bold', fontSize:'18px',color:userFrame.darkMode?'white':'black'}}>
                      Territory: 
                      </h1>
                      <p style={{display:'inline-block', color:userFrame.darkMode?'white':'black'}}>&nbsp;{switchTerritories(userFrame.territory)}</p>
                    </div>
                    <WalklistDropDownMenus/>

                    <button onClick={()=>setShowApp()} 
                    style={{width:'100%', height:'60px',marginTop:'25px', backgroundColor:userFrame.darkMode?'#D8D8D8':'#2B8000', 
                    color:userFrame.darkMode?'black':'white',fontWeight:'bold', borderColor:userFrame.darkMode?'#D8D8D8':'#2B8000', 
                    borderWidth:'2px', borderStyle:'solid', borderRadius:'5px'}}>
                      Go to walklist
                    </button>
                    </Box>
                  
                </div>
                
            </div>
        </div> 
    </div>
  </div>

  );
}

export default WalkListSelection;

