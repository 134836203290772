
import React, { useState, useEffect } from "react";

import MapViewPhone from "../../MapView/Phone/MapViewPhone";
import ListViewPhone from "../../ListView/ListViewPhone"
import Search from "../../Search/Phone/Search";
import Header from './SubComponents/Header';
import ButtonJoinedMenu from "./SubComponents/ButtonJoinedMenu";
import Settings from "../../Settings/Phone/Settings";
import Progress from "../../ProgressPage/Phone/Progress";
import FollowUp from "../../ProgressPage/Phone/FollowUp";
import ErrorHandle from "./SubComponents/ErrorHandle";
import LogoutHandle from "./SubComponents/LogoutHandle";
import ConfirmBox from "./SubComponents/ConfirmBox";
import ProductsCommitmentsOffers from "../../PCOs/Phone/ProductsCommitmentsOffers";

import { useSelector,useDispatch } from "react-redux";
import {updateHouse} from '../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../redux/actions/userFrameActions'
import ProductDetails from "./SubComponents/ProductDetails";

function ListMapSearch({ }) {
  
  const userFrame = useSelector(state=>state.user_frame_reducer[0])
  const houses = useSelector(state=>state.house_reducer)
  const dispatch = useDispatch()

  useEffect(()=>{
    setTimeout(()=>{
      //toggleErrorHandler()
    },3000)
  },[])

  function toggleErrorHandler() {
    const frame = userFrame;
    frame.errorMainApp = true
    dispatch(updateUserFrame(frame))
  }

  function showErrorHandler(){
    if(userFrame.errorMainApp){
      return(
        <ErrorHandle/>  
      )
    }
  }

  function showLogoutHandler(){
    if(userFrame.logoutHandle){
      return(
        <LogoutHandle/>  
      )
    }
}

  function showConfirmationBox(){
    if(userFrame.confirmBox){
       return( <ConfirmBox/> )
    }
  }

  function switchMenuTab() {
    switch(userFrame.menuTab){
      
      case 1:
        return <Progress/>
       
      case 2:
        return <Settings/>
      
      case 3: 
        return <FollowUp/>
      
      default:
        return <Progress/>
  
  }
}

  function showMenu() {
    if(userFrame.menu){
      return(
        <div style={{position:'absolute', top:'10%', left:0, height:'90%', width:'100%', backgroundColor:userFrame.darkMode?'#54595F':'white', zIndex:8,overflow:'scroll'}}>
            <div style={{width:'100%', height:'15%', display:'block', }}>
              <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'100%', width:'100%'}}>
                <ButtonJoinedMenu />
              </div>
            </div>
            <div style={{width:'100%', height:'85%', display:'block' }}>
                {switchMenuTab()}
            </div>
        </div>
      )
    }
    
  }

  function showProductDetails(){
    if(userFrame.showProductDetails){
      return( <ProductDetails/> )
    }
  }
 
  function switchMainTab(){

    switch(userFrame.tabView){
        case 1:
           /* LIST VIEW */
          return( <ListViewPhone /> )
        
        case 2:
          /* MAP VIEW */
          return  ( <MapViewPhone/> )
            
        case 3:
           /* SEARCH */
          return ( <Search /> )
          
        default:
          /* MAP VIEW */
          return ( <MapViewPhone/> )
      }
  }

  function showProductsCommitmentsOffers(){

    return(
      <ProductsCommitmentsOffers/>
    )

}

  function showMapView(){


    return(
      
      <>
      {/* Overlay Logout Handler*/}
      {showLogoutHandler()}
      
      {/* Overlay Error Handler*/}
      {showErrorHandler()}

      {/* CONFIRMATION BOX - Overlays Views */}
      {showConfirmationBox()}

      {/* Overlay Confirmation Box */}
      {showProductDetails()}

      {/* MENU - Overlays Views */}
      {showMenu()}
        
      <div style={{height:'100%', width:'100%', position:'absolute', top:'0', left:'0', overflowY:'hidden'}}>
        
      { userFrame.showPCO ?
        /* Show products, commitments, and offers */
        showProductsCommitmentsOffers()
        
        :
        <>
        {/* HEADER */}
        <div style={{display: 'inline-block',width:'100%', height:'10%', top: '0', left:'0', position: 'absolute', backgroundColor:'white', boxShadow:userFrame.darkMode?'none':'0 4px 2px -2px #9E9E9E',zIndex:10 }}>
          <Header />
        </div>

        {/* BODY/PAGE (LIST/MAP/STREET VIEWS) */}
        {switchMainTab()}
        </>
        }
      </div>

      </>
      
    )
  }
  
  return (

    <div >
      {showMapView()}
    </div>
    
  );
}

export default ListMapSearch;
