import React, { useEffect, useState, } from "react";
import { useSelector, useDispatch } from "react-redux";

import Hamburger from 'hamburger-react'
import { PikTV, Internet, Tv, HomeSecurity,OnlineSecurity, SecurityHouse,CallTalking,Phone, Heartbeat, Delivery, Deals, ArrowUp, Clipboard, Calendar, WifiBoost, Mobility, Call, LockOpened, Gift, Time, Shop, Devices, PiggyBank } from '@tds/core-decorative-icon'
import arrowDownP from "../../../icons/arrow/down/purple.png"
import arrowDownW from "../../../icons/arrow/down/white.png"
import arrowUpW from "../../../icons/arrow/up/white.png"
import arrowUp from "../../../icons/arrow/up/purple.png"
import arrowUpP from "../../../icons/arrow/up/purple.png"
import alpaca from "../../../icons/images/alpaca.png"
import plus from "../../../icons/plus/white/plus.png"
import plusP from "../../../icons/plus/purple/plus.png"
import minus from '../../../icons/minus/white.png'
import minusP from '../../../icons/minus/purple.png'
import DimpleDivider from '@tds/core-dimple-divider'
import {updateHouse} from '../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../redux/actions/userFrameActions'
import HairlineDivider from "@tds/core-hairline-divider";
import { Subtract } from "@tds/core-interactive-icon";

function ProductsCommitmentsOffers() {

  const userFrame = useSelector(state=>state.user_frame_reducer[0])
  const houses = useSelector(state=>state.house_reducer)
  const dispatch = useDispatch()

  let [width, setWidth] = useState(window.innerWidth 
    || document.documentElement.clientWidth 
    || document.body.clientWidth);

    /*
        Component did mount
    */
    useEffect(() => {
        
         function resizeListener(){
            const getWidth = window.innerWidth 
            || document.documentElement.clientWidth 
            || document.body.clientWidth;
            
            setWidth(getWidth)
        };

        // set resize listener
        window.addEventListener('resize', resizeListener);
    
        // clean up function
        return () => {
          // remove resize listener
          window.removeEventListener('resize', resizeListener);
        }
      }, [])

    function showDetails(value){
        const u = userFrame
        u.showProductDetails = value;
        dispatch(updateUserFrame(u))
    }

    function exitHasBeenClicked(){
        
        const u = userFrame
        u.showPCO = false;
        dispatch(updateUserFrame(u))
        
    }

    function containsProduct(product) {
        const offers = houses[userFrame.houseId-1].offer;
        var contains = false
        offers.length > 0 && offers.forEach((o, i)=>{
            //check if there is an offer selected for this product
            if(o.product == product.type){
                contains = true
            }
        })
        return contains
    }

    function removeOffer(id){

        const h = houses[userFrame.houseId-1]
        var offers = houses[userFrame.houseId-1].offer
        var index
        offers.forEach((o,i)=>{
            if(o.id == id){
                index = i
            }
        })
        if(offers.length>1){
            offers.splice(index, 1);
        }else{
            offers=[]
        }
        h.offer = offers
        dispatch(updateHouse(h))

        const u = userFrame
        var products = u.products
        products.forEach((p)=>{
            if(p.offer == id){
                p.offer = 0
                p.commitment = ''
                p.product = false
            }
        })
        u.products = products
        dispatch(updateUserFrame(u))

    }

    function clearCartOfThisProduct(product){

        // search the checkout if the offer/product is already selected
        var foundOffer = -1
        var offers = houses[userFrame.houseId-1].offer
        // go through each offer in this houses cart
        offers.length > 0 && offers.forEach((o, i)=>{
            //check if there is an offer for this product
            if(o.product == product.type){
                foundOffer = i
            }
        })
        // if an offer exists for the product
        if(foundOffer != -1){
            if(offers.length>1){
                offers.splice(foundOffer, 1); // remove it or 
            }else{
                offers = []; // empty the array if 1 item left
            }
        }

        // update redux
        var h = houses[userFrame.houseId-1]
        h.offer = offers
        dispatch(updateHouse(h))
    }

    function selectRowProduct(type, justExpandCollapse) {
        const u = userFrame
        u.products.forEach((product)=>{
            if(product.type == type){
                if(!justExpandCollapse && product.product){
                    product.commitment = ''
                    product.offer = 0
            
                    clearCartOfThisProduct(product)
                }
                product.product = !product.product
            }
        })
        dispatch(updateUserFrame(u))
    }

    function selectRowCommitment(p, term) {
        const u = userFrame
        u.products.forEach((product)=>{
            if(product.type == p.type){
                if(product.commitment == term){
                    product.commitment = ''
                    product.offer = 0

                    clearCartOfThisProduct(product)
                }else{
                    product.commitment = term
                }
            }
        })
        dispatch(updateUserFrame(u))
    }

    function selectRowOffer(offer, p) {
        
        /*** FOR THE CART ***/
        // save offer, commitment, and product to specific-house checkout
        const h = houses[userFrame.houseId-1]
        var offers = h.offer
        var foundOffer = -1
        var sameOffer = false

        // search the checkout if the offer/product is already selected
        offers.length > 0 && offers.forEach((o, i)=>{
            if(o.id == offer){
                sameOffer = true
            }

            if(o.product == p.type){
                foundOffer = i
            }
        })

        // if no products in checkout match the selected offer
        if(foundOffer == -1){
            // add it to the checkout
            offers.push({
                id: offer, 
                price: p, 
                commitment: p.commitment, 
                product: p.type, })
                
        // else that offer already exists
        }else{
            
            if(offers.length>1){
                // offer is being unselected
                if(sameOffer){
                    // remove it
                    offers.splice(foundOffer, 1);
                
                // offer is replacing another offer from this product
                }else{
                    // remove it
                    offers.splice(foundOffer, 1);
                    // replace it
                    offers.push({
                        id: offer, 
                        price: p, 
                        commitment: p.commitment, 
                        product: p.type, })
                }
                
            }else{
                if(sameOffer){
                    offers = []
                }else{
                    offers = [{
                        id: offer, 
                        price: p, 
                        commitment: p.commitment, 
                        product: p.type, },]
                }
            }
        }
        
        h.offer = offers
        dispatch(updateHouse(h))
        /*** END FOR THE CART ***/  
        
        /*** FOR THE STATE MGNT ***/
        // save offer for state mgnt (viewing selected offers)
        const u = userFrame
        u.products.forEach((product)=>{
            if(product.type == p.type){
                if(product.offer == offer){
                    product.offer = 0
                }else{
                    product.offer = offer
                }
            }
        })
        dispatch(updateUserFrame(u))
    }

    function renderSelectedOffer(p){

        return(
            <div style={{height:'100%', width:'100%', marginLeft:'5%'}}>
                {p.offers
                .filter((offer)=>{
                    if(offer.id == p.offer){
                        return offer
                    }
                })
                .map((offer, i) => {
                return (
                    <div key={i} 
                        style={{height:'50px',borderRadius:'5px', width:'250px', boxShadow: userFrame.darkMode? 'none': '0px 0px 7px 1px rgba(0,0,0,0.3)', 
                            backgroundColor: userFrame.darkMode?'#2A2C2E':"#4B286D", whiteSpace:'normal',padding:'20px', 
                            display:'flex', flexDirection:'column',justifyContent:'center', alignItems:'center', 
                            position:'relative', top:'50%', left:'50%', WebkitTransform:'translate(-50%, -50%)'}}>
                            
                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%',height:'100%', marginLeft:'-20px'}}>
                                {renderProductIcon(offer.price, true, 32 )}
                                <h1 style={{marginLeft: '10px', fontSize: '30px', fontWeight:'bold', color:userFrame.darkMode? '#D8D8D8':"white"}}>
                                    <span style={{verticalAlign:'super',fontSize:'15px', fontWeight:'normal'}}>
                                        $
                                    </span>
                                    {offer.price}
                                    <span style={{fontSize:'15px', fontWeight:'normal'}}>
                                        /mo.
                                    </span>
                                </h1>
                            </div>
                    
                            <div onClick={()=>selectRowOffer(offer.id, p)} style={{position:'absolute', top:8, right:7, width:'30px', height:'30px', borderRadius:'30px', backgroundColor:userFrame.darkMode?'#71757B':'white', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <img src={userFrame.darkMode?minus:minusP} style={{width:'20px', }}/>
                            </div>
                    </div>
                )})}
                 
            </div>
        )
    }

    function renderSelectedCommitment(p){

        return(
            <div style={{height:'100%', width:'100%', marginLeft:'5%'}}>
                {p.commitments
                .filter((commitment)=>{
                    if(commitment.term == p.commitment){
                        return commitment
                    }
                })
                .map((commitment, i) => {

                return (
                    <div key={i}  
                    style={{height:'50px',borderRadius:'5px', width:'250px', boxShadow: userFrame.darkMode? 'none': '0px 0px 7px 1px rgba(0,0,0,0.3)', 
                        backgroundColor: userFrame.darkMode?'#2A2C2E':"#4B286D", whiteSpace:'normal',padding:'20px', 
                        display:'flex', flexDirection:'column',justifyContent:'center', alignItems:'center', 
                        position:'relative', top:'50%', left:'50%', WebkitTransform:'translate(-50%, -50%)'}}>
                        
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%',height:'100%', marginLeft:'-20px'}}>
                        {renderProductIcon(commitment.term, true, 32 )}
                        <h1 style={{marginLeft: '10px', fontSize: '30px', fontWeight:'bold', color:userFrame.darkMode? '#D8D8D8':"white"}}>
                                    {commitment.term == 'No term'?
                                        'No Term'
                                    :
                                        <>
                                        {commitment.term}
                                        <span style={{fontSize:'15px', fontWeight:'normal'}}>
                                        &nbsp;mo.
                                        </span>
                                        </>}
                                </h1>
                            </div>
                            
                            <div onClick={()=>selectRowCommitment(p, commitment.term)} style={{position:'absolute', top:8, right:7, width:'30px', height:'30px', borderRadius:'30px', backgroundColor:userFrame.darkMode? '#71757B':'white', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <img src={userFrame.darkMode? minus:minusP} style={{width:'20px', }}/>
                            </div>
                    </div>
                )})}
                 
            </div>
        )
    }

    function renderSelectedProduct(product) {
        return(
            <div style={{display:'flex', flexDirection:'column',justifyContent:'center', alignItems:'center', width: '100%', height:'100%', paddingLeft:'5%' }}>
                <div style={{ borderRadius:'5px', backgroundColor:(userFrame.darkMode?'#2A2C2E':"#4B286D"), width:'250px', height:'50px', boxShadow: userFrame.darkMode? 'none': '0px 0px 7px 1px rgba(0,0,0,0.3)', }}>
                    {/* ICON PROD TYPE */}
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%',height:'100%', marginLeft:'-20px'}}>
                        {renderProductIcon(product.type, true, 32 )}
                        <h1 style={{marginLeft: '10px', fontSize: '30px', fontWeight:'bold', color:userFrame.darkMode? '#D8D8D8':"white"}}>
                            {product.type}
                        </h1>
                    </div>
                    <div onClick={()=>selectRowProduct(product.type, false)} style={{position:'relative', top:'-85%', right:'-85%', width:'30px', height:'30px', borderRadius:'30px', backgroundColor:userFrame.darkMode?'#71757B':'white', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <img src={userFrame.darkMode?minus:minusP} style={{width:'20px', }}/>
                    </div>
                </div>
            </div>
        )
    }

    function renderAllOffers(p){

        return(
            <div style={{ width:'100%', display:'block',}}>
                <h1 style={{color:userFrame.darkMode? '#D8D8D8':"#4B286D", marginLeft:'10%', fontWeight:'bold', fontSize:'23px'}}>
                    Offer                        
                </h1>
                <div style={{height:'95%', width:'100%', overflowX:'scroll', overflowY:'hidden', whiteSpace:'nowrap',}}>
                    {p.offers
                    .map((offer, i) => {
                    const selected = p.offer == offer.id ? true : false
                    return (
                        <div key={i} 
                        style={{height:'250px',borderRadius:'5px', width:'300px', margin:'5%', boxShadow: userFrame.darkMode? 'none': (selected?'0px 0px 11px 3px rgba(75, 40, 109, 0.5)':'0px 0px 7px 1px rgba(0,0,0,0.3)'), 
                            backgroundColor: userFrame.darkMode?'#54595F':'white', display:'inline-block', verticalAlign:'top', whiteSpace:'normal'}}>
                            
                            <div style={{display:'flex', flexDirection:'column',justifyContent:'center', alignItems:'center', width:'100%', height:'100%', padding:'10px',}}>
                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%',marginBottom:'20px' }}>
                                    {renderProductIcon('dollar-sign', false, 48)}
                                    <h1 style={{marginLeft: '10px', fontSize: '30px', fontWeight:selected?'bolder':'bold', color:userFrame.darkMode? '#D8D8D8':(selected ? '#4B286D' : "#4B286D")}}>
                                        <span style={{verticalAlign:'super',fontSize:'15px', fontWeight:selected?'bold':'normal'}}>
                                            $
                                        </span>
                                        {offer.price}
                                        <span style={{fontSize:'15px', fontWeight:selected?'bold':'normal'}}>
                                            /mo.
                                        </span>
                                    </h1>
                                </div>

                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%',}}>
                                    <h1 style={{textAlign:'center', color:userFrame.darkMode? '#D8D8D8':(selected ? '#4B286D' : "#4B286D"), fontSize:15, fontWeight:'normal'}}>
                                        {offer.finePrint}
                                    </h1>
                                </div>

                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', marginTop:'5px'}}>
                                    <h1 style={{textAlign:'center', fontWeight:selected?'bolder':'bold',color:userFrame.darkMode? '#D8D8D8':(selected ? '#4B286D' : "#4B286D")}}>
                                        {offer.desc}
                                    </h1>
                                </div>
                               
                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%',marginTop:'5px'}}>
                                    <a onClick={()=>showDetails(true)} style={{textAlign:'center', textDecoration:'underline', color:userFrame.darkMode? '#D8D8D8': "#4B286D", fontSize:15, fontWeight:selected?'bold':'normal'}}>
                                        View Details
                                    </a>
                                </div>
                            </div>
                            
                            {selected?<></>
                            /*<div onClick={()=>selectRowOffer(offer.id, p)} style={{position:'relative', top:'-95%', right:'-83%', width:'35px', height:'35px', borderRadius:'35px', backgroundColor:userFrame.darkMode?'#2A2C2E':'#4B286D', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <img src={minus} style={{width:'25px', }}/>
                            </div>*/
                            :
                            <div onClick={()=>selectRowOffer(offer.id, p)} style={{position:'relative', top:'-95%', right:'-83%', width:'35px', height:'35px', borderRadius:'35px', backgroundColor:userFrame.darkMode?'#2A2C2E':'#4B286D'}}>
                                <img src={plus} style={{width:'35px', }}/>
                            </div>
                            }
                        </div>
                    )})}
                </div>
            </div>
        )
    }

    function renderAllCommitments(p){

        return(
            <div style={{ width:'100%', display:'block',}}>
                <h1 style={{color:userFrame.darkMode? '#D8D8D8':"#4B286D", marginLeft:'10%', fontWeight:'bold', fontSize:'23px'}}>
                    Commitment                        
                </h1>
                <div style={{height:'95%', width:'100%', overflowX:'scroll', overflowY:'hidden', whiteSpace:'nowrap',}}>
                    {p.commitments
                    .map((commitment, i) => {
                    return (
                        <div key={i} 
                        style={{height:'250px',borderRadius:'5px', width:'300px', margin:'5%', boxShadow: userFrame.darkMode? 'none': '0px 0px 7px 1px rgba(0,0,0,0.3)', 
                            backgroundColor: userFrame.darkMode?'#54595F':'white', display:'inline-block', verticalAlign:'top', whiteSpace:'normal'}}>
                            
                            <div style={{display:'flex', flexDirection:'column',justifyContent:'center', alignItems:'center', width:'100%', height:'100%', padding:'10px',}}>
                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%',marginBottom:'20px' }}>
                                    {renderProductIcon(commitment.term, false, 48 )}
                                    <h1 style={{marginLeft: '10px', fontSize: '30px', fontWeight:'bold', color:userFrame.darkMode? '#D8D8D8':"#4B286D"}}>
                                        {commitment.term == 'No term'?
                                            'No Term'
                                        :
                                        <>
                                        {commitment.term}
                                        <span style={{fontSize:'15px', fontWeight:'normal'}}>
                                            &nbsp;mo.
                                        </span>
                                        </>}
                                    </h1>
                                </div>

                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%',}}>
                                    <h1 style={{textAlign:'center', color:userFrame.darkMode? '#D8D8D8':"#4B286D"}}>
                                        {commitment.desc}
                                    </h1>
                                </div>

                                {/*commitment.term != 'No term' && <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%',marginTop:'5px'}}>
                                    <h1 style={{textAlign:'center', textDecoration:'underline', color:userFrame.darkMode? '#D8D8D8': "#4B286D", fontSize:15, fontWeight:'normal'}}>
                                        View Terms
                                    </h1>
                                </div>*/}
                            </div>
                            
                            <div onClick={()=>selectRowCommitment(p, commitment.term)} style={{position:'relative', top:'-95%', right:'-83%', width:'35px', height:'35px', borderRadius:'35px', backgroundColor:userFrame.darkMode?'#2A2C2E':'#4B286D'}}>
                                <img src={plus} style={{fontWeight:'bolder', width:'35px', }}/>
                            </div>
                        </div>
                    )})}
                </div>
            </div>
        )
    }

    function renderAllProducts(product){
        
        return(
            <>
            {/* SMALLER ROW */}
            <div style={{height:'100%', width:'80%', display:'inline-block', verticalAlign:'top'}}>
                <div style={{display:'inline-block', width: '100%', height:'100%',verticalAlign:'top' }}>
                    <div style={{display:'flex', flexDirection:'column',justifyContent:'center', alignItems:'center', width: '100%', height:'100%', paddingLeft:'20px'}}>
                        {/* ICON PROD TYPE */}
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', marginBottom:'15px'}}>
                            {renderProductIcon(product.type, false, 48)}
                            <h1 style={{marginLeft: '10px', fontSize: '40px', fontWeight:'bold', color:userFrame.darkMode? '#D8D8D8':"#4B286D"}}>
                                {product.type}
                            </h1>
                        </div>
                        {/* MEMO */}
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%',}}>
                            <h1 style={{textAlign:'center', color:userFrame.darkMode? '#D8D8D8':"#4B286D"}}>
                            {product.description}
                            </h1>
                        </div>
                    </div>
                </div>
                
                {/* PROD DESCRIPTION 
                <div style={{display:'inline-block', width: '60%', height:'100%',verticalAlign:'top',  }}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%',height:'100%'}}>
                        <h1 style={{color:userFrame.darkMode? '#D8D8D8':"#4B286D", marginLeft:'30px'}}>
                        {product.description}
                        </h1>
                    </div>
                </div>*/}
            </div>

            {/* EXPAND COLLAPSE ARROW */}
            <div onClick={()=>selectRowProduct(product.type, true)} style={{height:'100%', width:'15%', display:'inline-block', verticalAlign:'top'}}>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'100%', width:'100%'}}>
                    <img src={userFrame.darkMode? arrowDownW : arrowDownP} width={'40px'}/>
                </div>
            </div>
            </>)
    }

    function renderProductsCommitmentsOffers(){

        return(
            userFrame.products.map((product, i) => {            
            
            const expandProduct = product.product 
            const showOffers = product.commitment != '' ? true : false
            const highlight = containsProduct(product);
    
            return (
            <div key={i} style={{height:expandProduct?'620px':'250px',
            borderRadius:'5px', width:'90%', margin:'5%', boxShadow: userFrame.darkMode? 'none': highlight?'0px 0px 11px 3px rgba(75, 40, 109, 0.5)':'0px 0px 7px 1px rgba(0,0,0,0.3)', 
            backgroundColor: (userFrame.darkMode?'#71757B':'white'),}}>
                {expandProduct?
                <>
                    {/* TOP ROW */}
                    <div style={{height:'250px', width:'100%', display:'block',}}>
                        <div style={{height:'100%', width:'80%', display:'inline-block', verticalAlign:'top'}}>
                            <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width:'100%', height:'100%', marginLeft:width>550?'-90px':0, }}>
                                {renderSelectedProduct(product)}
                                {renderSelectedCommitment(product)}
                                {renderSelectedOffer(product)}
                            </div>
                            {width<550 && product.offer == 0 &&<img src={alpaca} width={200} style={{position:'relative', bottom:130, left:0}}/>}
                            {width>550 && <img src={alpaca} width={200} style={{position:'relative', bottom:130, left:400}}/>}
                        </div>
                        {/* EXPAND COLLAPSE ARROW */}
                        <div onClick={()=>selectRowProduct(product.type, true)} style={{height:'100%', width:'15%', display:'inline-block', verticalAlign:'top', }}>
                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'100%', width:'100%'}}>
                                <img src={userFrame.darkMode? arrowUpW : arrowUpP} width={'40px'}/>
                            </div>
                        </div>
                    </div>

                    <DimpleDivider/>
                    
                    {/* BOTTOM ROW */}
                    {showOffers ?
                    renderAllOffers(product)
                        :
                    renderAllCommitments(product)}
                </>
                :
                renderAllProducts(product)}
                <div style={{height:'120px', width:'100%'}}/>
            </div>
            )})
        )
    }

    function renderProductIcon(type, selected, size) {
        switch(type){
            case 'TV':
                return <Tv size={size} variant={userFrame.darkMode?"inverted":(selected?"inverted":"default")}/>
            case 'Internet':
                return <Internet size={size} variant={userFrame.darkMode?"inverted":(selected?"inverted":"default")}/>
            case 'SHS':
                return <SecurityHouse size={size} variant={userFrame.darkMode?"inverted":(selected?"inverted":"default")}/>
            case 'Phone':
                return <Call size={size} variant={userFrame.darkMode?"inverted":(selected?"inverted":"default")}/>
            case 'No term':
                return <LockOpened size={size} variant={userFrame.darkMode?"inverted":(selected?"inverted":"default")}/>
            case '12':
                return <Calendar size={size} variant={userFrame.darkMode?"inverted":(selected?"inverted":"default")}/>
            case '24':
                return <Gift size={size} variant={userFrame.darkMode?"inverted":(selected?"inverted":"default")}/>
            case 'dollar-sign':
                return <Deals size={size} variant={userFrame.darkMode?"inverted":(selected?"inverted":"default")}/>
            case 'LivingWell':
                return <Heartbeat size={size} variant={userFrame.darkMode?"inverted":(selected?"inverted":"default")}/>
            default:
                return <Deals size={size} variant={userFrame.darkMode?"inverted":(selected?"inverted":"default")}/>

        }
    }

    function renderMessage() {
        if(userFrame.viewCartDetails){
            return ("Checkout and pay")
        }else{
            return ("Select an offer")
        }
    }

    function renderIcon() {
        if(userFrame.viewCartDetails){
            return <PiggyBank size={24} variant={userFrame.darkMode?"inverted":"inverted"}/>
        }else{
            return <Deals size={24} variant={userFrame.darkMode?"inverted":"inverted"}/>
        }
    }

    function viewCartDetails(){
        const u = userFrame
        u. viewCartDetails = !u.viewCartDetails
        dispatch(updateUserFrame(u))
    }

    function calculateTotal(){
        var total = 0
        userFrame.products.forEach(product=>{
            product.offers.forEach(offer=>{
                if(offer.id == product.offer){
                    total=parseInt(total)+parseInt(offer.price)
                }
            })
        })
        return total
    }

    function getCostForOffer(id){
        var total = 0
        userFrame.products.forEach(product=>{
            product.offers.forEach(offer=>{
                if(offer.id == id){
                    total=parseInt(offer.price)
                }
            })
        })
        return total
    }

    return (
    <div >
        <div style={{position:'absolute', top:0, left:0,width:'100%', height:'10%',backgroundColor:userFrame.darkMode?'#54595F':'white', zIndex:17,boxShadow:userFrame.darkMode?'none':'0 4px 2px -2px #9E9E9E' }}>
            <div style={{display:'inline-block', width:'20%', height:'100%', }}>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'100%', width:'100%'}}>
                    <Hamburger color={userFrame.darkMode?'white':'#4B286D'} toggle={exitHasBeenClicked} toggled={userFrame.showPCO}/>
                </div>
            </div>
            <div style={{display:'inline-block', height:'100%', width:'60%', verticalAlign:'top'}}>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'100%', width:'100%',}}>
                <Deals size={24}/>
                <h1 style={{color:userFrame.darkMode?'white':'#4B286D', fontSize:'27px', marginLeft:'10px'}}>Place Order</h1>
                </div>
            </div>
        </div>
        <div style={{position:'absolute', top:'10%', left:0, height:'90%', width:'100%', backgroundColor:userFrame.darkMode?'#54595F':'white', zIndex:16, }}>
            <div style={{width:'100%', height:'100%', display:'block' }}>
                {/* Body */}
                <div style={{width:'100%', height:'100%', overflow:'scroll',}}>
                    {renderProductsCommitmentsOffers()}
                </div>

                {/* Cart Details Footer */}
                {calculateTotal() > 0 &&
                <div style={{position:'absolute', bottom:0, left:0, width:'100%', backgroundColor:'#2A2C2E', zIndex:17, boxShadow: userFrame.darkMode? 'none':'0px 0px 7px 1px rgba(0,0,0,0.3)'}}>
                    <div style={{display:'inline-block', width:'65%', height:'80px',verticalAlign:'top'}}>
                        <div style={{display:'flex', justifyContent:'center',alignItems:'center', width:'100%', height:'100%'}}>    
                            {width>550 && (<>
                                <h1 style={{color:'#D8D8D8', fontWeight:'bold', fontSize:'17px', marginLeft:'15px'}}>1-844-372-8559 toll free</h1>
                                <h1 style={{color:'#D8D8D8', fontWeight:'bold', fontSize:'17px', marginLeft:'15px'}}>|</h1>
                                </>)}
                            <a onClick={()=>{viewCartDetails()}} style={{display:'flex', justifyContent:'center', alignItems:'center',marginLeft:'15px'}}>
                                <h1 style={{color:'#D8D8D8', fontWeight:'bold', fontSize:'17px', marginRight:'5px'}}>Cart details</h1>
                                <img src={!userFrame.viewCartDetails?arrowUpW:arrowDownW} width={'20px'}/>
                            </a>
                            <h1 style={{color:'#D8D8D8', fontWeight:'bold', fontSize:'17px', marginLeft:'15px'}}>|</h1>
                            <h1 style={{color:'#D8D8D8', fontWeight:'bold', fontSize:'27px', marginLeft:'15px'}}>${calculateTotal()}.<span style={{fontSize:'17px', verticalAlign:'super'}}>00</span></h1>
                        </div>
                    </div>

                    <div style={{display:'inline-block', width:'35%', height:'80px', verticalAlign:'top'}}>
                        <div style={{display:'flex', justifyContent:'center',alignItems:'center', width:'100%', height:'100%', }}>
                            <button style={{width:'200px', height:'50px', backgroundColor:'#2B8000', color:'white', fontWeight:'bold', borderRadius:'5px', outline:'none', border:'none',marginRight:'10px' }}>Next</button>
                        </div>
                    </div>

                    {userFrame.viewCartDetails &&
                    <div style={{width:'95%', paddingTop:'20px', paddingBottom:'20px', marginLeft:'2.5%', marginBottom:'20px', borderRadius:'5px', backgroundColor:'#54595F'}}>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%',}}>
                            
                        <h1 style={{color:'white', fontSize:'18px', fontWeight:'bold', marginLeft:'5px',width:'32%'}}>
                            <Gift size={24} variant={"inverted"}/>
                            <span style={{marginLeft:'10px'}}>Product</span>    
                        </h1>

                        <h1 style={{color:'white', fontSize:'18px', fontWeight:'bold',marginLeft:'5px',width:'26%'}}>
                            <Deals size={24} variant={"inverted"}/>
                            <span style={{marginLeft:'10px'}}>Price</span>    
                        </h1>

                        <h1 style={{color:'white', fontSize:'18px', fontWeight:'bold',marginLeft:'5px',width:'20%'}}>
                            <Calendar size={24} variant={"inverted"}/>
                            <span style={{marginLeft:'10px'}}>Term</span>
                        </h1>

                        <h1 style={{color:'white', fontSize:'18px', fontWeight:'bold',marginLeft:'5px',width:'5%'}}>
                            
                        </h1>
                        </div>

                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'90%', paddingLeft:'5%'}}>
                            <HairlineDivider gradient />
                        </div>
                        <div style={{marginBottom:'20px'}}/>

                        {houses[userFrame.houseId-1].offer.map( (o, i) => {
                        return(
                            <div key = {i} style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', marginTop:'15px'}}>

                                <h1 style={{color:'white', fontSize:'18px', marginLeft:'5px', width:'32%'}}>
                                    {renderProductIcon(o.product, true, 24)}
                                    <span style={{marginLeft:'10px'}}>{o.product}</span>     
                                </h1>

                                <h1 style={{color:'white', fontSize:'18px', marginLeft:'5px',width:'26%'}}>
                                {'$ '}{getCostForOffer(o.id)}.<span style={{fontSize:'14px',}}>00 / mo.</span>
                                </h1>

                                <h1 style={{color:'white', fontSize:'18px', marginLeft:'5px',width:'20%' }}>
                                    {o.commitment}{o.commitment!='No term'?<span style={{fontSize:'15px'}}>{' mo.'}</span> :''}
                                </h1>

                                <h1 style={{color:'white', fontSize:'18px', width:'5%', marginLeft:'5px' }}>
                                    <a onClick={()=>removeOffer(o.id)}>
                                        <Subtract size={24} color={"white"}/>
                                    </a>
                                </h1>
                            </div>
                        )})}

                    </div>
                    } 
                </div>}
            </div>
        </div>
    </div>
    );
}

export default ProductsCommitmentsOffers;
