import React, { useEffect, useRef, useState } from "react";

import Card from '@tds/core-card'
import Box from "@tds/core-box";
import Heading from "@tds/core-heading";
import Paragraph from "@tds/core-paragraph";
import Button from "@tds/core-button";

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../../redux/actions/userFrameActions'
import Hamburger from "hamburger-react";

function ProductDetails({}) {

  const userFrame = useSelector(state=>state.user_frame_reducer[0])
  const houses = useSelector(state=>state.house_reducer)
  const dispatch = useDispatch()

  const [clickOutside, setClickOutside] = useState()
  const ref = useRef(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        exit()
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [ref]);

  function exit() {

    const u = userFrame
    u.showProductDetails = false;
    dispatch(updateUserFrame(u))
    
  }

  return (
    <div style={{position:'absolute', left:'0', top:'0', width:'100%', height:'100%', zIndex:100000, backgroundColor:'rgba(125, 125, 125, 0.3)' }}>
      <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'100%',}}>
        <div ref={ref} style={{width:'40%', minHeight: '60%', backgroundColor:userFrame.darkMode?'#2A2C2E':'white', borderRadius:'5px'}}>
            <div style={{display:'block', width:'100%', height:'60px'}}>
              <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'10%', marginLeft:'90%'}}>
                <Hamburger color={userFrame.darkMode?'#D8D8D8':'#4B286D'} toggle={()=>exit()} toggled={userFrame.showProductDetails}/>
              </div>
            </div>

            <div style={{display:'block', width:'100%', height:'60px'}}>
              <h1></h1>
            </div>
        </div>
      </div>
  </div>
  );
}

export default ProductDetails;