import React, { useState } from "react";

import Button from '@tds/core-button'
import Input from '@tds/core-input'
import Box from '@tds/core-box'
import TextButton from '@tds/core-text-button'
import Heading from '@tds/core-heading'

import alpaca from '../../../icons/images/alpaca.png';
import telus from '../../../icons/telus/purple.png';

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../redux/actions/userFrameActions'

function ErrorLogin() {

  const userFrame = useSelector(state=>state.user_frame_reducer[0])
  const houses = useSelector(state=>state.house_reducer)
  const dispatch = useDispatch()

  function handleClick(){

    const frame = userFrame;
    frame.errorLogin = false
    dispatch(updateUserFrame(frame))
    
  }


  return (
    
    <div style={{width:'100%', height:'100%', position:'absolute', top:0, left:0, backgroundColor:'white', zIndex:10}}>

        {/* Header */}
        <div style={{position: 'absolute', top:0, left: 0, height:'15%', width:'100%', boxShadow:'3px 3px 3px 3px #9E9E9E', backgroundColor:'#4B286D', overflowY:'hidden'}}>
            <Box horizontal={7} vertical={2}>
                <img src={telus} width="90px" />
            </Box>
        </div>

        {/* Login Error Form */}
        <div style={{position: 'absolute', top:'20%', left: '25%',height:'60%', width:'50%', boxShadow:'3px 3px 3px 3px #9E9E9E',overflowY:'scroll', backgroundColor:'white', zIndex:10}}>

            <div style={{position: 'absolute', top:'10%', left: '20%',height:'33%', width:'60%', }}>
            <Box between={3}>
                <Heading level={"h2"}>Error:</Heading>
                <Heading level={"h3"}>You must input a valid username and password</Heading>
                <br></br>
                <Button onClick={() => handleClick()}>
                    Try Again
                </Button>
                <br></br>
                <TextButton onClick={() => alert('Support Screen')}>
                    Contact Support
                </TextButton>
            </Box>
            </div>
        </div>

        {/* Row Image */}
        <div style={{ position:'absolute', bottom: '0', right: '0', width:'100%'}}>
            <img src={alpaca} width="300px" style={{position:'absolute', bottom: '0', right: '0', zIndex:-1}}/>
        </div>

    </div>
        
  );
}

export default ErrorLogin;