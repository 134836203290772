import React, { useEffect, useState } from "react";

import stickMan from '../../../../icons/stick-man/solid.png';
import whiteStickMan from '../../../../icons/stick-man/white-solid.png';

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../../redux/actions/userFrameActions'

function StickMan ({id}) {

  const userFrame = useSelector(state=>state.user_frame_reducer[0])
  const houses = useSelector(state=>state.house_reducer)
  const dispatch = useDispatch()

  function reCenter() {
      
  }

return (
      <div style={{cursor: 'pointer',overflow:'visible'}}>
        <a onClick={() => reCenter()}>
        <img src={userFrame.darkMode?whiteStickMan:stickMan} width={'48px'} style={{marginLeft:'-50%', marginTop:'-50%',overflow:'visible'}}/>
        </a> 
      </div>
);
  
};

  export default StickMan;