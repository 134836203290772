import React, { useEffect, useState } from 'react';

import HairlineDivider from '@tds/core-hairline-divider'
import { Clipboard, Delivery } from '@tds/core-decorative-icon';

import ButtonHouse from '../SubComponents/ButtonHouse';
import IconHouse from '../SubComponents/IconHouse';
import ButtonMic from '../SubComponents/ButtonMic';
import HiddenRightSideBar from './HiddenRightSideBar';
import ProductBar from '../SubComponents/ProductBarVariant';
import ButtonPill from '../SubComponents/ButtonPill';
import ButtonRectangle from '../SubComponents/ButtonRectangle';

import { useSelector, useDispatch } from 'react-redux';
import {updateHouse} from '../../../../redux/actions/houseActions'
import {updateUserFrame} from '../../../../redux/actions/userFrameActions'
import FollowUp from '../SubComponents/FollowUp';
import OtherKnocks from '../SubComponents/OtherKnocks';
import ShowDetails from '../SubComponents/ShowDetails';


function RightSideBar({}) {

    const userFrame = useSelector(state=>state.user_frame_reducer[0])
    const houses = useSelector(state=>state.house_reducer)
    const dispatch = useDispatch()

    function notesText(event){

        const h = houses[userFrame.houseId-1]
        h.notes = event.target.value
        dispatch(updateHouse(h))

    }

    function setFollowUpButtonClicked(value){

        const frame = userFrame
        frame.followingUp=value
        dispatch(updateUserFrame(frame))

    }

    function collectDrop(value) {
        alert('Collect Drop Screen')
    }

    function placeOrder(value) {
        const h = houses
        h[userFrame.houseId-1].existingCustomer = !h[userFrame.houseId-1].existingCustomer
        dispatch(updateHouse(h))  
    }

    function setOrderButtonClicked(value) {
        //console.log(value)
    }

    function otherKnocksPopup(){

        if(userFrame.loggingKnock && houses[userFrame.houseId-1].icon != 'DNK'){
        
            return(
            <OtherKnocks/>
            )
        }
            
    }

    function followUpPopup(){

        if(userFrame.followingUp && houses[userFrame.houseId-1].icon != 'DNK'){
        
            return(
            <FollowUp/>
            )
        }
        
    }

    function disablePremise() {
        if(houses[userFrame.houseId-1].icon == 'DNK'){
            return(
                <div style={{width:'100%', height:'100%'}}>                
                    <div style={{position:'absolute', left:'0', top:'0', width:'100%', height:'100%', zIndex:10, backgroundColor:'rgba(125, 125, 125, 0.3)', boxShadow:userFrame.darkMode?'none':`0 0 10px 10px rgba(125,125,125,0.7)`  }}>
                        <div style={{marginTop:'35%', width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <h1 style={{color:userFrame.darkMode?'white':'#C12335', fontSize:'30px', fontWeight:'bold', textAlign:'center', textShadow:'0px 0px 15px #FFF'}}>Do Not Knock</h1>
                        </div>
                    </div>
                </div>

            )
        }
    }

    function showDetailsPopup(){

        if(userFrame.showDetails && houses[userFrame.houseId-1].icon != 'DNK'){
            return(
                <ShowDetails/>
            )
        }
    }

    function showDetails() {
        const u = userFrame;
        u.showDetails = true;
        dispatch(updateUserFrame(u))
    }

    function captureLead(value) {
        alert('Capture Lead Screen')
    }
    

  return (

    <div>
        {disablePremise()}
        {otherKnocksPopup()}
        {followUpPopup()}
        {showDetailsPopup()}
        
        {/* Details Side Card - drawer clicked */}
        <>
        <div style={{position:'absolute', top: '0', left: '0', height:'100%', width:'50%',backgroundColor:userFrame.darkMode?'#2A2C2E':'white', overflowY:'scroll',}}>
            <div style={{display:'flex',alignItems:'center', justifyContent:'center', height:'100%', width:'100%'}}>
            <div style={{width:'100%', }}>
            

            <div style={{ height:'95px', width:'100%',backgroundColor:userFrame.darkMode?'#2A2C2E':'white', display: 'block', overflowY:'hidden'}}>
                    <a onClick={()=>showDetails()}>
                        <div style={{ float:'left', marginTop:'25px', marginLeft:'11%', width:'50px', backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}>
                            <IconHouse icon={houses[userFrame.houseId-1].icon}/>
                        </div> 
                        <div style={{float:'left', marginTop: '-5px',  marginLeft:'2px', width:'60%',backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}>
                            <h1 style={{color:userFrame.darkMode?'white':'#4B286D', fontWeight:'bold'}}>{houses[userFrame.houseId-1].address}&nbsp;{">"}</h1>
                            <h1 style={{color:userFrame.darkMode?'white':'#2B8000', fontSize:'12px',overflow:'hidden',textOverflow:'ellipsis', whiteSpace:'nowrap',}}>
                            {houses[userFrame.houseId-1].westernPremise==true ? 
                                    <>
                                    {houses[userFrame.houseId-1].copperAvail == false ? "" : (houses[userFrame.houseId-1].copperCurrent == false ? <span style={{color:userFrame.darkMode?'white':'#FFA500'}}>Copper Eligible</span> : "Existing Copper,")}
                                    {houses[userFrame.houseId-1].copperAvail == false && houses[userFrame.houseId-1].fibreAvail == false? (<span style={{color:userFrame.darkMode?'white':'#C12335'}}>Copper Not Available,</span>):""}
                                    {houses[userFrame.houseId-1].fibreAvail == false ? (<span style={{color:userFrame.darkMode?'white':'#C12335'}}>&nbsp;Fibre Not Available</span>) : (houses[userFrame.houseId-1].fibreCurrent == false ? <span style={{color:userFrame.darkMode?'white':'#FFA500'}}>&nbsp;Fibre Eligible</span> : " Fibre")}
                                    {houses[userFrame.houseId-1].dropRequired == true ? <span style={{color:userFrame.darkMode?'white':'#FFA500'}}>&nbsp;- Drop Required</span>:""}
                                    {houses[userFrame.houseId-1].selfInstall == true ? <span style={{color:userFrame.darkMode?'white':'#FFA500'}}>&nbsp;- FFH Self Install</span>:""}
                                    </>
                                :
                                    <>
                                    {Math.floor(Math.random() * 10) > 4 ? '5G':'LTE'}
                                    </>}
                            </h1>
                            <h1 style={{color:userFrame.darkMode?'white':'#2B8000', fontSize:'12px',overflow:'hidden',textOverflow:'ellipsis', whiteSpace:'nowrap',}}>
                                    Next available due date -
                                    {" "+houses[userFrame.houseId-1].dueDate} 
                            </h1>
                        </div>
                    </a>
            </div> 

            <div style={{ width: '80%', height:'5px', display:'block', marginLeft: '10%',backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}>
                <HairlineDivider gradient />
            </div>

            <div style={{height:'10px', width:'100%', display:'block', backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}></div>    
            
            <div style={{ display:'block',height:'155px',marginLeft: '10%', width:'90%',backgroundColor:userFrame.darkMode?'#2A2C2E':'white', display: 'block', overflowY:'hidden'}}>  
                <ProductBar />
            </div>
            
            <div style={{height:'20px', width:'100%', display:'block', backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}></div>

            <div style={{ width: '80%', height:'5px', display:'block', marginLeft: '10%',backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}>
                <HairlineDivider gradient />
            </div>
 
            <div style={{height:'10px', width:'100%', display:'block', backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}></div>

            <div style={{display:'block', height:'140px', width:'100%',backgroundColor:userFrame.darkMode?'#2A2C2E':'white', overflowY:'hidden', }}>
        
                <h1 style={{display:'block',marginLeft: '10%', width:'90%', color:userFrame.darkMode?'white':'black', fontWeight:'bold', zIndex:10}}>Knocks</h1>
            
                <div style={{ zIndex:10,marginLeft: '10%',}}>                
                    <h1 style={{ fontSize: '12px', color:userFrame.darkMode?'white':'black', display:'inline-block'}}>Last Knock: </h1>
                    <h1 style={{ fontSize: '12px', color:userFrame.darkMode?'white':'#2B8000', display:'inline-block'}}>&nbsp;{houses[userFrame.houseId-1].knockHistory[houses[userFrame.houseId-1].knockHistory.length - 1].value}, </h1>
                    <h1 style={{ fontSize: '12px', color:userFrame.darkMode?'white':'#4B286D', display:'inline-block'}}>&nbsp;{houses[userFrame.houseId-1].knockHistory[houses[userFrame.houseId-1].knockHistory.length - 1].date}&nbsp;{houses[userFrame.houseId-1].knockHistory[houses[userFrame.houseId-1].knockHistory.length - 1].time} </h1>
                </div>

                <div style={{ height:'90px', width:'85%', marginLeft:'7%',  marginTop:'5px', backgroundColor:userFrame.darkMode?'#2A2C2E':'white', overflowY:'hidden'}}>
                    
                    <div style={{width:'100%', height:'100%',display:'inline-block', backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}>  
                       
                        <div style={{width:'100%', height:'100%', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                            <div style={{width:'16.5%',}}>
                                <div>
                                <ButtonHouse title = {"No One Home"} icon = {'NOH'}  />
                                </div>
                            </div> 
                            <div style={{width:'16.5%',}}>
                                <div>
                                <ButtonHouse title = {"Soft No"} icon = {'SN'} extra={true}  />
                                </div>
                            </div>
                            <div style={{width:'16.5%',}}>
                                <div>
                                <ButtonHouse title = {"Hard No"} icon = {'HN'} extra={true} />
                                </div>
                            </div> 
                            <div style={{width:'16.5%',}}>
                                <div>
                                <ButtonHouse title = {"Wrong Party"} icon = {'WP'} extra={true}  />
                                </div>
                            </div>
                            <div style={{width:'16.5%',}}>
                                <div>
                                <ButtonHouse title = {"Not Interested"} icon = {'NI'} extra={true}  />
                                </div>
                            </div> 
                            <div style={{width:'16.5%',}}>   
                                <div>
                                <ButtonHouse title = {"Other Knocks"}  icon = {'OK'} extra={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{height:'10px', width:'100%', display:'block', backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}></div>

            <div style={{ width: '80%', height:'5px', display:'block', marginLeft: '10%',backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}>
                <HairlineDivider gradient />
            </div>

            <div style={{height:'10px', width:'100%', display:'block', backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}></div>

            <div style={{display:'block', height:'85px',backgroundColor:userFrame.darkMode?'#2A2C2E':'white',  width:'100%', paddingLeft:'10%', overflow:'visible'}}>
                <label style={{width:'100%', display:'inline-block', color:userFrame.darkMode?'white':'black', fontWeight:'bold', overflow:'visible'}}>
                    Notes
                    <br></br>
                    <div style={{width:'76%', display:'inline-block',}}>
                        <textarea value={houses[userFrame.houseId-1].notes} onChange={notesText} 
                        style={{ width:'99%',height:'60px', resize:'none', outlineWidth:'1px', borderRadius:'5px',
                         borderColor:userFrame.darkMode?'#2A2C2E':'#4B286D', color:userFrame.darkMode?'white':'#4B286D', 
                         outlineColor:userFrame.darkMode?'#2A2C2E':'#4B286D', backgroundColor:userFrame.darkMode?'#54595F':'white', 
                         fontSize:'12px', fontWeight:'400', padding:'17px', outlineStyle:'none',}}/>
                    </div>
                    <div style={{width:'10%', display:'inline-block', overflow:'visible'}}>     
                        <ButtonMic/>
                    </div>
                </label>
            </div>

            <div style={{height:'10px', width:'100%', display:'block', backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}></div>
            
            {/*<div style={{ width: '80%', height:'5px', display:'block', marginLeft: '10%',backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}>
                <HairlineDivider gradient />
            </div>*/}

            <div style={{height:'10px', width:'100%', display:'block', backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}></div>


            <div style={{ display:'block',height:'50px', width:'100%',backgroundColor:userFrame.darkMode?'#2A2C2E':'white', display: 'block', overflowY:'hidden'}}>  
                <div style={{marginLeft:'10%'}}>
                    <button
                        style={{ 
                        paddingTop: '7px', paddingBottom: '7px', paddingLeft: '45px', paddingRight: '45px',
                        color: 'white', fontWeight: 'bold', borderColor:userFrame.darkMode?'#54595F':'#2B8000', cursor:'pointer',
                        borderRadius:'5px', borderWidth: '1px', width:'90%',
                        borderStyle: 'solid', backgroundColor: userFrame.darkMode?'#54595F':'#2B8000' }}
                        onClick={() => captureLead(true)}
                    >
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', height:'100%'}}>
                            <Clipboard variant={'inverted'} size={32}/>
                            <h1 style={{marginLeft:'20px'}}>Capture Lead</h1>
                        </div>
                    </button>
                    {/*<ButtonRectangle text='Place Order' color={userFrame.darkMode?'#D8D8D8':'#2B8000'} onButtonClicked = {placeOrder}/>*/}
                </div>  
            </div>

            <div style={{height:'10px', width:'100%', display:'block', backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}></div>


            <div style={{ display:'block',height:'50px', width:'100%',backgroundColor:userFrame.darkMode?'#2A2C2E':'white', display: 'block', overflowY:'hidden'}}>  
                <div style={{marginLeft:'10%'}}>
                    <button
                        style={{ 
                        paddingTop: '7px', paddingBottom: '7px', paddingLeft: '45px', paddingRight: '45px',
                        color: userFrame.darkMode? '#54595F':'white', fontWeight: 'bold', borderColor:userFrame.darkMode?'#D8D8D8':'#4B286D', cursor:'pointer',
                        borderRadius:'5px', borderWidth: '1px', width:'90%', 
                        borderStyle: 'solid', backgroundColor: userFrame.darkMode?'#D8D8D8':'#4B286D' }}
                        onClick={() => collectDrop(true)}
                      >
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', height:'100%'}}>
                          <Delivery variant={userFrame.darkMode?'alternative':'inverted'} size={32}/>
                          <h1 style={{marginLeft:'20px'}}>Collect Drop</h1>
                        </div>
                      </button>
                    {/*<ButtonRectangle text='Collect Drop' color={userFrame.darkMode?'#54595F':'#4B286D'} onButtonClicked = {collectDrop}/>*/}
                </div>  
            </div>



            </div>
            </div>
        </div>

        {/*  Divider border:'1px solid #4B286D', */}
        <div style={{ width: '1%', height: '100%', position:'absolute', top:'0', left:'50%', backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}>
            <div style={{ width: '1px', height: '50%', position:'absolute', top:'0', left:'50%', border:userFrame.darkMode?'1px solid white':'1px solid #4B286D', borderImage:userFrame.darkMode?'linear-gradient(to top, white, #2A2C2E) 1 90%':'linear-gradient(to top, #4B286D, white) 1 90%',}}>
            </div>
            <div style={{ width: '1px', height: '50%', position:'absolute', bottom:'0', left:'50%', border:userFrame.darkMode?'1px solid white':'1px solid #4B286D', borderImage:userFrame.darkMode?'linear-gradient(to bottom, white, #2A2C2E) 1 90%':'linear-gradient(to bottom, #4B286D, white) 1 90%',}}>
            </div>
        </div>
       
        <div style={{position:'absolute', top: '0', left: '51%' ,height:'100%', width:'48%',backgroundColor:userFrame.darkMode?'#2A2C2E':'white'}}>
            
            <HiddenRightSideBar setOrderButtonClicked={setOrderButtonClicked} setFollowUpButtonClicked={setFollowUpButtonClicked}/>
            
        </div>
        </>
        
    </div>
    
  );
}

export default RightSideBar;

